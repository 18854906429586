import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { NewInsuranceClaimReportDataTable } from '@behavehealth/constructs/LiveReports/NewInsuranceClaimReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const NewInsuranceClaims = () => {
  const tableProps = useDataTable({
    name: ['reports', 'new-insurance-claims'],
    endpoint: `/live_reports?category=new_insurance_claims`,
    localStorageKey: 'report_new_insurance_claims_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.client)}/insurance`, [])

  return (
    <Page icon="insurance" title="Live Insurance Claim Report">
      <Grid>
        <NewInsuranceClaimReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(NewInsuranceClaims)
