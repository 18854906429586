import React from 'react'
import size from 'lodash/size'
import isUndefined from 'lodash/isUndefined'
import kebabCase from 'lodash/kebabCase'
import produce from 'immer'
import { DateTime } from 'luxon'

import { usDateTime, isDefined, arrayToMapWithKey } from '../../utils/functions'
import { request } from '../../modules/axios'
import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { useStore } from './useStore'

import Button from '../Button'

type Props = {
  url: string
}

export const DataTableExportCSVButton = ({ url, params }: Props) => {
  const title: any = useStore((state: any) => state.title) || ''
  const columnIds: any = useStore((state: any) => state.columnIds) || []
  const hiddenColumnIds: any = useStore((state: any) => state.hiddenColumnIds) || []
  const filters: any = useStore((state: any) => state.filters)
  const sorting: any = useStore((state: any) => state.sorting)
  const currentPage: any = useStore((state: any) => state.currentPage)
  const pageSize: any = useStore((state: any) => state.pageSize)

  const validFilters = React.useMemo(() => {
    const result: any = {}

    if (!filters) return result

    return produce(filters, (draft: any) => {
      for (const key in draft) {
        const value = draft[key]?.value

        // boolean filters are always valid
        if (typeof value === 'boolean') continue

        // remove all invalid filters
        if (isUndefined(key)) {
          delete draft[key]
          continue
        }

        if (typeof value === 'string' && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (Array.isArray(value) && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (!isDefined(value)) {
          delete draft[key]
          continue
        }
      }
    })
  }, [filters])

  const { timezone, isBehave } = useSettings()

  const [loading, setLoading] = React.useState(false)

  const columns = React.useMemo(() => {
    const res: any = []

    for (const columnId of columnIds) {
      if (hiddenColumnIds.includes(columnId)) continue

      res.push(columnId)
    }

    return res
  }, [columnIds, hiddenColumnIds])

  const { mutateAsync: exportAsync, isLoading: isExporting } = useCreate({
    name: ['export_csv'],
    url,
  })

  if (!isBehave) return null

  if (!url || size(columns) === 0) return null

  return (
    <Button
      type="default"
      display="inline-flex"
      glyph="database_export"
      label="Export CSV"
      isLoading={loading}
      size={100}
      onClick={async () => {
        setLoading(true)

        try {
          const response = await request.post(
            url,
            {
              columns,
              filters: btoa(JSON.stringify({ filters: arrayToMapWithKey(validFilters, 'key') })),
              sorting: btoa(JSON.stringify({ sorting })),
              page: currentPage,
              items: pageSize,
              ...params,
            },
            {
              responseType: 'blob',
            },
          )

          const blob = await response.data
          const filename = title && typeof title === 'string' ? kebabCase(title) + '-report' : 'data-export'
          const exportDate = usDateTime(DateTime.local().setZone(timezone)).replaceAll(' ', '').replaceAll(',', '-').replaceAll(':', '_')

          const downloadURL = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadURL
          a.download = `${filename}-${exportDate}.csv`
          document.body.appendChild(a)
          a.click()
          a.remove()
        } catch (e) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      }}
      isLoading={isExporting}
      className="leading-normal"
    />
  )
}
