import React from 'react'

import { apiGet } from '@behavehealth/modules/api'

import Card from '@behavehealth/components/Card'
import ChartCard from '@behavehealth/components/ChartCard'
import State from '@behavehealth/components/State'

import { AlumniChart, ClientDischargeTypeChart } from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const AlumniAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/admissions',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading || Object.keys(stats).length === 0) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  } else {
    return (
      <div css={styles.grid}>
        <ChartCard title="Client Discharge Type *" subtitle="How many clients have we had by discharge type?">
          <ClientDischargeTypeChart timeframe={timeframe} renderer={renderer} />
        </ChartCard>

        <ChartCard
          title="Past Client *"
          subtitle="How many alumni do we have? How many alumni have we discharged? How many alumni have we readmitted?"
        >
          <AlumniChart timeframe={timeframe} renderer={renderer} />
        </ChartCard>
      </div>
    )
  }
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default AlumniAnalytics
