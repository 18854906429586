import React from 'react'
import { Helmet } from 'react-helmet'
import { parse } from 'query-string'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Outlet, Navigate, Routes, Route as RouteV6 } from 'react-router-dom-v5-compat'
import IdleTimer from 'react-idle-timer'
import isUndefined from 'lodash/isUndefined'

import { store } from '@behavehealth/setup/store'
import Analytics from '@behavehealth/modules/analytics'
import withHotkeys from '@behavehealth/hocs/withHotkeys'

import { initialize, setup } from '../../modules/init'

import {
  AnimatedSwitch,
  AppLoader,
  AppNavItemSearch,
  AppSidebar,
  IdleSaver,
  NotificationsContainer,
  PortalsContainer,
  UploadTracker,
} from '@behavehealth/components'

import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'

// Financials
import EditPayers from '@behavehealth/components/Overlays/pages/Financials/EditPayers'
import PayerOverlay from '@behavehealth/components/Overlays/pages/Financials/PayerOverlay'
import PaymentMethods from '@behavehealth/components/Overlays/pages/Financials/PaymentMethods'
import SendFinancialPlanOverlay from '@behavehealth/components/Overlays/pages/Financials/SendFinancialPlanOverlay'

import AccessListImportOverlay from '@behavehealth/components/Overlays/pages/AccessListImportOverlay'
import AddressBookOverlay from '@behavehealth/components/Overlays/pages/AddressBookOverlay'
import AssignedEmployeesImportOverlay from '@behavehealth/components/Overlays/pages/AssignedEmployeesImportOverlay'
import BillingRosterOverlay from '@behavehealth/components/Overlays/pages/Billing/BillingRosterOverlay'
import ClientCashFunds from '@behavehealth/components/Overlays/pages/ClientCashFunds'
import ClientCashRefund from '@behavehealth/components/Overlays/pages/ClientCashRefund'
import ClientsMessageBlastOverlay from '@behavehealth/components/Overlays/pages/ClientsMessageBlastOverlay'
import CompanyMessageBlastOverlay from '@behavehealth/components/Overlays/pages/CompanyMessageBlastOverlay'
import DischargeReminderFormOverlay from '@behavehealth/components/Overlays/pages/DataForms/OutcomeMeasures/DischargeReminderFormOverlay'
import EventBuilderOverlay from '@behavehealth/components/Overlays/pages/Calendar/EventBuilderOverlay'
import EventOverlay from '@behavehealth/components/Overlays/pages/Calendar/EventOverlay'
import File from '@behavehealth/components/Overlays/pages/File'
import FollowUpFormOverlay from '@behavehealth/components/Overlays/pages/DataForms/OutcomeMeasures/FollowUpFormOverlay'
import ImportAgreementsOverlay from '@behavehealth/components/Overlays/pages/Agreements/ImportAgreementsOverlay'
import InitialPainScaleAssessment from '@behavehealth/components/Overlays/pages/DataForms/InitialPainScaleAssessment'
import InsuranceAuthorization from '@behavehealth/components/Overlays/pages/DataForms/InsuranceAuthorization'
import InsuranceServiceOverlay from '@behavehealth/components/Overlays/pages/InsuranceServiceOverlay'
import IntakeForm from '@behavehealth/components/Overlays/pages/DataForms/IntakeForm'
import IntakeFormOverlay from '@behavehealth/components/Overlays/pages/DataForms/OutcomeMeasures/IntakeFormOverlay'
import List from '@behavehealth/components/Overlays/pages/List'
import LocatorFormOverlay from '@behavehealth/components/Overlays/pages/DataForms/OutcomeMeasures/LocatorFormOverlay'
import Message from '@behavehealth/components/Overlays/pages/Message'
import PayerAuthorizationOverlay from '@behavehealth/components/Overlays/pages/PayerAuthorizationOverlay'
import PayerFacilityContactOverlay from '@behavehealth/components/Overlays/pages/PayerFacilityContactOverlay'
import PayerPublicContactOverlay from '@behavehealth/components/Overlays/pages/PayerPublicContactOverlay'
import PayerRuleOverlay from '@behavehealth/components/Overlays/pages/PayerRuleOverlay'
import PhoneBookOverlay from '@behavehealth/components/Overlays/pages/PhoneBookOverlay'
import ProviderOverlay from '@behavehealth/components/Overlays/pages/ProviderOverlay'
import RecordMessage from '@behavehealth/components/Overlays/pages/RecordMessage'
import SendAgreementsOverlay from '@behavehealth/components/Overlays/pages/Agreements/SendAgreementsOverlay'
import ServiceSummaryFormOverlay from '@behavehealth/components/Overlays/pages/DataForms/OutcomeMeasures/ServiceSummaryFormOverlay'
import SoberLivingHomeAgreement from '@behavehealth/components/Overlays/pages/DataForms/SoberLivingHomeAgreement'
import TodoBuilderOverlay from '@behavehealth/components/Overlays/pages/Calendar/TodoBuilderOverlay'
import TodoOverlay from '@behavehealth/components/Overlays/pages/Calendar/TodoOverlay'

import { StaffAllowedIPsOverlay } from '@behavehealth/constructs/StaffIPRestrictions/StaffAllowedIPsOverlay'
import { StaffCredentialOverlay } from '@behavehealth/constructs/StaffCredentials/StaffCredentialOverlay'
import { EVOBOverlay } from '@behavehealth/constructs/EVOB/EVOBOverlay'
import { FullVOBSummaryOverlay } from '@behavehealth/constructs/FullVOB/FullVOBSummaryOverlay'
import { DiagnosisOverlay } from '@behavehealth/constructs/Diagnoses/DiagnosisOverlay'

import { AllergyOverlay } from '@behavehealth/constructs/Allergies/AllergyOverlay'
import { IntakeApplicationOverlay } from '@behavehealth/constructs/IntakeApplications/IntakeApplicationOverlay'

// Bed Management New
import { BedOccupancyOverlay } from '@behavehealth/constructs/BedManagement/BedOccupancyOverlay'
import { LocationBedOverlay } from '@behavehealth/constructs/Locations/LocationBedOverlay'
import { LocationFloorOverlay } from '@behavehealth/constructs/Locations/LocationFloorOverlay'
import { LocationRoomOverlay } from '@behavehealth/constructs/Locations/LocationRoomOverlay'
import { MoveBedsOverlay } from '@behavehealth/constructs/BedManagement/MoveBedsOverlay'

// Programs New
import { ProgramAssignmentOverlay } from '@behavehealth/constructs/Programs/ProgramAssignmentOverlay'
import { ProgramMoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramMoveOverlay'
import { ProgramRemoveOverlay } from '@behavehealth/constructs/ClientWorkflows/ProgramRemoveOverlay'

import { NewClientOverlay } from '@behavehealth/constructs/Clients/NewClientOverlay'
import { NewAlumniOverlay } from '@behavehealth/constructs/Clients/NewAlumniOverlay'
import { NewPayerOverlay } from '@behavehealth/constructs/Payers/NewPayerOverlay'
import NewAddress from '@behavehealth/components/Overlays/new/NewAddress'
import NewPhone from '@behavehealth/components/Overlays/new/NewPhone'
import NewRule from '@behavehealth/components/Overlays/new/NewRule'
import NewPublicContact from '@behavehealth/components/Overlays/new/NewPublicContact'
import NewFacilityContact from '@behavehealth/components/Overlays/new/NewFacilityContact'

import VOBOverlay from '@behavehealth/constructs/RCM/VOBOverlay'
import VOBArchivedOverlay from '@behavehealth/components/Overlays/pages/VOBOverlay'

import UpdateLevelOfCare from '@behavehealth/components/Overlays/actions/UpdateLevelOfCare'

import { AcceptForAdmission } from '@behavehealth/constructs/ClientWorkflows/AcceptForAdmission'
import { AdmissionDecline } from '@behavehealth/constructs/ClientWorkflows/AdmissionDecline'
import { IntakeAdmit } from '@behavehealth/constructs/ClientWorkflows/IntakeAdmit'
import { IntakeClose } from '@behavehealth/constructs/ClientWorkflows/IntakeClose'
import { IntakeDischarge } from '@behavehealth/constructs/ClientWorkflows/IntakeDischarge'
import { IntakeEdit } from '@behavehealth/constructs/ClientWorkflows/IntakeEdit'
import { IntakeReadmit } from '@behavehealth/constructs/ClientWorkflows/IntakeReadmit'

import HelpOverlay from '@behavehealth/components/Help/HelpOverlay'
import { SearchOverlay } from '@behavehealth/constructs/Search/SearchOverlay'
import KitchenSync from '../kitchen_sync'

// Chat
import CompanyChat from '../company_chat/chat'
import ClientsChat from '../clients_chat/chat'

import BridgeProfile from '../../components/BridgeProfile/BridgeProfile'

import BedManagement from '../bed_management/bed_management'
import Dashboard from '../dashboard/dashboard'
import ErrorTest from '../dashboard/error_test.tsx'
import Updates from '../dashboard/updates'
import FinancialHub from '../financial_hub/financial_hub'
import Marketing from '../marketing/marketing'
import Files from '../files/files'
import Issues from '../issues/issues'
import ProductTour from '../product_tour/product_tour'
import Programs from '../programs/programs'
import RCMReportsFiles from '../rcm/reports_files'
import Rosters from '../rosters/rosters'
import User from '../user/user'

import Bridge from '../bridge/bridge'

import Admissions from '../clients/admissions'
import Clients from '../clients/clients'
import Alumni from '../clients/alumni'
import Billed from '../clients/billed'

import Employees from '../employees/employees'
import Properties from '../properties/properties'
import Places from '../places/places'

// RCM
import InsurancePayers from '../rcm/local_payers/insurance_payers.tsx'
import InsuranceERAReports from '../rcm/insurance_eras'

import { COLORS } from '@behavehealth/theme'
import { setIdle } from '@behavehealth/actions/common'
import { Cheatsheet } from '@behavehealth/components/Cheatsheet'
import { SessionTimerPopup } from '@behavehealth/components/SessionTimerPopup'
import { TrialTimerToast } from '@behavehealth/components/TrialTimerToast'

// const Admission = React.lazy(() => import('../clients/admission'))
// const Client = React.lazy(() => import('../clients/client'))
// const Employee = React.lazy(() => import('../employees/employee'))
// const Property = React.lazy(() => import('../properties/property'))
// const Office = React.lazy(() => import('../offices/office'))
// const Organization = React.lazy(() => import('../organizations/organization'))
// const Settings = React.lazy(() => import('../settings/settings'))

import Admission from '../clients/admission'
import Alumnus from '../clients/alumnus'
import Client from '../clients/client'
import CustomPages from '../custom_pages/custom_pages'
import Employee from '../employees/employee'
import Payer from '../rcm/local_payers/local_payer'
import Program from '../programs/program'
import Property from '../properties/property'
import Search from '../search/search'
import Settings from '../settings/settings'
import OnboardingChecklist from '../onboarding_checklist/onboarding_checklist'

import { ClientCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/ClientCustomNoteOverlay'
import { StaffCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/StaffCustomNoteOverlay'
import { OrganizationCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/OrganizationCustomNoteOverlay'
import { AdminHRCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/AdminHRCustomNoteOverlay'
import { GrievanceCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/GrievanceCustomNoteOverlay'

import LocalPayersImportOverlay from '@behavehealth/components/Overlays/pages/Billing/LocalPayersImportOverlay'

import FloorOverlay from '@behavehealth/components/Overlays/pages/FloorOverlay'

import AnalyticsPage from '../analytics/analytics'
import AnalyticsExamples from '../analytics/examples'

import SettingsNavItems from '@behavehealth/components/Navs/SettingsNavItems'

import { MeetingContextProvider } from '@behavehealth/components/Meeting/MeetingContextProvider'
import { MeetingOverlay } from '@behavehealth/components/Meeting/MeetingOverlay'

// RCM
import { ClaimNoteOverlay } from '@behavehealth/components/RCM/ClaimNoteOverlay'
import { ClaimTaskOverlay } from '@behavehealth/components/RCM/ClaimTaskOverlay'
import ClaimClientsPaymentsBuilderOverlay from '@behavehealth/components/RCM/ClaimClientsPaymentsBuilderOverlay'
import ClaimPaymentOverlay from '@behavehealth/components/RCM/ClaimPaymentOverlay'

import { ClaimPaymentsBuilderOverlay } from '@behavehealth/constructs/Insurance/ClaimPaymentsBuilderOverlay'
import { ClaimOverlay } from '@behavehealth/constructs/Insurance/ClaimOverlay'

// RCM – UR
import ClinicalAppealOverlay from '@behavehealth/components/RCM/utilization_review/ClinicalAppealOverlay'
import ConcurrentReviewOverlay from '@behavehealth/components/RCM/utilization_review/ConcurrentReviewOverlay'
import DischargePlanningOverlay from '@behavehealth/components/RCM/utilization_review/DischargePlanningOverlay'
import DocumentationReviewOverlay from '@behavehealth/components/RCM/utilization_review/DocumentationReviewOverlay'
import InitialAuthorizationOverlay from '@behavehealth/components/RCM/utilization_review/InitialAuthorizationOverlay'
import PreCertificationOverlay from '@behavehealth/components/RCM/utilization_review/PreCertificationOverlay'
import InsuranceERAReportOverlay from '@behavehealth/components/RCM/InsuranceERAReportOverlay'

import PayerInsuranceCodeImportOverlay from '@behavehealth/components/Overlays/pages/PayerInsuranceCodeImportOverlay'
import PayerInsuranceCodeOverlay from '@behavehealth/components/Overlays/pages/PayerInsuranceCodeOverlay'

import KitchenSink from '../kitchen_sync'

import { setTenantPrefix } from '@behavehealth/hooks/useNewAPI'

import { FileOverlay } from '@behavehealth/constructs/Overlays/FileOverlay'
import { FilePreviewOverlay } from '@behavehealth/constructs/Overlays/FilePreviewOverlay'

import { useSearchStore } from '@behavehealth/constructs/Search/store'
import useStore from '@behavehealth/modules/store'

// Data Form Overlays
import { HealthcareDischargeSummaryOverlay } from '@behavehealth/constructs/DischargeSummary/HealthcareDischargeSummaryOverlay'
import { NonClinicalDischargeSummaryOverlay } from '@behavehealth/constructs/DischargeSummary/NonClinicalDischargeSummaryOverlay'
import { DischargeSummaryOverlay } from '@behavehealth/constructs/DischargeSummary/DischargeSummaryOverlay'
import { ProgressReviewOverlay } from '@behavehealth/constructs/ProgressReviews/ProgressReviewOverlay'

// Treatment Plan
import { TreatmentPlanOverlayV1 } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanOverlayV1'
import { TreatmentPlanOverlayV2 } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanOverlayV2'

// Medical Assessments
import { ClientHealthQuestionnaireOverlay } from '@behavehealth/constructs/MedicalAssessments/ClientHealthQuestionnaireOverlay'
import { ClientHealthQuestionnaireInitialScreeningOverlay } from '@behavehealth/constructs/MedicalAssessments/ClientHealthQuestionnaireInitialScreeningOverlay'
import { HistoryAndPhysicalFollowUpOverlay } from '@behavehealth/constructs/MedicalAssessments/HistoryAndPhysicalFollowUpOverlay'
import { HistoryAndPhysicalOverlay } from '@behavehealth/constructs/MedicalAssessments/HistoryAndPhysicalOverlay'
import { PsychiatricAssessmentOverlay } from '@behavehealth/constructs/MedicalAssessments/PsychiatricAssessmentOverlay'

// Clinical Assessments
import { ASAMCriteriaOverlay } from '@behavehealth/constructs/ClinicalAssessments/ASAMCriteriaOverlay'
import { BiopsychosocialOverlayV1 } from '@behavehealth/constructs/ClinicalAssessments/BiopsychosocialOverlayV1'
import { BiopsychosocialOverlayV2 } from '@behavehealth/constructs/ClinicalAssessments/BiopsychosocialOverlayV2'
import { EatingDisorderBPSOverlay } from '@behavehealth/constructs/ClinicalAssessments/EatingDisorderBPSOverlay'
import { MentalHealthPreScreenOverlay } from '@behavehealth/constructs/ClinicalAssessments/MentalHealthPreScreenOverlay'
import { MentalStatusExamOverlay } from '@behavehealth/constructs/ClinicalAssessments/MentalStatusExamOverlay'
import { PreScreenOverlayV1 } from '@behavehealth/constructs/ClinicalAssessments/PreScreenOverlayV1'
import { PreScreenOverlayV2 } from '@behavehealth/constructs/ClinicalAssessments/PreScreenOverlayV2'
import { SuicideAssessmentOverlay } from '@behavehealth/constructs/ClinicalAssessments/SuicideAssessmentOverlay'

// Clinical Measures
import { ACEScoreOverlay } from '@behavehealth/constructs/ClinicalMeasures/ACEScoreOverlay'
import { ASI5Overlay } from '@behavehealth/constructs/ClinicalMeasures/ASI5Overlay'
import { ASILiteOverlay } from '@behavehealth/constructs/ClinicalMeasures/ASILiteOverlay'
import { ASRMOverlay } from '@behavehealth/constructs/ClinicalMeasures/ASRMOverlay'
import { AUDITCOverlay } from '@behavehealth/constructs/ClinicalMeasures/AUDITCOverlay'
import { BAMOverlay } from '@behavehealth/constructs/ClinicalMeasures/BAMOverlay'
import { BAMIOPOverlay } from '@behavehealth/constructs/ClinicalMeasures/BAMIOPOverlay'
import { BAMROverlay } from '@behavehealth/constructs/ClinicalMeasures/BAMROverlay'
import { BARC10Overlay } from '@behavehealth/constructs/ClinicalMeasures/BARC10Overlay'
import { CLSOverlay } from '@behavehealth/constructs/ClinicalMeasures/CLSOverlay'
import { CPSSVSROverlay } from '@behavehealth/constructs/ClinicalMeasures/CPSSVSROverlay'
import { DAST10Overlay } from '@behavehealth/constructs/ClinicalMeasures/DAST10Overlay'
import { DimensionsOfWellnessOverlay } from '@behavehealth/constructs/ClinicalMeasures/DimensionsOfWellnessOverlay'
import { EAT26Overlay } from '@behavehealth/constructs/ClinicalMeasures/EAT26Overlay'
import { EDEQ6Overlay } from '@behavehealth/constructs/ClinicalMeasures/EDEQ6Overlay'
import { GAD2Overlay } from '@behavehealth/constructs/ClinicalMeasures/GAD2Overlay'
import { GAD7Overlay } from '@behavehealth/constructs/ClinicalMeasures/GAD7Overlay'
import { MDIOverlay } from '@behavehealth/constructs/ClinicalMeasures/MDIOverlay'
import { MSCSBOverlay } from '@behavehealth/constructs/ClinicalMeasures/MSCSBOverlay'
import { PCLCOverlay } from '@behavehealth/constructs/ClinicalMeasures/PCLCOverlay'
import { PDSSOverlay } from '@behavehealth/constructs/ClinicalMeasures/PDSSOverlay'
import { PHQ15Overlay } from '@behavehealth/constructs/ClinicalMeasures/PHQ15Overlay'
import { PHQ2Overlay } from '@behavehealth/constructs/ClinicalMeasures/PHQ2Overlay'
import { PHQ9Overlay } from '@behavehealth/constructs/ClinicalMeasures/PHQ9Overlay'
import { PHQ9TeensOverlay } from '@behavehealth/constructs/ClinicalMeasures/PHQ9TeensOverlay'
import { PROQOLOverlay } from '@behavehealth/constructs/ClinicalMeasures/PROQOLOverlay'
import { SCOFFOverlay } from '@behavehealth/constructs/ClinicalMeasures/SCOFFOverlay'
import { TEAOverlay } from '@behavehealth/constructs/ClinicalMeasures/TEAOverlay'
import { WHOQOLOverlay } from '@behavehealth/constructs/ClinicalMeasures/WHOQOLOverlay'

import { ASQOverlay } from '@behavehealth/constructs/ClinicalMeasures/ASQOverlay'
import { CRAFFTOverlay } from '@behavehealth/constructs/ClinicalMeasures/CRAFFTOverlay'
import { CAGEAIDOverlay } from '@behavehealth/constructs/ClinicalMeasures/CAGEAIDOverlay'
import { SUBSOverlay } from '@behavehealth/constructs/ClinicalMeasures/SUBSOverlay'
import { SDMIOverlay } from '@behavehealth/constructs/ClinicalMeasures/SDMIOverlay'
import { SEDOverlay } from '@behavehealth/constructs/ClinicalMeasures/SEDOverlay'
import { EPDSOverlay } from '@behavehealth/constructs/ClinicalMeasures/EPDSOverlay'

import { TreatmentEpisodeOverlay } from '@behavehealth/constructs/TreatmentEpisodes/TreatmentEpisodeOverlay'
import { GeolocatedLocationCheckInOverlay } from '@behavehealth/constructs/LocationCheckIns/GeolocatedLocationCheckInOverlay'
import { GeolocatedLocationCheckInWorksheetOverlay } from '@behavehealth/constructs/LocationCheckIns/GeolocatedLocationCheckInWorksheetOverlay'

import { ClientHighRiskAlertOverlay } from '@behavehealth/constructs/HighRiskAlert/ClientHighRiskAlertOverlay'
import { MagicLoginOverlay } from '@behavehealth/constructs/Auth/MagicLoginOverlay'
import { SetPasswordOverlay } from '@behavehealth/constructs/Auth/SetPasswordOverlay'

import { GlobalSummonOverlayProvider, GlobalSummonOverlay } from '@behavehealth/components/GlobalSummonOverlay'

// Forms
import { FormSubmissionOverlay } from '@behavehealth/constructs/FormSubmissions/FormSubmissionOverlay'

// Overlays
import { CaseManagementChecklistOverlay } from '@behavehealth/constructs/RecoveryNotes/CaseManagementChecklistOverlay'
import { ClientConnectionOverlay } from '@behavehealth/constructs/ClientConnection/ClientConnectionOverlay'
import { CommunicationOverlay } from '@behavehealth/constructs/Communications/CommunicationOverlay'
import { CommunicationWorksheetOverlay } from '@behavehealth/constructs/Communications/CommunicationWorksheetOverlay'
import { ContactOverlay } from '@behavehealth/constructs/Contacts/ContactOverlay'
import { RecoveryCapitalFormOverlayV1 } from '@behavehealth/constructs/RecoveryCapitalForms/RecoveryCapitalFormOverlayV1'
import { RecoveryCapitalFormOverlayV2 } from '@behavehealth/constructs/RecoveryCapitalForms/RecoveryCapitalFormOverlayV2'

import { RecoveryCapitalScaleOverlay } from '@behavehealth/constructs/RecoveryNotes/RecoveryCapitalScaleOverlay'
import { RecoveryCapitalPlanOverlay } from '@behavehealth/constructs/RecoveryNotes/RecoveryCapitalPlanOverlay'
import { RecoveryCapitalScaleAndPlanOverlay } from '@behavehealth/constructs/RecoveryNotes/RecoveryCapitalScaleAndPlanOverlay'

import { ShiftNoteOverlay } from '@behavehealth/constructs/ShiftNotes/ShiftNoteOverlay'
import { InsuranceAuthorizationOverlay } from '@behavehealth/constructs/RCM/InsuranceAuthorizationOverlay'

import { MIRCOverlay } from '@behavehealth/constructs/RecoveryNotes/MIRCOverlay'

import { ChargeBuilderOverlay } from '@behavehealth/constructs/Financials/ChargeBuilderOverlay'
import { ChargeOverlay } from '@behavehealth/constructs/Financials/ChargeOverlay'
import { FeeOverlay } from '@behavehealth/constructs/Financials/FeeOverlay'
import { PaymentOverlay } from '@behavehealth/constructs/Financials/PaymentOverlay'
import { PaymentWorksheetOverlay } from '@behavehealth/constructs/Financials/PaymentWorksheetOverlay'
import { CreditOverlay } from '@behavehealth/constructs/Financials/CreditOverlay'
import { RefundOverlay } from '@behavehealth/constructs/Financials/RefundOverlay'
import { WriteOffOverlay } from '@behavehealth/constructs/Financials/WriteOffOverlay'
import { AutoRepeatingChargeOverlay } from '@behavehealth/constructs/Financials/AutoRepeatingChargeOverlay'

import { OrganizationChargeBuilderOverlay } from '@behavehealth/constructs/Financials/OrganizationChargeBuilderOverlay'
import { OrganizationChargeOverlay } from '@behavehealth/constructs/Financials/OrganizationChargeOverlay'
import { OrganizationPaymentOverlay } from '@behavehealth/constructs/Financials/OrganizationPaymentOverlay'
import { OrganizationCreditOverlay } from '@behavehealth/constructs/Financials/OrganizationCreditOverlay'
import { OrganizationRefundOverlay } from '@behavehealth/constructs/Financials/OrganizationRefundOverlay'
import { OrganizationWriteOffOverlay } from '@behavehealth/constructs/Financials/OrganizationWriteOffOverlay'
import { OrganizationAutoRepeatingChargeOverlay } from '@behavehealth/constructs/Financials/OrganizationAutoRepeatingChargeOverlay'

// New Record Overlays
import { NewEmployeeInviteOverlay } from '@behavehealth/constructs/UserInvites/NewEmployeeInviteOverlay'
import { ExistingEmployeeInviteOverlay } from '@behavehealth/constructs/UserInvites/ExistingEmployeeInviteOverlay'
import { NewClientPortalInviteOverlay } from '@behavehealth/constructs/UserInvites/NewClientPortalInviteOverlay'

import { NewOrganizationOverlay } from '@behavehealth/constructs/Organizations/NewOrganizationOverlay'
import { NewCommunityOrganizationOverlay } from '@behavehealth/constructs/Organizations/NewCommunityOrganizationOverlay'

// Clinical Notes
import { ClinicalNoteCaseManagementOverlay } from '@behavehealth/constructs/ClinicalNotes/ClinicalNoteCaseManagementOverlay'
import { ClinicalNoteFamilySessionOverlay } from '@behavehealth/constructs/ClinicalNotes/ClinicalNoteFamilySessionOverlay'
import { ClinicalNoteGroupOverlay } from '@behavehealth/constructs/ClinicalNotes/ClinicalNoteGroupOverlay'
import { ClinicalNoteIndividualOverlay } from '@behavehealth/constructs/ClinicalNotes/ClinicalNoteIndividualOverlay'
import { NewClinicalNoteGroupOverlay } from '@behavehealth/constructs/ClinicalNotes/NewClinicalNoteGroupOverlay'

// Peer Notes
import { PeerNoteGroupOverlay } from '@behavehealth/constructs/PeerNotes/PeerNoteGroupOverlay'
import { PeerNoteGroupBuilderOverlay } from '@behavehealth/constructs/PeerNotes/PeerNoteGroupBuilderOverlay'
import { PeerNoteIndividualOverlay } from '@behavehealth/constructs/PeerNotes/PeerNoteIndividualOverlay'

import { DailyNoteGroupOverlay } from '@behavehealth/constructs/DailyNotes/DailyNoteGroupOverlay'
import { DailyNoteGroupBuilderOverlay } from '@behavehealth/constructs/DailyNotes/DailyNoteGroupBuilderOverlay'
import { DailyNoteIndividualOverlay } from '@behavehealth/constructs/DailyNotes/DailyNoteIndividualOverlay'

import { ShiftNoteWorksheetOverlay } from '@behavehealth/constructs/ShiftNotes/ShiftNoteWorksheetOverlay'

import { MedicationDestructionLogOverlay } from '@behavehealth/constructs/Medications/MedicationDestructionLogOverlay'
import { MedicationIncidentLogOverlay } from '@behavehealth/constructs/Medications/MedicationIncidentLogOverlay'
import { MedicationLogOverlay } from '@behavehealth/constructs/Medications/MedicationLogOverlay'
import { MedicationLogWorksheetOverlay } from '@behavehealth/constructs/Medications/MedicationLogWorksheetOverlay'
import { MedicationOverlay } from '@behavehealth/constructs/Medications/MedicationOverlay'
import { MedicationWorksheetOverlay } from '@behavehealth/constructs/Medications/MedicationWorksheetOverlay'

import { FoRSEAdmitSurveyOverlay } from '@behavehealth/constructs/OutcomeMeasures/FoRSEAdmitSurveyOverlay'
import { FoRSEDemographicInformationOverlay } from '@behavehealth/constructs/OutcomeMeasures/FoRSEDemographicInformationOverlay'
import { FoRSEDischargeSurveyOverlay } from '@behavehealth/constructs/OutcomeMeasures/FoRSEDischargeSurveyOverlay'
import { FoRSEOutcomesSurveyOverlay } from '@behavehealth/constructs/OutcomeMeasures/FoRSEOutcomesSurveyOverlay'
import { FoRSEProgressMonitoringSurveyOverlay } from '@behavehealth/constructs/OutcomeMeasures/FoRSEProgressMonitoringSurveyOverlay'
import { OutcomeMeasureRecoveryCapitalScaleOverlay } from '@behavehealth/constructs/OutcomeMeasures/OutcomeMeasureRecoveryCapitalScaleOverlay'
import { OutcomeMeasureMIRCOverlay } from '@behavehealth/constructs/OutcomeMeasures/OutcomeMeasureMIRCOverlay'

import { DataExportOverlay } from '@behavehealth/constructs/DataExports/DataExportOverlay'

import { WorksheetDemo } from '../worksheet_demo'

import { AppMessages } from '@behavehealth/constructs/AppMessages/AppMessages'
import { AppsHeader } from '@behavehealth/constructs/Apps/AppsHeader'
import { AppsUIContext, AppsUIContextProvider } from '@behavehealth/constructs/Apps/AppsUIContext'
import { EHRAppNavItems } from '../../components/EHRAppNavItems'
import { FocusedSession } from '@behavehealth/components/FocusedSession'

import QuickAdd from '@behavehealth/components/QuickAdd'

import { FeedbackSummonOverlay } from '@behavehealth/components/FeedbackTool'

import { ImportEmployeeAgreementsOverlay } from '@behavehealth/components/Overlays/pages/Agreements/ImportEmployeeAgreementsOverlay'
import { InsuranceInvoiceOverlay } from '@behavehealth/constructs/RCM/InsuranceInvoiceOverlay'
import { ClientInvoiceOverlay } from '@behavehealth/constructs/ClientInvoices/ClientInvoiceOverlay'
import { ClientInvoiceBuilderOverlay } from '@behavehealth/constructs/ClientInvoices/ClientInvoiceBuilderOverlay'
import { ClientRecurringInvoiceOverlay } from '@behavehealth/constructs/ClientInvoices/ClientRecurringInvoiceOverlay'

import { ClaimsRCM } from '../rcm/claims/base'
import { PostingRCM } from '../rcm/posting/base'
import { Statements } from '../rcm/statements'
import { ClearingHouseMailbox } from '../rcm/clearing_house/mailbox'

import { CiwaOverlay } from '@behavehealth/constructs/Nursing/CiwaOverlay'
import { CowsOverlay } from '@behavehealth/constructs/Nursing/CowsOverlay'
import { FallRiskAssessmentOverlay } from '@behavehealth/constructs/Nursing/FallRiskAssessmentOverlay'
import { NursingAssessmentOverlay } from '@behavehealth/constructs/Nursing/NursingAssessmentOverlay'
import { NursingReassessmentOverlay } from '@behavehealth/constructs/Nursing/NursingReassessmentOverlay'
import { NursingProgressNoteOverlay } from '@behavehealth/constructs/Nursing/NursingProgressNoteOverlay'

import { Organization } from '../community/community_organizations/organization'
import { CommunityOrganizations } from '../community/community_organizations'

import { ClientAgreementOverlay } from '@behavehealth/constructs/Agreements/ClientAgreementOverlay'
import { EmployeeAgreementOverlay } from '@behavehealth/constructs/Agreements/EmployeeAgreementOverlay'
import { OrganizationAgreementOverlay } from '@behavehealth/constructs/Agreements/OrganizationAgreementOverlay'

import { ServiceEpisodeOverlay } from '@behavehealth/constructs/ServiceEpisodes/ServiceEpisodeOverlay'

import { useDebug } from '@behavehealth/hooks/useDebug'

import { RoundLineOverlay } from '@behavehealth/constructs/RoundLines/RoundLineOverlay'
import { Rounds } from '../rounds'

import { SafetyCheckOverlay } from '@behavehealth/constructs/SafetyChecks/SafetyCheckOverlay'
import { SafetyCheckWorksheetOverlay } from '@behavehealth/constructs/SafetyChecks/SafetyCheckWorksheetOverlay'

import { DataFormSubmissionOverlay } from '@behavehealth/constructs/DataFormsBuilder/DataFormSubmissionOverlay'
import { InsurancePolicyOverlay } from '@behavehealth/constructs/Insurance/InsurancePolicyOverlay'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

// Migrated to React Router 6
import { Accounting } from '../accounting/accounting'
import { CommunityExplore } from '../community/community_explore'
import { CommunityInvites } from '../community/community_invites'
import { CommunityProfiles } from '../community/community_profiles'
import { FormSubmissions } from '../form_submissions/form_submissions'
import { MedsPass } from '../meds_pass/meds_pass'

import Calendar from '../calendar/calendar'
import ClientsJourney from '../clients_journey/clients_journey'
import Console from '../console/console'
import Reports from '../reports/reports'
import Todos from '../todo/todo'
import UserInvites from '../user_invites/user_invites'
import { CommunityLeads } from '../community/community_leads'
import { HelpCenter } from '../help_center'

import { FacilitiesSearchBar } from '@behavehealth/constructs/EHR/FacilitiesSearchBar'

const AppContextProviders: React.FC = ({ children }) => {
  return (
    <GlobalSummonOverlayProvider>
      <AppsUIContextProvider>
        <MeetingContextProvider>{children}</MeetingContextProvider>
      </AppsUIContextProvider>
    </GlobalSummonOverlayProvider>
  )
}

class AppEntry extends React.Component {
  timeout = null
  state = {
    prompt: null,
    showSearch: false,
    forceShowSetup: false,
    isMounted: false,
  }

  componentDidMount = async () => {
    let params = parse(location.search)
    if (params.tenant) {
      if (!!window.ReactNativeWebView) localStorage.setItem('bh.stn', params.tenant)
      else sessionStorage.setItem('bh.stn', params.tenant)
    }
    if (params.session_token) localStorage.setItem('bh.stk', params.session_token)
    if (params.token) localStorage.setItem('bh.at', params.token)
    if (params.as) localStorage.setItem('bh.as', params.as)

    const tenant = sessionStorage.getItem('bh.stn') || localStorage.getItem('bh.ftn') || localStorage.getItem('bh.stn')

    if (!tenant) {
      return this.props.history.push('/logout', {
        type: 'no_tenant_found',
        message: 'Please enter your credentials to login to your account',
      })
    }

    setTenantPrefix(tenant)

    await initialize()
    await setup()

    const isBehave = this.props.user.type === 'bh_employee'
    const planStatus = this.props?.tenant?.plan_status

    if (!isBehave) {
      if (planStatus === 'free') return this.props.history.push('/select-plan')
      if (planStatus === 'trial_expired') return this.props.history.push('/select-plan')
      if (planStatus === 'archived') return this.props.history.push('/select-plan')
      if (planStatus === 'canceled') return this.props.history.push('/select-plan')
      if (planStatus === 'unpaid') return this.props.history.push('/unpaid')
      if (planStatus === 'proposal') return this.props.history.push('/proposal')
    }

    this.setState({ isMounted: true })
  }

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout)
  }

  onIdle = () => {
    Analytics.queue({
      type: 'track',
      name: 'Idle Sign-Out',
    })

    store.dispatch(setIdle(true))
  }

  openSearch = () => {
    this.setState({ showSearch: true })
  }

  showSetup = () => {
    this.setState({ forceShowSetup: true })
  }

  getSession = () => {
    // if (LogRocket.getRocket()) {
    //   const url = LogRocket.getRocket().sessionURL
    //   window.open(url)
    //   window.open(
    //     `https://linear.app/new?title=New%20Bug&description=Session:%20${url}%20Tenant:%20${this.props.tenant}%Url:${this.props.location.pathname}`,
    //   )
    // }
  }

  render() {
    const { blocks, tenant, location, isSearchOpen, openSearch, closeSearch } = this.props

    if (!this.state.isMounted || !this.props.user) {
      return (
        <div
          css={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: COLORS.hover,
          }}
        >
          <AppLoader user={this.props.user} />
        </div>
      )
    }

    const isBehave = this.props.user.type === 'bh_employee'
    const isManagement = ['owner', 'administrator'].includes(this.props.user.position)
    const isEHR = tenant.category === 'healthcare_facility' || tenant.category === 'healthcare_practice'
    const isOwner = this.props.user.position === 'owner'
    const hasAccountingAccess = this.props.user.has_accounting_access === true

    let redirectTo = '/dashboard'

    let params = parse(location.search)
    let showHelp = !!params.help
    let showAppSidebar = isUndefined(params.show_app_sidebar) ? true : params.show_app_sidebar === 'true'

    return (
      <AppContextProviders>
        <MeetingOverlay />
        <FeedbackSummonOverlay />

        <Helmet>
          <title>{tenant?.name}</title>
        </Helmet>

        <IdleTimer onIdle={this.onIdle} timeout={1000 * 60 * 60} startOnLoad />
        <IdleSaver />

        <FocusedSession />

        <SessionTimerPopup />
        {(tenant?.plan_status === 'trialing' || tenant?.plan_status === 'trial_requested') && <TrialTimerToast />}

        {/* <VegasSign /> */}
        <MobileQuickAdd />

        <div css={STYLES.appSidebarLayout}>
          <div css={STYLES.appMain}>
            <div>
              <AppMessages />

              <AppsHeader
                showHelp
                tenant={tenant}
                isBehave={isBehave}
                mobileNavItems={<EHRAppNavItems blocks={blocks} />}
                settingsNavItems={<SettingsNavItems />}
                onSearchClick={openSearch}
                showAppSidebar={showAppSidebar}
                desktopSearch={<AppNavItemSearch onClick={openSearch} label="Search…" />}
                settingsLinkTo="/settings"
                afterLogo={
                  isBehave && (
                    <div className="hidden mq1100:flex items-center w-[160px] pl-5">
                      <FacilitiesSearchBar />
                    </div>
                  )
                }
              />
            </div>

            <div css={STYLES.appMainColumns}>
              <AppNav blocks={blocks} />

              {/* <Animated show={this.state.showSearch} animation="animation">
                <GlobalSearch onClose={() => this.setState({ showSearch: false })} />
              </Animated> */}

              <Routes>
                <RouteV6
                  path="/console/*"
                  element={
                    <ProtectedRouteV6 permission={isBehave}>
                      <Console />
                    </ProtectedRouteV6>
                  }
                />
                <RouteV6
                  path="/user-invites/*"
                  element={
                    <ProtectedRouteV6 permission="user_invites.view">
                      <UserInvites />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/form-submissions/*"
                  element={
                    <ProtectedRouteV6 featureFlag="form_submissions" permission="form_submissions.view">
                      <FormSubmissions />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/community/explore/*"
                  element={
                    <ProtectedRouteV6 featureFlag="organizations" permission="organizations.view">
                      <CommunityExplore />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/community/invites/*"
                  element={
                    <ProtectedRouteV6 featureFlag="organizations" permission="organizations.view">
                      <CommunityInvites />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/community/leads/*"
                  element={
                    <ProtectedRouteV6 featureFlag="organizations" permission="organizations.view">
                      <CommunityLeads />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/community/profiles/*"
                  element={
                    <ProtectedRouteV6 featureFlag="organizations" permission="organizations.view">
                      <CommunityProfiles />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/todo/*"
                  element={
                    <ProtectedRouteV6 featureFlag="todos" permission="todos.view">
                      <Todos />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/calendar/*"
                  element={
                    <ProtectedRouteV6 featureFlag="calendar" permission="events.view">
                      <Calendar />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/reports/*"
                  element={
                    <ProtectedRouteV6 featureFlag="live_reports" permission="reports.view">
                      <Reports />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/clients-journey/*"
                  element={
                    <ProtectedRouteV6 featureFlag="client_workflows" permission={isBehave}>
                      <ClientsJourney />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/meds-pass/*"
                  element={
                    <ProtectedRouteV6 featureFlag="med_pass" permission={isBehave ? true : 'med_pass.view'}>
                      <MedsPass />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/accounting/*"
                  element={
                    <ProtectedRouteV6 featureFlag="accounting" permission={isBehave || isOwner || isManagement || hasAccountingAccess}>
                      <Accounting />
                    </ProtectedRouteV6>
                  }
                />

                <RouteV6
                  path="/programs/*"
                  element={
                    <ProtectedRouteV6 featureFlag="programs" permission="programs.view">
                      <Outlet />
                      <Routes>
                        <RouteV6 index element={<Navigate to="table" replace />} />
                      </Routes>
                    </ProtectedRouteV6>
                  }
                >
                  <RouteV6 path="table/*" element={<Programs />} />
                  <RouteV6 path="timeline/*" element={<Programs />} />
                  <RouteV6 path=":resource_id/*" element={<Program />} />
                </RouteV6>

                <RouteV6
                  path="/locations/*"
                  element={
                    <ProtectedRouteV6 featureFlag="properties" permission="properties.view">
                      <Outlet />

                      <Routes>
                        <RouteV6 index element={<Navigate to="all" replace />} />
                      </Routes>
                    </ProtectedRouteV6>
                  }
                >
                  <RouteV6 path="all/*" element={<Properties />} />
                  <RouteV6 path=":resource_id/*" element={<Property />} />
                </RouteV6>

                <RouteV6
                  path="/rounds/*"
                  element={
                    <ProtectedRouteV6 featureFlag="rounds" permission="rounds.view">
                      <Rounds />
                    </ProtectedRouteV6>
                  }
                />
              </Routes>

              <React.Suspense fallback={<PageLoader />}>
                <Switch>
                  <Route exact path="/kitchen-sink" component={KitchenSink} />
                  <Route exact path="/worksheet-demo" component={WorksheetDemo} />
                  <ProtectedRoute exact path="/help-center" component={HelpCenter} featureFlag="help_center_main_page" />

                  <Route path={`/search`} component={Search} />
                  <Route path={`/dashboard`} component={Dashboard} />
                  <Route path={`/error`} component={ErrorTest} />
                  <Route path={`/updates`} component={Updates} />
                  <ProtectedRoute path={`/financial-hub`} component={FinancialHub} featureFlag="finance_hub" />
                  <Route path={`/marketing`} component={Marketing} />

                  <Route path={`/product-tour`} component={ProductTour} />
                  <Route path={`/me/:resource_id`} component={User} />
                  <Route path={`/issues`} component={Issues} />
                  <ProtectedRoute path={`/files`} component={Files} featureFlag="files" permission="companies.files.view" />
                  <Route path={`/rcm`} component={RCMReportsFiles} />
                  <ProtectedRoute
                    path={`/bed-management`}
                    component={BedManagement}
                    permission="bed_management.view"
                    featureFlag="bed_management"
                  />

                  <ProtectedRoute path={`/onboarding`} component={OnboardingChecklist} />

                  <Route path={`/rosters`} component={Rosters} />
                  <ProtectedRoute path={`/company-chat`} component={CompanyChat} permission="company_chat.view" featureFlag="staff_chat" />
                  <ProtectedRoute
                    path={`/clients-chat`}
                    component={ClientsChat}
                    permission="clients_chat.view"
                    featureFlag="clients_chat"
                  />

                  <Route path={`/bridge`} component={Bridge} />
                  <ProtectedRoute exact path={`/clients(|/new)`} component={Clients} featureFlag="clients" permission="clients.view" />
                  <ProtectedRoute exact path={`/alumni(|/new)`} component={Alumni} featureFlag="alumni" permission="clients.view" />
                  <Route exact path={`/billed`} component={Billed} />
                  <ProtectedRoute
                    exact
                    path={[
                      `/insurance-payers`,
                      `/insurance-payers/active`,
                      `/insurance-payers/archived`,
                      `/insurance-payers/active(|/import)`,
                      `/insurance-payers/active(|/new)`,
                      `/insurance-payers/archived(|/import)`,
                      `/insurance-payers/archived(|/new)`,
                    ]}
                    component={InsurancePayers}
                    permission="insurance_local_payers.view"
                    featureFlag="insurance_payers"
                  />

                  <ProtectedRoute exact path={`/places(|/new)`} component={Places} permission="properties.view" featureFlag="properties" />
                  <Route path={`/insurance-era-reports`} component={InsuranceERAReports} />
                  <Route path={`/behave-forms/:id`} component={CustomPages} />

                  <Route path={`/analytics`} component={AnalyticsPage} />
                  <Route exact path={`/analytics/examples`} component={AnalyticsExamples} />

                  <ProtectedRoute
                    exact
                    path={[
                      `/admissions`,
                      `/admissions/applicants`,
                      `/admissions/pending-review`,
                      `/admissions/intake`,
                      `/admissions/declined`,
                    ]}
                    component={Admissions}
                    featureFlag="admissions"
                    permission="applications.view"
                  />

                  <ProtectedRoute
                    exact
                    path={[
                      `/employees`,
                      `/employees/active(|/new)`,
                      `/employees/archived(|/new)`,
                      `/employees/invites/:id`,
                      `/employees/invites(|/new)`,
                    ]}
                    component={Employees}
                    permission="employees.view"
                    featureFlag="staff"
                  />

                  <ProtectedRoute
                    path={`/admissions/:client_id`}
                    component={Admission}
                    featureFlag="admissions"
                    permission="applications.view"
                  />
                  <ProtectedRoute path={`/clients/:client_id`} component={Client} featureFlag="clients" permission="clients.view" />
                  <ProtectedRoute path={`/alumni/:client_id`} component={Alumnus} featureFlag="alumni" permission="clients.view" />
                  <ProtectedRoute path={`/employees/:employee_id`} component={Employee} featureFlag="staff" permission="employees.view" />
                  <ProtectedRoute
                    path={`/insurance-payers/:payer_id`}
                    component={Payer}
                    permission="insurance_local_payers.view"
                    featureFlag="insurance_payers"
                  />

                  <ProtectedRoute
                    path={`/claims`}
                    component={ClaimsRCM}
                    featureFlag="insurance_claims"
                    permission="insurance_claims.view"
                  />
                  <ProtectedRoute
                    path={`/posting`}
                    component={PostingRCM}
                    featureFlag="insurance_posting"
                    permission="insurance_posting.view"
                  />
                  <ProtectedRoute
                    path={`/statements`}
                    component={Statements}
                    featureFlag="insurance_invoicing"
                    permission="insurance_invoicing.view"
                  />
                  <ProtectedRoute
                    path={`/clearing-house`}
                    component={ClearingHouseMailbox}
                    featureFlag="insurance_clearinghouse"
                    permission="insurance_clearinghouse.view"
                  />

                  <Route path={`/settings`} component={Settings} />

                  <ProtectedRoute path={`/kitchen-sync`} component={KitchenSync} featureFlag={isBehave} />

                  <ProtectedRoute
                    exact
                    path={[
                      '/community/organizations',
                      '/community/organizations/internal(|/import-new|/create-new)',
                      '/community/organizations/authorities(|/import-new|/create-new)',
                      '/community/organizations/providers(|/import-new|/create-new)',
                      '/community/organizations/vendors(|/import-new|/create-new)',
                      '/community/organizations/archived(|/import-new|/create-new)',
                      '/community/organizations/shared-locations',
                      '/community/organizations/message-board',
                    ]}
                    component={CommunityOrganizations}
                    featureFlag="organizations"
                    permission="organizations.view"
                  />

                  <ProtectedRoute
                    path={`/community/organizations/:resource_id`}
                    component={Organization}
                    featureFlag="organizations"
                    permission="organizations.view"
                  />

                  <Redirect exact from="/" to={redirectTo} />
                </Switch>
              </React.Suspense>
            </div>
          </div>

          {showAppSidebar && <AppSidebar />}
        </div>

        <AnimatedSwitch className="overlays" animation="animation" timeout={{ enter: 400, exit: 400 }}>
          {/* QUICK Intakes */}
          <Route exact path={`/:resource/:resource_id/**/update_loc`} component={UpdateLevelOfCare} />
          <Route exact path={`/:resource/:resource_id/**/invite-to-portal`} component={NewClientPortalInviteOverlay} />
          <Route exact path={`/:resource/:resource_id/**/invite-to-ehr`} component={ExistingEmployeeInviteOverlay} />
          <Route exact path={`/:resource/:resource_id/**/admit-client`} component={IntakeAdmit} />
          <Route exact path={`/:resource/:resource_id/**/discharge-client`} component={IntakeDischarge} />
          <Route exact path={`/:resource/:resource_id/**/readmit-client`} component={IntakeReadmit} />
          <Route exact path={`/:resource/:resource_id/**/accept-client`} component={AcceptForAdmission} />
          <Route exact path={`/:resource/:resource_id/**/decline-client`} component={AdmissionDecline} />
          <Route exact path={`/:resource/:resource_id/**/high-risk-alert`} component={ClientHighRiskAlertOverlay} />
          <Route exact path={`/:resource/:resource_id/**/magic-login`} component={MagicLoginOverlay} />
          <Route exact path={`/:resource/:resource_id/**/update-password`} component={SetPasswordOverlay} />
          <Route exact path={`/:resource/:resource_id/**/tep/:id/close`} component={IntakeClose} />

          {/* Dashboard Messages */}
          <Route path={`/dashboard/messages/:id`} component={Message} />

          <ProtectedRoute
            path={`/meds-pass/meds/:id`}
            children={<MedicationOverlay hideDelete />}
            permission={isBehave ? true : 'med_pass.view'}
            featureFlag="med_pass"
          />
          <ProtectedRoute
            path={`/meds-pass/med-logs/:id`}
            component={MedicationLogOverlay}
            permission={isBehave ? true : 'med_pass.view'}
            featureFlag="med_pass"
          />

          {/* Form Submissions */}
          <Route exact path={`/:resource/:resource_id/contacts-form-submissions/new-:form_id`} component={FormSubmissionOverlay} />
          <Route exact path={`/:resource/:resource_id/contacts-form-submissions/:id`} component={FormSubmissionOverlay} />
          <Route exact path={`/:resource/:resource_id/form-submissions/new-:form_id`} component={FormSubmissionOverlay} />
          <Route exact path={`/:resource/:resource_id/form-submissions/:id`} component={FormSubmissionOverlay} />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/form-submissions/new-:form_id`}
            component={FormSubmissionOverlay}
          />
          <Route exact path={`/community/:resource/:resource_id/internal/form-submissions/:id`} component={FormSubmissionOverlay} />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/contacts-form-submissions/new-:form_id`}
            component={FormSubmissionOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/contacts-form-submissions/:id`}
            component={FormSubmissionOverlay}
          />

          {/* Payers */}
          <Route exact path={`/:resource/:resource_id/address-book/new`} component={NewAddress} />
          <Route exact path={`/:resource/:resource_id/phone-book/new`} component={NewPhone} />
          <Route exact path={`/:resource/:resource_id/rules/new`} component={NewRule} />
          <Route exact path={`/:resource/:resource_id/contacts/public/new`} component={NewPublicContact} />
          <Route exact path={`/:resource/:resource_id/contacts/facility/new`} component={NewFacilityContact} />

          {/* Clients */}
          <Route exact path={`/:resource/:resource_id/data-exports/:id`} component={DataExportOverlay} />
          <Route path={`/:resource/:resource_id/**/message-board/:id`} component={Message} />

          {/* Bed Management */}
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/floors/:id`}
            component={LocationFloorOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/floors/:floor_id/rooms/:id`}
            component={LocationRoomOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/floors/:floor_id/rooms/:room_id/beds/:id`}
            component={LocationBedOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/occupancies/:id`}
            children={<BedOccupancyOverlay isEditable />}
            permission="bed_management.view"
            featureFlag="bed_management"
          />

          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/rooms/:id`}
            component={LocationRoomOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/assign-bed/:client_id`}
            component={MoveBedsOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/unassigned/assign-bed/:client_id`}
            component={MoveBedsOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/move-beds/:client_id`}
            component={MoveBedsOverlay}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          {/* <Route exact path={`/bed-management/:view/:property_id/move/:room_id/:client_id`} component={MoveBedsOverlay} /> */}
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/admit/:resource_id`}
            component={IntakeAdmit}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/:property_id/accept/:resource_id`}
            component={AcceptForAdmission}
            permission="bed_management.view"
            featureFlag="bed_management"
          />
          <ProtectedRoute
            exact
            path={`/bed-management/:view/discharge/:id`}
            component={IntakeDischarge}
            permission="bed_management.view"
            featureFlag="bed_management"
          />

          <Route exact path={`/:resource/:resource_id/occupancies/move-beds`} component={MoveBedsOverlay} />
          <Route exact path={`/:resource/:resource_id/occupancies/:id`} children={<BedOccupancyOverlay showMoveBeds />} />

          {/* Common */}
          <Route path={`/lists/:id`} component={List} />

          <ProtectedRoute exact path={`/files/:resource_type/preview-:id`} component={FilePreviewOverlay} featureFlag="files" />
          <ProtectedRoute exact path={`/files/:resource_type/:id`} component={FileOverlay} featureFlag="files" />
          <Route path={`/rcm/:resource_type/:id`} component={File} />

          {/* Intakes */}
          <Route exact path={`/:resource/:resource_id/treatment-episodes/:id`} component={TreatmentEpisodeOverlay} />
          <Route exact path={`/:resource/:resource_id/treatment-episodes/:id/edit`} component={IntakeEdit} />
          <Route exact path={`/:resource/:resource_id/treatment-episodes/:id/admit`} component={IntakeAdmit} />
          <Route exact path={`/:resource/:resource_id/treatment-episodes/:id/discharge`} component={IntakeDischarge} />
          <Route exact path={`/:resource/:resource_id/treatment-episodes/:id/close`} component={IntakeClose} />

          {/* New main data */}
          <Route exact path={`/bridge/application`} component={BridgeProfile} />
          <Route exact path={`/clients/new`} component={NewClientOverlay} />
          <Route exact path={`/alumni/new`} component={NewAlumniOverlay} />
          <ProtectedRoute
            exact
            path={`/employees/(active|invites|archived)/new`}
            component={NewEmployeeInviteOverlay}
            permission="employees.view"
            featureFlag="staff"
          />

          <ProtectedRoute
            exact
            path={`/insurance-payers/(active|archived)/import`}
            component={LocalPayersImportOverlay}
            permission="insurance_local_payers.view"
            featureFlag="insurance_payers"
          />
          <ProtectedRoute
            exact
            path={`/insurance-payers/(active|archived)/new`}
            component={NewPayerOverlay}
            permission="insurance_local_payers.view"
            featureFlag="insurance_payers"
          />

          {/* Agreements */}
          <Route exact path={`/employees/:resource_id/agreements/agreements/import`} component={ImportEmployeeAgreementsOverlay} />
          <Route exact path={`/:resource/:resource_id/agreements/agreements/import`} component={ImportAgreementsOverlay} />
          <Route
            exact
            path={[
              `/:resource/:resource_id/agreements/agreements/import`,
              `/community/:resource/:resource_id/internal/agreements/agreements/import`,
            ]}
            component={ImportAgreementsOverlay}
          />
          <Route
            exact
            path={[
              `/:resource/:resource_id/agreements/send-agreements`,
              `/community/:resource/:resource_id/internal/agreements/send-agreements`,
            ]}
            component={SendAgreementsOverlay}
          />
          <Route exact path={`/(admissions|clients|alumni)/:resource_id/agreements/agreements/:id`} component={ClientAgreementOverlay} />
          <Route
            exact
            path={`/(admissions|clients|alumni)/:resource_id/agreements/admin-agreements/:id`}
            component={isBehave || isManagement ? ClientAgreementOverlay : null}
          />

          <Route exact path={`/employees/:resource_id/agreements/agreements/:id`} component={EmployeeAgreementOverlay} />
          <Route
            exact
            path={`/employees/:resource_id/agreements/admin-agreements/:id`}
            component={isBehave || isManagement ? EmployeeAgreementOverlay : null}
          />

          <Route exact path={`/community/organizations/:resource_id/internal/agreements/:id`} component={OrganizationAgreementOverlay} />

          {/* Subpages overlays */}
          <ProtectedRoute
            path={[
              `/:resource/:resource_id/todo/:todo_type/todo-builder`,
              `/community/:resource/:resource_id/internal/todo/:todo_type/todo-builder`,
            ]}
            component={TodoBuilderOverlay}
            featureFlag="todos"
            permission="todos.view"
          />
          <ProtectedRoute
            path={[`/:resource/:resource_id/todo/:todo_type/:id`, `/community/:resource/:resource_id/internal/todo/:todo_type/:id`]}
            component={TodoOverlay}
            featureFlag="todos"
            permission="todos.view"
          />

          <Route exact path={`/(admissions|clients|alumni)/:resource_id/custom-notes/:id`} component={ClientCustomNoteOverlay} />
          <ProtectedRoute
            exact
            path={`/(admissions|clients|alumni)/:resource_id/grievance-notes/:id`}
            component={GrievanceCustomNoteOverlay}
            permission={isBehave || isManagement}
            featureFlag="grievance_notes"
          />
          <Route exact path={`/me/:resource_id/custom-notes/:id`} component={StaffCustomNoteOverlay} />
          <ProtectedRoute
            exact
            path={`/employees/:resource_id/admin-hr-notes/:id`}
            component={AdminHRCustomNoteOverlay}
            permission={isBehave || isManagement}
            // featureFlag="admin_hr_custom_notes"
          />
          <Route exact path={`/employees/:resource_id/custom-notes/:id`} component={StaffCustomNoteOverlay} />
          <Route
            exact
            path={`/community/organizations/:organization_id/internal/custom-notes/:id`}
            component={OrganizationCustomNoteOverlay}
          />

          <ProtectedRoute exact path={`/:resource/:resource_id/files/preview-:id`} component={FilePreviewOverlay} featureFlag="files" />
          <ProtectedRoute exact path={`/:resource/:resource_id/files/:id`} component={FileOverlay} featureFlag="files" />

          <ProtectedRoute
            exact
            path={`/:resource/:resource_id/admin-hr-files/preview-:id`}
            component={FilePreviewOverlay}
            featureFlag="files"
          />
          <ProtectedRoute exact path={`/:resource/:resource_id/admin-hr-files/:id`} component={FileOverlay} featureFlag="files" />

          <ProtectedRoute
            exact
            path={`/community/:resource/:resource_id/internal/files/preview-:id`}
            component={FilePreviewOverlay}
            featureFlag="files"
          />
          <ProtectedRoute exact path={`/community/:resource/:resource_id/internal/files/:id`} component={FileOverlay} featureFlag="files" />

          <Route path={`/:resource/:resource_id/faxes-and-voicemails/:id`} component={File} />
          <Route
            path={[`/:resource/:resource_id/shift-notes/new-batch`, `/community/:resource/:resource_id/internal/shift-notes/new-batch`]}
            component={ShiftNoteWorksheetOverlay}
          />
          <Route
            path={[`/:resource/:resource_id/shift-notes/:id`, `/community/:resource/:resource_id/internal/shift-notes/:id`]}
            component={ShiftNoteOverlay}
          />
          <Route path="/:resource/:resource_id/safety-checks/new-batch" component={SafetyCheckWorksheetOverlay} />
          <Route path="/:resource/:resource_id/safety-checks/:id" component={SafetyCheckOverlay} />
          <Route path="/:resource/:resource_id/rounds/:id" component={RoundLineOverlay} />
          <Route path={`/:resource/:resource_id/floors/:id`} component={FloorOverlay} />
          <Route exact path={`/:resource/:resource_id/programs/:id`} children={<ProgramAssignmentOverlay />} />
          <Route exact path={`/:resource/:resource_id/programs/:occupancy_id/move`} component={ProgramMoveOverlay} />
          <Route exact path={`/:resource/:resource_id/programs/:occupancy_id/remove`} component={ProgramRemoveOverlay} />
          <Route
            path={[
              `/:resource/:resource_id/communications/new-batch`,
              `/community/:resource/:resource_id/internal/communications/new-batch`,
            ]}
            component={CommunicationWorksheetOverlay}
          />
          <Route path={`/:resource/:resource_id/communications/:id`} component={CommunicationOverlay} />
          <Route
            path={`/community/:resource/:resource_id/internal/communications/:id`}
            children={<CommunicationOverlay parentType="organization" parentRequest="organizations" />}
          />
          <Route path={`/:resource/:resource_id/applications/:id`} component={IntakeApplicationOverlay} />
          <Route path={`/:resource/:resource_id/address-book/:id`} component={AddressBookOverlay} />
          <Route path={`/:resource/:resource_id/phone-book/:id`} component={PhoneBookOverlay} />
          <Route path={`/:resource/:resource_id/contacts/facility/:id`} component={PayerFacilityContactOverlay} />
          <Route path={`/:resource/:resource_id/contacts/public/:id`} component={PayerPublicContactOverlay} />
          {/* <Route path={`/:resource/:resource_id/test-results/ua-tests/new-batch`} component={UATestWorksheetOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/ua-tests/:id`} component={UATestOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/breathalyzer-tests/new-batch`} component={BreathalyzerTestWorksheetOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/breathalyzer-tests/:id`} component={BreathalyzerTestOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/vital-signs/:id`} component={VitalSignsOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/communicable-disease-tests/:id`} component={CommunicableDiseaseTestOverlay} /> */}
          {/* <Route path={`/:resource/:resource_id/test-results/labs/:id`} component={LabTestOverlay} /> */}
          <Route path={`/:resource/:resource_id/**/messages/:id`} component={RecordMessage} />
          <Route path={`/:resource/:resource_id/diagnoses/:id`} component={DiagnosisOverlay} />
          <Route path={`/:resource/:resource_id/invoices/:id`} component={InsuranceInvoiceOverlay} />
          <Route path={`/:resource/:resource_id/financials/recurring-invoices/:id`} component={ClientRecurringInvoiceOverlay} />
          <Route path={`/:resource/:resource_id/financials/invoices/batch`} component={ClientInvoiceBuilderOverlay} />
          <Route path={`/:resource/:resource_id/financials/invoices/:id`} component={ClientInvoiceOverlay} />
          <Route path={`/:resource/:resource_id/financials/client-statements/:id`} component={InsuranceInvoiceOverlay} />
          {/* <Route path={[`/invoicing/invoices/:id`, `/invoicing/client-balances/:id`]} component={InsuranceInvoiceOverlay} /> */}

          <Route path={`/:resource/:resource_id/peer-notes/:peer_type/i/:id`} component={PeerNoteIndividualOverlay} />
          <Route path={`/:resource/:resource_id/peer-notes/:peer_type/gn/builder`} component={PeerNoteGroupBuilderOverlay} />
          <Route path={`/:resource/:resource_id/peer-notes/:peer_type/gn/:id`} component={PeerNoteGroupOverlay} />

          <Route path={`/:resource/:resource_id/daily-notes/:daily_type/i/:id`} component={DailyNoteIndividualOverlay} />
          <Route path={`/:resource/:resource_id/daily-notes/:daily_type/gn/builder`} component={DailyNoteGroupBuilderOverlay} />
          <Route path={`/:resource/:resource_id/daily-notes/:daily_type/gn/:id`} component={DailyNoteGroupOverlay} />

          <Route path={`/:resource/:resource_id/clinical-notes/:clinical_type/i/:id`} component={ClinicalNoteIndividualOverlay} />
          <Route path={`/:resource/:resource_id/clinical-notes/:clinical_type/cg/:id`} component={NewClinicalNoteGroupOverlay} />
          <Route path={`/:resource/:resource_id/clinical-notes/:clinical_type/cm/:id`} component={ClinicalNoteCaseManagementOverlay} />
          <Route path={`/:resource/:resource_id/clinical-notes/:clinical_type/fs/:id`} component={ClinicalNoteFamilySessionOverlay} />
          <Route exact path={`/:resource/:resource_id/insurance/vob-summaries/:id`} component={FullVOBSummaryOverlay} />
          <Route exact path={`/:resource/:resource_id/insurance/evob-summaries/:id`} component={EVOBOverlay} />
          <Route exact path={`/:resource/:resource_id/insurance/:id`} component={InsurancePolicyOverlay} />
          <Route exact path={`/:resource/:resource_id/vobs/:id`} component={VOBOverlay} />
          <Route exact path={`/:resource/:resource_id/archived-vobs/:id`} component={VOBArchivedOverlay} />
          <Route exact path={`/:resource/:resource_id/billing-roster/:id`} component={BillingRosterOverlay} />

          {/* RCM */}
          <Route exact path={`/:resource/:resource_id/rcm/billing/:id`} component={ClaimOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/claim-status/claims/:id`} component={ClaimOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/claim-status/:insurance_claim_id/notes/:id`} component={ClaimNoteOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/claim-status/:insurance_claim_id/tasks/:id`} component={ClaimTaskOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/posting/payments-builder`} component={ClaimPaymentsBuilderOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/posting/:id`} component={ClaimPaymentOverlay} />
          <Route
            exact
            path={`/insurance-payers/:resource_id/rcm/posting/payments-builder`}
            component={ClaimClientsPaymentsBuilderOverlay}
          />
          <Route exact path={`/insurance-payers/:resource_id/insurance-codes/import`} component={PayerInsuranceCodeImportOverlay} />
          <Route exact path={`/insurance-payers/:resource_id/insurance-codes/:id`} component={PayerInsuranceCodeOverlay} />
          <Route path={`/insurance-era-reports/:id`} component={InsuranceERAReportOverlay} />

          {/* RCM – UR */}
          <Route exact path={`/:resource/:resource_id/rcm/utilization-review/clinical-appeals/:id`} component={ClinicalAppealOverlay} />
          <Route exact path={`/:resource/:resource_id/rcm/utilization-review/concurrent-reviews/:id`} component={ConcurrentReviewOverlay} />
          <Route
            exact
            path={`/:resource/:resource_id/rcm/utilization-review/discharge-plannings/:id`}
            component={DischargePlanningOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/rcm/utilization-review/documentation-reviews/:id`}
            component={DocumentationReviewOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/rcm/utilization-review/initial-authorizations/:id`}
            component={InitialAuthorizationOverlay}
          />
          <Route exact path={`/:resource/:resource_id/rcm/utilization-review/pre-certifications/:id`} component={PreCertificationOverlay} />

          <ProtectedRoute
            path={[`/:resource/:resource_id/calendar/event-builder`, `/community/:resource/:resource_id/internal/calendar/event-builder`]}
            component={EventBuilderOverlay}
            permission="events.view"
            featureFlag="calendar"
          />
          <ProtectedRoute
            path={[`/:resource/:resource_id/calendar/:id`, `/community/:resource/:resource_id/internal/calendar/:id`]}
            component={EventOverlay}
            permission="events.view"
            featureFlag="calendar"
          />

          <Route path={`/:resource/:resource_id/insurance-services/(all|customize)/:id`} component={InsuranceServiceOverlay} />

          {/* Specific overlays */}
          <Route exact path={`/:resource/:resource_id/staff-access/:tab/import`} component={AccessListImportOverlay} />
          <Route exact path={`/:resource/:resource_id/assigned-staff/import`} component={AssignedEmployeesImportOverlay} />
          <Route exact path={[`/:resource/:resource_id/contacts/:id`]} component={ContactOverlay} />
          <Route exact path={[`/:resource/:resource_id/recovery-capital-forms/10/:id`]} component={RecoveryCapitalFormOverlayV1} />
          <Route exact path={[`/:resource/:resource_id/recovery-capital-forms/20/:id`]} component={RecoveryCapitalFormOverlayV2} />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/contacts/:id`}
            children={<ContactOverlay parentType="organization" parentRequest="organizations" />}
          />

          <Route exact path={`/:resource/:resource_id/connections/:id`} component={ClientConnectionOverlay} />
          <Route exact path={`/:resource/:resource_id/service-episodes/:id`} component={ServiceEpisodeOverlay} />
          <Route exact path={`/:resource/:resource_id/authorizations/:id`} component={InsuranceAuthorizationOverlay} />
          <Route exact path={`/:resource/:resource_id/payerauthorizations/:id`} component={PayerAuthorizationOverlay} />
          <Route exact path={`/:resource/:resource_id/rules/:id`} component={PayerRuleOverlay} />
          <Route exact path={`/:resource/:resource_id/benefits/evobs/:id`} component={EVOBOverlay} />
          <Route exact path={`/:resource/:resource_id/benefits/summaries/:id`} component={FullVOBSummaryOverlay} />
          <Route exact path={`/:resource/:resource_id/benefits/vobs/:id`} component={VOBOverlay} />
          <Route exact path={`/:resource/:resource_id/medications/logs/new-batch`} component={MedicationLogWorksheetOverlay} />
          <Route exact path={`/:resource/:resource_id/medications/new-batch`} component={MedicationWorksheetOverlay} />
          <Route exact path={`/:resource/:resource_id/medications/logs/:id`} component={MedicationLogOverlay} />
          <Route exact path={`/:resource/:resource_id/medications/destruction-logs/:id`} component={MedicationDestructionLogOverlay} />
          <Route exact path={`/:resource/:resource_id/medications/incident-logs/:id`} component={MedicationIncidentLogOverlay} />
          <Route exact path={`/:resource/:resource_id/*medications/:id`} component={MedicationOverlay} />
          <Route exact path={`/:resource/:resource_id/client-cash/refund/:id`} component={ClientCashRefund} />
          <Route exact path={`/:resource/:resource_id/client-cash/funds/:id`} component={ClientCashFunds} />
          <Route exact path={`/:resource/:resource_id/location-checkin/new-batch`} component={GeolocatedLocationCheckInWorksheetOverlay} />
          <Route exact path={`/:resource/:resource_id/location-checkin/:id`} component={GeolocatedLocationCheckInOverlay} />
          <Route exact path={`/:resource/:resource_id/ip-restrictions/:id`} component={StaffAllowedIPsOverlay} />

          <Route path={`/:resource/:resource_id/medical-assessments/:slug/:id-30-:settings_id`} component={DataFormSubmissionOverlay} />
          <Route path={`/:resource/:resource_id/medical-assessments/:slug/:id-30`} component={DataFormSubmissionOverlay} />

          <Route
            exact
            path={`/:resource/:resource_id/medical-assessments/history-and-physical/:id`}
            component={HistoryAndPhysicalOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/medical-assessments/history-and-physical-follow-up/:id`}
            component={HistoryAndPhysicalFollowUpOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/medical-assessments/psychiatric-assessment/:id`}
            component={PsychiatricAssessmentOverlay}
          />

          <Route
            exact
            path={`/:resource/:resource_id/medical-assessments/client-health-questionnaire/:id`}
            component={ClientHealthQuestionnaireOverlay}
          />

          <Route
            exact
            path={`/:resource/:resource_id/medical-assessments/client-health-questionnaire-initial-screening/:id`}
            component={ClientHealthQuestionnaireInitialScreeningOverlay}
          />

          <Route path={`/:resource/:resource_id/clinical-assessments/:slug/:id-30-:settings_id`} component={DataFormSubmissionOverlay} />
          <Route path={`/:resource/:resource_id/clinical-assessments/:slug/:id-30`} component={DataFormSubmissionOverlay} />

          <Route
            exact
            path={`/:resource/:resource_id/clinical-assessments/biopsychosocial-assessment/:id-10`}
            component={BiopsychosocialOverlayV1}
          />
          <Route
            exact
            path={`/:resource/:resource_id/clinical-assessments/biopsychosocial-assessment/:id-20`}
            component={BiopsychosocialOverlayV2}
          />
          <Route
            exact
            path={`/:resource/:resource_id/clinical-assessments/biopsychosocial-assessment/:id`}
            component={BiopsychosocialOverlayV1}
          />

          <Route
            exact
            path={`/:resource/:resource_id/clinical-assessments/mental-health-pre-screen/:id`}
            component={MentalHealthPreScreenOverlay}
          />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/pre-screen/:id-10`} component={PreScreenOverlayV1} />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/pre-screen/:id-20`} component={PreScreenOverlayV2} />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/pre-screen/:id`} component={PreScreenOverlayV1} />

          <Route exact path={`/:resource/:resource_id/clinical-assessments/suicide-assessment/:id`} component={SuicideAssessmentOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/asam-criteria/:id`} component={ASAMCriteriaOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/mental-status-exam/:id`} component={MentalStatusExamOverlay} />
          <Route
            exact
            path={`/:resource/:resource_id/clinical-assessments/eating-disorder-bps/:id-20`}
            component={EatingDisorderBPSOverlay}
          />
          <Route exact path={`/:resource/:resource_id/clinical-assessments/eating-disorder-bps/:id`} component={EatingDisorderBPSOverlay} />

          <Route exact path={`/:resource/:resource_id/archived-treatment-plans/:id-10`} component={TreatmentPlanOverlayV1} />
          <Route exact path={`/:resource/:resource_id/archived-treatment-plans/:id-20`} component={TreatmentPlanOverlayV2} />
          <Route exact path={`/:resource/:resource_id/archived-treatment-plans/:id`} component={TreatmentPlanOverlayV1} />

          <Route exact path={`/:resource/:resource_id/reported-conditions/:id`} component={AllergyOverlay} />

          <Route path={`/:resource/:resource_id/discharges/:slug/:id-30-:settings_id`} component={DataFormSubmissionOverlay} />
          <Route path={`/:resource/:resource_id/discharges/:slug/:id-30`} component={DataFormSubmissionOverlay} />

          {isEHR && (
            <Route exact path={`/:resource/:resource_id/discharges/non-clinical/:id`} component={NonClinicalDischargeSummaryOverlay} />
          )}
          {isEHR && <Route exact path={`/:resource/:resource_id/discharges/:id`} component={HealthcareDischargeSummaryOverlay} />}
          {!isEHR && <Route exact path={`/:resource/:resource_id/discharges/:id`} component={DischargeSummaryOverlay} />}

          <Route exact path={`/:resource/:resource_id/intake-forms/intake-form/:id`} component={IntakeForm} />

          <Route path={`/:resource/:resource_id/progress-reviews/:slug/:id-30-:settings_id`} component={DataFormSubmissionOverlay} />
          <Route path={`/:resource/:resource_id/progress-reviews/:slug/:id-30`} component={DataFormSubmissionOverlay} />
          <Route exact path={`/:resource/:resource_id/progress-reviews/:id-20`} component={ProgressReviewOverlay} />
          <Route exact path={`/:resource/:resource_id/progress-reviews/:id-10`} component={ProgressReviewOverlay} />
          <Route exact path={`/:resource/:resource_id/progress-reviews/:id`} component={ProgressReviewOverlay} />

          <Route
            path={`/:resource/:resource_id/recovery-coaching/case-management-checklist/:id`}
            component={CaseManagementChecklistOverlay}
          />

          <Route
            exact
            path={`/:resource/:resource_id/recovery-coaching/recovery-capital-scale/:id`}
            component={RecoveryCapitalScaleOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/recovery-coaching/recovery-capital-plan/:id`}
            component={RecoveryCapitalPlanOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/recovery-coaching/recovery-capital-scale-and-plan/:id`}
            component={RecoveryCapitalScaleAndPlanOverlay}
          />
          <Route exact path={`/:resource/:resource_id/recovery-coaching/mirc/:id`} component={MIRCOverlay} />
          <Route exact path={`/:resource/:resource_id/insurance-authorizations/:id`} component={InsuranceAuthorization} />
          <Route exact path={`/:resource/:resource_id/intake-forms/sober-living-home-agreement/:id`} component={SoberLivingHomeAgreement} />

          <Route path={`/:resource/:resource_id/nursing/:slug/:id-30-:settings_id`} component={DataFormSubmissionOverlay} />
          <Route path={`/:resource/:resource_id/nursing/:slug/:id-30`} component={DataFormSubmissionOverlay} />

          <Route exact path={`/:resource/:resource_id/nursing/cows/:id`} component={CowsOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/ciwa/:id`} component={CiwaOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/nursing-assessment/:id`} component={NursingAssessmentOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/nursing-reassessment/:id`} component={NursingReassessmentOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/progress-note/:id`} component={NursingProgressNoteOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/fall-risk-assessment/:id`} component={FallRiskAssessmentOverlay} />
          <Route exact path={`/:resource/:resource_id/nursing/initial-pain-scale-assessment/:id`} component={InitialPainScaleAssessment} />

          {/* Clinical Measures */}
          <Route exact path={`/:resource/:resource_id/clinical-measurements/ace-score/:id`} component={ACEScoreOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/asi-lite/:id`} component={ASILiteOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/asi5/:id`} component={ASI5Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/asrm/:id`} component={ASRMOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/audit-c/:id`} component={AUDITCOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/bam/:id`} component={BAMOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/bam-iop/:id`} component={BAMIOPOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/bam-r/:id`} component={BAMROverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/barc10/:id`} component={BARC10Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/dast10/:id`} component={DAST10Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/dow/:id`} component={DimensionsOfWellnessOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/gad2/:id`} component={GAD2Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/gad7/:id`} component={GAD7Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/mdi/:id`} component={MDIOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/pclc/:id`} component={PCLCOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/pdss/:id`} component={PDSSOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/phq15/:id`} component={PHQ15Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/phq2/:id`} component={PHQ2Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/phq9/:id`} component={PHQ9Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/whoqol/:id`} component={WHOQOLOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/tea/:id`} component={TEAOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/eat26/:id`} component={EAT26Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/edeq6/:id`} component={EDEQ6Overlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/scoff/:id`} component={SCOFFOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/cls/:id`} component={CLSOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/proqol/:id`} component={PROQOLOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/mscsb/:id`} component={MSCSBOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/phq9-teens/:id`} component={PHQ9TeensOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/cpss-v-sr/:id`} component={CPSSVSROverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/asq/:id`} component={ASQOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/crafft/:id`} component={CRAFFTOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/cage-aid/:id`} component={CAGEAIDOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/subs/:id`} component={SUBSOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/sdmi/:id`} component={SDMIOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/sed/:id`} component={SEDOverlay} />
          <Route exact path={`/:resource/:resource_id/clinical-measurements/epds/:id`} component={EPDSOverlay} />

          {/* Outcome Measures */}
          <Route exact path={`/:resource/:resource_id/outcome-measures/intake/:id`} component={IntakeFormOverlay} />
          <Route exact path={`/:resource/:resource_id/outcome-measures/locator/:id`} component={LocatorFormOverlay} />
          <Route exact path={`/:resource/:resource_id/outcome-measures/discharge-reminder/:id`} component={DischargeReminderFormOverlay} />
          <Route exact path={`/:resource/:resource_id/outcome-measures/follow-up/:id`} component={FollowUpFormOverlay} />
          <Route exact path={`/:resource/:resource_id/outcome-measures/service-summary/:id`} component={ServiceSummaryFormOverlay} />

          <Route exact path={`/:resource/:resource_id/outcome-measures/admit-survey/:id`} component={FoRSEAdmitSurveyOverlay} />
          <Route
            exact
            path={`/:resource/:resource_id/outcome-measures/demographic-information/:id`}
            component={FoRSEDemographicInformationOverlay}
          />
          <Route exact path={`/:resource/:resource_id/outcome-measures/discharge-survey/:id`} component={FoRSEDischargeSurveyOverlay} />
          <Route exact path={`/:resource/:resource_id/outcome-measures/outcomes-survey/:id`} component={FoRSEOutcomesSurveyOverlay} />
          <Route
            exact
            path={`/:resource/:resource_id/outcome-measures/progress-monitoring-survey/:id`}
            component={FoRSEProgressMonitoringSurveyOverlay}
          />
          <Route
            exact
            path={`/:resource/:resource_id/outcome-measures/recovery-capital-scale/:id`}
            component={OutcomeMeasureRecoveryCapitalScaleOverlay}
          />
          <Route exact path={`/:resource/:resource_id/outcome-measures/mirc/:id`} component={OutcomeMeasureMIRCOverlay} />

          {/* Financials */}
          <Route exact path="/:resource/:resource_id/financials/charges/:id" component={ChargeOverlay} />
          <Route exact path="/:resource/:resource_id/financials/fees/:id" component={FeeOverlay} />
          <Route exact path="/:resource/:resource_id/financials/payments/new-batch" component={PaymentWorksheetOverlay} />
          <Route exact path="/:resource/:resource_id/financials/payments/:id" component={PaymentOverlay} />
          <Route exact path="/:resource/:resource_id/financials/credits/:id" component={CreditOverlay} />
          <Route exact path="/:resource/:resource_id/financials/refunds/:id" component={RefundOverlay} />
          <Route exact path="/:resource/:resource_id/financials/write-offs/:id" component={WriteOffOverlay} />
          <Route exact path="/:resource/:resource_id/financials/charge-builder" component={ChargeBuilderOverlay} />
          <Route exact path="/:resource/:resource_id/financials/auto-repeating-charges/:id" component={AutoRepeatingChargeOverlay} />
          <Route exact path="/:resource/:resource_id/financials/send-notifications" component={SendFinancialPlanOverlay} />
          <Route exact path="/:resource/:resource_id/financials/payers/edit" component={EditPayers} />
          <Route exact path="/:resource/:resource_id/financials/payers/:id" component={PayerOverlay} />
          <Route exact path="/:resource/:resource_id/financials/payment-methods" component={PaymentMethods} />

          {/* Local Organization Financials */}
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/charges/:id`}
            component={OrganizationChargeOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/payments/:id`}
            component={OrganizationPaymentOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/credits/:id`}
            component={OrganizationCreditOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/refunds/:id`}
            component={OrganizationRefundOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/write-offs/:id`}
            component={OrganizationWriteOffOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/charge-builder`}
            component={OrganizationChargeBuilderOverlay}
          />
          <Route
            exact
            path={`/community/:resource/:resource_id/internal/financials/organization/auto-repeating-charges/:id`}
            component={OrganizationAutoRepeatingChargeOverlay}
          />

          {/* Staff Credentials */}
          <Route exact path="/:resource/:resource_id/credentials/:id" component={StaffCredentialOverlay} />
          <Route exact path="/:resource/:resource_id/insurance-credentials/:id" component={ProviderOverlay} />

          {/* Chat */}
          <ProtectedRoute
            path={`/clients-chat/message-blasts/:id`}
            component={ClientsMessageBlastOverlay}
            permission="clients_chat.view"
            featureFlag="clients_chat"
          />
          <ProtectedRoute
            path={`/company-chat/message-blasts/:id`}
            component={CompanyMessageBlastOverlay}
            permission="company_chat.view"
            featureFlag="staff_chat"
          />

          {/* Calendar */}
          <ProtectedRoute
            path={`/me/calendar/:view/event-builder`}
            component={EventBuilderOverlay}
            permission="events.view"
            featureFlag="calendar"
          />

          <ProtectedRoute
            path={'/community/organizations/(internal|authorities|providers|vendors)/import-new'}
            component={NewCommunityOrganizationOverlay}
          />
          <ProtectedRoute
            path={'/community/organizations/(internal|authorities|providers|vendors)/create-new'}
            component={NewOrganizationOverlay}
          />
        </AnimatedSwitch>

        {/* Help Center */}
        {showHelp && <HelpOverlay id={params.help} />}
        {isSearchOpen && <SearchOverlay onClose={closeSearch} />}

        <UploadTracker />
        <NotificationsContainer />
        <PortalsContainer />
        <Cheatsheet />

        <GlobalSummonOverlay />
      </AppContextProviders>
    )
  }
}

const AppNav = (props: any) => {
  const { blocks } = props

  const { isMobile, isTablet, isDesktop }: any = React.useContext(AppsUIContext)

  if (isMobile) return null

  return (
    <>
      <nav css={STYLES.appNav}>
        <QuickAdd hideLabel={isTablet && !isDesktop} css={STYLES.quickAdd} />
        <EHRAppNavItems blocks={blocks} />

        {/* TODO: Andrei add back */}
        {/* {false && (
        <>
          <div css={trialingStyles.root}>
            <Status
              color="lightGray"
              css={trialingStyles.status}
              label={accountType}
              onClick={() => {
                if (isBehave) this.getSession()
              }}
            />
            {isTrialing && (
              <Status color="gold" css={trialingStyles.status} label="Trialing" onClick={() => window.Beacon('open')} />
            )}
            <Divider className="!m-0" />
          </div>
        </>
      )} */}
      </nav>

      {/* <HelpTrigger /> */}
    </>
  )
}

const MobileQuickAdd = () => {
  const { isMobile }: any = React.useContext(AppsUIContext)

  if (!isMobile) return null

  return <QuickAdd hideLabel isFloating />
}

const AppEntryWithHotkeys = withHotkeys(AppEntry, 'app', [
  {
    key: '/',
    description: 'Search',
    action: (instance) => instance.openSearch(),
  },
  {
    key: 'ctrl+s',
    description: 'Show Setup',
    action: (instance) => instance.showSetup(),
  },
])

const AppEntryWithHotkeysWithStore = (props: any) => {
  const { isDebug } = useDebug()

  const access = useStore((state: any) => state.access)
  const user = useStore((state: any) => state.user)
  const newUser = useStore((state: any) => state.newUser)
  const tenant = useStore((state: any) => state.tenant)
  const online = useStore((state: any) => state.online)
  const idle = useStore((state: any) => state.idle)
  const devBar = useStore((state: any) => state.devBar)
  const blocks = useStore((state: any) => state.blocks)
  const debug = useStore((state: any) => state.debug)
  const showSecurity = useStore((state: any) => state.showSecurity)

  const isSearchOpen = useSearchStore((state: any) => state.isOpen)
  const openSearch = useSearchStore((state: any) => state.open)
  const closeSearch = useSearchStore((state: any) => state.close)

  return (
    <AppEntryWithHotkeys
      {...props}
      access={access}
      blocks={blocks}
      user={user}
      newUser={newUser}
      showSecurity={showSecurity}
      tenant={tenant}
      online={online}
      idle={idle}
      debug={debug}
      devBar={devBar}
      isSearchOpen={isSearchOpen}
      openSearch={openSearch}
      closeSearch={closeSearch}
    />
  )
}

const STYLES = {
  appSidebarLayout: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },

  appNav: {
    background: COLORS.white,
    padding: '0.5rem 1rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRight: `1px solid ${COLORS.divider}`,
    boxShadow: `
      1px 0 6px ${COLORS.shadow},
      3px 0 12px ${COLORS.shadow},
      6px 0 24px ${COLORS.shadow}
    `,
    position: 'relative',
    zIndex: 10,
  },

  appMain: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    flex: '1 1',
  },

  appMainColumns: {
    '@media(min-width: 600px)': {
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: '100%',
      width: '100%',
      height: '100%',
      overflow: 'hidden',

      '& > *': {
        overflowY: 'auto',
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'touch',
      },
    },
  },

  quickAdd: {
    margin: '0 -0.4rem 0.75rem',
  },
}

export default AppEntryWithHotkeysWithStore
