import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { PaymentMethodsReportDataTable } from '@behavehealth/constructs/LiveReports/PaymentMethodsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const PaymentMethods = () => {
  const tableProps = useDataTable({
    name: ['reports', 'payment-methods'],
    endpoint: `/live_reports?category=payment_methods`,
    localStorageKey: 'report_payment_methods_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.profile)}/financials`, [])

  return (
    <Page feature="financials" title="Live Payment Methods Report">
      <Grid>
        <PaymentMethodsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(PaymentMethods)
