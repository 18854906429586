import React from 'react'
import startCase from 'lodash/startCase'
import size from 'lodash/size'

import { age, address, monthsBetweenRelative } from '../../../../utils/functions'
import { ICONS } from '../../../../theme'
import { RECURRING_DAYS } from '../../../../utils/recurrence'
import { useOverlay } from '../../../../hooks/useOverlay'
import { useSettings } from '../../../../hooks/useSettings'
import { withOverlayError } from '../../../../hocs/withOverlayError'

import Accordions from '../../../Accordions'
import Alert from '../../../Alert'
import Attachments from '../../../Forms/Attachments'
import Button from '../../../Button'
import Checkbox from '../../../Forms/Checkbox'
import CheckboxGroup from '../../../Forms/CheckboxGroup'
import ColorSelector from '../../../Forms/ColorSelector'
import ContextShow from '../../../Forms/ContextShow'
import DateInput from '../../../Forms/DateInput'
import Divider from '../../../Divider'
import Flex from '../../../Flex'
import Form from '../../../Forms/Form'
import FormSection from '../../../Forms/FormSection'
import Glyph from '../../../Glyph'
import HelpTagIframe from '../../../Help/HelpTagIframe'
import Icon from '../../../Icon'
import Input from '../../../Forms/Input'
import Label from '../../../Label'
import Links from '../../../Forms/Links'
import MiniRichTextEditor from '../../../Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../../Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../../Forms/Option'
import Overlay from '../../../Overlay'
import OverlaySelector from '../../../Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../../Forms/Radio'
import RadioGroup from '../../../Forms/RadioGroup'
import Section from '../../../Section'
import Select from '../../../Forms/Select'
import Subtasks from '../../../Forms/Subtasks'
import TimeInputSelector from '../../../Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../../Forms/URLInput'

import client_portal_todo_example from '../../../../assets/images/client_portal_todo_example.png'

const TodoBuilderOverlay = (props: any) => {
  const { close, data, form, initialData, isInvalid, isSaving, onValidationUpdate, save } = useOverlay({
    name: 'todo',
    endpoint: '/events/builder',
    invalidate: 'todos',
    options: props,
    onAfterClose: (args) => {
      // open the newly created record
      if (!args.isNew || !args?.data?.id || !!props?.onClose) return

      if (args?.location?.pathname?.endsWith('/todo-builder')) {
        args.navigate(args?.location?.pathname.replace('/todo-builder', `/${args.data.id}`))
      }
    },
  })

  const { timezone, isPortal } = useSettings()

  const [recurringFrequency, setRecurringFrequency]: any = React.useState('never')
  const [startedAtDate, setStartedAtDate]: any = React.useState()
  const [repeatFromDate, setRepeatFromDate]: any = React.useState()
  const [repeatUntilDate, setRepeatUntilDate]: any = React.useState(null)
  const [formData, setFormData] = React.useState(initialData)

  const monthsDiff = monthsBetweenRelative(repeatFromDate, repeatUntilDate, timezone)
  const isMonthsDiffValid = recurringFrequency === 'never' ? true : !!monthsDiff && monthsDiff <= 12

  // const startDate = recurringFrequency === 'never' ? startedAtDate?.value : repeatFromDate?.value
  // const now = DateTime.local().setZone(timezone)
  // const allowDynamicClients = daysBetween(startDate, now) <= 1

  const hasOrganizations = size(formData?.organization_ids) > 0

  return (
    <Overlay maxWidth={40} onClose={close}>
      <Overlay.Header icon="tasks" title="Add To-Do" help={<HelpTagIframe id="add_todo" />} />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialData,
            ...data,
            recurringFrequency: 'never',
          }}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          linked={{
            event_type: 'task',
          }}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                autoFocus
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a task title',
                  },
                }}
              />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <Select model="priority" label="Priority" fullWidth defaultValue="normal">
                    <Option label="Low" value="low" />
                    <Option label="Normal" value="normal" />
                    <Option label="High" value="high" />
                  </Select>
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <MiniRichTextEditor useDictation useQuickText label="Public Description" model="public_description" />
            </FormSection>
          </Section>

          <Section>
            <Accordions>
              <Accordions.Item isOpen title="Date & Time">
                <FormSection>
                  <Select
                    label="Repeat"
                    model="recurring_frequency"
                    defaultValue="never"
                    onUpdate={(model: any) => {
                      setRecurringFrequency(model.value)
                    }}
                  >
                    <Option label="Never" value="never" />
                    <Option label="Daily" value="daily" />
                    <Option label="Weekly" value="weekly" />
                    <Option label="Bi-Weekly" value="biweekly" />
                    <Option label="Monthly" value="monthly" />
                    <Option label="Quarterly" value="quarterly" />
                    <Option label="Yearly" value="yearly" />
                  </Select>

                  <ContextShow when="recurring_frequency" is="never">
                    <DateInput
                      defaultToNow
                      label="Due Day"
                      model="started_at"
                      onUpdate={(value: any) => {
                        setStartedAtDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please select an todo date',
                        },
                      }}
                    />
                  </ContextShow>

                  {recurringFrequency !== 'never' && (
                    <>
                      <Flex stretchChildrenX gap="1rem">
                        <DateInput
                          defaultToTomorrow
                          label="Repeat From"
                          model="repeat_from"
                          onUpdate={({ date }: any) => {
                            setRepeatFromDate(date)
                          }}
                          validations={{
                            presence: {
                              message: 'Please select when the repeating todo should start',
                            },
                          }}
                        />

                        <DateInput
                          label="Repeat Until"
                          model="repeat_until"
                          onUpdate={({ date }: any) => {
                            setRepeatUntilDate(date)
                          }}
                          validations={{
                            presence: {
                              message: 'Please select when the repeating todo should end',
                            },
                            datetime: {
                              earliest: repeatFromDate?.date,
                              message: 'Please enter a future date',
                            },
                          }}
                        />
                      </Flex>

                      {monthsDiff > 12 && (
                        <Alert small glyph="warning" type="warning">
                          <div>The to-do will repeat for more than a year ({Math.round(monthsDiff)} months).</div>

                          <div>Please select a shorter duration.</div>
                        </Alert>
                      )}
                    </>
                  )}

                  {parseInt(repeatFromDate?.day) >= 29 && (
                    <ContextShow when="recurring_frequency" within={['monthly', 'quarterly']}>
                      <Alert glyph="info">This todo will be repeat only on months with a {repeatFromDate.day} day.</Alert>
                    </ContextShow>
                  )}

                  <ContextShow when="recurring_frequency" within={['weekly', 'biweekly']}>
                    <div>
                      <Label label="Repeat On" />
                      <Flex nowrap>
                        {RECURRING_DAYS.weekly.map((day: string) => (
                          <Checkbox
                            key={day}
                            label={day.charAt(0)}
                            model={`weekly_repeat_days.${day?.toLowerCase()}`}
                            css={styles.dayCheckbox}
                          />
                        ))}
                      </Flex>
                    </div>
                  </ContextShow>

                  <ContextShow when="is_all_day" is={false}>
                    <TimeInputSelector
                      defaultToNow
                      label="Due By"
                      model="start_time"
                      validations={{
                        presence: {
                          message: 'Please select when the todo starts',
                        },
                      }}
                    />
                  </ContextShow>

                  <Checkbox label="All Day Task" model="is_all_day" defaultValue={false} />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item isOpen title="Tasks">
                <Subtasks endpoint="events" model="subtasks" taskId={data?.id} />
              </Accordions.Item>

              <Accordions.Item isOpen title="Staff">
                <FormSection>
                  <MultiOverlaySelector
                    model="employees"
                    type="employees.active"
                    icon={ICONS.employees}
                    // selectTitle={(data: any) => data?.name}
                    // selectDescription={(data: any) => startCase(data?.position)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item isOpen title="Clients">
                <FormSection maxWidth="100%">
                  <Checkbox
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="none"
                    label="Share with Client Portal"
                    description={`Show this To-Do individually in each Clients' Client Portal`}
                    after={<Glyph glyph="portal" css={styles.meetingIcon} />}
                    model="share_with_client_portal"
                    tooltip={
                      <>
                        <div className="font-[600] mb-2">Example of how to-do's appear in the Client Portal App:</div>

                        <img
                          src={client_portal_todo_example}
                          alt="Client Portal To-Do Example"
                          width={518}
                          height={1200}
                          className="block w-[400px] h-auto rounded-md overflow-hidden shadow-soft-3"
                        />
                      </>
                    }
                  />

                  <Select label="Task Type" model="meeting_type" defaultValue="one_to_one" className="!flex-1">
                    <Option label="One-to-One" value="one_to_one" />
                    <Option label="Group Task" value="group_meeting" />
                  </Select>

                  <ContextShow when="meeting_type" is="one_to_one">
                    <OverlaySelector label="Client" type="clients.admissions_and_current" model="resident" icon={ICONS.clients} />
                  </ContextShow>

                  <ContextShow when="meeting_type" is="group_meeting">
                    <RadioGroup label="Attendees Import" model="clients_import_type" layout="vertical-dense" defaultValue="dynamic">
                      <Radio label="Automatic from Locations / Programs" value="dynamic" />
                      <Radio label="Manual from clients list" value="manual" />
                    </RadioGroup>

                    <ContextShow when="clients_import_type" is="manual">
                      <MultiObjectSelector
                        label="Client Attendees"
                        type="applicants_and_clients"
                        model="residents"
                        icon={ICONS.clients}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => `${startCase(data?.sex) || '–'}, ${age(data?.dob)} y/o, #${data?.behave_id}`}
                      />
                    </ContextShow>

                    <ContextShow when="clients_import_type" is="dynamic">
                      <MultiObjectSelector
                        label="Import from Locations"
                        model="imported_houses"
                        type="properties"
                        icon={ICONS.properties}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => address(data.address)}
                      />

                      <RadioGroup
                        label="Programs Import Type"
                        model="programs_import_type"
                        layout="horizontal-dense"
                        defaultValue="programs"
                      >
                        <Radio label="Programs" value="programs" />
                        <Radio label="Program Lists" value="program_lists" />
                      </RadioGroup>

                      <ContextShow when="programs_import_type" is="programs">
                        <MultiObjectSelector
                          label="Import from Programs"
                          type="programs"
                          model="imported_programs"
                          icon={ICONS.checklist}
                          selectTitle={(data: any) => data.name}
                        />
                      </ContextShow>

                      <ContextShow when="programs_import_type" is="program_lists">
                        <MultiObjectSelector
                          label="Import from Program Lists"
                          type="program_lists"
                          model="imported_phases"
                          icon={ICONS.checklist}
                          selectTitle={(data: any) => data.name}
                          selectDescription={(data: any) => data?.program?.name || '–'}
                        />
                      </ContextShow>

                      <Alert glyph="info">
                        <strong>Clients</strong> from the above <i>Locations</i>, <i>Programs</i> or <i>Program Lists</i> and will be
                        imported here
                      </Alert>
                    </ContextShow>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Organizations">
                <FormSection>
                  <MultiOverlaySelector
                    label="Organizations"
                    model="organizations"
                    type="organizations.all"
                    icon={ICONS.organizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />

                  <MultiOverlaySelector
                    label="Organization Contacts"
                    description="Select Contacts from the Organizations selected above"
                    model="organization_contacts"
                    type="all_organization_contacts"
                    dependentValue={formData?.organization_ids}
                    icon={ICONS.organizations}
                    isDisabled={!hasOrganizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Notifications">
                <FormSection>
                  <FormSection
                    icon="employees"
                    heading="Staff Notifications"
                    description="Please select what Notifications you would like to send to the Staff selected above"
                  >
                    <CheckboxGroup label="Notifications / Reminders Type" layout="vertical-dense">
                      <Checkbox defaultChecked label="Send via SMS" model="staff_notifications.should_sms" />
                      <Checkbox defaultChecked label="Send via Email" model="staff_notifications.should_email" />
                    </CheckboxGroup>

                    <ContextShow orMode when={['staff_notifications.should_sms', 'staff_notifications.should_email']} is={true}>
                      <CheckboxGroup layout="vertical-dense" label="Send Staff Members a One-Time Notification about this Task">
                        <Checkbox defaultChecked label="Send Notification" model="staff_notifications.send_notification" />
                      </CheckboxGroup>

                      <CheckboxGroup layout="vertical-dense" label="Send Staff Members Time-based Reminders about this Task">
                        <Checkbox label="Send Reminders" model="staff_notifications.should_send_reminders" />
                      </CheckboxGroup>
                    </ContextShow>

                    <ContextShow when="staff_notifications.should_send_reminders" is={true}>
                      <CheckboxGroup
                        label="Reminders Options"
                        layout="vertical-dense"
                        validations={{
                          presence: {
                            message: 'Please select at least one option',
                          },
                        }}
                      >
                        <Checkbox label="90 days before the task" model="staff_notifications.send_reminders.ninety_days" />
                        <Checkbox label="60 days before the task" model="staff_notifications.send_reminders.sixty_days" />
                        <Checkbox label="30 days before the task" model="staff_notifications.send_reminders.thirty_days" />
                        <Checkbox label="3 days before the task" model="staff_notifications.send_reminders.three_days" />
                        <Checkbox label="2 days before the task" model="staff_notifications.send_reminders.two_days" />
                        <Checkbox label="1 day before the task" model="staff_notifications.send_reminders.one_day" />
                        <Checkbox label="3 hours before the task" model="staff_notifications.send_reminders.three_hours" />
                        <Checkbox label="2 hours before the task" model="staff_notifications.send_reminders.two_hours" />
                        <Checkbox label="1 hour before the task" model="staff_notifications.send_reminders.one_hour" />
                        <Checkbox label="15 min before task" model="staff_notifications.send_reminders.15_minutes" />
                      </CheckboxGroup>
                    </ContextShow>
                  </FormSection>

                  <Divider className="!my-0.5" />

                  <FormSection
                    icon="clients"
                    heading="Clients Notifications"
                    description="Please select what Notifications you would like to send to the Clients selected above"
                  >
                    <CheckboxGroup label="Notifications / Reminders Type" layout="vertical-dense">
                      <Checkbox defaultChecked label="Send via SMS" model="clients_notifications.should_sms" />
                      <Checkbox defaultChecked label="Send via Email" model="clients_notifications.should_email" />
                    </CheckboxGroup>

                    <ContextShow orMode when={['clients_notifications.should_sms', 'clients_notifications.should_email']} is={true}>
                      <CheckboxGroup layout="vertical-dense" label="Send Clients a One-Time Notification about this Task">
                        <Checkbox defaultChecked label="Send Notification" model="clients_notifications.send_notification" />
                      </CheckboxGroup>

                      <CheckboxGroup layout="vertical-dense" label="Send Clients Time-based Reminders about this Task">
                        <Checkbox label="Send Reminders" model="clients_notifications.should_send_reminders" />
                      </CheckboxGroup>
                    </ContextShow>

                    <ContextShow when="clients_notifications.should_send_reminders" is={true}>
                      <CheckboxGroup
                        label="Reminders Options"
                        layout="vertical-dense"
                        validations={{
                          presence: {
                            message: 'Please select at least one option',
                          },
                        }}
                      >
                        <Checkbox label="90 days before the task" model="clients_notifications.send_reminders.ninety_days" />
                        <Checkbox label="60 days before the task" model="clients_notifications.send_reminders.sixty_days" />
                        <Checkbox label="30 days before the task" model="clients_notifications.send_reminders.thirty_days" />
                        <Checkbox label="3 days before the task" model="clients_notifications.send_reminders.three_days" />
                        <Checkbox label="2 days before the task" model="clients_notifications.send_reminders.two_days" />
                        <Checkbox label="1 day before the task" model="clients_notifications.send_reminders.one_day" />
                        <Checkbox label="3 hours before the task" model="clients_notifications.send_reminders.three_hours" />
                        <Checkbox label="2 hours before the task" model="clients_notifications.send_reminders.two_hours" />
                        <Checkbox label="1 hour before the task" model="clients_notifications.send_reminders.one_hour" />
                        <Checkbox label="15 min before task" model="clients_notifications.send_reminders.15_minutes" />
                      </CheckboxGroup>
                    </ContextShow>
                  </FormSection>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Place">
                <FormSection>
                  <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                    <Option label="None" value="none" />
                    <Option label="Online" value="online" />
                    <Option label="Housing" value="property" />
                    <Option label="Office" value="office" />
                    <Option label="Organization" value="organization" />
                    <Option label="Other" value="other" />
                  </Select>

                  {formData?.meeting_place === 'online' && (
                    <>
                      <Checkbox
                        defaultChecked
                        falseIcon="cross"
                        falseStyle="none"
                        label=" "
                        description="I acknowledge it is my responsibility to secure this external meeting room service that is outside the control of Behave Health Corp. This includes any requirements related to HIPAA. If you have any questions email us at contact@behavehealth.com"
                        model="responsibility_acknowledgement"
                      />

                      <URLInput
                        label="Meeting Link"
                        model="meeting_url"
                        isDisabled={formData?.responsibility_acknowledgement !== true}
                        validations={{
                          presence: {
                            message: 'Please enter a meeting link',
                          },
                        }}
                      />
                    </>
                  )}

                  <ContextShow when="meeting_place" is="property">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Housing"
                      type="properties"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="office">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Office"
                      type="offices"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="organization">
                    <ObjectSelector
                      label="Organization"
                      model="organization"
                      type="organizations"
                      icon={ICONS.organizations}
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" not="none">
                    <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Links">
                <Links model="urls" />
              </Accordions.Item>

              <Accordions.Item title="Notes">
                <FormSection>
                  <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Attachments" description="Upload the files related to this todo">
                <FormSection layout="vertical">
                  <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                </FormSection>
              </Accordions.Item>
            </Accordions>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save"
          type="primary"
          color="green"
          isLoading={isSaving}
          onClick={save}
          isDisabled={isInvalid || !isMonthsDiffValid}
          flex="100 1 auto"
          permission="todos.create"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const styles = {
  dayCheckbox: {
    width: 'auto !important',
    flex: '1 1 auto !important',
    marginLeft: 4,
    marginRight: 4,

    '@media (max-width: 550px)': {
      marginLeft: 2,
      marginRight: 2,
    },

    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },

    label: {
      fontSize: '0.9rem',

      '@media (max-width: 550px)': {
        padding: '0.5em 0.4em',
        flexDirection: 'column',
      },
    },

    '.check-element': {
      width: 18,
      height: 18,

      '@media (max-width: 550px)': {
        margin: '0 0 0.4rem 0',
      },
    },
  },

  meetingIcon: {
    marginLeft: 'auto',
  },
}

export default withOverlayError(TodoBuilderOverlay)
