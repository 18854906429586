import React from 'react'
import kebabCase from 'lodash/kebabCase'

import { getResourceLink } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { TreatmentPlansReportDataTable } from '@behavehealth/constructs/LiveReports/TreatmentPlansReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const TreatmentPlans = () => {
  const tableProps = useDataTable({
    name: ['reports', 'treatment-plans'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_treatment_plans_v1',
    params: { category: 'treatment_plans' },
  })

  const to = React.useMemo(
    () => (rowData: any) => `${getResourceLink(rowData.client)}/treatment-plans/${kebabCase(rowData.category)}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="treatment_plans" title="Live Treatment Plans Report">
      <Grid>
        <TreatmentPlansReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(TreatmentPlans)
