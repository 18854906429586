import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Avatar from '@behavehealth/components/Avatar'
import Flex from '@behavehealth/components/Flex'
import Page from '@behavehealth/components/Page'

import { Worksheet } from '@behavehealth/components/Worksheet/Worksheet'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

async function setClipboard() {
  const textType = 'text/plain'
  const jsonType = 'application/behavehealth'

  const textBlob = new Blob(['TEST 123'], { type: textType })
  const jsonBlob = new Blob([JSON.stringify({ test: 'TEST 123' })], { type: jsonType })

  const data = [new ClipboardItem({ [textType]: textBlob, [jsonType]: jsonBlob })]

  try {
    navigator.clipboard.write(data)
  } catch {}
}

async function getClipboardContents() {
  try {
    console.log('navigator', navigator)
    console.log('clipboard', navigator.clipboard)

    if ('clipboard' in navigator) {
      const clipboardItems = await navigator.clipboard.read()
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          const blob = await clipboardItem.getType(type)
          console.log(URL.createObjectURL(blob))
        }
      }
    }
  } catch (err) {
    console.error(err.name, err.message)
  }
}

const RootWorksheetDemo = () => {
  const match = useRouteMatch()

  const queryKey = ['forms']
  const queryURL = '/forms'
  const query: any = useGet({ name: queryKey, url: queryURL })

  const { data, folders, isLoading } = query

  const { mutate: deleteRecords } = useDelete({
    name: queryKey,
    url: '/forms',
    invalidate: 'forms',
  })

  const tableProps = useDataTable({
    name: ['clinical-notes'],
    endpoint: `/clinical_notes`,
    updateDeleteEndpoint: `/clinical_notes`,
    params: { category: 'group_note' },
    localStorageKey: 'clinical_notes_v1',
  })

  // const isEmpty = size(data) === 0
  // const { isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client',
        model: 'client',
        type: 'single_object',
        formatDisplayValue: ({ value }: any) => {
          if (!value) return null

          return (
            <Flex centerY gap="0.25rem">
              <Avatar src={value.avatar} size={20} initials={value.name} />
              {value.name}
            </Flex>
          )
        },
      },
      {
        title: 'Session Date and Time',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Duration',
        model: 'duration',
        type: 'number',
        formatDisplayValue: ({ value }: any) => `${value || 0} min`,
      },
      {
        title: 'Supervisor',
        model: 'supervisor',
        type: 'single_object',
        formatDisplayValue: ({ value }: any) => {
          if (!value) return null

          return (
            <Flex centerY gap="0.25rem">
              <Avatar src={value.avatar} size={20} initials={value.name} />
              {value.name}
            </Flex>
          )
        },
        config: {
          endpoint: '/employees',
          params: { category: 'active' },
        },
      },
      {
        title: 'Group Topic',
        model: 'topic',
      },
      {
        title: 'Group Discussion',
        model: 'description',
      },
      {
        title: 'Treatment Goal Addressed',
        model: 'treatment_goal',
        type: '',
      },
      {
        title: 'Presence',
        model: 'is_attending',
        type: 'boolean',
        config: {
          trueValue: 'Present',
          falseValue: 'Absent',
        },
      },
      {
        title: 'Individual Assessment',
        model: 'assessment',
        type: '',
      },
      {
        title: 'Absentee Note',
        model: 'absentee_note',
      },
    ]
  }, [])

  return (
    <Page title="Worksheet Demo" glyph="table">
      <div css={{ display: 'grid', gridGap: '1rem', gridTemplateColumns: '100%' }}>
        <button onClick={() => setClipboard('AAA')}>SET CLIPBOARD</button>
        <button onClick={getClipboardContents}>VIEW CLIPBOARD</button>

        <Worksheet asCard {...tableProps} columns={columns} />
      </div>
    </Page>
  )
}

const DATA = [
  {
    _id: 'd56c6a38-7b59-4ec4-98cc-53bacf68bc52',
    order: 1,
    cells: [
      { _id: 'db28d782-b543-4cca-9a35-9b913deab119', type: 'string', align: 'left', value: 'String A' },
      { _id: 'fd1778be-3c63-4969-aa5a-71396bbb1392', type: 'number', align: 'right', value: '100' },
      { _id: '8212b599-0d9d-4727-a54f-fbc21b30f9c8', type: 'amount', align: 'right', value: '100' },
    ],
  },
  {
    _id: '02fbec25-d2f0-4416-840e-531f4eb89534',
    order: 2,
    cells: [
      { _id: 'f642eb7f-e75a-422f-bb90-cbfd2ad72e96', type: 'string', align: 'left', value: 'String B' },
      { _id: 'f14a6939-2b7a-47fa-b2c5-6285db524b01', type: 'number', align: 'right', value: '200' },
      { _id: 'e5a4918b-dc57-4db1-9ced-3fb926803e78', type: 'amount', align: 'right', value: '200' },
    ],
  },
  {
    _id: '388c50cd-19d7-46af-b43e-5cda8f948f9f',
    order: 3,
    cells: [
      { _id: '2a5708fb-adb8-4294-8b76-aa10237d804a', type: 'string', align: 'left', value: 'String C' },
      { _id: 'c5f0c803-5465-4606-af8f-58dacb4a8126', type: 'number', align: 'right', value: '300' },
      { _id: 'ea9efae9-f47f-477f-9b80-07d6d5e5c123', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '45a40d20-093d-451a-b060-e51853c038f6',
    order: 3,
    cells: [
      { _id: '68ace336-b324-4e96-a6ac-b5436834ad4c', type: 'string', align: 'left', value: 'String C' },
      { _id: '48081433-8ef5-4a86-9865-6fdefce79bed', type: 'number', align: 'right', value: '300' },
      { _id: '7640b927-cda2-4f68-9185-e2ce52b41daa', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: 'e61532b4-33c2-4aaa-bbbd-172ad3ecb248',
    order: 3,
    cells: [
      { _id: '4495392e-cc27-4540-be76-65a3f9f1c3ea', type: 'string', align: 'left', value: 'String C' },
      { _id: '82f235dc-696a-4f8a-9290-84b9660c4aba', type: 'number', align: 'right', value: '300' },
      { _id: 'b69ff9db-f351-4a9a-9513-8e0de16e0be3', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '7548d80e-1515-4a33-b9b6-3e687a80e2b9',
    order: 3,
    cells: [
      { _id: '25765c95-5e40-43f3-9de9-ac736ff2a7a2', type: 'string', align: 'left', value: 'String C' },
      { _id: '98bafb79-9dc6-4f32-85b6-c058f4ec1be8', type: 'number', align: 'right', value: '300' },
      { _id: '53b1941c-f7e8-4a68-b04c-e4e21105ae28', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '2c16df84-605d-4187-b7a4-5298a69ba3ec',
    order: 3,
    cells: [
      { _id: 'ac3871b6-0248-4127-97b3-e0d4bd04789e', type: 'string', align: 'left', value: 'String C' },
      { _id: '9348c5aa-8a6e-4f63-b7a3-b0b2b6a02c31', type: 'number', align: 'right', value: '300' },
      { _id: '35df86d5-94b0-494a-9ea2-bd605c5a54d5', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '9593b9bf-8e78-4219-bcf0-cf972e7d5805',
    order: 3,
    cells: [
      { _id: '163db6c8-8cef-497a-b7fa-2e490ab2368d', type: 'string', align: 'left', value: 'String C' },
      { _id: '9deb7f12-62ea-49de-b35a-1ee755b4b424', type: 'number', align: 'right', value: '300' },
      { _id: '8df76b25-8ae7-483f-85b3-8d2bbc116b4c', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: 'ec3379e6-ce5c-47d8-9beb-75e603f4322e',
    order: 3,
    cells: [
      { _id: 'f322e0b4-3bc2-4a8d-8d9d-ccfa829ad4cc', type: 'string', align: 'left', value: 'String C' },
      { _id: '5782be81-1c4f-4243-9cbe-e7e18f635740', type: 'number', align: 'right', value: '300' },
      { _id: 'b7871348-f961-4d17-a2d0-a98d8cd3be13', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '61dd4b3e-69cd-46ce-a47b-106187496421',
    order: 3,
    cells: [
      { _id: '4db58bf6-8e82-4361-94b5-3a3c0731517c', type: 'string', align: 'left', value: 'String C' },
      { _id: '4ea066a8-755d-4e13-8cb4-702815f6e67d', type: 'number', align: 'right', value: '300' },
      { _id: '6b6053de-4e75-4b3d-9a0b-13b6eb455a46', type: 'amount', align: 'right', value: '300' },
    ],
  },
  {
    _id: '49bc79a9-3578-4e01-92e1-949d52e62fd0',
    order: 3,
    cells: [
      { _id: 'b60c2b1f-7369-4c82-ae95-bc182949a11c', type: 'string', align: 'left', value: 'String C' },
      { _id: '9277cc88-b2ad-4ff9-b583-50736550b36e', type: 'number', align: 'right', value: '300' },
      { _id: '131d727f-5f03-44be-a9c7-c7a393a08302', type: 'amount', align: 'right', value: '300' },
    ],
  },
]

const COLUMNS = [
  {
    _id: '48a5b1c8-0631-4779-b692-8dd687f94ac3',
    minWidth: '200px',
    name: 'String',
    type: 'string',
    order: 1,
  },
  {
    _id: '60358715-c203-4776-b911-261fc918466a',
    minWidth: '200px',
    name: 'Number',
    type: 'number',
    order: 2,
  },
  {
    _id: '46103e76-f68e-4516-862c-a99d306ba531',
    minWidth: '200px',
    name: 'Amount',
    type: 'amount',
    order: 3,
  },
]

export const WorksheetDemo = withPageError(RootWorksheetDemo)
