import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { EventsReportDataTable } from '@behavehealth/constructs/LiveReports/EventsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Events = () => {
  const tableProps = useDataTable({
    name: ['reports', 'events'],
    endpoint: `/live_reports?category=events`,
    localStorageKey: 'report_events_v1',
    params: { event_type: 'event' },
  })

  const to = React.useMemo(() => (rowData: any) => `/calendar/${rowData.id}`, [])

  return (
    <Page feature="calendar" title="Live Events Report">
      <Grid>
        <EventsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Events)
