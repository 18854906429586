import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'
import { LocalPayer } from '@behavehealth/declarations'

import { Button, Card, CardContent, Chotomate, DnDUploader, Page, Uploader, HelpTagIframe } from '@behavehealth/components'

import FilesTable from '@behavehealth/components/Tables/FilesTable'

const FaxesAndVoicemails: React.FC = () => {
  const match = useRouteMatch()
  const payer: LocalPayer = useSelector((state) => state.data.insurance_local_payers?.data[match?.params?.resource_id])
  const data = useSelector((state) => state.data.documents?.data)
  const loading = useSelector((state) => state.data.documents?.loading)

  useAPI('documents', `/insurance_local_payers/${match.params?.resource_id}/documents?category=general&subcategory=fax_voicemail`)

  const isEmpty = size(data) === 0

  const actions = (
    <Uploader category="general" subcategory="fax_voicemail" resource={payer}>
      <Button type="primary" glyph="upload" label="Upload Files" />
    </Uploader>
  )

  return (
    <Page feature="payer_faxes_and_voicemails" actions={!isEmpty && actions} help={<HelpTagIframe id="payer_faxes_and_voicemails" />}>
      <Chotomate name="files" ready={!loading} />

      <Card>
        <FilesTable
          data={data}
          isLoading={isEmpty && loading}
          emptyActions={actions}
          localStorageKey="payer_faxes_and_voicemails"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
        <CardContent>
          <DnDUploader category="general" subcategory="fax_voicemail" resource={payer} isEditable={true} isDisabled={false} />
        </CardContent>
      </Card>
    </Page>
  )
}

export default FaxesAndVoicemails
