import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'

import Alert from '../../components/Alert'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

export const EPDSOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Edinburgh Postnatal Depression Scale (EPDS)"
      category="clinical_measurement"
      subcategory="epds"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <div className="grid gap-3">
          <div>
            The EPDS was developed for screening postpartum women in outpatient, home visiting settings, or at the 6 –8 week postpartum
            examination. It has been utilized among numerous populations including U.S. women and Spanish speaking women in other countries.
            The EPDS consists of 10 questions. The test can usually be completed in less than 5 minutes. Responses are scored 0, 1, 2, or 3
            according to increased severity of the symptom. Items marked with an asterisk (*) are reverse scored (i.e., 3, 2, 1, and 0). The
            total score is determined by adding together the scores for each of the 10 items. Validation studies have utilized various
            threshold scores in determining which women were positive and in need of referral. Cut-off scores ranged from 9 to 13 points.
            Therefore, to err on safety's side, a woman scoring 9 or more points or indicating any suicidal ideation – that is she scores 1
            or higher on question #10 – should be referred immediately for follow-up. Even if a woman scores less than 9, if the clinician
            feels the client is suffering from depression, an appropriate referral should be made. The EPDS is only a screening tool. It
            does not diagnose depression – that is done by appropriately licensed health care personnel. Users may reproduce the scale
            without permission providing the copyright is respected by quoting the names of the authors, title and the source of the paper
            in all reproduced copies.
          </div>

          <div>
            <b>Instructions for Users</b>
          </div>

          <ol>
            <li>
              The mother is asked to underline 1 of 4 possible responses that comes the closest to how she has been feeling the previous 7
              days.
            </li>
            <li>All 10 items must be completed.</li>
            <li>Care should be taken to avoid the possibility of the mother discussing her answers with others.</li>
            <li>The mother should complete the scale herself, unless she has limited English or has difficulty with reading.</li>
          </ol>
        </div>
      </Section>

      <Divider />

      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  return (
    <>
      <Alert small contrast glyph="info">
        As you have recently had a baby, we would like to know how you are feeling. Please UNDERLINE the answer which comes closest to how
        you have felt IN THE PAST 7 DAYS, not just how you feel today.
      </Alert>

      <FormSection>
        <h3 className="mt-6">In the past 7 days:</h3>

        <RadioGroup
          label="1. I have been able to laugh and see the funny side of things"
          model="data.questionnaire.q1"
          layout="vertical-dense"
        >
          <Radio label="As much as I always could" value={0} />
          <Radio label="Not quite so much now" value={1} />
          <Radio label="Definitely not so much now" value={2} />
          <Radio label="Not at all" value={3} />
        </RadioGroup>

        <RadioGroup label="2. I have looked forward with enjoyment to things" model="data.questionnaire.q2" layout="vertical-dense">
          <Radio label="As much as I ever did" value={0} />
          <Radio label="Rather less than I used to" value={1} />
          <Radio label="Definitely less than I used to" value={2} />
          <Radio label="Hardly at all" value={3} />
        </RadioGroup>

        <RadioGroup
          label="*3. I have blamed myself unnecessarily when things went wrong"
          model="data.questionnaire.q3"
          layout="vertical-dense"
        >
          <Radio label="Yes, most of the time" value={3} />
          <Radio label="Yes, some of the time" value={2} />
          <Radio label="Not very often" value={1} />
          <Radio label="No, never" value={0} />
        </RadioGroup>

        <RadioGroup label="4. I have been anxious or worried for no good reason" model="data.questionnaire.q4" layout="vertical-dense">
          <Radio label="No, not at all" value={0} />
          <Radio label="Hardly ever" value={1} />
          <Radio label="Yes, sometimes" value={2} />
          <Radio label="Yes, very often" value={3} />
        </RadioGroup>

        <RadioGroup label="*5. I have felt scared or panicky for no very good reason" model="data.questionnaire.q5" layout="vertical-dense">
          <Radio label="Yes, quite a lot" value={3} />
          <Radio label="Yes, sometimes" value={2} />
          <Radio label="No, not much" value={1} />
          <Radio label="No, not at all" value={0} />
        </RadioGroup>

        <RadioGroup label="*6. Things have been getting on top of me" model="data.questionnaire.q6" layout="vertical-dense">
          <Radio label="Yes, most of the time I haven't been able to cope at all" value={3} />
          <Radio label="Yes, sometimes I haven't been coping as well as usual" value={2} />
          <Radio label="No, most of the time I have coped quite well" value={1} />
          <Radio label="No, have been coping as well as ever" value={0} />
        </RadioGroup>

        <RadioGroup
          label="*7. I have been so unhappy that I have had difficulty sleeping"
          model="data.questionnaire.q7"
          layout="vertical-dense"
        >
          <Radio label="Yes, most of the time" value={3} />
          <Radio label="Yes, sometimes" value={2} />
          <Radio label="Not very often" value={1} />
          <Radio label="No, not at all" value={0} />
        </RadioGroup>

        <RadioGroup label="*8. I have felt sad or miserable" model="data.questionnaire.q8" layout="vertical-dense">
          <Radio label="Yes, most of the time" value={3} />
          <Radio label="Yes, quite often" value={2} />
          <Radio label="Not very often" value={1} />
          <Radio label="No, not at all" value={0} />
        </RadioGroup>

        <RadioGroup label="*9. I have been so unhappy that I have been crying" model="data.questionnaire.q9" layout="vertical-dense">
          <Radio label="Yes, most of the time" value={3} />
          <Radio label="Yes, quite often" value={2} />
          <Radio label="Only occasionally" value={1} />
          <Radio label="No, never" value={0} />
        </RadioGroup>

        <RadioGroup label="*10. The thought of harming myself has occurred to me" model="data.questionnaire.q10" layout="vertical-dense">
          <Radio label="Yes, quite often" value={3} />
          <Radio label="Sometimes" value={2} />
          <Radio label="Hardly ever" value={1} />
          <Radio label="Never" value={0} />
        </RadioGroup>
      </FormSection>

      <Divider />

      <div className="text-text-muted italic text-[0.9rem]">
        <div>EDINBURGH POSTNATAL DEPRESSION SCALE (EPDS) J. L. Cox, J.M. Holden, R. Sagovsky</div>
        <div>From: British Journal of Psychiatry (1987), 150, 782-786.</div>
      </div>
    </>
  )
}
