import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Global } from '@emotion/react'
import size from 'lodash/size'
import { parse } from 'query-string'
import { DateTime } from 'luxon'

import { COLORS, SHADOW } from '@behavehealth/theme'
import { supportedAuth, login, sendOTP, loginOTP, sendMagic, loginMagic } from '@behavehealth/modules/auth'
import { getToken, buildBaseUrl, setHeaders } from '@behavehealth/modules/axios'
import { setIdle, setIdleSaving } from '@behavehealth/actions/common'
import { titleCase, nicePhoneNo } from '@behavehealth/utils/functions'
import { store } from '@behavehealth/setup/store'

import Overlay from '@behavehealth/components/Overlay'

import {
  Alert,
  AppLayout,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardLink,
  CardSubtitle,
  CardTitle,
  DataGrid,
  Divider,
  Flex,
  Form,
  Glyph,
  Grid,
  Icon,
  Input,
  Link,
  Loader,
  Logo,
  MobileAppBadge,
  PortalsContainer,
  SubmitButton,
  SummonOverlay,
  Text,
  State,
} from '@behavehealth/components'

import PasswordShowHideInput from '@behavehealth/components/Forms/PasswordShowHideInput'

import { InternalEHRAccessDataTable } from '@behavehealth/constructs/Internal/InternalEHRAccessDataTable'
import ServicesStatus from '@behavehealth/components/ServicesStatus'

import { PinInput } from '@behavehealth/components/PinInput'
import { ResendOTP } from '@behavehealth/constructs/Auth/ResendOTP'

import { LoginMessages } from '@behavehealth/constructs/LoginMessages/LoginMessages'
import { APP_URLS, generateLoginURL } from '@behavehealth/constructs/MyAccount/utils'

const LOGIN_TYPES = {
  otp: 'otp',
  magic: 'magic',
  password: 'password',
}

const AlternativeLoginMethods = ({ onClose, username, onMagicLoginSelected, onPasscodeSelected }: any) => {
  const [isSendingMagicLogin, setIsSendingMagicLogin] = React.useState(false)
  const [isSendingPasscode, setIsSendingPasscode] = React.useState(false)

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header title="Choose an alternative Login Method" glyph="lock" />

      <Overlay.Content>
        <Flex stretchChildrenX gap="1rem" className="p-5">
          <CardLink
            showChevron
            isLoading={isSendingMagicLogin}
            graphic={<Icon icon="magic_link" />}
            onClick={async () => {
              setIsSendingMagicLogin(true)
              await onMagicLoginSelected()
              setIsSendingMagicLogin(false)
              onClose()
            }}
          >
            <CardTitle title="Magic Login" />
            <CardSubtitle subtitle={`Use this to send a direct login link to ${username}`} />
          </CardLink>

          <CardLink
            showChevron
            graphic={<Icon icon="passcode" />}
            isLoading={isSendingPasscode}
            onClick={async () => {
              setIsSendingPasscode(true)
              await onPasscodeSelected()
              setIsSendingPasscode(false)
              onClose()
            }}
          >
            <CardTitle title="One Time Passcode" />
            <CardSubtitle subtitle={`Use this to send a 6-digit passcode to ${nicePhoneNo(username)}`} />
          </CardLink>
        </Flex>
      </Overlay.Content>
    </Overlay>
  )
}

const Login: React.FC = () => {
  const form = React.useRef()

  const [errors, setErrors] = React.useState(null)
  const [isBehave, setIsBehave] = React.useState(false)
  const [isAutoLogIn, setIsAutoLogIn] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccessful, setIsSuccessful] = React.useState(false)
  const [tenants, setTenants] = React.useState([])

  const [username, setUsername]: any = React.useState('')
  const [checkedUsername, setCheckedUsername] = React.useState('')
  const [password, setPassword]: any = React.useState('')
  const [code, setCode]: any = React.useState('')

  const [loginType, setLoginType]: any = React.useState(null)
  const [authSupported, setAuthSupported]: any = React.useState([])
  const [isLoadingSL, setIsLoadingSL] = React.useState(false)
  const [isSendingOTP, setIsSendingOTP] = React.useState(false)
  const [didCheckOTP, setDidCheckOTP] = React.useState(false)
  const [didEnterWrongPassword, setDidEnterWrongPassword] = React.useState(false)

  const doesSupportOTP = authSupported.includes(LOGIN_TYPES.otp)
  const isUsernameInputVisible = !loginType || loginType === LOGIN_TYPES.password

  const isTenantsEmpty = size(tenants) === 0

  let history = useHistory()
  let location = useLocation()

  const checkLogin = async () => {
    setIsLoadingSL(true)
    setErrors(null)

    try {
      const { supported, is_behave }: any = await supportedAuth({ username })

      setAuthSupported(supported)
      setCheckedUsername(username)
      setIsBehave(is_behave)

      // default to password
      if (supported.includes(LOGIN_TYPES.password)) {
        setLoginType(LOGIN_TYPES.password)
      }
      // if password is not supported, default to Magic & send Magic Link
      else if (supported.includes(LOGIN_TYPES.magic)) {
        setLoginType(LOGIN_TYPES.magic)
        await handleSendMagic()
      }
      // if password or magic is not supported, default to otp & send OTP
      else if (supported.includes(LOGIN_TYPES.otp)) {
        setLoginType(LOGIN_TYPES.otp)
        await handleSendOTP()
      }
    } catch (error) {
      console.error(error)
      setErrors(error.data?.errors.join(' '))
    } finally {
      setIsLoading(false)
      setIsLoadingSL(false)
    }
  }

  const handleSendOTP = async () => {
    setErrors(null)
    setIsSendingOTP(true)
    setDidEnterWrongPassword(false)

    try {
      await sendOTP({ username })

      if (loginType !== LOGIN_TYPES.otp) setLoginType(LOGIN_TYPES.otp)
    } catch (error) {
      console.error(error)
    } finally {
      setIsSendingOTP(false)
    }
  }

  const handleSendMagic = async () => {
    setErrors(null)
    setDidEnterWrongPassword(false)

    try {
      await sendMagic({ username })

      if (loginType !== LOGIN_TYPES.magic) setLoginType(LOGIN_TYPES.magic)
    } catch (error) {
      console.error(error)
    }
  }

  const loginWithPassword = async () => {
    setIsLoading(true)
    setErrors(null)

    try {
      const results = await login({ as: 'employee', username, password, include_access: !isBehave })
      await setHeaders()

      setIsSuccessful(true)

      if (!isBehave) setTenants(results.data.tenants)
    } catch (error) {
      console.error(error)
      setErrors(error.data?.errors.join(' '))
      setDidEnterWrongPassword(true)
    } finally {
      setIsLoading(false)
    }
  }

  const loginWithOTP = async () => {
    setDidCheckOTP(true)
    setIsLoading(true)

    try {
      const results = await loginOTP({ as: 'employee', username, code })
      await setHeaders()

      setIsSuccessful(true)

      if (!isBehave) setTenants(results.data.data.access.ehr)
    } catch (error) {
      console.error(error)
      setErrors(error.data?.errors.join(' '))
    } finally {
      setIsLoading(false)
    }
  }

  // Handle automatic login with OTP
  React.useEffect(() => {
    if (didCheckOTP) return // only run once

    if (!username || size(code) < 6 || loginType !== LOGIN_TYPES.otp) return

    loginWithOTP()
  }, [didCheckOTP, code, loginType, username])

  // Reset login type and errors if username changes
  React.useEffect(() => {
    if (username !== checkedUsername) {
      setLoginType(null)
      setErrors(null)
    }
  }, [username, checkedUsername])

  React.useEffect(() => {
    store.dispatch(setIdle(false))
    store.dispatch(setIdleSaving(false))

    const onMount = async () => {
      // build urls for requests
      await buildBaseUrl()

      let params = parse(location.search)

      const token = await getToken()
      if (token) {
        if (params?.goto) {
          history.push(params.goto)
        } else {
          const tenantURL = sessionStorage.getItem('bh.stn') || localStorage.getItem('bh.stn')
          if (tenantURL) history.push('/')
        }
      }

      if (params?.at) {
        if (!!window.ReactNativeWebView) {
          localStorage.setItem('bh.stn', params?.stn)
        } else {
          sessionStorage.setItem('bh.stn', params?.stn)
        }
        localStorage.setItem('bh.as', params?.as)
        localStorage.setItem('bh.at', params?.at)
        localStorage.setItem('bh.ea', DateTime.local().plus({ seconds: params?.ea }).toISO())

        history.push('/dashboard')
      }

      if (params?.stytch_token_type && params?.stytch_token_type === 'magic_links') {
        setIsAutoLogIn(true)

        try {
          const results = await loginMagic({ as: 'employee', token: params?.token })
          await setHeaders()

          setIsSuccessful(true)

          if (!isBehave) setTenants(results.data.data.access.ehr)
        } catch (error: any) {
          console.error(error)
          setErrors(error.data?.errors.join(' '))
        } finally {
          setIsAutoLogIn(false)
        }
      }
    }

    onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    setIsSuccessful(false)
    setIsBehave(false)
    setTenants([])

    setCode(null)
    setErrors(null)
    setPassword('')
    setLoginType(null)
    setIsLoadingSL(false)
    setDidEnterWrongPassword(false)
  }

  const onSelect = ({ tenant, record }: any) => {
    if (!!window.ReactNativeWebView) {
      localStorage.setItem('bh.stn', tenant)
      localStorage.setItem('bh.str', record)
    } else {
      sessionStorage.setItem('bh.stn', tenant)
      sessionStorage.setItem('bh.str', record)
    }

    history.push('/')
  }

  return (
    <>
      <div css={{ height: '100%', display: 'contents' }}>
        <Global styles={globalStyles} />

        <AppLayout columns="1fr" background={COLORS.lightBackground}>
          <Flex vertical stretchSelfMax nowrap justifyContent="space-between" className="!w-full">
            <LoginMessages app="ehr" />

            <div>
              <ServicesStatus />

              <Flex justifyContent="center" className="!my-8 !mx-0">
                <Logo logo="ehr" />
              </Flex>
            </div>

            {isAutoLogIn ? (
              <Grid centerX centerY gap="1rem">
                <Loader size={24} speed={500} />
                <p>Logging You In…</p>
              </Grid>
            ) : (
              <>
                {!isSuccessful && (
                  <div
                    css={{
                      margin: '2rem auto',
                      maxWidth: loginType === LOGIN_TYPES.otp ? 500 : 380,
                      width: '100%',
                      padding: '3rem 1.75rem',
                    }}
                  >
                    <Grid gap="1rem" className="!my-4 !mx-0">
                      {location.state && (
                        <Alert contrast glyph="info" type="warning">
                          {location.state.message}
                        </Alert>
                      )}

                      <Form getForm={form}>
                        <>
                          <div
                            css={{
                              overflow: isUsernameInputVisible ? 'visible' : 'hidden',
                              height: isUsernameInputVisible ? 'auto' : 0,
                            }}
                          >
                            <Input
                              autoFocus
                              withHover={false}
                              autoCapitalize="off"
                              label="Email / Cell Number"
                              glyph="user_neutral"
                              value={username}
                              model="username"
                              onUpdate={({ value }: any) => {
                                setUsername(value)
                              }}
                              validations={{
                                presence: {
                                  message: 'Please enter your email or cell number',
                                },
                              }}
                            />
                          </div>

                          {loginType === LOGIN_TYPES.otp && (
                            <>
                              <div css={STYLES.backButton} onClick={goBack}>
                                <div className="back-button-arrow">←</div>
                                <div>Back</div>
                              </div>

                              <Grid gap="0.5rem">
                                <h1 css={STYLES.title}>Verify Passcode</h1>
                                <div css={STYLES.subtitle}>Please enter the 6-digit passcode sent to:</div>
                                <div css={STYLES.monoTypeBlock}>{username}</div>
                              </Grid>

                              <div css={STYLES.pinInputWrapper}>
                                <PinInput onChange={setCode} onComplete={setCode} />
                              </div>
                            </>
                          )}

                          {loginType === LOGIN_TYPES.magic && (
                            <div css={{ margin: '2rem 0 4rem 0' }}>
                              <div css={STYLES.backButton} onClick={goBack}>
                                <div className="back-button-arrow">←</div>
                                <div>Back</div>
                              </div>

                              <Grid centerX gap="0.5rem">
                                <Glyph glyph="email" size={40} />
                                <h1 css={STYLES.title}>Check your inbox</h1>
                                <div css={STYLES.subtitle}>We've sent you a magic link to:</div>
                                <div css={STYLES.monoTypeBlock}>{username}</div>

                                <div css={{ ...STYLES.subtitle, marginTop: '1rem' }}>
                                  Please <strong>click the "Log in" button</strong> in the email you received to login automatically.
                                </div>
                              </Grid>

                              <Grid gap="1rem" className="!mt-8">
                                <div>
                                  <strong>Can't see the email?</strong> Please check the spam folder.
                                </div>
                                <div>
                                  <strong>Wrong email?</strong> <Link onClick={goBack}>Please re-enter your address.</Link>
                                </div>
                              </Grid>
                            </div>
                          )}

                          <div css={STYLES.inputWrapper} className={loginType === LOGIN_TYPES.password ? 'input-show' : 'input-hide'}>
                            <PasswordShowHideInput
                              withHover={false}
                              autocomplete="password"
                              autoFocus={loginType === LOGIN_TYPES.password}
                              label="Password"
                              value={password}
                              onUpdate={({ value }: any) => {
                                setPassword(value)
                              }}
                              validateOn=" "
                              validations={{
                                presence: {
                                  message: 'Please enter a password',
                                },
                                length: {
                                  minimum: 10,
                                  message: 'Your password should have at least 10 characters',
                                },
                              }}
                              labelAfter={
                                doesSupportOTP && (
                                  <SummonOverlay
                                    overlay={
                                      <AlternativeLoginMethods
                                        onMagicLoginSelected={handleSendMagic}
                                        onPasscodeSelected={handleSendOTP}
                                        username={username}
                                      />
                                    }
                                  >
                                    <Button label="Use other Login Method" type="link" size={200} css={STYLES.labelButton} />
                                  </SummonOverlay>
                                )
                              }
                            />
                          </div>

                          {errors && (
                            <Alert contrast glyph="circle_error" type="negative" css={STYLES.alert}>
                              {errors}
                            </Alert>
                          )}

                          {doesSupportOTP && didEnterWrongPassword && loginType === LOGIN_TYPES.password && (
                            <Alert contrast glyph="one_time_password" css={STYLES.alert}>
                              <div>
                                <b>Forgot Password?</b>
                              </div>
                              <div>You can log in using a passcode instead. Click the link above to generate one.</div>
                            </Alert>
                          )}

                          {loginType === LOGIN_TYPES.password && (
                            <>
                              <SubmitButton
                                fullWidth
                                key="password-login-button"
                                label="Log In Securely →"
                                glyph="lock"
                                type="primary"
                                color="green"
                                isDisabled={!username || size(password) < 10 || isSendingOTP}
                                isLoading={isLoading}
                                css={STYLES.submitButton}
                                onClick={async (event: any) => {
                                  event.preventDefault()
                                  await loginWithPassword()
                                }}
                              />
                            </>
                          )}

                          {loginType === LOGIN_TYPES.otp && (
                            <Grid gap="1rem">
                              <SubmitButton
                                fullWidth
                                key="otp-login-button"
                                label="Verify & Log In →"
                                glyph="lock"
                                type="primary"
                                color="green"
                                isDisabled={!username || size(code) < 6}
                                isLoading={isLoading}
                                css={STYLES.submitButton}
                                onClick={async (event: any) => {
                                  event.preventDefault()
                                  await loginWithOTP()
                                }}
                              />

                              <ResendOTP onResend={handleSendOTP} />
                            </Grid>
                          )}

                          {!loginType && (
                            <SubmitButton
                              fullWidth
                              key="check-login-button"
                              label="Continue →"
                              type="primary"
                              color="blue"
                              isDisabled={!username}
                              isLoading={isLoadingSL}
                              css={STYLES.submitButton}
                              onClick={async (event: any) => {
                                event.preventDefault()
                                await checkLogin()
                              }}
                            />
                          )}

                          {loginType !== LOGIN_TYPES.magic && (
                            <Flex centerX css={STYLES.forgotPassword}>
                              <Button
                                target="_blank"
                                type="link"
                                label="Forgot password?"
                                href="https://apps.behavehealth.com/reset"
                                display="inline-flex"
                              />
                            </Flex>
                          )}
                        </>
                      </Form>
                    </Grid>

                    <Grid gap={8} css={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                      <div>
                        <Text css={textStyles}>
                          <b>First time logging in?</b>
                        </Text>
                        <Text css={textStyles}>
                          Go to <a href={APP_URLS.my_account}>MyAccount App</a> to set up your EHR account or accept the invite from your
                          employer.
                        </Text>
                      </div>

                      <div>
                        <Text css={textStyles}>
                          <b>Who should login here?</b>
                        </Text>
                        <Text css={textStyles}>
                          This login page is for Staff who already have been invited by their employer. Contact the company directly to get
                          your invite.
                        </Text>
                      </div>

                      <div>
                        <Text css={textStyles}>
                          <b>Where should clients login?</b>
                        </Text>
                        <Text css={textStyles}>
                          Clients should login to the Client Portal:{' '}
                          <a href="https://portal.behavehealth.com">https://portal.behavehealth.com</a>.
                          <p>Contact the company directly to get your invite.</p>
                        </Text>
                      </div>
                    </Grid>
                  </div>
                )}

                {isSuccessful && (
                  <Grid gap={16}>
                    <div css={{ margin: '0 auto', maxWidth: 500, width: '100%', padding: '0 1.75rem' }}>
                      <Button label="<- Back" type="link" display="inline-flex" className="!pl-0" onClick={goBack} />
                    </div>

                    <Grid gap={16}>
                      <div css={{ margin: '0 auto', maxWidth: 500, width: '100%', padding: '0 1.75rem' }}>
                        <h3>Staff Login</h3>
                      </div>

                      {isBehave ? (
                        <Grid css={{ margin: '0 auto 1rem', maxWidth: 1600, width: '100%', padding: '0 1.75rem' }}>
                          <h4 css={headerStyles}>Behave Health Login</h4>

                          <Card testKey="tenant_table">
                            <InternalEHRAccessDataTable
                              onClick={(tenant: any) => {
                                onSelect({ tenant: tenant?.subdomain })
                              }}
                            />
                          </Card>
                        </Grid>
                      ) : (
                        <div css={{ margin: '0 auto 1rem', maxWidth: 500, width: '100%', padding: '0 1.75rem' }}>
                          {isTenantsEmpty ? (
                            <Card>
                              <State
                                isEmpty
                                icon="behave_health"
                                title="Behave Health EHR"
                                emptyDescription="You don't have any Behave Health EHR Apps. Go to your account to set one up or accept any invites you received."
                                emptyActions={<Button label="Go to MyAccount →" type="primary" href={generateLoginURL({}, 'my_account')} />}
                              />
                            </Card>
                          ) : (
                            <>
                              <h4 css={headerStyles}>EHR</h4>

                              <DataGrid
                                data={tenants}
                                render={(tenant: any) => {
                                  return (
                                    <CardLink
                                      showChevron
                                      key={tenant?.app?.subdomain}
                                      to={'/'}
                                      graphic={<Avatar size={32} initials={tenant?.app?.name} />}
                                      onClick={() => {
                                        onSelect({ tenant: tenant?.app?.subdomain, record: tenant?.record_id })
                                      }}
                                    >
                                      <CardTitle title={tenant?.app?.name} />
                                      <CardSubtitle
                                        subtitle={<div css={{ textTransform: 'capitalize' }}>{titleCase(tenant?.app?.plan_status)}</div>}
                                      />
                                    </CardLink>
                                  )
                                }}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <div className="!p-4">
              <Divider />

              <Flex centerX gap={8}>
                <MobileAppBadge store="apple" app="staff" />
                <MobileAppBadge store="google" app="staff" />
              </Flex>
            </div>

            <div css={helpChatStyles} onClick={() => window.Beacon('open')}>
              <Glyph glyph="text_message" />
            </div>
          </Flex>
        </AppLayout>
      </div>

      <PortalsContainer />
    </>
  )
}

const globalStyles = {
  'html, body': {
    background: COLORS.white,
  },
}

const headerStyles = {
  margin: '0 0 0.5rem 0',
  textTransform: 'uppercase',
  opacity: 0.8,
}

const textStyles = {
  maxWidth: 450,
  fontSize: 12,
  color: 'rgba(56, 56, 89, 0.4)',
}

const helpChatStyles = {
  position: 'fixed',
  bottom: '1rem',
  right: '1rem',

  background: COLORS.white,
  borderRadius: '50%',
  padding: '1rem',

  boxShadow: SHADOW(2),
  cursor: 'pointer',

  '&:hover': {
    background: COLORS.hover,
  },
}

const STYLES = {
  title: {
    margin: 0,
    fontSize: '1.8rem',
    fontWeight: 700,
    marginTop: '0.5rem',
  },

  subtitle: {
    fontSize: '1.15rem',
    lineHeight: '1.5em',
    color: COLORS.textMuted,
    marginTop: '-0.25rem',
    marginBottom: '0.5rem',
  },

  monoTypeBlock: {
    display: 'inline-flex',
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    padding: '0.5rem 1rem',
    color: COLORS.text,
    letterSpacing: 2,

    '@media(min-width: 600px)': {
      fontSize: '1.2rem',
    },
  },

  submitButton: {
    marginTop: '1rem',
  },

  pinInputWrapper: {
    marginTop: '1.25rem',
  },

  inputWrapper: {
    '&.input-show': {
      marginTop: '0.75rem',
      overflow: 'visible',
      height: 'auto',
    },

    '&.input-hide': {
      overflow: 'hidden',
      height: 0,
    },
  },

  alert: {
    marginTop: '1rem',
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: COLORS.blue,
    minHeight: 40,
    cursor: 'pointer',

    '.back-button-arrow': {
      transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
      marginRight: '0.25rem',
    },

    '&:hover .back-button-arrow': {
      transform: 'translateX(-2px)',
    },
  },

  forgotPassword: {
    marginTop: '1rem',
  },

  labelButton: {
    marginLeft: 'auto',
    paddingRight: '0 !important',
  },
}

export default Login
