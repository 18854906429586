import React from 'react'
import produce from 'immer'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { amount, isDefined } from '../../utils/functions'

import Value from '../Value'
import { withFormContext } from './context'
import { Input } from './Input'
import { snakeCase } from 'lodash'

class AmountInput extends Input {
  processUpdate = (queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }
    const valueDefined = isDefined(this.props.value)
    let newValue = this.props.value

    if (valueDefined) {
      const parsedValue = parseFloat(newValue)
      if (!isNaN(parsedValue)) newValue = parsedValue
    }

    const newState = produce(this.state, (draft: any) => {
      draft.prevValue = draft.value

      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue

      if (valueDefined && newValue !== draft.value) draft.value = newValue

      draft.isDirty = draft.value !== queueEvent?.prevState?.value
      draft.isPristine = !draft.isDirty
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      prevValue: newState.prevValue,
      value: newState.value,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
      isRequired: newState.isRequired,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  readOnlyRender = () => {
    const { prefix, suffix } = this.props
    const { value } = this.state

    const valueDefined = isDefined(value)

    return (
      <Value
        testKey={`${this.props.testKey || snakeCase(this.props.label)}_value`}
        value={`${valueDefined ? prefix : ''}${valueDefined ? amount(value) : DEFAULT_EMPTY_VALUE} ${(value && suffix) || ''}`}
      />
    )
  }
}

AmountInput.defaultProps = {
  type: 'number',
  prefix: '$',
  placeholder: '0.00',
  min: 0,
  size: 6,
  validateOn: 'blur-change',
}

export default withFormContext(AmountInput)
