import React from 'react'
import { useHistory } from 'react-router-dom'

import { encodeObjectToURL } from '../../utils/functions'
import { getToken } from '../../modules/axios'
import { useSettings } from '../../hooks/useSettings'

import Button from '../Button'
import Flex from '../Flex'

type Props = {
  url: string
  params?: any
}

export const ExportCSVButton = ({ url, params }: Props) => {
  const history = useHistory()

  const { isBehave } = useSettings()

  if (!url || !isBehave) return null

  const downloadCSV = async () => {
    const tokenResult = await getToken()

    // if no token is found, redirect to logout
    if (!tokenResult) {
      return history.push('/logout', {
        type: 'session_expired',
        message: 'Your Session has expired for security purposes. Please log back in to continue',
      })
    }

    const encoded = encodeObjectToURL({
      tenant: sessionStorage.getItem('bh.stn') || localStorage.getItem('bh.ftn') || localStorage.getItem('bh.stn'),
      ...(tokenResult?.tokenType === 'session' && { session_token: tokenResult.token }),
      ...(tokenResult?.tokenType === 'jwt' && { token: tokenResult.token }),
      ...params,
    })

    const protocol = process.env.BH_API_BASE_PROTOCOL
    const apiBaseUrl = process.env.BH_API_BASE_URL
    const version = process.env.BH_API_VERSION

    window.open(`${protocol}://${apiBaseUrl}/${version}${url}?${encoded}`)
  }

  return (
    <Flex>
      <Button
        type="minimal"
        display="inline-flex"
        glyph="database_export"
        label="Export CSV"
        size={100}
        css={styles}
        onClick={downloadCSV}
      />
    </Flex>
  )
}

const styles = {
  lineHeight: 'normal',
  marginLeft: 'auto',
}
