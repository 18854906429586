import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { BedAssignmentsReportDataTable } from '@behavehealth/constructs/LiveReports/BedAssignmentsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const BedAssignments = () => {
  const tableProps = useDataTable({
    name: ['reports', 'bed-assignments'],
    endpoint: `/live_reports?category=bed_assignments`,
    localStorageKey: 'report_bed_assignments_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.reference)}/occupancies/${rowData.id}`, [])

  return (
    <Page feature="bed_management" title="Live Bed Assignments Report">
      <Grid>
        <BedAssignmentsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(BedAssignments)
