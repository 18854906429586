import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ArchivedTreatmentPlansReportDataTable } from '@behavehealth/constructs/LiveReports/ArchivedTreatmentPlansReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ArchivedTreatmentPlans = () => {
  const tableProps = useDataTable({
    name: ['reports', 'archived-treatment-plans'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_archived_treatment_plans_v1',
    params: { category: 'archived_treatment_plans' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.client)}/archived-treatment-plans/${rowData.id}`, [])

  return (
    <Page feature="treatment_plans" title="Live Archived Treatment Plans Report">
      <Grid>
        <ArchivedTreatmentPlansReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ArchivedTreatmentPlans)
