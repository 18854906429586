import React from 'react'
import produce from 'immer'
import size from 'lodash/size'
import { useParams } from 'react-router-dom'

import { COLORS } from '../../theme'
import { titleCase, countWord } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import AccordionCard from '../../components/AccordionCard'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DataArray from '../../components/Forms/DataArray'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import EventAttendanceAbsentStatusSelect from '../../components/Elements/EventAttendanceAbsentStatusSelect'
import EventAttendancePresentStatusSelect from '../../components/Elements/EventAttendancePresentStatusSelect'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import State from '../../components/State'
import Switch from '../../components/Forms/Switch'
import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import { InlineInsuranceBillingFormSection } from '../RCM/components/InlineInsuranceBillingFormSection'
import { TreatmentPlanSelector } from '../../components/Forms/TreatmentPlanSelector'
import ClaimPlaceOfServiceSelect from '../RCM/components/ClaimPlaceOfServiceSelect'
import { useUpdate } from '@behavehealth/hooks/useNewAPI'

const GroupNotes = ({ model, format }: any) => (
  <DataArray model={model}>
    {({ orderedIds, add, remove }) => {
      const isEmpty = size(orderedIds) === 0

      return (
        <>
          {orderedIds?.map((id: string, idx: number) => (
            <GroupNote key={id} id={id} idx={idx} remove={remove} format={format} />
          ))}

          {isEmpty ? (
            <Section>
              <State
                isEmpty
                emptyDescription="No Notes added yet"
                emptyActions={<Button type="default" glyph="add" label="Add Session Note" onClick={add} />}
              />
            </Section>
          ) : (
            <Button type="primary" glyph="add" label="Add Session Note" onClick={add} />
          )}
        </>
      )
    }}
  </DataArray>
)

const GroupNote = (props: any) => {
  const { id, idx, remove, format } = props

  const { resource_id } = useParams()

  const [clientId, setClientId] = React.useState<string | null>(null)

  const { tenant, user }: any = useSettings()

  const [isAttending, setIsAttending] = React.useState()

  const canDelete = clientId !== resource_id

  return (
    <AccordionCard
      isOpen
      title={`Note #${idx + 1}`}
      css={{ background: COLORS.lightBackground }}
      aside={
        canDelete && (
          <Button
            size={200}
            type="minimal"
            glyph="delete"
            color="red"
            label="Remove"
            display="inline-flex"
            onClick={(event: any) => {
              event.preventDefault()
              remove(id)
            }}
          />
        )
      }
    >
      <FormSection maxWidth="100%">
        <Flex gap="1rem">
          <OverlaySelector
            isEditable
            label="Client"
            icon="clients"
            type="clients.current"
            className="!flex-auto"
            model={`${id}.client`}
            selectTitle={(data: any) => data?.name}
            selectDescription={(data: any) => data?.category}
            validations={{
              presence: {
                message: 'Please select a Client',
              },
            }}
            onUpdate={({ object }) => {
              if (!object) return

              setClientId(object.id)
            }}
          />
        </Flex>

        {/* <ContextShow when={`${id}.is_billable`} is={true}>
          <ObjectSelector
            dependent="client_id"
            model={`${id}.insurance_service`}
            label="Rendered Insurance Service"
            type="client.insurance_services"
            icon="billing"
            searchKeys={['code', 'short_description', 'long_description']}
            selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
            selectDescription={(o: any) => o?.long_description}
            groupBy={{
              accessor: 'service_type',
              sections: {
                in_network: 'In Network',
                out_of_network: 'Out of Network',
                global_oon: 'Global Out of Network',
              },
            }}
            validations={{
              presence: {
                message: 'Please select a service',
              },
            }}
          />

          <MiniRichTextEditor useDictation model={`${id}.billing_notes`} label="Billing Notes" />

          <Divider className="!m-0" />
        </ContextShow> */}

        <TreatmentPlanSelector baseModel={id} clientId={clientId} />

        <Flex fixChildrenX gap="1rem" alignItems="flex-end">
          {/* <Input model={`${id}.treatment_goal`} label="Treatment Goal Addressed 3" className="!flex-auto" /> */}

          <RadioGroup
            label="Presence"
            model={`${id}.is_attending`}
            layout="horizontal-dense"
            trueIcon="check"
            falseIcon="cross"
            validations={{
              presence: {
                message: 'Please set presence',
              },
            }}
            onUpdate={({ value }) => {
              setIsAttending(value)
            }}
          >
            <Radio label="Present" value={true} />
            <Radio label="Absent" value={false} />
          </RadioGroup>

          <ContextShow when={`${id}.is_attending`} is={true}>
            <div className="!flex-auto">
              <EventAttendancePresentStatusSelect label="Present Status" model={`${id}.present_status`} />
            </div>
          </ContextShow>

          <ContextShow when={`${id}.is_attending`} is={false}>
            <div className="!flex-auto">
              <EventAttendanceAbsentStatusSelect label="Absent Status" model={`${id}.absent_status`} />
            </div>
          </ContextShow>
        </Flex>

        <ContextShow when={`${id}.is_attending`} is={true}>
          <Checkbox
            defaultValue={false}
            label="Override Default Session Date & Time"
            model={`${id}.is_custom_time`}
            trueIcon="check"
            falseIcon="cross"
            css={{ flex: '1 1 auto !important' }}
          />
        </ContextShow>

        <ContextShow when={`${id}.is_custom_time`} is={true}>
          <Flex gap="1rem">
            <DateTimeInput
              defaultToNow
              className="!flex-auto"
              model={`${id}.custom_started_at`}
              label="Custom Date & Time"
              validations={{
                presence: {
                  message: 'Please enter a custom date and time',
                },
              }}
            />

            <Input
              model={`${id}.custom_duration`}
              label="Custom Duration"
              suffix="mins"
              type="number"
              min={1}
              size={4}
              validations={{
                presence: {
                  message: 'Please enter a custom duration',
                },
                numericality: {
                  greaterThanOrEqualTo: 1,
                  message: 'The duration must be higher than 0 minutes',
                },
              }}
            />
          </Flex>
        </ContextShow>

        {isAttending && (
          <>
            {format === 'individual_assessment' && (
              <MiniRichTextEditor
                useDictation
                useQuickText
                model={`${id}.assessment`}
                label="Client Note"
                validations={{
                  presence: {
                    message: 'Please provide an individual assessment',
                  },
                }}
              />
            )}

            {format === 'dap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.data`} label="Data" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />
              </>
            )}

            {format === 'soap' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.subjective`} label="Subjective" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.objective`} label="Objective" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.assessment`} label="Assessment" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />
              </>
            )}

            {format === 'girp' && (
              <>
                <MiniRichTextEditor useDictation useQuickText model={`${id}.goals`} label="Goals" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.intervention`} label="Intervention" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.response`} label="Response" />
                <MiniRichTextEditor useDictation useQuickText model={`${id}.plan`} label="Plan" />

                <div className="!hidden">
                  {/* keep assessment model data when toggling between different note types */}
                  <MiniRichTextEditor model={`${id}.assessment`} label="Individual Assessment" />
                </div>
              </>
            )}
          </>
        )}

        <ContextShow when={`${id}.is_attending`} is={false}>
          <MiniRichTextEditor
            useDictation
            useQuickText
            model={`${id}.absentee_note`}
            label="Absentee Note"
            validations={{
              presence: {
                message: 'Please provide an absentee note',
              },
            }}
          />
        </ContextShow>

        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <h3 className="m-0">Insurance</h3>
            <div className="text-text-muted text-[0.95em]">Activate this section to add any Insurance related data</div>
          </div>
          <Switch horizontal size={200} withHover={false} label={null} model={`${id}.use_insurance`} />
        </Flex>

        <ContextShow when={`${id}.use_insurance`} is={true}>
          <FormSection maxWidth="100%">
            <CheckboxGroup label="Insurance" trueIcon="check" falseIcon="cross" falseStyle="faded">
              <Checkbox defaultValue={false} label="Set as Billable" model={`${id}.is_billable`} />
            </CheckboxGroup>

            <ContextShow when={`${id}.is_billable`} is={true}>
              <InlineInsuranceBillingFormSection isBillable />
            </ContextShow>

            <ClaimPlaceOfServiceSelect model={`${id}.place_of_service`} />

            <OverlaySelector maxWidth="100%" label="Location" type="properties.all" model={`${id}.location`} icon="gps_check_in_out" />
          </FormSection>
        </ContextShow>
      </FormSection>
    </AccordionCard>
  )
}

export const RootClinicalNoteGroupBatchOverlay = (props: any) => {
  const { data, sessionNotes = [], onClose } = props

  const form = React.useRef()
  const [isValid, setIsValid] = React.useState(false)

  const isEditable = true
  const { timezone, defaultSupervisor, systemPrefs }: any = useSettings()

  const isRequiredSupervisorNonEditable = systemPrefs?.require_clinical_notes_supervisor
  const [format, setFormat] = React.useState('individual_assessment')
  const [supervisor, setSupervisor] = React.useState<any>(data?.supervisor || null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(!!data?.require_supervisor)

  const { mutateAsync: updateNotes, isLoading: isSaving } = useUpdate({
    name: 'update-session-notes',
    url: 'clinical_notes/group',
    invalidate: 'clinical-notes',
  })

  const save = async () => {
    const formData = form.current.getFormValue()

    if (props.save) {
      await props.save({ ...formData, uuid: data.uuid })
      props.onClose?.()
    } else {
      await updateNotes({ ...formData, uuid: data.uuid })
      props.onClose?.()
    }
  }

  return (
    <Overlay isDirty showBackdrop maxWidth={90} onClose={onClose}>
      <Overlay.Header icon="clinical_notes" title="Batch Edit Group Notes" />

      <Overlay.Content className="mq900:grid mq900:grid-cols-[100%] mq900:grid-rows-[100%] mq900:!overflow-hidden">
        <Form
          isEditable
          getForm={form}
          timezone={timezone}
          initialModel={{
            group_notes: sessionNotes,
            ...produce(data || {}, (draft) => {
              delete draft.session_notes
            }),
            ...(defaultSupervisor && { supervisor: defaultSupervisor }),
          }}
          onValidationUpdate={setIsValid}
          linked={{ category: 'group_note' }}
          className="grid grid-cols-1 mq900:grid-cols-[480px_1fr] mq900:grid-rows-[100%] mq900:!overflow-hidden"
        >
          <div className="mq900:overflow-y-auto mq900:border-r mq900:border-solid mq900:border-divider border-0">
            <Section headingType="h2" title="Session Details">
              <FormSection maxWidth="100%">
                <Flex gap="1rem">
                  <DateTimeInput
                    defaultToNow
                    className="!flex-auto"
                    model="started_at"
                    label="Default Session Date and Time"
                    validations={{
                      presence: {
                        message: 'Please enter a session date and time',
                      },
                    }}
                  />

                  <Input
                    model="duration"
                    label="Duration"
                    suffix="mins"
                    type="number"
                    min={1}
                    size={4}
                    validations={{
                      presence: {
                        message: 'Please enter a session duration',
                      },
                      numericality: {
                        greaterThanOrEqualTo: 1,
                        message: 'The duration must be higher than 0 minutes',
                      },
                    }}
                  />
                </Flex>

                <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

                <Flex gap="1rem" justifyContent="space-between" alignItems="flex-end">
                  <ObjectSelector
                    isPolymorphic
                    css={isEditable && { flex: '1 1 auto' }}
                    model="supervisor"
                    label="Supervisor"
                    type="clinical_supervisors"
                    icon="employees"
                    selectTitle={(data) => data?.name}
                    selectDescription={(data) => titleCase(data?.position)}
                    validations={
                      requireSupervisor && {
                        presence: {
                          message: 'Please select a supervisor',
                        },
                      }
                    }
                    onUpdate={({ object }) => {
                      setSupervisor(object)
                    }}
                    value={isEditable ? supervisor : data?.supervisor}
                  />

                  <CheckboxGroup label="Supervisor" tooltip={isRequiredSupervisorNonEditable ? 'Required from Settings' : null}>
                    <Checkbox
                      isEditable={isRequiredSupervisorNonEditable ? false : isEditable}
                      defaultChecked
                      trueIcon="check"
                      falseIcon="cross"
                      falseStyle="none"
                      label="Require Supervisor"
                      model="require_supervisor"
                      onChange={(state: any) => {
                        const nextValue = state.value
                        setRequireSupervisor(nextValue)

                        if (nextValue === false) {
                          setSupervisor(null)
                        }
                      }}
                    />
                  </CheckboxGroup>
                </Flex>

                <MultiOverlaySelector
                  label="Additional Staff Members"
                  blankLabel="Select Staff…"
                  icon="employees"
                  type="employees.active"
                  model="employees"
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => titleCase(data.position)}
                />

                <Input model="topic" label="Group Topic" />
                <MiniRichTextEditor useDictation useQuickText model="description" label="Group Discussion" />
                <MiniRichTextEditor useDictation useQuickText model="notes" label="Notes" />

                <Divider className="!my-2" />

                <RadioGroup
                  model="format"
                  label="Note Type"
                  layout="horizontal-dense"
                  defaultValue={'individual_assessment'}
                  onUpdate={({ value }) => {
                    setFormat(value)
                  }}
                >
                  <Radio label="DAP" value="dap" />
                  <Radio label="SOAP" value="soap" />
                  <Radio label="GIRP" value="girp" />
                  <Radio label="Client Note" value="individual_assessment" />
                </RadioGroup>
              </FormSection>
            </Section>

            <Divider />

            <Section headingType="h2" title="Signatures">
              <FormSection maxWidth="100%" layout="vertical">
                <SignaturePad
                  allowPin
                  person={data?.signed_by}
                  label="Author Signature"
                  model="employee_signature"
                  signedAtModel="author_signed_at"
                  showAccept={false}
                />

                <SignaturePad
                  allowPin
                  isEditable={false}
                  person={data?.supervisor}
                  label="Supervisor Signature"
                  model="supervisor_signature"
                  signedAtModel="supervisor_signed_at"
                  showAccept={false}
                />
              </FormSection>
            </Section>

            {/* <Divider />

            <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
              <FormSection maxWidth="100%">
                <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
              </FormSection>
            </Section> */}
          </div>

          <div className="mq900:overflow-y-auto border-t border-solid border-divider border-0 mq900:border-t-0">
            <Section
              headingType="h2"
              title="All Session Notes"
              // aside={
              //   <SummonOverlay
              //     overlay={
              //       <QuickAddClients
              //         onAdd={(selected) => {
              //           console.log('selected', selected)
              //         }}
              //       />
              //     }
              //   >
              //     <Button label="Quick Add Clients" size={200} type="primary" glyph="user_group" />
              //   </SummonOverlay>
              // }
            >
              <FormSection maxWidth="100%">
                <GroupNotes model="group_notes" format={format} />
              </FormSection>
            </Section>
          </div>
        </Form>
      </Overlay.Content>

      <Overlay.Footer withGradient={false}>
        <Button
          label="Save"
          glyph="check"
          type="primary"
          color="green"
          isLoading={props.isSaving || isSaving}
          onClick={save}
          isDisabled={!isValid}
          flex="100 1 auto"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const QuickAddClients = (props) => {
  const { onClose, onAdd } = props

  const [selected, setSelected] = React.useState<any>([])

  const handleAdd = () => {
    onClose()
    onAdd?.(selected)
  }

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={90} position="center" onClose={onClose}>
      <Overlay.Header title="Quick Add Clients" icon="clients" />

      <Overlay.Content className="py-2 px-5">
        <AllClientsTable
          asCard
          canBatchSelect
          defaultTab="current_clients"
          tabsListClassName="!ml-0 mb-3"
          onRowSelectionUpdate={setSelected}
        />
      </Overlay.Content>

      <Overlay.Footer withGradient={false}>
        <Button
          label={size(selected) > 0 ? `Add ${countWord('Clients', size(selected))}` : 'Select Clients'}
          isDisabled={size(selected) === 0}
          onClick={handleAdd}
          color={size(selected) > 0 ? 'green' : 'gray'}
          type="primary"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ClinicalNoteGroupBatchOverlay = withOverlayError(RootClinicalNoteGroupBatchOverlay)
