import React from 'react'
import size from 'lodash/size'
import { DateTime } from 'luxon'

import { useGet } from '../../hooks/useNewAPI'
import { usDateShort } from '../../utils/functions'

import GridTable from '../../components/GridTable'
import State from '../../components/State'
import TreeItem from '../../components/TreeItem'

import { DIMENSIONS_V2 } from './constants'
import { LineChart } from './components/Charts'

export const RecoveryCapitalHistoricDataV2 = (props: any) => {
  const { clientId, dimension, collapsibleTable } = props

  const { data, isLoading, isError }: any = useGet({
    name: ['clients', clientId, 'recovery_capital_forms_historical_data', dimension],
    url: `/residents/${clientId}/recovery_capital_forms_historical_data`,
    params: { dimension: dimension },
    options: { enabled: !!clientId && !!dimension },
  })

  const TableWrapper = collapsibleTable ? TreeItem : React.Fragment

  if (isLoading) {
    return <State isLoading />
  }

  const dimensionConfig = DIMENSIONS_V2[dimension]

  if (!data || !dimensionConfig) return null

  const displayDates = data.dates.map((date: string) => {
    const dt = DateTime.fromFormat(date, 'yy-MM-dd')
    return usDateShort(dt)
  })

  if (isError) {
    return (
      <State
        isEmpty
        title="Error"
        glyph="circle_error"
        emptyDescription="The data for this dimension could not be loaded, please try refreshing and submit a ticket if the problem persists"
      />
    )
  }

  return (
    <div className="grid gap-4 grid-cols-1">
      <div className="px-2">
        <LineChart min={dimensionConfig.min} max={dimensionConfig.max} labels={displayDates} values={data.scores} />
      </div>

      {dimensionConfig.answers && (
        <TableWrapper title={`${dimensionConfig.name} – Historic Data`} indentPadding={0}>
          <GridTable templateColumns={`400px repeat(${size(data.dates)}, 1fr)`} className="border-t border-0 border-solid border-divider">
            <GridTable.Header>
              <GridTable.Cell></GridTable.Cell>
              {displayDates.map((date: string, index: number) => {
                return <GridTable.Cell key={`${date}-${index}`}>{date}</GridTable.Cell>
              })}
            </GridTable.Header>

            {Object.keys(dimensionConfig.answers).map((key: string) => {
              const label = dimensionConfig.answers[key]
              const answers = size(data.answers?.[key]) > 0 ? data.answers[key] : Array.from({ length: size(data.dates) })

              if (!answers) return null

              return (
                <GridTable.Row>
                  <GridTable.Cell>{label}</GridTable.Cell>
                  {answers.map((answer: number, index: number) => {
                    return (
                      <GridTable.Cell key={`${key}-${index}`}>
                        <AnswerCell answer={answer || ''} />
                      </GridTable.Cell>
                    )
                  })}
                </GridTable.Row>
              )
            })}
          </GridTable>
        </TableWrapper>
      )}
    </div>
  )
}

const AnswerCell = ({ answer }: any) => {
  if (typeof answer === 'number') {
    return <span>{answer}</span>
  }

  if (typeof answer === 'string') {
    return <span>{answer || '–'}</span>
  }

  if (answer === null) {
    return <span>–</span>
  }

  if (answer === true) {
    return <span>Yes</span>
  }

  if (answer === false) {
    return <span>No</span>
  }

  return <span>{answer}</span>
}
