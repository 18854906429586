import React from 'react'
import { connect } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import size from 'lodash/size'
import isFinite from 'lodash/isFinite'

import { age, titleCase } from '../../utils/functions'
import { DEFAULT_EMPTY_VALUE, MEDICATION_TIMES, SLUG_RESOURCE_TYPES } from '../../utils/constants'
import { useGet } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Permission from '../../components/Permission'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignaturePad from '../../components/Forms/SignaturePad'
import Status from '../../components/Status'
import Switch from '../../components/Forms/Switch'
import Textarea from '../../components/Forms/Textarea'
import Timeline from '../../components/Timeline/Timeline'

import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'

const RootMedicationLogOverlay = (props: any) => {
  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'medication-log',
    endpoint: '/medication_logs',
    invalidate: 'medication-logs',
    options: props,
    openAfterCreate: true,
  })

  const history: any = useHistory()
  const location: any = useLocation()

  const { resource, resource_id }: any = useParams()
  const { isPortal, timezone, tenant } = useSettings()

  const [medicationName, setMedicationName] = React.useState<any>('')
  const [medicationTime, setMedicationTime] = React.useState<any>([])
  const [medicationLogTime, setMedicationLogTime] = React.useState<any>([])

  const [dosage, setDosage] = React.useState()
  const [unit, setUnit] = React.useState()
  const [quantity, setQuantity] = React.useState(1)

  // display alert for medication log times that don't match the selected medication's times
  const medTimeDifferences = React.useMemo(() => {
    if (size(medicationTime) === 0 || size(medicationLogTime) === 0) return false

    const result: any = []

    for (const time of medicationLogTime) {
      if (!medicationTime.includes(time)) result.push(time)
    }

    return result
  }, [medicationTime, medicationLogTime])

  const medDifferencesCount = size(medTimeDifferences)
  const showMedDifferences = medDifferencesCount > 0

  const isTrialing = tenant?.plan_status === 'trialing'
  const isResident = SLUG_RESOURCE_TYPES?.[resource] === 'resident'

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
    options: { enabled: !!resource_id && isResident },
  })

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const secondLastIndex = location.pathname.lastIndexOf('/', location.pathname.lastIndexOf('/') - 1)

    const url = location.parent ? location.parent.url : location.pathname.substr(0, secondLastIndex)

    history.push(url)
  }

  React.useEffect(() => {
    if (!data) return

    setDosage(data.dosage)
    setUnit(data.unit)
    setQuantity(data.quantity)
  }, [data])

  if (isOverlayLoading) {
    return <OverlayLoader position="right" showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} />
  }

  return (
    <Overlay showBackdrop={props.showBackdrop || isNew || isEditable} closeOnBackdrop={!isEditable} onClose={close}>
      <Overlay.Header title="Medication Log" icon="medications" />

      {!isEditable && !isTrialing && (
        <Permission permission="clients.actions.export">
          <Overlay.SubHeader>
            <ExportPDFButton url={`/medication_logs/${id}/pdf`} />
          </Overlay.SubHeader>
        </Permission>
      )}

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          onValidationUpdate={onValidationUpdate}
          initialModel={isNew ? { quantity: 1, ...initialModel } : initialModel}
          linked={isNew && client && { resident_id: client.id, resident: client }}
          onUpdate={(model: any) => {
            if (!model.medication_time) return

            const result: any = []

            for (const time in model.medication_time) {
              if (model.medication_time[time]) result.push(time)
            }

            setMedicationLogTime(result)
          }}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical">
              <OverlaySelector
                isEditable={isNew}
                initialModelRequired={isNew}
                model="resident"
                label="Client"
                type="clients.admissions_and_current"
                icon="clients"
                showDescription={false}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a client',
                  },
                }}
                description="We load clients from Applicants, Accepted and Current Clients"
              />

              <ObjectSelector
                model="med"
                label="Medication"
                type="client.medications"
                dependent="resident_id"
                disableUnless="resident_id"
                icon="medications"
                showDescription={false}
                selectTitle={(data) => data.name}
                selectDescription={(data) => {
                  const checkedResults = data?.medication_time && Object.entries(data.medication_time).filter(([key, value]) => value)
                  const finalValue: any = []

                  checkedResults.map(([key, value]) => {
                    finalValue.push(MEDICATION_TIMES[key])
                  })

                  return (
                    <>
                      <b>Status: </b> {titleCase(data.status)}
                      {' | '}
                      <b>Medication Time: </b> {size(finalValue) > 0 ? finalValue.join(', ') : DEFAULT_EMPTY_VALUE}
                      {' | '}
                      <b>Dosage: </b> {data.dosage} {data.unit}
                      {' | '}
                      <b>Standard Dose Quantity: </b> {data.default_quantity || DEFAULT_EMPTY_VALUE}
                      {' | '}
                      <b>RX #: </b> {data.prescription_no || DEFAULT_EMPTY_VALUE}
                    </>
                  )
                }}
                disableLink={isPortal}
                validations={{
                  presence: {
                    message: 'Please select a medication',
                  },
                }}
                onUpdate={({ object }) => {
                  if (!object?.medication_time) {
                    setMedicationTime([])
                    setMedicationName('')
                    return
                  }

                  const result: any = []

                  for (const time in object.medication_time) {
                    if (object.medication_time[time]) result.push(time)
                  }

                  setMedicationName(object.name)
                  setMedicationTime(result)

                  const defaultQuantity = object?.default_quantity ? parseInt(object.default_quantity) : 1

                  if (isEditable) {
                    setDosage(object.dosage)
                    setUnit(object.unit)
                    setQuantity(isFinite(defaultQuantity) ? defaultQuantity : 1)
                  }
                }}
              />

              <Flex gap={16} stretchChildrenX>
                <Input
                  model="dosage"
                  label="Dosage"
                  type="number"
                  step="0.001"
                  min="0"
                  value={dosage}
                  onUpdate={({ value }: any) => {
                    setDosage(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a dosage',
                    },
                  }}
                />

                <Input
                  model="unit"
                  label="Unit Type"
                  labelDescription="(eg. mg, g, mL)"
                  value={unit}
                  onUpdate={({ value }: any) => {
                    setUnit(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter a unit',
                    },
                  }}
                />
              </Flex>

              <Flex gap={16} stretchChildrenX>
                <Input
                  model="quantity"
                  label="Quantity Distributed"
                  type="number"
                  value={quantity}
                  onUpdate={({ value }: any) => {
                    setQuantity(value)
                  }}
                  validations={{
                    presence: {
                      message: 'Please enter the quantity distributed',
                    },
                  }}
                />

                <Input model="remaining_units" label="Quantity Remaining" type="number" />
              </Flex>

              <CheckboxGroup label="Medication Time" layout="vertical-dense" trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox label="AM" model="medication_time.am" />
                <Checkbox label="PM" model="medication_time.pm" />
                <Checkbox label="Morning" model="medication_time.morning" />
                <Checkbox label="Afternoon" model="medication_time.afternoon" />
                <Checkbox label="Evening" model="medication_time.evening" />
                <Checkbox label="Bedtime" model="medication_time.bedtime" />
                <Checkbox label="PRN" model="medication_time.prn" />
              </CheckboxGroup>

              {showMedDifferences && medTimeDifferences && medicationTime && medicationName && (
                <Alert contrast type="negative" glyph="circle_error">
                  <div>
                    <b>Please Note:</b> The medication time{medDifferencesCount > 1 ? 's' : ''}{' '}
                    {medTimeDifferences.map((time) => (
                      <Status inline small key={time} label={MEDICATION_TIMES[time]} color="red" style={{ marginRight: '0.35rem' }} />
                    ))}{' '}
                    {medDifferencesCount > 1 ? 'do' : 'does'} not match the medication time on the medication record. Please ensure you have
                    selected the correct medication.
                  </div>

                  <div style={{ marginTop: '0.5rem', fontStyle: 'italic' }}>
                    <b>"{medicationName}" Medication Times:</b>{' '}
                    {medicationTime.map((time) => (
                      <Status inline small key={time} label={MEDICATION_TIMES[time]} color="blue" style={{ marginRight: '0.35rem' }} />
                    ))}
                  </div>
                </Alert>
              )}

              <RadioGroup
                defaultValue="administered"
                label="Administration Status"
                model="administration_status"
                layout="horizontal-dense"
                validations={{
                  presence: {
                    message: 'Please select a status',
                  },
                }}
              >
                <Radio label="Administered" value="administered" />
                <Radio label="Observed" value="observed" />
                <Radio label="Refused Administration" value="refused" />
              </RadioGroup>

              <ContextShow when="administration_status" is="administered">
                <DateTimeInput
                  defaultToNow
                  model="administered_at"
                  label="Administered At"
                  validations={{
                    presence: {
                      message: 'Please enter the time of administration',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="administration_status" is="observed">
                <DateTimeInput
                  defaultToNow
                  model="administered_at"
                  label="Administered At"
                  validations={{
                    presence: {
                      message: 'Please enter the time of administration',
                    },
                  }}
                />
              </ContextShow>

              <ContextShow when="administration_status" is="refused">
                <DateTimeInput
                  defaultToNow
                  model="administered_at"
                  label="Refused At"
                  validations={{
                    presence: {
                      message: 'Please enter the time of refusal',
                    },
                  }}
                />
              </ContextShow>

              <FormFutureDateWarning dateLabel="Date" model="administered_at" />

              <Textarea useQuickText model="notes" label="Notes" />
            </FormSection>
          </Section>

          <Divider />

          {tenant.is_billed && (
            <>
              <Section
                headingType="h2"
                title="Billing"
                description="Use this section to record the billable service"
                aside={<Switch defaultValue={false} model="is_billable" />}
              >
                <ContextShow when="is_billable" is={true}>
                  <FormSection maxWidth="100%">
                    <ObjectSelector
                      dependent="resident_id"
                      model="insurance_service"
                      label="Rendered Insurance Service"
                      type="client.insurance_services"
                      icon="billing"
                      searchKeys={['code', 'short_description', 'long_description']}
                      selectTitle={(o: any) => `${o?.code} - ${o?.short_description}`}
                      selectDescription={(o: any) => o?.long_description}
                      groupBy={{
                        accessor: 'service_type',
                        sections: {
                          in_network: 'In Network',
                          out_of_network: 'Out of Network',
                          global_oon: 'Global Out of Network',
                        },
                      }}
                      validations={{
                        presence: {
                          message: 'Please select a service',
                        },
                      }}
                    />

                    <Textarea useQuickText model="billing_notes" label="Billing Notes" />
                  </FormSection>
                </ContextShow>
              </Section>

              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload the files related to this log">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          <Divider />

          <Section headingType="h2" title="Signatures">
            <Grid gap={16}>
              <SignaturePad
                showSignedBy={false}
                label="Client Signature"
                model="client_signature"
                signedAtModel="client_signed_at"
                allowPin={false}
                showAccept={false}
              />

              <SignaturePad
                allowPin
                person={data?.signed_by}
                label="Staff Signature"
                model="employee_signature"
                signedAtModel="author_signed_at"
                showAccept={false}
              />

              <Checkbox
                trueIcon="check"
                falseIcon="cross"
                falseStyle="faded-linethrough"
                label="Require Co-Signer"
                defaultValue={false}
                model="require_cosigner"
              />

              <ContextShow when="require_cosigner" is={true}>
                <SignaturePad
                  allowPin
                  showSignedBy={false}
                  label="Co-Signer Signature"
                  model="cosigner_signature"
                  signedAtModel="cosigned_at"
                  showAccept={false}
                />
              </ContextShow>
            </Grid>
          </Section>

          {!isNew && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={isLoading} recordID={data?.id} recordType={data?.type} disableLinks={isPortal} />
              </Section>
            </>
          )}
        </Form>
      </Overlay.Content>

      {!isPortal && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="10 1 auto"
                permission={isNew ? 'medication_logs.create' : 'medication_logs.edit'}
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit"
                type="default"
                isDisabled={isLoading}
                onClick={edit}
                flex="10 1 auto"
                permission="medication_logs.edit"
              />

              <DeleteDialog
                title="Delete Medication Log?"
                message="Are you sure you want to delete this medication log? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  fullWidth
                  permission="medication_logs.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const MedicationLogOverlay = withOverlayError(RootMedicationLogOverlay)
