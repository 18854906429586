import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'

import Alert from '../../components/Alert'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

export const ASQOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Ask Suicide-Screening Questions (ASQ) Toolkit"
      category="clinical_measurement"
      subcategory="asq"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>

      <Divider />

      <Section
        title="Next Steps"
        headingType="h2"
        scrollview={{
          id: 'next_steps',
          name: 'Next Steps',
        }}
      >
        <ul>
          <li>
            If patient answers “No” to all questions 1 through 4, screening is complete (not necessary to ask question #5). No intervention
            is necessary (*Note: Clinical judgment can always override a negative screen).
          </li>
          <li>
            <div>
              If patient answers “Yes” to any of questions 1 through 4, or refuses to answer, they are considered a positive screen. Ask
              question #5 to assess acuity:
            </div>

            <ul>
              <li>
                <div>“Yes” to question #5 = acute positive screen (imminent risk identified)</div>

                <ul>
                  <li>Patient requires a STAT safety/full mental health evaluation. Patient cannot leave until evaluated for safety.</li>
                  <li>
                    Keep patient in sight. Remove all dangerous objects from room. Alert physician or clinician responsible for patient’s
                    care.
                  </li>
                </ul>
              </li>
              <li>
                <div>“No” to question #5 = non-acute positive screen (potential risk identified)</div>

                <ul>
                  <li>
                    Patient requires a brief suicide safety assessment to determine if a full mental health evaluation is needed. If a
                    patient (or parent/guardian) refuses the brief assessment, this should be treated as an “against medical advice” (AMA)
                    discharge.
                  </li>
                  <li>Alert physician or clinician responsible for patient’s care.</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </Section>

      <Divider />

      <Section
        title="Provide resources to all patients"
        headingType="h2"
        scrollview={{
          id: 'resources',
          name: 'Resources',
        }}
      >
        <ul>
          <li>24/7 National Suicide Prevention Lifeline, 988</li>
          <li>24/7 Crisis Text Line: Text “HOME” to 741741</li>
        </ul>
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = () => {
  return (
    <>
      <FormSection>
        <h3>Ask the patient:</h3>

        <YesNoRadioGroup label="1. In the past few weeks, have you wished you were dead?" model="data.questionnaire.q1" />
        <YesNoRadioGroup
          label="2. In the past few weeks, have you felt that you or your family would be better off if you were dead?"
          model="data.questionnaire.q2"
        />
        <YesNoRadioGroup label="3. In the past week, have you been having thoughts about killing yourself?" model="data.questionnaire.q3" />
        <YesNoRadioGroup label="4. Have you ever tried to kill yourself?" model="data.questionnaire.q4" />

        <ContextShow when="data.questionnaire.q4" is={true}>
          <Textarea label="How?" model="data.questionnaire.q4_how" />
          <Textarea label="When?" model="data.questionnaire.q4_when" />
        </ContextShow>

        <ContextShow
          orMode
          when={['data.questionnaire.q1', 'data.questionnaire.q2', 'data.questionnaire.q3', 'data.questionnaire.q4']}
          is={true}
        >
          <Alert small contrast glyph="info">
            If the patient answers Yes to any of the above, ask the following acuity question:
          </Alert>

          <YesNoRadioGroup label="5. Are you having thoughts of killing yourself right now?" model="data.questionnaire.q5" />

          <ContextShow when="data.questionnaire.q5" is={true}>
            <Textarea label="Please describe:" model="data.questionnaire.q5_description" />
          </ContextShow>
        </ContextShow>
      </FormSection>
    </>
  )
}
