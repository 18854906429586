import React from 'react'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DataTable } from '../../components/DataTable/DataTable'
import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'
import ClaimStatus from '@behavehealth/constructs/RCM/ClaimStatus'
import ClaimFlagStatus from '@behavehealth/constructs/RCM/ClaimFlagStatus'

export const NewInsuranceClaimReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Insurance Payer',
        id: 'insurance_payer',
        model: 'data.insurance_payer',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.insurance_payer?.name} to={to?.(data.data)} />,
      },
      {
        title: 'Plan Name',
        id: 'plan_name',
        model: 'data.plan_name',
        width: 160,
      },
      {
        title: 'Client Name',
        id: 'client',
        model: 'data.client',
        type: 'profile',
        width: 160,
      },
      {
        title: 'Service Start Date',
        id: 'service_start_date',
        model: 'data.service_start_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service End Date',
        id: 'service_end_date',
        model: 'data.service_end_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service Name',
        id: 'service_name',
        model: 'data.service_name',
        width: 160,
      },
      {
        title: 'Procedure Code',
        id: 'procedure_code',
        model: 'data.procedure_code',
        width: 160,
      },
      {
        title: 'Revenue Code',
        id: 'revenue_code',
        model: 'data.revenue_code',
        width: 160,
      },
      {
        title: 'Internal Status',
        id: 'internal_status',
        model: 'data.internal_status',
        width: 160,
        formatValue: ({ value }: any) => (!!value ? <ClaimStatus status={value} /> : '–'),
      },
      {
        title: 'Flag Status',
        id: 'flag_status',
        model: 'data.flag_status',
        width: 160,
        formatValue: ({ value }: any) => (!!value ? <ClaimFlagStatus status={value} /> : '–'),
      },
      {
        title: 'Billed Amount',
        id: 'billed_amount',
        model: 'data.billed_amount',
        width: 160,
      },
      {
        title: 'Billed Date',
        id: 'billed_date',
        model: 'data.billed_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Days In Aging',
        id: 'days_in_aging',
        model: 'data.days_in_aging',
        type: 'number',
        width: 160,
      },
      {
        title: '0-30 Days',
        id: 'between_0_and_30_days',
        model: 'data.between_0_and_30_days',
        width: 160,
      },
      {
        title: '31-60 Days',
        id: 'between_31_and_60_days',
        model: 'data.between_31_and_60_days',
        width: 160,
      },
      {
        title: '61-90 Days',
        id: 'between_61_and_90_days',
        model: 'data.between_61_and_90_days',
        width: 160,
      },
      {
        title: '91+ Days',
        id: 'over_91_days',
        model: 'data.over_91_days',
        width: 160,
      },
      {
        title: 'Last Updated Date',
        id: 'updated_at',
        model: 'data.updated_at',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Most Recent Claim Note',
        id: 'last_claim_note',
        model: 'data.last_claim_note',
        width: 160,
      },
      {
        title: 'Note Author',
        id: 'last_note_author',
        model: 'data.last_note_author',
        type: 'profile',
        width: 160,
      },
      {
        title: 'Note Date',
        id: 'last_note_date',
        model: 'data.last_note_date',
        type: 'date_time',
        width: 160,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance Claims"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTER_CONFIG}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="new_insurance_claims"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=new_insurance_claims'}
        />
      }
      {...props}
    />
  )
}

const FILTER_CONFIG = {
  insurance_payer: {
    label: 'Insurance Payer',
    type: 'multi_object',
    endpoint: '/insurance_local_payers',
    apiKey: 'insurance_local_payers',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => {
      if (item.status === 'active') return <Status small color="green" label="Active" />
      if (item.status === 'archived') return <Status small color="red" label="Archived" />
      return null
    },
  },
  plan_name: {
    label: 'Plan Name',
    type: 'string',
  },
  client: {
    label: 'Client',
    type: 'multi_object',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  service_start_date: {
    label: 'Service Start Date',
    type: 'date_time',
  },
  service_end_date: {
    label: 'Service End Date',
    type: 'date_time',
  },
  service_name: {
    label: 'Service Name',
    type: 'string',
  },
  procedure_code: {
    label: 'Procedure Code',
    type: 'string',
  },
  revenue_code: {
    label: 'Revenue Code',
    type: 'string',
  },
  internal_status: {
    label: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Draft', value: 'draft' },
      { label: 'Validated', value: 'validated' },
      { label: 'Validation Error', value: 'validation_error' },
      { label: 'Submitted', value: 'submitted' },
      { label: 'Submission Error', value: 'submission_error' },
      { label: 'Denied', value: 'denied' },
      { label: 'Balance Due Client', value: 'balance_due_client' },
      { label: 'Paid', value: 'paid' },
      { label: 'Written Off', value: 'written_off' },
    ],
  },
  flag_status: {
    label: 'Flag Status',
    type: 'multi_select',
    options: [
      { label: 'To Review', value: 'flagged_to_review' },
      { label: 'Pending', value: 'flagged_pending' },
      { label: 'Finalized', value: 'flagged_finalized' },
    ],
  },
  billed_amount: {
    label: 'Billed Amount',
    type: 'number',
  },
  billed_date: {
    label: 'Billed Date',
    type: 'date_time',
    glyph: 'date',
  },
  days_in_aging: {
    label: 'Days In Aging',
    type: 'number',
  },
  between_0_and_30_days: {
    label: '0-30 Days',
    type: 'number',
  },
  between_31_and_60_days: {
    label: '31-60 Days',
    type: 'number',
  },
  between_61_and_90_days: {
    label: '61-90 Days',
    type: 'number',
  },
  over_91_days: {
    label: '90+ Days',
    type: 'number',
  },
  last_claim_note: {
    label: 'Latest Claim Note',
    type: 'string',
  },
  last_note_author: {
    label: 'Note Author',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  last_note_date: {
    label: 'Last Note Date',
    type: 'date_time',
    glyph: 'date',
  },
  updated_at: {
    label: 'Updated At',
    type: 'date_time',
    glyph: 'date',
  },
}
