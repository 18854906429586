import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { MedicationLogsReportDataTable } from '@behavehealth/constructs/LiveReports/MedicationLogsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const MedicationLogs = () => {
  const tableProps = useDataTable({
    name: ['reports', 'medication-logs'],
    endpoint: `/live_reports?category=medication_logs`,
    localStorageKey: 'report_medication_logs_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/medications/logs/${rowData.id}`, [])

  return (
    <Page feature="medication_logs" title="Live Medication Logs Report">
      <Grid>
        <MedicationLogsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(MedicationLogs)
