import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

import InsuranceEobStatus from '../../components/Statuses/InsuranceEobStatus'

export const EOBDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'EOB',
        model: 'identifier',
        width: 200,
        formatValue: ({ data, value }: any) => <MainCell testKey="main_cell" id={data.id} value={value} to={props?.to?.(data)} />,
      },
      {
        title: 'File Name',
        model: 'upload_name',
        width: 200,
      },
      {
        title: 'Status',
        model: 'status',
        width: 120,
        formatValue: ({ value }: any) => <InsuranceEobStatus status={value} />,
      },
      {
        title: 'Upload Date',
        model: 'uploaded_at',
        type: 'date_time',
      },
      {
        title: 'Uploaded By',
        model: 'uploaded_by',
        type: 'profile',
      },
      {
        title: 'Payment #',
        id: 'insurance_payment_identifier',
        model: 'insurance_new_payment.identifier',
      },
      {
        title: 'Payment Reference #',
        id: 'insurance_payment_reference',
        model: 'insurance_new_payment.reference',
      },
      {
        width: 150,
        title: 'Payment Date',
        id: 'insurance_payment_payed_at',
        model: 'insurance_new_payment.payed_at',
        type: 'date',
      },
      {
        width: 150,
        title: 'Posted Date',
        id: 'insurance_payment_posted_at',
        model: 'insurance_new_payment.posted_at',
        type: 'date_time',
      },
      {
        width: 300,
        title: 'Insurance Payer',
        id: 'insurance_local_payer',
        model: 'insurance_new_payment.insurance_local_payer.name',
      },
      {
        title: 'Total Paid Amount',
        id: 'paid_amount',
        model: 'insurance_new_payment.paid_amount',
        width: 200,
        type: 'amount',
      },
      {
        title: 'Total Client Responsibility',
        id: 'responsibility_amount',
        model: 'insurance_new_payment.client_responsibility_amount',
        width: 200,
        type: 'amount',
      },
      {
        title: 'Total Adjustment',
        id: 'adjustment_amount',
        model: 'insurance_new_payment.adjustment_amount',
        width: 200,
        type: 'amount',
      },
      {
        title: 'Total Balance Due Client',
        id: 'balance_client_due_amount',
        model: 'insurance_new_payment.balance_client_due_amount',
        width: 200,
        type: 'amount',
      },
    ],
    [props.to],
  )

  return <DataTable asCard title="EOBs" icon="accounting" columns={columns} filtersConfig={FILTERS_CONFIG} {...props} />
}

const FILTERS_CONFIG = {
  identifier: {
    title: 'EOB',
    type: 'string',
  },
  upload_name: {
    title: 'File Name',
    type: 'string',
  },
  status: {
    title: 'Status',
    type: 'multi_select',
    options: [
      { label: 'Draft', value: 'draft' },
      { label: 'Connected', value: 'connected' },
      { label: 'Archived', value: 'archived' },
    ],
  },
  uploaded_at: {
    title: 'Upload Date',
    type: 'date_time',
  },
  uploaded_by: {
    title: 'Uploaded By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: true,
    selectTitle: (data: any) => data.name,
  },
  insurance_payment_identifier: {
    title: 'Payment #',
    type: 'string',
  },
  insurance_payment_reference: {
    title: 'Payment Reference #',
    type: 'string',
  },
  insurance_payment_posted_at: {
    title: 'Posted Date',
    type: 'date_time',
  },
  insurance_local_payer: {
    title: 'Insurance Payer',
    type: 'multi_object',
    endpoint: '/insurance_local_payers',
    apiKey: 'insurance_local_payers',
    showAvatar: true,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => {
      if (item.status === 'active') return <Status small color="green" label="Active" />
      if (item.status === 'archived') return <Status small color="red" label="Archived" />
      return null
    },
  },
  paid_amount: {
    title: 'Total Paid Amount',
    type: 'number',
  },
  responsibility_amount: {
    title: 'Total Client Responsibility',
    type: 'number',
  },
  adjustment_amount: {
    title: 'Total Adjustment',
    type: 'number',
  },
  balance_client_due_amount: {
    title: 'Total Balance Due Client',
    type: 'number',
  },
}
