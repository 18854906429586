import React from 'react'
import { DateTime } from 'luxon'
import { tint } from 'polished'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'
import size from 'lodash/size'
import SignaturePad from 'signature_pad'

import { COLORS } from '../../../theme'
import { isURL, isBase64Image } from '../../../utils/functions'
import { useFormField } from '../../Forms/hooks/useFormField'
import { useSettings } from '../../../hooks/useSettings'
import { withFormContext } from '../../Forms/context'

import { PopoverMenu } from '../../../components/PopoverMenu'
import Glyph from '../../../components/Glyph'
import Grid from '../../../components/Grid'
import Flex from '../../../components/Flex'
import Button from '../../../components/Button'
import PinSignature from '../../../components/Forms/PinSignature'
import SignatureCanvas from '../../../components/Forms/SignatureCanvas'

import { useStore } from '../utils/useStore'

const RootSignatureCell = (props: any) => {
  const { canEdit, column, form, isEditing, model, onBlur, setValue, validations, value } = props

  const { timezone } = useSettings()

  const allowPin = !!column?.config?.allowPin
  const showInvalid: any = useStore((state: any) => state.showInvalid)

  const { formState } = useFormField({
    model: model,
    form: form,
    validations: validations,
    isValid: size(validations) === 0,
  })

  const { isInvalid } = formState

  const [isOpen, setIsOpen] = React.useState(isEditing)
  const [view, setView] = React.useState('manual')
  const [didSign, setDidSign] = React.useState(false)

  const currentData = form?.getFieldValue(`${model}_data`)
  const currentURL = form?.getFieldValue(`${model}_url`)

  React.useEffect(() => {
    if (currentData && value !== currentData) {
      setValue(currentData)
      setDidSign(true)
    } else if (currentURL && value !== currentURL) setValue(currentURL)
  }, [currentData, currentURL])

  // Update form state
  React.useEffect(() => {
    if (isUndefined(value) || !model) return

    if (isURL(value)) {
      form.setFieldValue(`${model}_url`, value)
    } else if (isBase64Image(value)) {
      form.setFieldValue(`${model}_data`, value)
    }

    form.setFieldValue(`${props.model}_signed_at`, DateTime.local().setZone(timezone).toISO())
  }, [value])

  const triggerClasses = clsx(isEditing && 'is-editing', isInvalid && showInvalid && 'is-invalid')
  const isSigned = isURL(value) || !!currentURL || didSign

  const trigger = (
    <div className={triggerClasses} css={STYLES.trigger}>
      {value && <img src={value} className="block w-auto h-full" />}
      {canEdit && <Glyph glyph="signature" size={12} color={COLORS.blue} className="select-trigger-glyph" />}
    </div>
  )

  if (!canEdit) return trigger

  return (
    <PopoverMenu
      isOpen={isOpen}
      onOpenUpdated={(currentIsOpen: boolean) => {
        setIsOpen(currentIsOpen)

        if (!currentIsOpen) {
          setDidSign(!!value)
          onBlur()
        }
      }}
      closeOnItemClick={false}
      maxWidth="640px"
      trigger={trigger}
    >
      <div css={STYLES.menu}>
        {isSigned && (
          <>
            <img src={value} className="block w-full max-w-[400px] h-[200px]" />
            <Button
              fullWidth
              testKey="clear_signature_button"
              type="minimal"
              color="red"
              glyph="cross"
              label="Clear"
              onClick={() => {
                setValue(null)
                setDidSign(false)

                form.setFieldValue(`${model}_url`, null)
                form.setFieldValue(`${model}_data`, null)
                form.setFieldValue(`${model}_signed_at`, null)
              }}
            />
          </>
        )}

        {!isSigned && (
          <>
            {allowPin && (
              <Flex css={STYLES.tabs}>
                <Button
                  label="PIN Signature"
                  type="minimal"
                  onClick={() => setView('pin')}
                  className={'tab ' + (view === 'pin' && 'is-active')}
                />
                <Button
                  label="Manual Signature"
                  type="minimal"
                  onClick={() => setView('manual')}
                  className={'tab ' + (view === 'manual' && 'is-active')}
                />
              </Flex>
            )}

            {allowPin && view === 'pin' && (
              <PinSignature
                onUpdate={(state) => {
                  setValue(state.value)
                }}
              />
            )}

            {view === 'manual' && (
              <SignatureCanvas
                onUpdate={(state: any) => {
                  setValue(state.value)
                }}
              />
            )}
          </>
        )}
      </div>
    </PopoverMenu>
  )
}

const STYLES = {
  tabs: {
    display: 'flex',

    '.tab': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 auto',
      minHeight: '2.75rem',
      transition: 'all 100ms',
      background: 'none !important',
      border: 'none',
      borderRadius: 0,

      '&:hover': {
        transform: 'translateY(-2px)',
      },

      cursor: 'pointer',
      color: COLORS.link,
      textAlign: 'center',
      fontSize: 15,
      textDecoration: 'none',
      fontWeight: 500,

      '&.is-active': {
        boxShadow: `inset 0 -2px 0 ${COLORS.link}`,

        '&:hover': {
          transform: 'translateY(0)',
        },
      },
    },
  },

  trigger: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    flex: '1 1 auto',
    padding: '0.2rem',
    paddingLeft: '0.4rem',
    paddingRight: '1.2rem',
    overflow: 'hidden',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    '.select-trigger-label': {
      fontWeight: 500,
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '.select-trigger-glyph': {
      position: 'absolute',
      right: '0.25rem',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
    },

    '&.is-empty': {
      fontStyle: 'italic',
      color: COLORS.textStronglyMuted,
    },

    '&.is-editing': {
      borderRadius: 3,
      background: COLORS.white,
      boxShadow: 'var(--input-focus-box-shadow)',
    },

    '&.is-invalid': {
      display: 'block',
      background: tint(0.85, COLORS.red),
    },
  },

  menu: {
    display: 'grid',
    gridTemplateColumns: '100%',

    '.DateTimeInput': {
      padding: '0.5rem 1rem',
      borderBottom: `1px solid ${COLORS.divider}`,
      margin: '0 !important',
    },
  },
}

export const SignatureCell: any = withFormContext(RootSignatureCell)
