import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'

import Alert from '../../components/Alert'
import Card from '../../components/Card'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import NumberInput from '../../components/Forms/NumberInput'
import Section from '../../components/Section'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import crafft_score from '../../assets/images/crafft_score.png'

export const CRAFFTOverlay = (props: any) => {
  const [q1, setQ1]: any = React.useState()
  const [q2, setQ2]: any = React.useState()
  const [q3, setQ3]: any = React.useState()

  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="CRAFFT Screening Tool"
      category="clinical_measurement"
      subcategory="crafft"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <FormSection>
          <div>
            <b>Begin:</b>{' '}
            <i>
              “I'm going to ask you a few questions that I ask all my patients. Please be honest. I will keep your answers confidential.”
            </i>
          </div>

          <h3>Part A</h3>
          <h4>During the PAST 12 MONTHS, on how many days did you:</h4>

          <NumberInput
            label="1. Drink more than a few sips of beer, wine, or any drink containing alcohol? Say “0” if none."
            model="data.questionnaire.q1"
            suffix="days"
            size={6}
            onUpdate={({ value }) => {
              setQ1(parseInt(value))
            }}
          />

          <NumberInput
            label="2. Use any marijuana (cannabis, weed, oil, wax, or hash by smoking, vaping, dabbing, or in edibles) or “synthetic marijuana” (like “K2,” “Spice”)? Say “0” if none."
            model="data.questionnaire.q2"
            suffix="days"
            size={6}
            onUpdate={({ value }) => {
              setQ2(parseInt(value))
            }}
          />

          <NumberInput
            label="3. Useanythingelsetogethigh(likeotherillegaldrugs,pills, prescription or over-the-counter medications, and things that you sniff, huff, vape, or inject)? Say “0” if none."
            model="data.questionnaire.q3"
            suffix="days"
            size={6}
            onUpdate={({ value }) => {
              setQ3(parseInt(value))
            }}
          />

          <Divider className="!m-0" />

          <h3>Part B</h3>

          <YesNoRadioGroup
            label="C. Have you ever ridden in a CAR driven by someone (including yourself) who was “high” or had been using alcohol or drugs?"
            model="data.questionnaire.q4"
          />

          {(q1 > 0 || q2 > 0 || q3 > 0) && (
            <>
              <YesNoRadioGroup
                label="R. Do you ever use alcohol or drugs to RELAX, feel better about yourself, or fit in?"
                model="data.questionnaire.q5"
              />

              <YesNoRadioGroup
                label="A. Do you ever use alcohol or drugs while you are by yourself, or ALONE?"
                model="data.questionnaire.q6"
              />

              <YesNoRadioGroup label="F. Do you ever FORGET things you did while using alcohol or drugs?" model="data.questionnaire.q7" />

              <YesNoRadioGroup
                label="F. Do your FAMILY or FRIENDS ever tell you that you should cut down on your drinking or drug use?"
                model="data.questionnaire.q8"
              />

              <YesNoRadioGroup
                label="T. Have you ever gotten into TROUBLE while you were using alcohol or drugs?"
                model="data.questionnaire.q9"
              />
            </>
          )}

          <Alert small contrast glyph="info">
            Two or more YES answers in Part B suggests a serious problem that needs further assessment.
          </Alert>

          <Card>
            <img src={crafft_score} width={580} className="block w-full h-auto" />

            <div className="text-text-strongly-muted text-[0.9rem] px-4 pb-4 pt-2">
              *Data source: Mitchell SG, Kelly SM, Gryczynski J, Myers CP, O’Grady KE, Kirk AS, & Schwartz RP. (2014). The CRAFFT cut-points
              and DSM-5 criteria for alcohol and other drugs: a reevaluation and reexamination. Substance Abuse, 35(4), 376–80.
            </div>
          </Card>
        </FormSection>
      </Section>

      <Divider />

      <Section
        title="Score Interpretation"
        headingType="h2"
        scrollview={{
          id: 'score_interpretation',
          name: 'Score Interpretation',
        }}
      >
        <div className="mt-4">
          <b>1. REVIEW screening results</b>
        </div>
        <div>For each “yes” response: “Can you tell me more about that?”</div>

        <div className="mt-4">
          <b>2. RECOMMEND not to use</b>
        </div>
        <div>
          “As your doctor (nurse/health care provider), my recommendation is not to use any alcohol, marijuana or other drug because they
          can: 1) Harm your developing brain; 2) Interfere with learning and memory, and 3) Put you in embarrassing or dangerous
          situations.”
        </div>

        <div className="mt-4">
          <b>3. RIDING/DRIVING risk counseling</b>
        </div>
        <div>
          “Motor vehicle crashes are the leading cause of death for young people. I give all my patients the Contract for Life. Please take
          it home and discuss it with your parents/guardians to create a plan for safe rides home.”
        </div>

        <div className="mt-4">
          <b>4. RESPONSE elicit self-motivational statements</b>
        </div>
        <div>
          Non-users: “If someone asked you why you don’t drink or use drugs, what would you say?” Users: “What would be some of the benefits
          of not using?”
        </div>

        <div className="mt-4">
          <b>5. REINFORCE self-efficacy</b>
        </div>
        <div>"I believe you have what it takes to keep alcohol and drugs from getting in the way of achieving your goals."</div>

        <div className="mt-6 text-text-strongly-muted grid gap-2 text-[0.9rem]">
          <div>
            © John R. Knight, MD, Boston Children’s Hospital, 2020. Reproduced with permission from the Center for Adolescent Behavioral
            Health Research (CABHRe), Boston Children’s Hospital.
          </div>

          <div>crafft@childrens.harvard.edu www.crafft.org</div>

          <div>For more information and versions in other languages, see www.crafft.org.</div>
        </div>
      </Section>
    </DataFormOverlay>
  )
}
