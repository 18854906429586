import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import Card from '@behavehealth/components/Card'
import ClaimsTable from '@behavehealth/components/RCM/ClaimsTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'

const Billing = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['client', resource_id, 'insurance-claims'],
    url: `/residents/${resource_id}/insurance_claims`,
  })

  return (
    <div className="grid grid-cols-[100%]">
      <Card>
        <ClaimsTable
          title="Claims"
          data={data}
          isLoading={isLoading}
          to={(id: string) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </div>
  )
}

export default Billing
