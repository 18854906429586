import React from 'react'

import { getClientLink, getVersionSlug } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { DischargesReportDataTable } from '@behavehealth/constructs/LiveReports/DischargesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { DISCHARGE_FORMS_SLUGS } from '@behavehealth/utils/constants'

const Discharges = () => {
  const queryParams = { category: 'discharges' }

  const tableProps = useDataTable({
    name: ['reports', 'discharges'],
    endpoint: `/live_reports`,
    params: queryParams,
    localStorageKey: 'report_discharges_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      const { id, version, subcategory } = rowData

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`
      const slug = !version || version === '1.0' ? '' : `/${DISCHARGE_FORMS_SLUGS[subcategory]}`

      return `${getClientLink(rowData.client)}/discharges${slug}/${id}${formattedVersion}`
    },
    [],
  )

  return (
    <Page feature="discharges" title="Live Discharge Summaries Report">
      <Grid>
        <DischargesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Discharges)
