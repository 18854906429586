import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { TodosReportDataTable } from '@behavehealth/constructs/LiveReports/TodosReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Todos = () => {
  const tableProps = useDataTable({
    name: ['reports', 'events'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_todos_v1',
    params: { event_type: 'task', category: 'todos' },
  })

  const to = React.useMemo(() => (rowData: any) => `/todo/active/${rowData.id}`, [])

  return (
    <Page feature="todos" title="Live To-Do's Report">
      <Grid>
        <TodosReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Todos)
