import React from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import Page from '@behavehealth/components/Page'
import Tabs from '@behavehealth/components/Tabs'

import Billing from './pages/billing'
import Collections from './pages/collections'
import Dashboard from './pages/dashboard'
import Posting from './pages/posting'
import UtilizationReview from './pages/utilization_review'
import Verifications from './pages/verifications'

const RCM = () => {
  const match = useRouteMatch()
  const { pathname } = useLocation()

  let selectedTab = ''
  let actions = null

  if (pathname.includes(`${match.url}/dashboard`)) {
    selectedTab = 'dashboard'
  } else if (pathname.includes(`${match.url}/verifications`)) {
    selectedTab = 'verifications'
  } else if (pathname.includes(`${match.url}/utilization-review`)) {
    selectedTab = 'utilization_review'
    actions = (
      <Dropdown label="Add New…" buttonType="primary">
        <DropdownItem label="Pre-certification" glyph="add" color="blue" link={`${match.url}/utilization-review/pre-certifications/new`} />
        <DropdownItem
          label="Initial Authorization"
          glyph="add"
          color="blue"
          link={`${match.url}/utilization-review/initial-authorizations/new`}
        />
        <DropdownItem label="Concurrent Review" glyph="add" color="blue" link={`${match.url}/utilization-review/concurrent-reviews/new`} />
        <DropdownItem
          label="Documentation Review"
          glyph="add"
          color="blue"
          link={`${match.url}/utilization-review/documentation-reviews/new`}
        />
        <DropdownItem label="Clinical Appeal" glyph="add" color="blue" link={`${match.url}/utilization-review/clinical-appeals/new`} />
        <DropdownItem
          label="Discharge Planning"
          glyph="add"
          color="blue"
          link={`${match.url}/utilization-review/discharge-plannings/new`}
        />
      </Dropdown>
    )
  } else if (pathname.includes(`${match.url}/billing`)) {
    selectedTab = 'billing'
    actions = <Button label="Add Claim" link={`${pathname}/new`} type="primary" glyph="add" />
  } else if (pathname.includes(`${match.url}/collections`)) {
    selectedTab = 'collections'
  } else if (pathname.includes(`${match.url}/posting`)) {
    selectedTab = 'posting'
    actions = (
      <Dropdown label="Add Payment…" buttonType="primary">
        <DropdownItem label="Batch Payments" glyph="circle_add" color="blue" link={`${match.url}/posting/payments-builder`} />
        <Divider />
        <DropdownItem
          label="Client Payment"
          glyph="add"
          color="blue"
          link={{
            pathname: `${match.url}/posting/payments/new`,
            data: { category: 'client_payment' },
          }}
        />
        <DropdownItem
          label="Insurance Payment"
          glyph="add"
          color="blue"
          link={{
            pathname: `${match.url}/posting/payments/new`,
            data: { category: 'insurance_payment' },
          }}
        />
        <DropdownItem
          label="Write-Off"
          glyph="add"
          color="blue"
          link={{
            pathname: `${match.url}/posting/payments/new`,
            data: { category: 'write_off' },
          }}
        />
        <DropdownItem isDisabled label="Patient Responsibility" glyph="add" color="gray" />
      </Dropdown>
    )
  }

  return (
    <Page icon="financials" title="Revenue Cycle Management" breakpoint={4} actions={actions}>
      <Tabs defaultTab="support_main_plan">
        <Tabs.List css={styles.tabsList}>
          {/* <Tabs.Item isDisabled label="Dashboard" icon="dashboard" to={`${match.url}/dashboard`} /> */}
          {/* <Tabs.Item isDisabled label="Verifications" icon="rcm_verifications" to={`${match.url}/verifications`} /> */}
          <Tabs.Item label="Utilization Review" icon="rcm_utilization_review" to={`${match.url}/utilization-review`} />
          <Tabs.Item label="Billing" icon="rcm_billing" to={`${match.url}/billing`} />
          <Tabs.Item label="Collections" icon="rcm_claim_status" to={`${match.url}/claim-status`} />
          <Tabs.Item label="Posting" icon="rcm_posting" to={`${match.url}/posting`} />
        </Tabs.List>
      </Tabs>

      <Switch>
        <Route path={`${match.url}/dashboard`} component={Dashboard} />
        <Route path={`${match.url}/verifications`} component={Verifications} />
        <Route path={`${match.url}/utilization-review`} component={UtilizationReview} />
        <Route path={`${match.url}/billing`} component={Billing} />
        <Route path={`${match.url}/claim-status`} component={Collections} />
        <Route path={`${match.url}/posting`} component={Posting} />

        <Redirect exact from={match.url} to={`${match.url}/utilization-review`} />
      </Switch>
    </Page>
  )
}

const styles = {
  tabsList: {
    marginTop: '-0.75rem',
    marginBottom: '1.25rem',
  },
}

export default RCM
