import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const NewInsuranceBillingReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Insurance Payer',
        id: 'insurance_payer',
        model: 'data.insurance_payer',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={data.data.insurance_payer?.name} to={to?.(data.data)} />,
      },
      {
        title: 'Plan Name',
        id: 'plan_name',
        model: 'data.plan_name',
        width: 160,
      },
      {
        title: 'Client Name',
        id: 'client',
        model: 'data.client',
        type: 'profile',
        width: 160,
      },
      {
        title: 'Service Start Date',
        id: 'service_start_date',
        model: 'data.service_start_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service End Date',
        id: 'service_end_date',
        model: 'data.service_end_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Service Name',
        id: 'service_name',
        model: 'data.service_name',
        width: 160,
      },
      {
        title: 'Procedure Code',
        id: 'procedure_code',
        model: 'data.procedure_code',
        width: 160,
      },
      {
        title: 'Revenue Code',
        id: 'revenue_code',
        model: 'data.revenue_code',
        width: 160,
      },
      {
        title: 'Modifier Code',
        id: 'modifier_codes',
        model: 'data.modifier_codes',
        width: 160,
      },
      {
        title: 'Units',
        id: 'units',
        model: 'data.units',
        width: 160,
      },
      {
        title: 'Unit Type',
        id: 'unit_type',
        model: 'data.unit_type',
        width: 160,
      },
      {
        title: 'Billed Amount',
        id: 'billed_amount',
        model: 'data.billed_amount',
        width: 160,
      },

      {
        title: 'Initial Bill Date',
        id: 'initial_bill_date',
        model: 'data.initial_bill_date',
        type: 'date_time',
        width: 160,
      },

      {
        title: 'Last Bill Date',
        id: 'last_bill_date',
        model: 'data.last_bill_date',
        type: 'date_time',
        width: 160,
      },
      {
        title: 'Billing Provider Name',
        id: 'billing_provider_name',
        model: 'data.billing_provider_name',
        width: 160,
      },
      {
        title: 'Claim ID',
        id: 'claim_id',
        model: 'data.claim_id',
        width: 160,
      },
      {
        title: 'Service Line ID',
        id: 'service_line_id',
        model: 'data.service_line_id',
        width: 160,
      },
      {
        title: 'Claim Created By',
        id: 'claim_created_by',
        model: 'data.claim_created_by',
        width: 160,
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Insurance Billing"
      icon="insurance"
      columns={columns}
      filtersConfig={FILTER_CONFIG}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="new_insurance_billing"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=new_insurance_billing'}
        />
      }
      {...props}
    />
  )
}

const FILTER_CONFIG = {
  insurance_payer: {
    label: 'Insurance Payer',
    type: 'multi_object',
    endpoint: '/insurance_local_payers',
    apiKey: 'insurance_local_payers',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
    selectDescription: (item: any) => {
      if (item.status === 'active') return <Status small color="green" label="Active" />
      if (item.status === 'archived') return <Status small color="red" label="Archived" />
      return null
    },
  },
  plan_name: {
    label: 'Plan Name',
    type: 'string',
  },
  client: {
    label: 'Client',
    type: 'multi_object',
    endpoint: '/residents',
    apiKey: 'residents',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
  service_start_date: {
    label: 'Service Start Date',
    type: 'date_time',
  },
  service_end_date: {
    label: 'Service End Date',
    type: 'date_time',
  },
  service_name: {
    label: 'Service Name',
    type: 'string',
  },
  procedure_code: {
    label: 'Procedure Code',
    type: 'string',
  },
  revenue_code: {
    label: 'Revenue Code',
    type: 'string',
  },
  modifier_codes: {
    label: 'Modifier Code',
    type: 'string',
  },
  units: {
    label: 'Units',
    type: 'number',
  },
  unit_type: {
    label: 'Unit Type',
    type: 'string',
  },
  billed_amount: {
    label: 'Billed Amount',
    type: 'number',
  },
  initial_bill_date: {
    label: 'Initial Bill Date',
    type: 'date_time',
  },
  last_bill_date: {
    label: 'Last Bill Date',
    type: 'date_time',
  },
  billing_provider_name: {
    label: 'Billing Provider Name',
    type: 'string',
  },
  claim_id: {
    label: 'Claim ID',
    type: 'string',
  },
  service_line_id: {
    label: 'Service Line ID',
    type: 'string',
  },
  claim_created_by: {
    label: 'Claim Created By',
    type: 'multi_object',
    endpoint: '/employees',
    apiKey: 'employees',
    glyph: 'user_group',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (item: any) => item.name,
  },
}
