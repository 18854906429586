import React from 'react'
import { useParams } from 'react-router-dom'
import produce from 'immer'

import { useGet } from '../../hooks/useNewAPI'
import { titleCase } from '../../utils/functions'
import { withOverlayError } from '../../hocs/withOverlayError'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import SigneeStatus from '../../components/Statuses/SigneeStatus'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Link from '../../components/Link'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import OverlayLoader from '../../components/OverlayLoader'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Textarea from '../../components/Forms/Textarea'
import TipAlert from '../../components/TipAlert'

import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const RootRefundOverlay = (props: any) => {
  const {
    anonymize,
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
  })

  const { timezone } = useSettings()
  const { resource_id }: any = useParams()
  const [formData, setFormData] = React.useState(initialModel)

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const save = () => {
    const saveData = produce(formData, (draft) => {
      if (draft.reference_category === 'client') {
        draft.reference_id = client.id
        draft.reference_type = client.type
        return
      }

      if (draft.reference_category === 'contact' && draft.contact_reference_id) {
        draft.reference_id = draft.contact_reference_id
        draft.reference_type = draft.contact_reference_type
        return
      }

      if (draft.reference_category === 'organization' && draft.organization_reference_id) {
        draft.reference_id = draft.organization_reference_id
        draft.reference_type = draft.organization_reference_type
        return
      }

      draft.reference_id = null
      draft.reference_type = null
    })

    saveWithData({ ...saveData, payed_with: formData.payed_with?.model })
  }

  if (isOverlayLoading || !client) {
    return <OverlayLoader position="right" />
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="financials" title={isNew ? 'Add Refund' : 'Refund'} />

      <Overlay.Content>
        <Chotomate ready name="refund_overlay" />

        {client?.financial_plan ? (
          <Form
            key={`updated-${data?.updated_at}`}
            initialModel={produce(initialModel, (draft) => {
              if (draft.reference_category === 'client' && draft.reference?.type === 'resident') {
                draft.client_reference = draft.client
                return
              }

              if (draft.reference_category === 'contact' && draft.reference?.type === 'contact') {
                draft.contact_reference = draft.reference
                return
              }

              if (draft.reference_category === 'organization' && draft.reference?.type === 'organization') {
                draft.organization_reference = draft.reference
                return
              }
            })}
            getForm={form}
            timezone={timezone}
            isEditable={isEditable}
            onUpdate={setFormData}
            onValidationUpdate={onValidationUpdate}
            linked={
              isNew && {
                category: 'refund',
                financial_plan_id: client?.financial_plan?.id,
              }
            }
          >
            <Section>
              <FormSection layout="vertical">
                <TipAlert small contrast localStorageKey="stripe_refunds_overlay">
                  <b>Please note: </b> all refunds must be processed through Stripe. If you need to refund a payment, please do so through
                  the{' '}
                  <a href="https://dashboard.stripe.com/payments" target="_blank" rel="noopener noreferrer">
                    Stripe dashboard
                  </a>
                  . Changes made here will not be reflected in Stripe.
                </TipAlert>

                <Input
                  label="Name"
                  model="name"
                  validations={{
                    presence: {
                      message: 'Please enter a charge name',
                    },
                  }}
                />

                <Flex gap={16}>
                  <AmountInput
                    label="Amount"
                    model="amount"
                    isEditable={isNew || (isEditable && initialModel.source === 'app')}
                    validations={{
                      presence: {
                        message: 'Please enter an amount',
                      },
                      numericality: {
                        greaterThan: 0,
                        message: 'Please enter an amount greater than 0',
                      },
                    }}
                  />

                  <DateInput
                    defaultToNow
                    label="Refund Date"
                    model="transactioned_at"
                    fullWidth
                    validations={{
                      presence: {
                        message: 'Please set a refund date',
                      },
                    }}
                  />
                </Flex>

                <Divider className="!m-0" />

                <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                  <Radio label="Client" value="client" />
                  <Radio label="Contact" value="contact" />
                  <Radio label="Organization" value="organization" />
                </RadioGroup>

                {!isEditable ? (
                  <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" />
                ) : (
                  <>
                    <ContextShow when="reference_category" is="client">
                      <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="client_reference" value={client} />
                    </ContextShow>

                    <ContextShow when="reference_category" is="contact">
                      <ObjectSelector
                        isPolymorphic
                        icon="contacts"
                        label="Payer"
                        model="contact_reference"
                        type="client.contacts"
                        description={
                          <>
                            Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                          </>
                        }
                        dependentValue={client?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => titleCase(data?.relationship)}
                      />
                    </ContextShow>

                    <ContextShow when="reference_category" is="organization">
                      <ObjectSelector
                        isPolymorphic
                        icon="organizations"
                        label="Payer"
                        model="organization_reference"
                        type="organizations"
                        dependentValue={client?.id}
                        selectTitle={(data: any) => data?.name}
                        selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                        description={
                          <>
                            Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link>{' '}
                            to add it.
                          </>
                        }
                      />
                    </ContextShow>
                  </>
                )}

                <Divider className="!mt-2 !mx-0 !mb-0" />

                <Textarea useQuickText label="Notes" model="notes" />

                {!isNew && data && (
                  <Flex gap="1rem">
                    <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                    <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
                  </Flex>
                )}
              </FormSection>
            </Section>

            {initialModel.payer && (
              <>
                <Section title="Payer">
                  <Grid gap="1rem">
                    <Alert glyph="warning" type="warning">
                      We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. Payers will not show up for new
                      Transactions.
                    </Alert>

                    <ObjectSelector
                      isEditable={false}
                      apiData={client?.financial_plan?.payers}
                      icon="financials"
                      model="payer"
                      label="Payer"
                      type="client.payers"
                      selectTitle={(data: any) => data?.reference?.name}
                      selectDescription={(data: any) => <SigneeStatus signee={data?.reference} />}
                    />
                  </Grid>
                </Section>

                <Divider />
              </>
            )}

            {!anonymize && (
              <>
                <Divider />

                <Section hide={anonymize} headingType="h2" title="Attachments" description="Upload the files related to this refund">
                  <FormSection layout="vertical">
                    <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                  </FormSection>
                </Section>

                {isNew && (
                  <>
                    <Divider />

                    <Section title="Send Notifications">
                      <Grid gap={8}>
                        <Alert glyph="notification">Send a notification alert via SMS / Email to the Payer(s) above</Alert>

                        <CheckboxGroup layout="vertical-dense">
                          <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                          <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                        </CheckboxGroup>
                      </Grid>
                    </Section>
                  </>
                )}

                {/* {!isNew && (
                <>
                  <Divider />

                  <Section headingType="h2" title="Timeline">
                    <Timeline isLoadingRecord={loading} recordID={initialModel.id} recordType={initialModel.type} />
                  </Section>
                </>
              )} */}
              </>
            )}
          </Form>
        ) : (
          <Alert type="negative" className="!m-4">
            The data required to create <strong>Charges</strong> could not be loaded from our servers. To try again, please close this
            Overlay and refresh. If this problem persists, please get in touch with Support.
          </Alert>
        )}
      </Overlay.Content>

      {!anonymize && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save Refund"
                glyph="check"
                type="primary"
                color="green"
                isLoading={isSaving}
                onClick={save}
                isDisabled={isInvalid}
                flex="100 1 auto"
                permission="ledger.create"
              />
              {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
            </>
          )}

          {!isEditable && (
            <>
              <Button
                glyph="edit"
                label="Edit Refund"
                type="default"
                isDisabled={isSaving}
                onClick={edit}
                flex="100 1 auto"
                permission="ledger.edit"
              />

              <DeleteDialog
                title="Delete Refund?"
                message="Are you sure you want to delete this refunds record? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  fullWidth
                  glyph="delete"
                  label="Delete"
                  type="default"
                  color="red"
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

export const RefundOverlay = withOverlayError(RootRefundOverlay)
