import React from 'react'
import compact from 'lodash/compact'
import { Route, Redirect, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { fullname, age, usDate, usDateTime } from '@behavehealth/utils/functions'
import Notifications from '@behavehealth/modules/notifications'
import { store } from '@behavehealth/setup/store'
import { useMedia } from 'use-media'
import { Route as RouteV6, Routes } from 'react-router-dom-v5-compat'

import { BREAKPOINT } from '@behavehealth/theme'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withBackListener from '@behavehealth/hocs/withBackListener'

import { ClientTrackSelector } from '@behavehealth/constructs/ClientTracks/ClientTrackSelector'
import ClientQuickActions from '@behavehealth/components/QuickActions/Client'
import RequestVOBOverlay from '@behavehealth/components/Overlays/actions/RequestVOBOverlay'

import {
  BillingStatus,
  Button,
  ClientNavItems,
  ClientPortalStatus,
  ClientStatus,
  CollaborateStatus,
  Divider,
  DropdownItem,
  HelpTagIframe,
  Nav,
  PageGrid,
  PageProfile,
  SummonOverlay,
  TagsSelector,
  Text,
} from '@behavehealth/components'

import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import ClientColorPicker from '@behavehealth/components/ColorPickers/ClientColorPicker'

import ActivityTimeline from './pages/activity_timeline'
import Agreements from './pages/agreements'
import Allergies from './pages/allergies'
import Applications from './pages/applications'
import Authorizations from './pages/authorizations'
import BillingRosters from './pages/billing_rosters'
import Calendar from './pages/calendar'
import ChargeNotes from './pages/charge_notes'
import Claims from './pages/claims'
import ClientCash from './pages/client_cash'
import ClientPortal from './pages/client_portal'
import ClinicalAssessments from './pages/clinical_assessments'
import ClinicalMeasurements from './pages/clinical_measurements'
import ClinicalNotes from './pages/clinical_notes'
import Communications from './pages/communications'
import Connections from './pages/connections'
import Contacts from './pages/contacts'
import CustomNotes from './pages/custom_notes'
import DailyNotes from './pages/daily_notes'
import DataExports from './pages/data_exports'
import Diagnoses from './pages/diagnoses'
import Discharges from './pages/discharges'
import Files from './pages/files'
import Financials from './pages/financials'
import FormSubmissions from './pages/form_submissions'
import GeneralInfo from './pages/general_info'
import HealthcareDischarges from './pages/healthcare_discharges'
import Insurance from './pages/insurance'
import IntakeForms from './pages/intake_forms'
import Invoices from './pages/invoices'
import LocationCheckIn from './pages/location_checkin'
import MedicalAssessments from './pages/medical_assessments'
import Medications from './pages/medications'
import Nursing from './pages/nursing'
import Occupancies from './pages/occupancies'
import OutcomeMeasures from './pages/outcome_measures'
import PeerNotes from './pages/peer_notes'
import PhysicianOrders from './pages/physician_orders'
import Programs from './pages/programs'
import ProgressNotes from './pages/progress_notes'
import ProgressReviews from './pages/progress_reviews'
import RCM from './pages/rcm/rcm'
import RecoveryCapitalForms from './pages/recovery_capital_forms'
import RecoveryCoaching from './pages/recovery_coaching'
import ServiceEpisodes from './pages/service_episodes'
import ShiftNotes from './pages/shift_notes'
import TestResults from './pages/test_results'
import Todos from './pages/todos/todos'
import TreatmentEpisodes from './pages/treatment_episodes'
import TreatmentPlans from './pages/treatment_plans'
import TreatmentPlansV2 from './pages/treatment_plans_v2/treatment_plans'
import VOB from './pages/vob'
import VOBArchived from './pages/vob_archived'
import { ClientJourneyPage } from './pages/client_journey'
import { Referrals } from './pages/referrals'
import { ContactsFormSubmissions } from './pages/contacts_form_submissions'
import { GrievanceNotes } from './pages/grievance_notes'
import SafetyChecks from './pages/safety_checks'
import RoundLines from './pages/round_lines'

import Status from '@behavehealth/components/Status'

import InsuranceClaims from './pages/insurance_claims'
import InsuranceCharges from './pages/insurance_charges'

import FlowChart from './pages/flow_chart'

import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import useStore from '@behavehealth/modules/store'
import { clearData, updateData } from '@behavehealth/actions/data'
import { update } from '@behavehealth/modules/api/requests'

import { HighRiskAlert } from '@behavehealth/constructs/HighRiskAlert/HighRiskAlert'
import ERXStatus from '@behavehealth/components/Statuses/ERXStatus'
import ERXDropdownItem from '@behavehealth/components/Buttons/ERXDropdownItem'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

const Client = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const params: any = useParams()

  const tenant = useStore((state) => state.tenant)
  const isDesktop = useMedia({ minWidth: BREAKPOINT[3] })
  const { isBehave, isManagement, timezone } = useSettings()

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const { data: client, isLoading, isError, error }: any = useGet({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
    invalidate: ['clients'],
  })

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    if (error?.error?.data?.errors?.[0]) Notifications.send(error.error.data.errors[0], 'negative')
    if (error?.data?.errors?.[0]) Notifications.send(error.data.errors[0], 'negative')

    // go back to the Clients list if unauthorized
    if (error?.error?.status === 401) history.push('/clients')

    // go back to the Clients list if unauthorized
    if (error?.status === 404) history.push('/clients')
  }, [isError])

  React.useEffect(() => {
    if (!client) return

    store.dispatch(updateData(`clients.data.${client?.id}`, client))

    return () => {
      store.dispatch(clearData('clients'))
    }
  }, [client])

  if (isLoading) return <PageLoader />

  const isEHR = tenant.category === 'healthcare_facility' || tenant.category === 'healthcare_practice'
  const isTrialing = tenant?.plan_status === 'trialing'

  const isTenantBilledByBehave = tenant?.is_billed
  const showERXFeatures = tenant?.feature_flags?.erx === true

  const updatePassword = async (password: string) => {
    try {
      await update(`/residents/${params?.client_id}/update_password`, {
        new_password: password,
      })

      Notifications.send('Password updated successfully', 'positive')
    } catch {
      Notifications.send(
        'Something went wrong with the Password Update. Please try again or contact support if the problem persists',
        'negative',
      )
    }
  }

  const actions = (
    <>
      <ClientQuickActions data={client} />

      <Divider />

      {showERXFeatures && (
        <ERXDropdownItem isDisabled={client.erx_status !== 'synced'} rcopiaID={client.erx_id} rcopiaExternalID={client.external_id} />
      )}

      <Divider />

      {!client?.client_portal_status && (
        <DropdownItem
          label="Invite to Client Portal"
          glyph="portal"
          color="blue"
          link={{
            pathname: `${location.pathname}/invite-to-portal`,
            parent: location.pathname,
          }}
          featureFlag="client_portal"
          permission="clients.actions.invite_to_client_portal"
          featureFlagV2="client_portal"
        />
      )}

      <DropdownItem
        label="Discharge…"
        glyph="discharge"
        color="orange"
        link={{
          pathname: `${location.pathname}/discharge-client`,
          parent: location.pathname,
        }}
        permission="client_journey.actions.discharge"
      />

      {client?.current_user?.can_do_magic_link && (
        <>
          <Divider />

          <DropdownItem
            label="Send Magic Login"
            emoji="🔮"
            link={{
              pathname: `${location.pathname}/magic-login`,
              parent: location.pathname,
            }}
          />
        </>
      )}

      <Divider />

      <DropdownItem
        label="Add High-Risk Alert"
        glyph="warning"
        glyphColor="red"
        color="red"
        link={{
          pathname: `${location.pathname}/high-risk-alert`,
          parent: location.pathname,
        }}
        featureFlagV2="risk_alerts"
      />

      <Divider />

      {!isTrialing && (
        <>
          <Divider />

          <DropdownItem
            label="Export Data…"
            glyph="long_arrow_right"
            color="gray"
            link={`/clients/${params?.client_id}/data-exports`}
            permission="clients.actions.export"
            featureFlagV2="data_export"
          />
        </>
      )}
    </>
  )

  return (
    <PageGrid columns="320px 1fr" breakpoint={3}>
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={client}
        title={client?.name}
        avatar={client?.avatar}
        description={`${startCase(client?.sex) || '–'}, #${client?.behave_id}`}
        desktopProps={{
          icon: 'clients',
          title: 'Client',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="client_profile" />}
        profile={
          <PageProfile
            more
            record={client}
            actions={actions}
            title={fullname(client)}
            subtitle={
              <>
                {client?.current_bed_occupancy && (
                  <Text
                    description={compact([
                      client.current_bed_occupancy.place?.house?.name,
                      client.current_bed_occupancy.place?.room?.name,
                    ]).join(', ')}
                    avatar={client.current_bed_occupancy.place?.house?.avatar}
                  />
                )}

                {client?.dob && (
                  <div>
                    <b>DOB:</b> {usDate(client.dob, timezone)} ({age(client.dob)} y/o)
                  </div>
                )}

                {client?.admitted_at && (
                  <div>
                    <b>Admitted:</b> {usDate(client.admitted_at, timezone)}
                  </div>
                )}
              </>
            }
            description={`${startCase(client?.sex) || '–'}, #${client?.behave_id}`}
            content={
              <>
                <Button
                  label={client?.active_level_of_care ? 'Update Primary Service…' : 'Set Primary Service…'}
                  glyph="recurring_charge"
                  type="primary"
                  color="green"
                  link={{
                    pathname: `${location.pathname}/update_loc`,
                    parent: location.pathname,
                  }}
                  featureFlagV2="service_episodes"
                />
              </>
            }
            beforeActions={
              <>
                {client?.current_user?.last_logged_in_at && (
                  <Status color="green" label={`Successful Login: ${usDateTime(client?.current_user?.last_logged_in_at, timezone)}`} />
                )}
              </>
            }
            underActions={
              <>
                {isTenantBilledByBehave && (
                  <SummonOverlay overlay={<RequestVOBOverlay client={client} />}>
                    <Button label="Request Full VOB" glyph="behave_health" color="green" featureFlagV2="full_vobs" />
                  </SummonOverlay>
                )}

                <div className="!mt-3 w-full">
                  <TagsSelector
                    tags={client?.tags}
                    onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })}
                    smartCategories="current_clients"
                  />
                </div>
              </>
            }
            status={
              <>
                {isDesktop && <HighRiskAlert isRounded title={client?.high_risk_title} notes={client?.high_risk_notes} />}

                {client?.id && (
                  <div className="w-full pb-3">
                    <ClientTrackSelector clientId={client.id} activeTrack={client.client_track} />
                  </div>
                )}

                <ClientColorPicker clientID={client?.id} color={client?.color_code} />
                {client?.is_billed_by_behave && <BillingStatus status="billed" />}
                <ClientStatus status={client?.status} />
                {client?.active_level_of_care && <Status color="green" label={client?.active_level_of_care?.name} />}
                {client?.client_portal_status && <ClientPortalStatus status={client?.client_portal_status} />}
                {showERXFeatures && client?.erx_status && <ERXStatus status={client?.erx_status} />}
                {client?.collaboratemd_id && <CollaborateStatus status="collaboratemd" />}
              </>
            }
          />
        }
      >
        <ClientNavItems id={params?.client_id} />
      </Nav>

      {!isDesktop && <HighRiskAlert title={client?.high_risk_title} notes={client?.high_risk_notes} />}

      <Switch>
        <ProtectedRoute
          path={`/clients/:resource_id/activity-timeline`}
          component={ActivityTimeline}
          featureFlagV2="activity_timeline"
          permission="activity_timeline.view"
        />
        <Route path={`/clients/:resource_id/general-info`} component={GeneralInfo} />
        <Route path={`/clients/:resource_id/treatment-episodes`} component={TreatmentEpisodes} />
        <ProtectedRoute
          path={`/clients/:resource_id/service-episodes`}
          component={ServiceEpisodes}
          permission="service_episodes.view"
          featureFlagV2="service_episodes"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/authorizations`}
          component={Authorizations}
          permission={isBehave ? true : 'insurance_authorizations.view'}
          featureFlagV2="insurance_authorizations"
        />
        <ProtectedRoute path={`/clients/:resource_id/todo`} component={Todos} permission="todos.view" featureFlagV2="todos" />
        <ProtectedRoute path={`/clients/:resource_id/files`} component={Files} permission="clients.files.view" featureFlagV2="files" />
        <ProtectedRoute
          path={`/clients/:resource_id/safety-checks`}
          component={SafetyChecks}
          permission="safety_checks.view"
          featureFlag="safety_checks"
        />
        <ProtectedRoute path={`/clients/:resource_id/rounds`} component={RoundLines} permission="rounds.view" featureFlag="rounds" />
        <ProtectedRoute
          path={`/clients/:resource_id/shift-notes`}
          component={ShiftNotes}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <ProtectedRoute path={`/clients/:resource_id/programs`} component={Programs} permission="programs.view" featureFlagV2="programs" />
        <ProtectedRoute
          path={`/clients/:resource_id/client-journey`}
          component={ClientJourneyPage}
          permission="client_journey.view"
          featureFlagV2="client_journey"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/recovery-coaching`}
          component={RecoveryCoaching}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/location-checkin`}
          component={LocationCheckIn}
          permission="location_checkin.view"
          featureFlagV2="location_gps_check_in"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/agreements`}
          component={Agreements}
          permission="agreements.view"
          featureFlagV2="client_agreements"
        />
        <ProtectedRoute path={`/clients/:resource_id/calendar`} component={Calendar} permission="events.view" featureFlagV2="calendar" />
        <ProtectedRoute
          path={`/clients/:resource_id/custom-notes`}
          component={CustomNotes}
          permission="client_custom_notes.view"
          featureFlagV2="client_custom_notes"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/grievance-notes`}
          component={GrievanceNotes}
          permission={isBehave || isManagement}
          featureFlag="grievance_notes"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/client-portal`}
          component={ClientPortal}
          featureFlagV2="client_portal"
          permission="clients.actions.invite_to_client_portal"
        />
        <ProtectedRoute path={`/clients/:resource_id/form-submissions`} featureFlagV2="form_submissions" permission="form_submissions.view">
          <FormSubmissions reference={client} allowedReference="current_clients" />
        </ProtectedRoute>
        <ProtectedRoute
          path={`/clients/:resource_id/occupancies`}
          component={Occupancies}
          permission="bed_management.view"
          featureFlagV2="bed_management"
        />
        <ProtectedRoute path={`/clients/:resource_id/referrals`} component={Referrals} permission={isBehave} />
        <ProtectedRoute
          path={`/clients/:resource_id/peer-notes`}
          component={PeerNotes}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />
        <Route path={`/clients/:resource_id/daily-notes`} component={DailyNotes} />
        <ProtectedRoute
          path={`/clients/:resource_id/contacts-form-submissions`}
          component={ContactsFormSubmissions}
          permission={isBehave}
        />

        {/* Contacts */}
        <ProtectedRoute
          path={`/clients/:resource_id/connections`}
          component={Connections}
          permission="connections.view"
          featureFlagV2="care_team"
        />
        <ProtectedRoute path={`/clients/:resource_id/contacts`} component={Contacts} permission="contacts.view" featureFlagV2="contacts" />
        <ProtectedRoute
          path={`/clients/:resource_id/communications`}
          component={Communications}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />

        {/* Admissions */}
        <ProtectedRoute
          path={`/clients/:resource_id/applications`}
          component={Applications}
          permission="applications.view"
          featureFlagV2="admissions"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/insurance`}
          component={Insurance}
          permission="insurance_policies.view"
          featureFlagV2="insurance_policies"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/vobs`}
          component={VOB}
          permission={isBehave ? true : 'full_vobs.view'}
          featureFlagV2="full_vobs"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/archived-vobs`}
          component={VOBArchived}
          permission={isBehave ? true : 'full_vobs.view'}
          featureFlagV2="full_vobs"
        />
        <Route path={`/clients/:resource_id/intake-forms`} component={IntakeForms} />

        {isEHR ? (
          <Route path={`/clients/:resource_id/discharges`} component={HealthcareDischarges} />
        ) : (
          <ProtectedRoute
            path={`/clients/:resource_id/discharges`}
            component={Discharges}
            permission="discharges.view"
            featureFlagV2="discharges"
          />
        )}

        {/* Financials */}
        <ProtectedRoute
          path={`/clients/:resource_id/financials`}
          component={Financials}
          permission="ledger.view"
          featureFlagV2="financials"
        />
        <Route path={`/clients/:resource_id/charge-notes`} component={ChargeNotes} />
        <Route path={`/clients/:resource_id/client-cash`} component={ClientCash} />

        {/* Billing */}
        <Route path={`/clients/:resource_id/claims`} component={InsuranceClaims} />
        <Route path={`/clients/:resource_id/charges`} component={InsuranceCharges} />
        <Route path={`/clients/:resource_id/billing-roster`} component={BillingRosters} />
        <Route path={`/clients/:resource_id/claims`} component={Claims} />

        {/* Clinical */}
        <ProtectedRoute
          path={`/clients/:resource_id/clinical-assessments`}
          component={ClinicalAssessments}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/diagnoses`}
          component={Diagnoses}
          permission="diagnoses.view"
          featureFlagV2="diagnoses"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/archived-treatment-plans`}
          component={TreatmentPlans}
          permission="treatment_plans.view"
          featureFlagV2="treatment_plans"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/clinical-notes`}
          component={ClinicalNotes}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/outcome-measures`}
          component={OutcomeMeasures}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/clinical-measurements`}
          component={ClinicalMeasurements}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/recovery-capital-forms`}
          component={RecoveryCapitalForms}
          permission={isBehave ? true : 'recovery_capital_forms.view'}
          featureFlagV2={isBehave ? true : 'recovery_capital_forms'}
        />
        <ProtectedRoute
          path={`/clients/:resource_id/progress-reviews`}
          component={ProgressReviews}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />

        {/* Medical */}
        <Route path={`/clients/:resource_id/reported-conditions`} component={Allergies} />
        <ProtectedRoute
          path={`/clients/:resource_id/medications`}
          component={Medications}
          permission="medications.view"
          featureFlagV2="medications"
        />
        <ProtectedRoute
          path={`/clients/:resource_id/medical-assessments`}
          component={MedicalAssessments}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />
        <ProtectedRoute path={`/clients/:resource_id/nursing`} component={Nursing} featureFlagV2="nursing" permission="nursing.view" />

        <Route path={`/clients/:resource_id/flow-chart`} component={FlowChart} />
        <Route path={`/clients/:resource_id/rcm`} component={RCM} />
        <ProtectedRoute
          path={`/clients/:resource_id/data-exports`}
          component={DataExports}
          permission="clients.actions.export"
          featureFlagV2="data_export"
        />

        <ProtectedRoute path={`/clients/:resource_id/invoices`} component={Invoices} permission={isBehave} />

        <Redirect exact from={match.url} to={`/clients/${params?.client_id}/general-info`} />
      </Switch>

      <Routes>
        <RouteV6
          path="/clients/:resource_id/treatment-plans/*"
          element={
            <ProtectedRouteV6 featureFlagV2="treatment_plans" permission={viewSomeTreatmentPlans}>
              <TreatmentPlansV2 />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/clients/:resource_id/progress-notes/*"
          element={
            <ProtectedRouteV6 featureFlagV2="progress_notes" permission="progress_notes.view">
              <ProgressNotes />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/clients/:resource_id/physician-orders/*"
          element={
            <ProtectedRouteV6 featureFlagV2="physician_orders" permission="physician_orders.view">
              <PhysicianOrders />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/clients/:resource_id/test-results/*"
          element={
            <ProtectedRouteV6 featureFlagV2="test_results" permission="test_results">
              <TestResults />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Client))
