import React from 'react'
import clsx from 'clsx'

import Label from '../Label'
import Graphic from '../Graphic'
import Flex from '../Flex'

import { Text } from '../Typography'

const FormSection = (props: any) => {
  const { children, description, heading, maxWidth, horizontal, labelWidth, className, label, icon, glyph } = props

  const classNames = clsx({
    'is-horizontal': horizontal,
    [className]: className,
  })

  return (
    <div
      className={classNames}
      css={styles}
      style={{
        ...(maxWidth && { '--field-max-width': maxWidth }),
        ...(labelWidth && { '--field-label-width': labelWidth }),
      }}
    >
      {heading && (
        <div className="FormSection-header">
          <Flex gap="0.6rem">
            {(icon || glyph) && <Graphic glyph={glyph} icon={icon} size={20} />}
            {heading && <h3 className="FormSection-heading">{heading}</h3>}
          </Flex>

          {description && (
            <Text css={{ marginTop: '0.25em' }} className="italic">
              {description}
            </Text>
          )}
        </div>
      )}

      {label && <Label label={label} />}

      {children}
    </div>
  )
}

const styles = {
  display: 'grid',
  gridGap: '1rem',
  gridTemplateColumns: '100%',
  maxWidth: 'var(--field-max-width)',

  '&.is-horizontal': {
    display: 'flex',
    gridGap: 'initial',
    margin: '-0.5rem',
    gridTemplateColumns: 'auto',
    flexWrap: 'wrap',

    '& > *': {
      margin: '0.5rem',
    },
  },
}

export default FormSection
