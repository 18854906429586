import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { CustomNoteTemplatesReportDataTable } from '@behavehealth/constructs/LiveReports/CustomNoteTemplatesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const SLUGS_BY_VARIANT = {
  client: 'clients',
  staff: 'staff',
  property: 'properties',
  organization: 'organizations',
  program: 'programs',
  team: 'team',
}

const CustomNotes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'custom-note-templates'],
    endpoint: `/live_reports?category=custom_note_templates`,
    localStorageKey: 'report_custom_note_templates_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `/settings/custom-note-templates/${SLUGS_BY_VARIANT[rowData.variant]}/${rowData.id}`, [])

  return (
    <Page feature="custom_notes" title="Live Custom Note Templates Report">
      <Grid>
        <CustomNoteTemplatesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(CustomNotes)
