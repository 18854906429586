import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useAPI } from '@behavehealth/hooks'

import { Card, Chotomate, Page, HelpTagIframe } from '@behavehealth/components'
import InsuranceLocalPayerAuthorizationsTable from '@behavehealth/components/Tables/InsuranceLocalPayerAuthorizationsTable'

const Authorizations: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.insurance_authorizations?.data)
  const loading = useSelector((state) => state.data.insurance_authorizations?.loading)

  useAPI('insurance_authorizations', `/insurance_local_payers/${match.params?.resource_id}/insurance_authorizations`)

  return (
    <Page feature="payer_authorizations" help={<HelpTagIframe id="payer_authorizations" />}>
      <Chotomate name="payer_authorizations" ready={!loading} />

      <Card>
        <InsuranceLocalPayerAuthorizationsTable
          data={data}
          isLoading={loading}
          localStorageKey="insurance_authorizations"
          to={(id) => ({
            pathname: `/clients/${id}/authorizations`,
            parent: match,
          })}
        />
      </Card>
    </Page>
  )
}

export default Authorizations
