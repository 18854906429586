import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { PageGrid, HelpTagIframe, Nav, NavGroup, NavItem, Divider } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Accepted from './pages/accepted'
import AdmitSurvey from './pages/admit_survey'
import Agreements from './pages/agreements'
import Allergies from './pages/allergies'
import Alumni from './pages/alumni'
import Applicants from './pages/applicants'
import Applications from './pages/applications'
import ArchivedTreatmentPlans from './pages/archived_treatment_plans'
import AutoRepeatingCharges from './pages/auto_repeating_charges'
import BedAssignments from './pages/bed_assignments'
import BillingRosters from './pages/billing_rosters'
import BreathalyzerTests from './pages/breathalyzer_tests'
import Charges from './pages/charges'
import ClientConnection from './pages/client_connections'
import Clients from './pages/clients'
import ClientsCommunications from './pages/clients_communications'
import ClientsContacts from './pages/clients_contacts'
import ClientsFiles from './pages/clients_files'
import ClientsJourney from './pages/clients_journey'
import ClinicalAssessments from './pages/clinical_assessments'
import ClinicalMeasures from './pages/clinical_measures'
import ClinicalNotes from './pages/clinical_notes'
import CommunicableDiseaseTests from './pages/communicable_disease_tests'
import Credits from './pages/credits'
import CustomNotes from './pages/custom_notes'
import CustomNoteTemplates from './pages/custom_note_templates'
import DataExports from './pages/data_exports'
import Declined from './pages/declined'
import DemographicInfoForm from './pages/demographic_info_form'
import Discharges from './pages/discharges'
import DischargeSurvey from './pages/discharge_survey'
import Employees from './pages/employees'
import EmployeesFiles from './pages/employees_files'
import EventDocumentations from './pages/event_documentations'
import Events from './pages/events'
import Excel from './pages/excel'
import InsuranceAgingReport from './pages/insurance/aging_report'
import InsuranceAuthorizations from './pages/insurance_authorizations'
import InsuranceBillingReport from './pages/insurance/billing_report'
import InsuranceCharges from './pages/insurance_charges'
import InsuranceClaimNotes from './pages/insurance_claim_notes'
import InsuranceClaimTasks from './pages/insurance_claim_tasks'
import InsuranceEvobs from './pages/insurance_evobs'
import InsurancePaymentsReport from './pages/insurance/payments_report'
import InsurancePolicies from './pages/insurance_policies'
import InsuranceVobs from './pages/insurance_vobs'
import InsuranceVobSummaries from './pages/insurance_vob_summaries'
import Labs from './pages/labs'
import LocationCheckins from './pages/location_checkins'
import MedicalAssessments from './pages/medical_assessments'
import MedicationLogs from './pages/medication_logs'
import Medications from './pages/medications'
import Naatp from './pages/naatp'
import NewInsuranceBilling from './pages/new_insurance_billing'
import NewInsuranceClaims from './pages/new_insurance_claims'
import NewInsurancePayments from './pages/new_insurance_payments'
import Notifications from './pages/notifications'
import Nursing from './pages/nursing'
import OrganizationContacts from './pages/organization_contacts'
import OrganizationsCommunications from './pages/organizations_communications'
import OrganizationsFiles from './pages/organizations_files'
import OutcomesSurvey from './pages/outcomes_survey'
import PastClientCharges from './pages/past_client_charges'
import PaymentMethods from './pages/payment_methods'
import Payments from './pages/payments'
import PeerNotes from './pages/peer_notes'
import DailyNotes from './pages/daily_notes'
import PhysiciansOrders from './pages/physicians_orders'
import ProgramAssignments from './pages/program_assignments'
import ProgramLists from './pages/program_lists'
import ProgressMonitoringSurvey from './pages/progress_monitoring_survey'
import ProgressNotes from './pages/progress_notes'
import ProgressReviews from './pages/progress_reviews'
import PropertiesFiles from './pages/properties_files'
import Referrals from './pages/referrals'
import Refunds from './pages/refunds'
import Rounds from './pages/rounds'
import SafetyChecks from './pages/safety_checks'
import ServiceEpisodes from './pages/service_episodes'
import ShiftNotes from './pages/shift_notes'
import Tags from './pages/tags'
import Timeliness from './pages/timeliness'
import Todos from './pages/todos'
import TreatmentEpisodes from './pages/treatment_episodes'
import TreatmentPlans from './pages/treatment_plans'
import UATests from './pages/ua_tests'
import VitalSigns from './pages/vital_signs'
import WriteOffs from './pages/write_offs'

const pageConfig = {
  feature: 'live_reports',
  help: <HelpTagIframe id="reports" />,
  marketingID: 'reports',
}

const Reports = () => {
  const tenant = useSelector((state: any) => state.me?.tenant)
  const user = useSelector((state: any) => state.me?.user)

  const isBehave = user?.type === 'bh_employee'
  const isTrialing = tenant?.plan_status === 'trialing'
  const isOwner = ['owner', 'administrator'].includes(user?.position)

  return (
    <PageGrid breakpoint={3} columns="280px 1fr">
      <Nav breakpoint={3} headingSize={300} title="Reports" icon="reports" help={<HelpTagIframe id="reports" />}>
        <NavGroup label="Live Reports">
          {/* <NavItem as={NavLink} feature="admissions" label="Admissions" to="admissions" permission="applications.view" /> */}

          <NavItem
            as={NavLink}
            feature="admissions"
            label="Applicants"
            to="applicants"
            permission="applications.view"
            featureFlagV2="admissions"
          />
          <NavItem as={NavLink} icon="checklist" label="Accepted" to="accepted" permission="applications.view" featureFlagV2="admissions" />
          <NavItem
            as={NavLink}
            icon="care_level_6"
            label="Declined"
            to="declined"
            permission="applications.view"
            featureFlagV2="admissions"
          />

          <NavItem as={NavLink} feature="clients" label="Current Clients" to="clients" permission="clients.view" featureFlagV2="clients" />
          <NavItem as={NavLink} feature="alumni" label="Past Clients" to="alumni" permission="clients.view" featureFlagV2="alumni" />
          <NavItem
            as={NavLink}
            feature="alumni"
            label="Past Clients w/ New Charges"
            to="past-client-charges"
            permission="clients.view"
            featureFlagV2="alumni"
          />
          <NavItem as={NavLink} feature="employees" label="Staff" to="employees" permission="employees.view" featureFlagV2="staff" />
          <NavItem as={NavLink} feature="tags" label="Tags" to="tags" />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="treatment_episodes"
            label="Treatment Episodes"
            to="treatment-episodes"
            permission="treatment_episodes.view"
            featureFlagV2="admissions"
          />
          <NavItem
            as={NavLink}
            feature="agreements"
            label="Agreements"
            to="agreements"
            permission="agreements.view"
            featureFlagV2="client_agreements"
          />
          <NavItem
            as={NavLink}
            feature="applications"
            label="Applications"
            to="applications"
            permission="applications.view"
            featureFlagV2="custom_application_form"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            icon="patient_progress_measure"
            label="Documentation Status"
            to="clients-journey"
            featureFlagV2="clinical_notes"
          />
          <NavItem as={NavLink} icon="patient_progress_measure" label="Documentation Time" to="timeliness" featureFlagV2="clinical_notes" />
          <NavItem
            as={NavLink}
            label="Event Documentations"
            to="event-documentations"
            feature="calendar"
            permission="events.view"
            featureFlagV2="calendar"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="service_episodes"
            label="Service Episodes"
            to="service-episodes"
            permission="insurance_policies.view"
            featureFlagV2="insurance_authorizations"
          />
          <NavItem
            as={NavLink}
            feature="authorizations"
            label="Authorizations"
            to="authorizations"
            permission="insurance_policies.view"
            featureFlagV2="insurance_authorizations"
          />
          <NavItem
            as={NavLink}
            feature="insurance_policies"
            label="Insurance Policies"
            to="insurance-policies"
            permission="insurance_policies.view"
            featureFlagV2="insurance_policies"
          />
          <NavItem
            as={NavLink}
            feature="insurance_evobs"
            label="Insurance EVOBs"
            to="insurance-evobs"
            permission="insurance_evobs.view"
            featureFlagV2="evobs"
          />
          <NavItem as={NavLink} feature="vob" label="Insurance VOBs" to="insurance-vobs" featureFlagV2={isBehave} />
          <NavItem as={NavLink} feature="vob" label="VOB Summaries" to="insurance-vob-summaries" featureFlagV2={isBehave} />

          <Divider className="!my-2 !mx-0" />

          {isBehave && (
            <>
              <NavItem as={NavLink} feature="rcm" label="New Insurance Payments" to="new-insurance-payments" featureFlagV2={isBehave} />

              <NavItem as={NavLink} feature="rcm" label="New Insurance Claims" to="new-insurance-claims" featureFlagV2={isBehave} />

              <NavItem as={NavLink} feature="rcm" label="New Insurance Billing" to="new-insurance-billing" featureFlagV2={isBehave} />

              <Divider className="!my-2 !mx-0" />
            </>
          )}

          <NavItem
            as={NavLink}
            label="Discharge Summaries"
            to="discharges"
            feature="discharges"
            permission="discharges.view"
            featureFlagV2="discharges"
          />
          <NavItem
            as={NavLink}
            label="Program Lists"
            to="program-lists"
            feature="programs"
            permission="programs.view"
            featureFlagV2="programs"
          />

          <NavItem
            as={NavLink}
            label="Bed Assignments"
            to="bed-assignments"
            feature="bed_management"
            permission="bed_management.view"
            featureFlagV2="bed_management"
          />

          <NavItem
            as={NavLink}
            label="Program Assignments"
            to="program-assignments"
            feature="programs"
            permission="programs.view"
            featureFlagV2="programs"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem as={NavLink} label="Events" to="events" feature="calendar" permission="events.view" featureFlagV2="calendar" />
          <NavItem as={NavLink} label="To-Do's" to="todos" feature="tasks" permission="todos.view" featureFlagV2="todos" />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            label="Data Exports"
            feature="data_exports"
            to="data_exports"
            permission="clients.actions.export"
            featureFlagV2="data_export"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="files"
            label="Clients Files"
            to="clients-files"
            permission="clients.files.view"
            featureFlagV2="files"
          />
          <NavItem
            as={NavLink}
            feature="files"
            label="Organizations Files"
            to="organizations-files"
            permission="organizations.files.view"
            featureFlagV2="files"
          />
          <NavItem
            as={NavLink}
            feature="files"
            label="Locations Files"
            to="properties-files"
            permission="properties.files.view"
            featureFlagV2="files"
          />
          <NavItem
            as={NavLink}
            feature="files"
            label="Staff Files"
            to="employees-files"
            permission="employees.files.view"
            featureFlagV2="files"
          />
          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="test_results"
            label="Breathalyzer Tests"
            to="breathalyzer-tests"
            permission="test_results.breathalyzer_tests.view"
            featureFlagV2="breathalyzer_tests"
          />
          <NavItem
            as={NavLink}
            feature="test_results"
            label="UA Tests"
            to="ua-tests"
            permission="test_results.ua_tests.view"
            featureFlagV2="ua_tests"
          />
          <NavItem
            as={NavLink}
            feature="test_results"
            label="Vital Signs"
            to="vital-signs"
            permission="test_results.vital_signs.view"
            featureFlagV2="vital_signs"
          />
          <NavItem
            as={NavLink}
            feature="test_results"
            label="CD Tests"
            to="communicable-disease-tests"
            permission="test_results.communicable_disease_tests.view"
            featureFlagV2="communicable_disease_tests"
          />
          <NavItem as={NavLink} feature="test_results" label="Labs" to="labs" permission="test_results.labs.view" featureFlagV2="labs" />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="contacts"
            label="Client Contacts"
            to="clients-contacts"
            permission="contacts.view"
            featureFlagV2="contacts"
          />
          <NavItem
            as={NavLink}
            feature="contacts"
            label="Org Contacts"
            to="organization-contacts"
            permission="contacts.view"
            featureFlagV2="contacts"
          />
          <NavItem
            as={NavLink}
            feature="communications"
            label="Client Communications"
            to="clients-communications"
            permission="communications.view"
            featureFlagV2="communication_logs"
          />
          <NavItem
            as={NavLink}
            feature="communications"
            label="Org Communications"
            to="organizations-communications"
            permission="communications.view"
            featureFlagV2="communication_logs"
          />
          {(isBehave || isOwner) && <NavItem as={NavLink} feature="notifications" label="Notifications" to="notifications" />}

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="custom_notes"
            label="Custom Notes"
            to="custom-notes"
            permission="client_custom_notes.view"
            featureFlagV2="client_custom_notes"
          />
          <NavItem
            as={NavLink}
            feature="custom_notes"
            label="Custom Note Templates"
            to="custom-note-templates"
            permission="client_custom_notes.view"
            featureFlagV2="client_custom_note_templates"
          />
          <NavItem
            as={NavLink}
            icon="housing_notes"
            label="Shift Notes"
            to="shift-notes"
            permission="shift_notes.view"
            featureFlagV2="shift_notes"
          />
          <NavItem
            as={NavLink}
            icon="safety_checks"
            label="Safety Checks"
            to="safety-checks"
            permission="safety_checks.view"
            featureFlagV2="safety_checks"
          />
          <NavItem as={NavLink} icon="rounds" label="Rounds" to="rounds" permission="rounds.view" featureFlagV2="rounds" />
          <NavItem
            as={NavLink}
            icon="peer_notes"
            label="Peer Notes"
            to="peer-notes"
            permission="peer_notes.view"
            featureFlagV2="peer_notes"
          />
          <NavItem
            as={NavLink}
            icon="daily_notes"
            label="Daily Notes"
            to="daily-notes"
            permission="daily_notes.view"
            featureFlagV2="daily_notes"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem as={NavLink} icon="clients" label="Referrals" to="referrals" permission="clients.view" featureFlagV2="clients" />
          <NavItem
            as={NavLink}
            icon="care_team"
            label="Care Team Connections"
            to="client-connections"
            permission="clients.view"
            featureFlagV2="care_team"
            feature="care_team"
          />
          <NavItem
            as={NavLink}
            icon="recovery_navigation"
            label="Location Check-in"
            to="location-checkins"
            permission="location_checkin.view"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="financials"
            label="Auto Repeating Charges"
            to="auto-repeating-charges"
            permission="ledger.view"
            featureFlagV2="financials"
          />
          <NavItem
            as={NavLink}
            feature="financials"
            label="Payment Methods"
            to="payment-methods"
            permission="ledger.view"
            featureFlagV2="financials"
          />
          <NavItem as={NavLink} feature="financials" label="Charges" to="charges" permission="ledger.view" featureFlagV2="financials" />
          <NavItem as={NavLink} feature="financials" label="Payments" to="payments" permission="ledger.view" featureFlagV2="financials" />
          <NavItem as={NavLink} feature="financials" label="Credits" to="credits" permission="ledger.view" featureFlagV2="financials" />
          <NavItem as={NavLink} feature="financials" label="Refunds" to="refunds" permission="ledger.view" featureFlagV2="financials" />
          <NavItem
            as={NavLink}
            feature="financials"
            label="Write-Offs"
            to="write-offs"
            permission="ledger.view"
            featureFlagV2="financials"
          />

          <Divider className="!my-2 !mx-0" />

          {/* <NavItem as={NavLink} feature="billing_rosters" label="Billing Rosters" to="billing-rosters" permission={isBehave} />
          <NavItem as={NavLink}
            feature="insurance"
            label="Insurance Charges"
            to="insurance-charges"
            featureFlagV2="insurance_rosters_claims_charges"
          /> */}

          {/* <NavGroup label="Insurance">
            <NavItem as={NavLink}
              feature="insurance"

              label="Billing Report"
              to="insurance-billing-report"
              permission={isBehave}
            />
            <NavItem as={NavLink}
              feature="insurance"

              label="Payments Report"
              to="insurance-payments-report"
              permission={isBehave}
            />
            <NavItem as={NavLink}
              feature="insurance"

              label="Aging Report"
              to="insurance-aging-report"
              permission={isBehave}
            />
            <NavItem as={NavLink}
              feature="insurance"

              label="Claim Notes"
              to="insurance-claim-notes"
              permission={isBehave}
            />
            <NavItem as={NavLink}
              feature="insurance"

              label="Claim Tasks"
              to="insurance-claim-tasks"
              permission={isBehave}
            />
          </NavGroup> */}

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="outcome_measures"
            label="Admit Survey"
            to="admit-survey"
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />

          <NavItem
            as={NavLink}
            feature="outcome_measures"
            label="Demographic Info"
            to="demographic-info-form"
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />

          <NavItem
            as={NavLink}
            feature="outcome_measures"
            label="Progress Monitoring Survey"
            to="progress-monitoring-survey"
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />

          <NavItem
            as={NavLink}
            feature="outcome_measures"
            label="Outcomes Survey"
            to="outcomes-survey"
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />

          <NavItem
            as={NavLink}
            feature="outcome_measures"
            label="Discharge Survey"
            to="discharge-survey"
            permission="outcome_measures.view"
            featureFlagV2="outcome_measures"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="clinical_measurements"
            label="Clinical Measures"
            to="clinical-measures"
            permission="clinical_measurements.view"
            featureFlagV2="clinical_measures"
          />
          <NavItem
            as={NavLink}
            feature="clinical_notes"
            label="Clinical Notes"
            to="clinical-notes"
            permission="clinical_notes.view"
            featureFlagV2="clinical_notes"
          />
          <NavItem
            as={NavLink}
            feature="clinical_assessments"
            label="Clinical Assessments"
            to="clinical-assessments"
            permission="clinical_assessments.view"
            featureFlagV2="clinical_assessments"
          />
          <NavItem
            as={NavLink}
            feature="progress_reviews"
            label="Progress Reviews"
            to="progress-reviews"
            permission="progress_reviews.view"
            featureFlagV2="progress_reviews"
          />
          <NavItem
            as={NavLink}
            feature="treatment_plans"
            label="Treatment Plans"
            to="treatment-plans"
            permission="treatment_plans.view"
            featureFlagV2="treatment_plans"
          />
          <NavItem
            as={NavLink}
            feature="treatment_plans"
            label="Archived Treatment Plans"
            to="archived-treatment-plans"
            permission="treatment_plans.view"
            featureFlagV2="treatment_plans"
          />

          <Divider className="!my-2 !mx-0" />

          <NavItem
            as={NavLink}
            feature="allergies"
            label="Allergies"
            to="allergies"
            permission="medications.view"
            featureFlagV2="medications"
          />
          <NavItem
            as={NavLink}
            feature="medications"
            label="Medication List"
            to="medications"
            permission="medications.view"
            featureFlagV2="medications"
          />
          <NavItem
            as={NavLink}
            feature="medication_logs"
            label="Medication Logs"
            to="medication-logs"
            permission="medication_logs.view"
            featureFlagV2="medication_logs"
          />
          <NavItem
            as={NavLink}
            feature="medical_assessments"
            label="Medical Assessments"
            to="medical-assessments"
            permission="medical_assessments.view"
            featureFlagV2="medical_assessments"
          />
          <NavItem
            as={NavLink}
            feature="physician_orders"
            label="Physicians Orders"
            to="physicians-orders"
            permission="physician_orders.view"
            featureFlagV2="physician_orders"
          />
          <NavItem as={NavLink} feature="nursing" label="Nursing" to="nursing" permission="nursing.view" featureFlagV2="nursing" />
          <NavItem
            as={NavLink}
            feature="progress_notes"
            label="Progress Notes"
            to="progress-notes"
            permission="progress_notes.view"
            featureFlagV2="progress_notes"
          />

          <Divider className="!my-2 !mx-0" />
        </NavGroup>

        {isBehave && (
          <NavGroup label="Vendor Reports">
            <NavItem
              as={NavLink}
              icon="outcome_measures"
              color="green"
              label="NAATP Survey Reports"
              to="naatp"
              permission="generated_reports.view"
              // featureFlagV2="excel_reports"
            />
          </NavGroup>
        )}

        {!isTrialing && (
          <NavGroup label="Generated Reports">
            <NavItem
              as={NavLink}
              icon="reports"
              color="green"
              label="Excel Reports"
              to="excel"
              permission="generated_reports.view"
              featureFlagV2="excel_reports"
            />
          </NavGroup>
        )}
      </Nav>

      <Routes>
        <Route index element={<Navigate to="applicants" replace />} />

        <Route path="accepted" element={<Accepted />} />
        <Route path="admit-survey" element={<AdmitSurvey />} />
        <Route path="agreements" element={<Agreements />} />
        <Route path="allergies" element={<Allergies />} />
        <Route path="alumni" element={<Alumni />} />
        <Route path="applicants" element={<Applicants />} />
        <Route path="applications" element={<Applications />} />
        <Route path="archived-treatment-plans" element={<ArchivedTreatmentPlans />} />
        <Route path="authorizations" element={<InsuranceAuthorizations />} />
        <Route path="auto-repeating-charges" element={<AutoRepeatingCharges />} />
        <Route path="bed-assignments" element={<BedAssignments />} />
        <Route path="billing-rosters" element={<BillingRosters />} />
        <Route path="breathalyzer-tests" element={<BreathalyzerTests />} />
        <Route path="charges" element={<Charges />} />
        <Route path="client-connections" element={<ClientConnection />} />
        <Route path="clients-communications" element={<ClientsCommunications />} />
        <Route path="clients-contacts" element={<ClientsContacts />} />
        <Route path="clients-files" element={<ClientsFiles />} />
        <Route path="clients-journey" element={<ClientsJourney />} />
        <Route path="clients" element={<Clients />} />
        <Route path="clinical-assessments" element={<ClinicalAssessments />} />
        <Route path="clinical-measures" element={<ClinicalMeasures />} />
        <Route path="clinical-notes" element={<ClinicalNotes />} />
        <Route path="communicable-disease-tests" element={<CommunicableDiseaseTests />} />
        <Route path="credits" element={<Credits />} />
        <Route path="custom-note-templates" element={<CustomNoteTemplates />} />
        <Route path="custom-notes" element={<CustomNotes />} />
        <Route path="data_exports" element={<DataExports />} />
        <Route path="declined" element={<Declined />} />
        <Route path="demographic-info-form" element={<DemographicInfoForm />} />
        <Route path="discharge-survey" element={<DischargeSurvey />} />
        <Route path="discharges" element={<Discharges />} />
        <Route path="employees-files" element={<EmployeesFiles />} />
        <Route path="employees" element={<Employees />} />
        <Route path="event-documentations" element={<EventDocumentations />} />
        <Route path="events" element={<Events />} />
        <Route path="insurance-aging-report" element={<InsuranceAgingReport />} />
        <Route path="insurance-billing-report" element={<InsuranceBillingReport />} />
        <Route path="insurance-charges" element={<InsuranceCharges />} />
        <Route path="insurance-claim-notes" element={<InsuranceClaimNotes />} />
        <Route path="insurance-claim-tasks" element={<InsuranceClaimTasks />} />
        <Route path="insurance-evobs" element={<InsuranceEvobs />} />
        <Route path="insurance-payments-report" element={<InsurancePaymentsReport />} />
        <Route path="insurance-policies" element={<InsurancePolicies />} />
        <Route path="insurance-vob-summaries" element={<InsuranceVobSummaries />} />
        <Route path="insurance-vobs" element={<InsuranceVobs />} />
        <Route path="labs" element={<Labs />} />
        <Route path="location-checkins" element={<LocationCheckins />} />
        <Route path="medical-assessments" element={<MedicalAssessments />} />
        <Route path="medication-logs" element={<MedicationLogs />} />
        <Route path="medications" element={<Medications />} />
        <Route path="naatp" element={<Naatp />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="nursing" element={<Nursing />} />
        <Route path="organization-contacts" element={<OrganizationContacts />} />
        <Route path="organizations-communications" element={<OrganizationsCommunications />} />
        <Route path="organizations-files" element={<OrganizationsFiles />} />
        <Route path="outcomes-survey" element={<OutcomesSurvey />} />
        <Route path="past-client-charges" element={<PastClientCharges />} />
        <Route path="payment-methods" element={<PaymentMethods />} />
        <Route path="payments" element={<Payments />} />
        <Route path="peer-notes" element={<PeerNotes />} />
        <Route path="daily-notes" element={<DailyNotes />} />
        <Route path="physicians-orders" element={<PhysiciansOrders />} />
        <Route path="program-assignments" element={<ProgramAssignments />} />
        <Route path="program-lists" element={<ProgramLists />} />
        <Route path="progress-monitoring-survey" element={<ProgressMonitoringSurvey />} />
        <Route path="progress-notes" element={<ProgressNotes />} />
        <Route path="progress-reviews" element={<ProgressReviews />} />
        <Route path="properties-files" element={<PropertiesFiles />} />
        <Route path="referrals" element={<Referrals />} />
        <Route path="refunds" element={<Refunds />} />
        <Route path="rounds" element={<Rounds />} />
        <Route path="safety-checks" element={<SafetyChecks />} />
        <Route path="service-episodes" element={<ServiceEpisodes />} />
        <Route path="shift-notes" element={<ShiftNotes />} />
        <Route path="tags/*" element={<Tags />} />
        <Route path="timeliness" element={<Timeliness />} />
        <Route path="todos" element={<Todos />} />
        <Route path="treatment-episodes" element={<TreatmentEpisodes />} />
        <Route path="treatment-plans" element={<TreatmentPlans />} />
        <Route path="ua-tests" element={<UATests />} />
        <Route path="vital-signs" element={<VitalSigns />} />
        <Route path="write-offs" element={<WriteOffs />} />

        <Route
          path="/excel/*"
          element={
            <ProtectedRouteV6 featureFlag="excel_reports">
              <Excel />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="new-insurance-payments"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <NewInsurancePayments />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="new-insurance-claims"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <NewInsuranceClaims />
            </ProtectedRouteV6>
          }
        />

        <Route
          path="new-insurance-billing"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <NewInsuranceBilling />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withPageError(withMarketing(Reports, pageConfig))
