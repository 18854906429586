import React from 'react'
import isUndefined from 'lodash/isUndefined'
import produce from 'immer'
import size from 'lodash/size'

import { isDefined } from '../../utils/functions'
import { update } from '../../modules/api/requests'
import { useGet, useDelete, useUpdate, invalidateQueries } from '../../hooks/useNewAPI'

import { getTableSettings, setTableSettings } from './localStorage'

const isDevelopment = process.env.NODE_ENV === 'development'

export const useDataTable = (initProps: any) => {
  const {
    name,
    endpoint,
    params,
    pageSize: initialPageSize = 25,
    localStorageKey,
    enabled = true,
    initialFilters,
    keepPreviousData = true,
    headers,
    staleTime,
  } = initProps

  const tableSettings = getTableSettings(localStorageKey)
  const updateDeleteEndpoint = initProps.updateDeleteEndpoint || endpoint

  const [filters, setFilters] = React.useState(tableSettings?.filters || initialFilters || {})
  const [sorting, setSorting] = React.useState(tableSettings?.sorting || [])

  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(tableSettings?.pageSize || initialPageSize)

  const validFilters = React.useMemo(() => {
    const result: any = {}

    if (!filters) return result

    return produce(filters, (draft: any) => {
      for (const key in draft) {
        const value = draft[key]?.value

        // boolean filters are always valid
        if (typeof value === 'boolean') continue

        // remove all invalid filters
        if (isUndefined(key)) {
          console.error(`Undefined key in filters`, { localStorageKey, filters, tableSettings, endpoint, params })
          delete draft[key]
          continue
        }

        if (typeof value === 'string' && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (Array.isArray(value) && size(value) === 0) {
          delete draft[key]
          continue
        }

        if (!isDefined(value)) {
          delete draft[key]
          continue
        }
      }
    })
  }, [filters])

  const queryParams = {
    filters: validFilters || {},
    sorting: sorting || {},
    page: currentPage,
    items: pageSize,
    ...params,
  }

  const queryKey = [name, queryParams].flat()

  const { data, folders, meta, isLoading, isRefetching, error, isError, refetch }: any = useGet({
    name: queryKey,
    url: endpoint,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters: validFilters })),
      sorting: btoa(JSON.stringify({ sorting })),
      ...(isDevelopment ? { decoded_filters: JSON.stringify({ filters: validFilters }) } : {}),
    },
    headers: initProps.headers,
    options: {
      enabled: !!(endpoint && enabled),
      keepPreviousData,
      staleTime,
    },
  })

  const { mutateAsync: updateRecord, isLoading: isUpdating } = useUpdate({
    name: queryKey,
    url: updateDeleteEndpoint,
    invalidate: queryKey,
    headers: headers,
  })

  const { mutateAsync: deleteRecords, isLoading: isDeleting } = useDelete({
    name: queryKey,
    url: updateDeleteEndpoint,
    invalidate: queryKey,
    headers: headers,
  })

  const batchUpdateRecords = async (ids: string[], params: any) => {
    if (size(ids) === 0 || !params || !updateDeleteEndpoint) return

    await update(`${updateDeleteEndpoint}/${ids.join(',')}`, params)

    invalidateQueries(queryKey)
  }

  React.useEffect(() => {
    setTableSettings(localStorageKey, { filters: validFilters, sorting, pageSize })
  }, [validFilters, sorting, pageSize])

  return {
    batchUpdateRecords,
    currentPage,
    data,
    deleteRecords,
    error,
    filters: validFilters,
    folders,
    isDeleting,
    isError,
    isLoading,
    isRefetching,
    isUpdating,
    localStorageKey,
    meta,
    onCurrentPageChange: setCurrentPage,
    onFiltersUpdate: setFilters,
    onPageSizeChange: setPageSize,
    onSortingUpdate: setSorting,
    pageSize,
    queryKey,
    refetch,
    sorting,
    updateDeleteEndpoint,
    updateRecord,
  }
}
