import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Card from '@behavehealth/components/Card'
import Grid from '@behavehealth/components/Grid'

import ClientsInvitesTable from '@behavehealth/components/Tables/ClientsInvitesTable'

const ClientsUserInvites: React.FC = () => {
  const queryParams = { category: 'client' }
  const queryKey = ['invites', queryParams]

  const { data, meta, isFetching, isLoading }: any = useGet({
    name: queryKey,
    url: `/invites`,
    params: queryParams,
  })

  return (
    <Grid gap="1rem" columns="100%">
      <Card>
        <ClientsInvitesTable
          data={data}
          meta={meta}
          apiKey={queryKey}
          isLoading={isLoading}
          isFetching={!isLoading && isFetching}
          localStorageKey="clients_invites"
          testKey="clients_invites_table"
          to={(id) => id}
          mainLinkAs={NavLink}
        />
      </Card>
    </Grid>
  )
}

export default withPageError(ClientsUserInvites)
