import React from 'react'

import { DataFormOverlay } from '../DataFormOverlay'

import Alert from '../../components/Alert'
import FormSection from '../../components/Forms/FormSection'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'

export const SUBSOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Substance Use Brief Screen (SUBS)"
      category="clinical_measurement"
      subcategory="subs"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <FormSection>
          <Alert small contrast glyph="info">
            <div className="grid gap-3">
              <div>
                Consider a "drink" to be a can or bottle of beer (12 ounces), a glass of wine (5 ounces), a wine cooler (12 ounces) or a
                shot of hard liquor like gin, vodka or whiskey (1.5 ounces).
              </div>

              <div>
                "Recreationally" means taking medications just for the feeling or experience they cause, to get high, or taking them more
                often or at higher doses than prescribed. Prescription Medications are those that are prescribed to you or to someone else.
              </div>
            </div>
          </Alert>

          <RadioGroup
            label={`1. In the past 12 months, on how many days did you use Tobacco?`}
            model="data.questionnaire.q1"
            layout="vertical-dense"
          >
            <Radio label="Three or more days in the past 12 months" value="three_or_more_days" />
            <Radio label="One or two days in the past 12 months" value="one_or_two_days" />
            <Radio label="Never in the past 12 months" value="never" />
          </RadioGroup>

          <RadioGroup
            label={`2. In the past 12 months on how many days did you have 4 or more alcoholic drinks in a day, including wine or beer?`}
            model="data.questionnaire.q2"
            layout="vertical-dense"
          >
            <Radio label="Three or more days in the past 12 months" value="three_or_more_days" />
            <Radio label="One or two days in the past 12 months" value="one_or_two_days" />
            <Radio label="Never in the past 12 months" value="never" />
          </RadioGroup>

          <RadioGroup
            label={`3. In the past 12 months on how many days did you use any Illegal Drug, including marijuana?`}
            model="data.questionnaire.q3"
            layout="vertical-dense"
          >
            <Radio label="Three or more days in the past 12 months" value="three_or_more_days" />
            <Radio label="One or two days in the past 12 months" value="one_or_two_days" />
            <Radio label="Never in the past 12 months" value="never" />
          </RadioGroup>

          <RadioGroup
            label="4. In the past 12 months on how many days did you use any Prescription Medications 'recreationally' (just for the feeling, or using more than prescribed)?"
            model="data.questionnaire.q4"
            layout="vertical-dense"
          >
            <Radio label="Three or more days in the past 12 months" value="three_or_more_days" />
            <Radio label="One or two days in the past 12 months" value="one_or_two_days" />
            <Radio label="Never in the past 12 months" value="never" />
          </RadioGroup>
        </FormSection>
      </Section>
    </DataFormOverlay>
  )
}
