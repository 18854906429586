import React from 'react'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'
import { ProgramCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/ProgramCustomNoteTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'program_custom_note_templates',
  title: 'Program Custom Note Templates',
}

const ProgramCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'program' },
    localStorageKey: 'program_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <ProgramCustomNoteTemplatesDataTable
          to={(row: any) => row.id}
          {...tableProps}
          duplicateColumn={setDuplicateColumnV6({
            link: 'new',
            permission: 'settings.program_custom_note_templates.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withMarketing(ProgramCustomNoteTemplates, pageConfig)
