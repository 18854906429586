import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'

import withMarketing from '@behavehealth/hocs/withMarketing'
import { LocalPayer } from '@behavehealth/declarations'

import { Button, Card, CardContent, Chotomate, DnDUploader, Page, Uploader, HelpTagIframe, Permission } from '@behavehealth/components'

import FilesTable from '@behavehealth/components/Tables/FilesTable'

const pageConfig = {
  feature: 'insurance_reports_files',
  help: <HelpTagIframe id="payer_files" />,
  marketingID: 'payer_files',
}

const PayerFiles: React.FC = ({ match }: any) => {
  const payer: LocalPayer = useSelector((state) => state.data.insurance_local_payers?.data[match?.params?.resource_id])

  const { resource_id }: any = useParams()

  const { data, isLoading }: any = useGet({
    name: ['insurance_local_payers', resource_id, 'files'],
    url: `/insurance_local_payers/${resource_id}/documents?category=general&subcategory=document`,
  })

  const { mutate: deleteFiles } = useDelete({
    name: ['insurance_local_payers', resource_id, 'files'],
    url: '/documents',
    invalidate: 'files',
  })

  const isEmpty = size(data) === 0

  const actions = (
    <Uploader category="general" subcategory="document" resource={payer}>
      <Button type="primary" glyph="upload" label="Upload Files" permission="insurance_local_payers.files.create" />
    </Uploader>
  )

  return (
    <Page actions={!isEmpty && actions} {...pageConfig}>
      <Chotomate name="payer_files" ready={!isLoading} />

      <Card>
        <FilesTable
          data={data}
          isLoading={isEmpty && isLoading}
          emptyActions={actions}
          localStorageKey="payer_files"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'insurance_local_payers.files.delete',
              action: async ({ ids }: any) => {
                await deleteFiles(ids.join(','))
              },
            },
          ]}
        />
        <CardContent>
          <Permission permission="insurance_local_payers.files.create">
            <DnDUploader category="general" subcategory="document" resource={payer} isEditable={true} isDisabled={false} />
          </Permission>
        </CardContent>
      </Card>
    </Page>
  )
}

export default withMarketing(PayerFiles, pageConfig)
