import React from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import find from 'lodash/find'
import merge from 'lodash/merge'
import produce from 'immer'

import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Dialog from '../../components/Dialog'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Workflow from '../../components/Workflow/Workflow'

import Events from '../ClientWorkflows/common/Events'
import Todos from '../ClientWorkflows/common/Todos'
import ServiceEpisodes from '../ClientWorkflows/common/ServiceEpisodes'

import Financials from './common/Financials'
import MoveTo from './common/MoveTo'
import ReviewSave from './common/ReviewSave'
import StaffAccessLists from './common/StaffAccessLists'

const initialSteps = {
  move_to: 'todo',
  service_episodes: 'todo',
  todos: 'todo',
  events: 'todo',
  financials: 'todo',
  staff_access_lists: 'todo',
  review_save: 'todo',
}

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const initialData = {}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const RootMoveBedsOverlay = () => {
  const match: any = useRouteMatch()
  const location: any = useLocation()
  const history: any = useHistory()

  const { timezone, isBehave, isSelfServiceBilling } = useSettings()

  const [steps, setSteps] = React.useReducer(stepsReducer, initialSteps)
  const [data, setData] = React.useReducer(dataReducer, initialData)

  const clientID = match.params?.client_id || match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client-to-move', clientID],
    url: `/residents/${clientID}`,
    options: { enabled: !!clientID },
  })

  const close = () => {
    if (location.parent) {
      history.push(location.parent.url)
    } else {
      const path = location.pathname
      history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  if (!client) return <OverlayLoader />

  return (
    <Overlay
      fullheight
      showBackdrop
      onClose={close}
      position="center"
      maxWidth={44}
      closeWrapper={(closeElement: any) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost if not saved on the Review & Save step"
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
        >
          {closeElement}
        </Dialog>
      )}
    >
      <Overlay.Header icon="bed_management" title={client?.current_bed_occupancy ? 'Move Beds' : 'Assign Bed'} />

      <Overlay.Content>
        <Workflow>
          {/* Move To */}
          <Workflow.Panel step="move_to">
            <Workflow.Header title="Move To" after={<Workflow.Status status={steps.move_to} />} />
            <Workflow.Content>
              <MoveTo
                data={data}
                setData={updateData}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'move_to', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Service Episodes */}
          <Workflow.Panel step="service_episodes" permission={isBehave ? true : 'service_episodes.view'} featureFlagV2="service_episodes">
            <Workflow.Header title="Service Episodes" after={<Workflow.Status status={steps.service_episodes} />} />
            <Workflow.Content>
              <ServiceEpisodes
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'service_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel step="todos" permission="todos.view" featureFlagV2="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel step="events" permission="events.view" featureFlagV2="calendar">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                match={match}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" permission="ledger.view" featureFlagV2="financials">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <Financials
                data={data}
                setData={updateData}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Staff Access Lists */}
          <Workflow.Panel hideOnNoPlan plan="pro" featureFlagV2="staff_clients_access" step="staff_access_lists">
            <Workflow.Header title="Staff Access Lists" after={<Workflow.Status status={steps.staff_access_lists} />} />
            <Workflow.Content>
              <StaffAccessLists
                data={data}
                setData={updateData}
                client={client}
                // currentProperty={currentProperty}
                // newProperty={newProperty}
                setStatus={(status: string) => {
                  setSteps({ step: 'staff_access_lists', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Review & Save */}
          <Workflow.Panel step="review_save">
            <Workflow.Header
              title={`${client?.current_bed_occupancy ? 'Move Beds' : 'Assign Bed'} – Review & Save`}
              after={<Workflow.Status status={steps.review_save} />}
            />
            <Workflow.Content>
              <ReviewSave data={data} client={client} close={close} />
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

export const MoveBedsOverlay = withOverlayError(RootMoveBedsOverlay)
