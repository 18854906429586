import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useAPI from '@behavehealth/hooks/useAPI'
import { Card, Chotomate } from '@behavehealth/components'
import PayersVOBsTable from '@behavehealth/components/Tables/PayersVOBsTable'

const VOBs: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.insurance_vobs?.data)
  const loading = useSelector((state) => state.data.insurance_vobs?.loading)

  useAPI('insurance_vobs', `/insurance_local_payers/${match.params?.resource_id}/insurance_vobs`)

  const isEmpty = size(data) === 0

  return (
    <>
      <Chotomate name="payers_vobs" ready={!loading} />
      <Card>
        <PayersVOBsTable
          data={data}
          isLoading={isEmpty && loading}
          //emptyActions={actions}
          localStorageKey="insurance_vobs"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </>
  )
}

export default VOBs
