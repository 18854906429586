import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import Card from '@behavehealth/components/Card'
import ChartCard from '@behavehealth/components/ChartCard'
import State from '@behavehealth/components/State'

import {
  AllPropertiesOccupancyRateChart,
  ClientCensusByPropertyChart,
  ClientCensusChart,
  ClientsCensusChart,
  PropertyOccupancyRateChart,
} from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const CensusAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/census',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading || Object.keys(stats).length === 0) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  } else {
    return (
      <div css={styles.grid}>
        <ChartCard title="Clients Census" subtitle="What is the current client daily census count over time?">
          <ClientsCensusChart timeframe={timeframe} renderer={renderer} xData={stats.clients?.x} yData={stats.clients?.y} />
        </ChartCard>

        <ChartCard title="Property Occupancy Rate" subtitle="What is the daily occupancy rate over time by property?">
          <PropertyOccupancyRateChart timeframe={timeframe} renderer={renderer} data={stats.properties_occupancy_rate} />
        </ChartCard>

        <ChartCard title="All Properties Occupancy Rate" subtitle="What is the daily occupancy rate over time of all properties?">
          <AllPropertiesOccupancyRateChart
            timeframe={timeframe}
            renderer={renderer}
            xData={stats.total_bed_occupancy?.x}
            yData={stats.total_bed_occupancy?.y}
          />
        </ChartCard>

        <ChartCard title="Client Census by Property" subtitle="What is the client census by property over time?">
          <ClientCensusByPropertyChart timeframe={timeframe} renderer={renderer} data={stats.properties_occupancy} />
        </ChartCard>

        <ChartCard title="Client Census" subtitle="What is the client census over time?">
          <ClientCensusChart timeframe={timeframe} renderer={renderer} xData={stats.all_clients?.x} yData={stats.all_clients?.y} />
        </ChartCard>
      </div>
    )
  }
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default CensusAnalytics
