import React from 'react'
import { tint, transparentize } from 'polished'
import { useNavigate } from 'react-router-dom-v5-compat'
import clsx from 'clsx'
import size from 'lodash/size'
import debounce from 'lodash/debounce'

import { COLORS, SHADOW } from '../../theme'
import { OUTCOMES_ADD_MORE } from '../Overlays/pages/Calendar/EventOutcomes'
import { PopoverMenu } from '../PopoverMenu'
import { usDate, usTimeShort } from '../../utils/functions'
import { useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import CalendarEvent from './CalendarEvent'
import Glyph from '../Glyph'
import Loader from '../Loader'
import Tooltip from '../Tooltip'

export const CalendarSearch = ({ className }) => {
  const navigate = useNavigate()

  const { tenant, timezone } = useSettings()

  const isHealthcare = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'

  const [query, setQuery] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [currentId, setCurrentId] = React.useState()

  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)
  const [rootRef, setRootRef] = React.useState<HTMLDivElement | null>(null)
  const [focusIndex, setFocusIndex] = React.useState(0)

  const [q, setQ] = React.useState('')
  const debouncedSetQ = React.useCallback(debounce(setQ, 300), [])

  const { data, isLoading, isRefetching }: any = useGet({
    name: ['events', { q, event_type: 'event' }],
    url: `/events/search`,
    params: { q, event_type: 'event' },
    options: { enabled: !!q, keepPreviousData: true },
  })

  React.useEffect(() => {
    setIsOpen(!!query)
    debouncedSetQ(query)
  }, [query, debouncedSetQ])

  // handle keyboard navigation
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
      }

      if (e.key === 'ArrowDown') {
        setFocusIndex((prev) => prev + 1)
      }

      if (e.key === 'ArrowUp') {
        setFocusIndex((prev) => prev - 1)
      }

      if (e.key === 'Enter') {
        const event = data?.[focusIndex]

        if (event?.id) {
          setCurrentId(event.id)
          navigate(event.id)
        }
      }
    }

    if (isOpen) document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [data, focusIndex, isOpen])

  React.useEffect(() => {
    if (!data) return

    // scroll to focused item
    const item = document.getElementById(`search-item-${data[focusIndex]?.id}`)

    if (item) {
      item.scrollIntoView({ block: 'nearest' })
    }
  }, [data, focusIndex])

  React.useEffect(() => {
    if (!data) return

    if (focusIndex < 0) {
      setFocusIndex(size(data) - 1)
    } else if (focusIndex >= size(data)) {
      setFocusIndex(0)
    }
  }, [focusIndex, data])

  const handleInteractOutside = (e) => {
    // if clicked on input, prevent closing
    if (inputRef?.contains(e.target) || rootRef?.contains(e.target)) {
      e.preventDefault()
    }
  }

  return (
    <>
      <div
        ref={setRootRef}
        className={clsx('relative bg-[#eef0f7] rounded-[5px] inline-flex w-full max-w-[200px] flex-nowrap h-8', className)}
      >
        <div className="self-stretch flex items-center justify-center w-8 pointer-events-none relative z-[1]">
          {isLoading || isRefetching || q !== query ? (
            <Loader size="1rem" />
          ) : (
            <Glyph glyph="search" size="1.2rem" color={COLORS.textMuted} />
          )}
        </div>

        <PopoverMenu
          preventAutoFocus
          stopPropagation
          isOpen={isOpen}
          onOpenUpdated={setIsOpen}
          onInteractOutside={handleInteractOutside}
          portalType="overlay"
          trigger={
            <input
              className="absolute top-0 left-0 right-0 bottom-0 px-8 bg-transparent border-solid border-transparent h-8 !cursor-text outline-none rounded-[5px]"
              ref={setInputRef}
              type="text"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value)
              }}
              placeholder="Search…"
              css={inputStyles}
            />
          }
        >
          {!!query && (
            <div className="p-1.5 text-[0.9rem] grid gap-[1px]">
              {(isLoading || q !== query) && size(data) === 0 ? (
                <div className="px-3 py-4 text-text-muted text-center">Searching…</div>
              ) : (
                size(data) === 0 &&
                q === query && <div className="px-3 py-4 text-text-muted text-center">No results found for "{query}"</div>
              )}

              {data?.map((item, index) => {
                const { status } = item

                const color = item?.color
                  ? item?.color
                  : status === 'completed'
                  ? COLORS.green
                  : status === 'cancelled'
                  ? COLORS.red
                  : COLORS.blue

                const background =
                  status === 'completed' ? tint(0.88, COLORS.green) : status === 'cancelled' ? tint(0.88, COLORS.red) : tint(0.88, color)

                const isOutcomesEnabled = isHealthcare && item?.category && OUTCOMES_ADD_MORE.hasOwnProperty(item?.category)

                const isActive = currentId === item.id

                return (
                  <CalendarEvent
                    useV6Router
                    event={item}
                    timezone={timezone}
                    key={`search-item-${item.id}`}
                    link={item.id}
                    trigger={
                      <div
                        className={clsx(
                          'flex items-center flex-nowrap px-2 py-1 rounded-[5px] cursor-pointer hover:bg-hover',
                          index === focusIndex && 'bg-hover',
                        )}
                        id={`search-item-${item.id}`}
                        style={{ background: isActive ? background : '' }}
                        onClick={(e) => {
                          if (index === focusIndex) {
                            navigate(item.id)
                          }

                          setCurrentId(item.id)
                          setFocusIndex(index)
                        }}
                      >
                        <div className="w-2 h-2 rounded-full mr-1.5 flex-[0_0_auto]" style={{ background: color }} />

                        <div className="flex-[1_1_auto]">
                          <div className="truncate min-w-0 max-w-[280px] font-[600]">{item.title}</div>
                          <div className="tabular-nums mr-1.5 opacity-60 font-[400] truncate flex-[0_0_auto] lowercase">
                            {usDate(item?.started_at, timezone)}, {item?.is_all_day ? 'All day' : usTimeShort(item?.start_time, timezone)}
                          </div>
                        </div>

                        {isOutcomesEnabled && <Glyph glyph="star" color={COLORS.text} size={13} className="ml-1 mr-0.5" />}

                        {item?.share_with_client_portal && <Glyph glyph="portal" size={14} className="ml-1" />}

                        {item?.status === 'completed' && <Glyph glyph="tick_circle" color={'green'} size={14} className="ml-1" />}
                      </div>
                    }
                  />
                )
              })}
            </div>
          )}
        </PopoverMenu>

        {!!query && (
          <button
            type="button"
            className="self-stretch inline-flex items-center justify-center w-8 z-[1] border-none bg-transparent z-[1] absolute top-0 right-0 bottom-0 cursor-pointer"
            onClick={() => {
              setQuery('')
            }}
          >
            <Glyph glyph="circle_error" size="1rem" color={COLORS.textMuted} />
          </button>
        )}
      </div>

      <Tooltip
        className="mr-3"
        content={
          <>
            <div className="font-[600]">The following data points are searchable within Events:</div>
            <ul>
              <li>Title</li>
              <li>Public Description</li>
              <li>Clients Name</li>
              <li>Clients Email</li>
              <li>Clients Phone No</li>
              <li>Staff Position</li>
              <li>Staff Title</li>
              <li>Staff Name</li>
              <li>Staff Email</li>
              <li>Staff Phone No</li>
              <li>Location Name</li>
              <li>Organization Name</li>
              <li>Imported Programs Name</li>
              <li>Imported Locations Name</li>
              <li>Imported Program Lists Name</li>
            </ul>
          </>
        }
      />
    </>
  )
}

const inputStyles = {
  '&:focus': {
    background: 'white',
    boxShadow: `
      0 0 0 1px var(--input-border-color-focus),
      ${SHADOW(5, transparentize(0.95, COLORS.blue))},
      0 0 0 3px ${transparentize(0.75, COLORS.blue)}
    `,
  },
}
