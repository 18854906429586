import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { MedicationsReportDataTable } from '@behavehealth/constructs/LiveReports/MedicationsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Medications = () => {
  const tableProps = useDataTable({
    name: ['reports', 'medications'],
    endpoint: `/live_reports?category=meds`,
    localStorageKey: 'report_medications_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/medications/${rowData.id}`, [])

  return (
    <Page feature="medications" title="Live Medication List Report">
      <Grid>
        <MedicationsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Medications)
