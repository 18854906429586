import React from 'react'
import { useHistory } from 'react-router-dom'

import { useCreate } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordions from '../../components/Accordions'
import AddressSelectorInput from '../../components/Forms/AddressSelectorInput'
import Alert from '../../components/Alert'
import BedSelector from '../../components/Forms/BedSelector'
import Button from '../../components/Button'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import DateOfBirthInput from '../../components/Forms/DateOfBirthInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import EmailInput from '../../components/Forms/EmailInput'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Overlay from '../../components/Overlay'
import PhoneInput from '../../components/Forms/PhoneInput'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SexSelect from '../../components/Forms/elements/SexSelect'

const RootNewClientOverlay = () => {
  const [valid, setValid] = React.useState(false)
  const { timezone } = useSettings()
  const form = React.useRef()
  const history = useHistory()

  const [fromDate, setFromDate] = React.useState(null)
  const [untilDate, setUntilDate] = React.useState(null)

  const { mutateAsync: createClient, isLoading: isCreating } = useCreate({
    name: 'clients',
    url: `/residents/new_client`,
    invalidateKeys: ['clients'],
  })

  const header = <Overlay.Header icon="clients" title="Add Client" help={<HelpTagIframe id="add_client" />} />

  const content = (
    <Overlay.Content>
      <Form
        isEditable
        getForm={form}
        timezone={timezone}
        onValidationUpdate={setValid}
        linked={{ care_level: 'sober_living', status: 'current' }}
        decorate={(model: any) => ({
          status: 'current',
          room_id: model.admissions?.[0].room_id,
          encrypted_data_attributes: {
            address: model.encrypted_data?.address,
          },
        })}
      >
        <Section headingType="h3" title="General Info">
          <FormSection>
            <Input
              label="First Name"
              model="first_name"
              validations={{
                presence: {
                  message: 'Please enter a first name',
                },
              }}
            />

            <Input label="Middle Name" model="middle_name" />

            <Input
              label="Last Name"
              model="last_name"
              validations={{
                presence: {
                  message: 'Please enter a last name',
                },
              }}
            />

            <Flex gap="1rem">
              <SexSelect label="Sex Assigned at Birth" model="sex" />
              <DateOfBirthInput label="Date of Birth" model="dob" />
            </Flex>

            <PhoneInput label="Phone Number" model="phone_no" />
            <EmailInput label="Email Address" model="email" />

            <AddressSelectorInput label="Home Address" model="encrypted_data.address" actionLabel="Find Address" />
          </FormSection>
        </Section>

        <Section>
          <Accordions>
            <FormSection layout="vertical">
              <Accordions.Item isOpen title="Intake Details">
                <FormSection layout="vertical">
                  <Flex gap={16}>
                    <DateInput
                      defaultToNow
                      label="Admit Date"
                      model="admission.admitted_at"
                      value={fromDate}
                      onUpdate={({ value }: any) => {
                        setFromDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please add an admit date',
                        },
                      }}
                    />

                    <DateInput
                      defaultInOneMonth
                      label="Est. Discharge Date"
                      model="admission.estimated_discharge"
                      value={untilDate}
                      onUpdate={({ value }: any) => {
                        setUntilDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please add an Est. Discharge Date',
                        },
                        datetime: {
                          earliest: fromDate,
                          message: `Please enter a date after the Admit Date`,
                        },
                      }}
                    />
                  </Flex>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Bed Assignment">
                <FormSection layout="vertical">
                  <Alert small glyph="info">
                    Please select both a date and time to avoid overlapping bed occupancies. If unsure of the time, give an estimate.
                  </Alert>

                  <DateTimeInput
                    defaultToNow
                    label="Occupy Bed From"
                    model="bed_assignment.started_at"
                    validations={{
                      presence: {
                        message: 'Please enter a date',
                      },
                    }}
                  />

                  <RadioGroup
                    label="Bed Occupied Until…"
                    model="bed_assignment.period_type"
                    layout="horizontal-dense"
                    defaultValue="indefinite"
                  >
                    <Radio label="Client Discharged" value="indefinite" />
                    <Radio label="Date" value="set_datetime" />
                  </RadioGroup>

                  <ContextShow when="bed_assignment.period_type" is="set_datetime">
                    <DateTimeInput
                      defaultToTomorrow
                      label="Bed Occupied Until"
                      model="bed_assignment.ended_at"
                      value={untilDate}
                      onUpdate={({ value }: any) => {
                        setUntilDate(value)
                      }}
                      validations={{
                        presence: {
                          message: 'Please enter an end date',
                        },
                        datetime: {
                          earliest: fromDate,
                          message: `Please enter a date after "Occupy Bed From"`,
                        },
                      }}
                    />
                  </ContextShow>

                  <BedSelector
                    fromDate={fromDate}
                    fromLabel="Move-in Date"
                    setFromDate={setFromDate}
                    setUntilDate={setUntilDate}
                    untilDate={untilDate}
                    untilLabel="Est. Discharge Date"
                    label="Assigned Bed"
                    model="bed_assignment.place"
                  />
                </FormSection>
              </Accordions.Item>
            </FormSection>
          </Accordions>
        </Section>
      </Form>
    </Overlay.Content>
  )

  const footer = (
    <Overlay.Footer>
      <Button
        color="green"
        glyph="check"
        type="primary"
        label="Create & Move-in Client"
        isLoading={isCreating}
        onClick={async () => {
          await createClient(form.current.getFormValue())
          history.goBack()
        }}
        isDisabled={!valid}
      />
    </Overlay.Footer>
  )

  return <Overlay header={header} content={content} footer={footer} />
}

export const NewClientOverlay = withOverlayError(RootNewClientOverlay)
