import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { OUTCOME_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import { ProgressMonitoringSurveyReportDataTable } from '@behavehealth/constructs/LiveReports/ProgressMonitoringSurveyReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ProgressMonitoringSurvey = () => {
  const tableProps = useDataTable({
    name: ['reports', 'progress_monitoring_survey'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_progress_monitoring_survey_v1',
    params: { category: 'progress_monitoring_survey' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/outcome-measures/${OUTCOME_MEASURES_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="outcome_measures" title="Live Progress Monitoring Survey Report">
      <Grid>
        <ProgressMonitoringSurveyReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ProgressMonitoringSurvey)
