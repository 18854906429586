import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { CreditsReportDataTable } from '@behavehealth/constructs/LiveReports/CreditsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const STATUSES = {
  lead: 'admissions',
  intake: 'admissions',
  declined: 'admissions',
  current: 'clients',
  alumni: 'alumni',
}

const Credits = () => {
  const tableProps = useDataTable({
    name: ['reports', 'credits'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_credits_v1',
    params: { category: 'credits' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/financials/credits/${rowData.id}`, [])

  return (
    <Page feature="financials" title="Live Credits Report">
      <Grid>
        <CreditsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Credits)
