import React from 'react'

import { apiUpdate } from '@behavehealth/modules/api'
import { URL, CONFIG } from '@behavehealth/utils/globals'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Accordion,
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Chotomate,
  Divider,
  Flex,
  Form,
  FormSection,
  Glyph,
  Heading,
  HelpTagIframe,
  Page,
  Radio,
  RadioGroup,
  ObjectSelector,
  MultiObjectSelector,
  ContextShow,
} from '@behavehealth/components'

import { PAYMENT_METHODS_LIST } from '@behavehealth/utils/constants'
import { PRICING_STRATEGIES } from '@behavehealth/components/Billing/constants'
import { beautifulAmount } from '@behavehealth/utils/functions'
import useStore from '@behavehealth/modules/store'

const pageConfig = {
  title: 'Financials Settings',
  icon: 'settings',
  help: <HelpTagIframe id="settings_payments" />,
  marketingID: 'settings_payments',
}

const FinancialsSettings: React.FC = () => {
  const tenant: any = useStore((state) => state?.tenant)

  const isStripeConnected = tenant?.is_stripe_connected
  const connectURL = process.env.BH_APP_CONNECT_URL

  return (
    <Page {...pageConfig}>
      <Chotomate name="payments" ready={tenant} />

      <Accordion
        isOpen
        title="Payments"
        activateEditMode
        initialModel={tenant}
        onSubmit={(data) =>
          apiUpdate({
            name: 'tenant',
            url: '/me/tenant',
            params: { financial_prefs: Object.assign({}, tenant.financial_prefs, data.financial_prefs) },
            reducer: 'me',
          })
        }
        editPermission="settings.online_payments.edit"
      >
        <Form>
          <FormSection maxWidth="600px">
            <CheckboxGroup label="Notifications" trueIcon="check" falseIcon="cross" falseStyle="linethrough">
              <Checkbox
                model="financial_prefs.send_weekly_reminder"
                label="Send Weekly Negative Balance Reminder"
                description="If the Client has a negative Total Balance, sends a reminder on Monday"
              />
              <Checkbox
                model="financial_prefs.send_monthly_reminder"
                label="Send Monthly Negative Balance Reminder"
                description="If the Client has a negative Total Balance, sends a reminder on the 1st of the month"
              />
            </CheckboxGroup>

            <MultiObjectSelector
              isRelations={false}
              model="financial_prefs.payment_methods"
              label="Default Payment Methods"
              apiData={PAYMENT_METHODS_LIST}
              showAvatars={false}
              selectTitle={(data: any) => (
                <>
                  <Glyph glyph={data.glyph} /> {data.name}
                </>
              )}
              selectDescription={(data: any) => data.description}
            />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        isOpen
        title="Online Payments"
        activateEditMode={isStripeConnected}
        initialModel={tenant}
        onSubmit={(data: any) =>
          apiUpdate({
            name: 'tenant',
            url: '/me/tenant',
            params: { financial_prefs: Object.assign({}, tenant.financial_prefs, data.financial_prefs) },
            reducer: 'me',
          })
        }
        editPermission="settings.online_payments.edit"
      >
        <Form>
          {!isStripeConnected && (
            <>
              <Alert glyph="info" type="neutral">
                We use Stripe to handle online payments for you. Stripe is a secure, trustworthy online payments processor and offers one of
                the best user experiences for you and your clients.
              </Alert>

              <Button
                display="inline-flex"
                type="primary"
                label="Connect Stripe"
                href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${CONFIG.stripe_client_id}&scope=read_write&stripe_landing=login&redirect_uri=${connectURL}&state=${URL.subdomain}`}
                className="!mt-4"
              />
            </>
          )}

          {isStripeConnected && (
            <>
              <Alert type="positive" glyph="check" className="!mb-4">
                Stripe Payments is active. Your clients will now see the option to pay you with Stripe from their Client Portal or you can
                share their Ledger Link with them and their family members to seamlessly collect payments.
              </Alert>

              <FormSection>
                <RadioGroup label="Who pays the processing fee?" model="financial_prefs.pass_fees_to_payers" layout="vertical-dense">
                  <Radio label="We pay the fee" value={false} />
                  <Radio label="Pass the fee to the payers" value={true} />
                </RadioGroup>

                <RadioGroup label="Data collection on Payments" model="financial_prefs.hipaa_compliant" layout="vertical-dense">
                  <Radio label="Don't collect Name & Email, stay HIPAA compliant" value={true} />
                  <Radio label="Collect the Name & Email of the Payer for Stripe" value={false} />
                </RadioGroup>

                <Alert type="neutral" glyph="info">
                  Collecting more information for payments can help you with disputes for chargebacks and refunds, but it is not HIPAA
                  compliant. Read more on <a href="https://stripe.com/docs/disputes/prevention">Stripe's Dispute Prevention</a>.
                </Alert>
              </FormSection>
            </>
          )}
        </Form>
      </Accordion>

      <Accordion
        isOpen
        title="Client RCM"
        activateEditMode
        initialModel={tenant}
        featureFlagV2="financials_pass_rcm_fees"
        onSubmit={(data: any) =>
          apiUpdate({
            name: 'tenant',
            url: '/me/tenant',
            reducer: 'me',
            params: {
              financial_prefs: Object.assign({}, tenant.financial_prefs, data.financial_prefs),
              active_rcm_subscription_id: data.active_rcm_subscription_id,
            },
          })
        }
        editPermission="settings.online_payments.edit"
      >
        <Form>
          <FormSection maxWidth="650px">
            <RadioGroup
              label="Do you want to automatically pass the Client RCM Fee to payers, as a Behave Pass-thru?"
              model="financial_prefs.pass_behave_rcm_fees_to_payers"
              layout="vertical-dense"
            >
              <Radio label="Yes, pass the fee" value={true} />
              <Radio label="No" value={false} />
            </RadioGroup>

            <ContextShow when="financial_prefs.pass_behave_rcm_fees_to_payers" is={true}>
              <ObjectSelector
                label="Active Client RCM Subscription"
                icon="certificate"
                type="subscriptions"
                model="active_rcm_subscription"
                selectTitle={(data: any) => data.product?.name}
                selectDescription={(data: any) => {
                  return `${PRICING_STRATEGIES[data?.product?.pricing_type]} – ${beautifulAmount(data?.product?.price)}`
                }}
                validations={{
                  presence: {
                    message: 'Please select the Active Financials RCM Subscription',
                  },
                }}
              />

              <RadioGroup
                label="On Discharge, automatically create a Write-Off for remaining RCM Fees?"
                model="financial_prefs.write_off_remaining_rcm_fees_on_discharge"
                layout="vertical-dense"
                defaultValue={true}
                description="Client Discharges on the 5th of February. On the 1st of March, we charge you RCM, so we generate an extra RCM fee for the Client on the 1st of March. Check this box to avoid this scenario"
              >
                <Radio label="Yes" value={true} />
                <Radio label="No" value={false} />
              </RadioGroup>
            </ContextShow>
          </FormSection>
        </Form>
      </Accordion>

      <Accordion title="Frequently Asked Questions">
        <div>
          {!isStripeConnected && (
            <>
              <Heading is="h3" size={500}>
                How do I get started with Stripe?
              </Heading>
              <p>
                Click the "Connect" button above to sign up for Stripe. Once you create your account, you will get redirected back here,
                where you will see a confirmation message.
              </p>
              <Divider />
            </>
          )}

          <Heading is="h3" size={500}>
            Who do I contact for support?
          </Heading>

          <p>
            If you're having any issues connecting with Stripe or using the Ledger Payments portal, we are happy to assist you. You can
            reach us at <a href="mailto:contact@behavehealth.com">contact@behavehealth.com</a>.
          </p>

          <Divider />

          <Heading is="h3" size={500}>
            How do the Behave fees work?
          </Heading>

          <p>
            Behave fees are based on the underlying charges in the Behave system. The Behave Fees are independent of the Stripe fees. If no
            stripe payment collection occurs, the Behave Fee still applies.
          </p>

          <Divider />

          <Heading is="h3" size={500}>
            How do Stripe processing fees work?
          </Heading>

          <p>
            You have two options - you either <strong>support</strong> the processing fees yourself, or you <strong>pass</strong> them to
            the payer. This choice becomes available above after you connected successfully to Stripe.
          </p>

          <table class="min-w-full border border-gray-300 bg-white shadow-md rounded-lg overflow-hidden border-collapse mb-[20px]">
            <thead class="bg-gray-100 !border-b !border-gray-300">
              <tr>
                <th class="px-4 py-3 text-left font-semibold text-gray-700 border-r border-gray-300">Transaction Type</th>
                <th class="px-4 py-3 text-left font-semibold text-gray-700 border-r border-gray-300">Example Underlying Charge</th>
                <th class="px-4 py-3 text-left font-semibold text-gray-700 border-r border-gray-300">Example Behave Health Fee 1%</th>
                <th class="px-4 py-3 text-left font-semibold text-gray-700 border-r border-gray-300">Example Stripe Fee</th>
                <th class="px-4 py-3 text-left font-semibold text-gray-700">Example Total Fee (Stripe &amp; Behave)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-b border-gray-300">
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">No Stripe</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$100.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$1.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$0</td>
                <td class="px-4 py-3 text-gray-700">$1.00</td>
              </tr>
              <tr class="border-b border-gray-300">
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">Credit Card</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$100.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$1.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$2.90 + 30¢ = $3.20</td>
                <td class="px-4 py-3 text-gray-700">$4.20</td>
              </tr>
              <tr>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">ACH</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$100.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">$1.00</td>
                <td class="px-4 py-3 text-gray-700 border-r border-gray-300">.80 (capped at $5.00 total)</td>
                <td class="px-4 py-3 text-gray-700">$1.80</td>
              </tr>
            </tbody>
          </table>

          <Flex vertical gap={16}>
            <div>
              <em>
                <strong>Supporting the Fee</strong>
              </em>
              <p>
                For every successful card charge, you will pay only Stripe's charge. Currently that is 2.9% + 30¢ (
                <a href="https://stripe.com/us/pricing" target="_blank" rel="noopener noreferrer">
                  see fees
                </a>
                ). Behave Health takes no fees.
              </p>
              <p>
                <em>For a $500.00 charge, the processing fee is $14.80. You receive $485.20 from Stripe.</em>
              </p>
            </div>

            <div>
              <em>
                <strong>Passing the Fee</strong>
              </em>

              <p>
                <Alert glyph="info" type="neutral">
                  It is important to ensure that this action complies with any applicable laws that pertain to your business. If you are
                  unsure whether such laws apply, consult with your legal counsel. Behave Health offers the tooling, but configuring these
                  is your responsibility.
                </Alert>
              </p>
              <p>
                You have the option of asking payers to pay any processing fees themselves – this ensures that you receive the full amount
                you are asking for (
                <a
                  href="https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  fee calculation
                </a>
                ).
              </p>

              <p>
                <em>
                  For a $500.00 charge, the extra processing fee paid by the payer is $15.24, making the total $515.24. You receive $500.00
                  from Stripe.
                </em>
              </p>
            </div>
          </Flex>
        </div>
      </Accordion>
    </Page>
  )
}

export default withPageError(withMarketing(FinancialsSettings, pageConfig))
