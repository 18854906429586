import React from 'react'
import { NavLink, useParams } from 'react-router-dom-v5-compat'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import withPermissions from '@behavehealth/hocs/withPermissions'

import Grid from '@behavehealth/components/Grid'
import Button from '@behavehealth/components/Button'
import { UATestsDataTable } from '@behavehealth/constructs/TestResults/UATestsDataTable'

const UATests = () => {
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'ua-tests'],
    endpoint: `/residents/${resource_id}/ua_tests`,
    updateDeleteEndpoint: '/ua_tests',
    enabled: !!resource_id,
    localStorageKey: 'ua_tests_v1',
  })

  return (
    <Grid gap="1rem" columns="100%">
      <UATestsDataTable
        {...tableProps}
        to={(row: any) => row.id}
        hiddenColumnIds={['reference']}
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/ua-tests',
          },
        ]}
        emptyActions={
          <Button
            as={NavLink}
            label="Add UA Test"
            type="primary"
            glyph="add"
            link="new"
            state={{
              data: {
                reference: client,
              },
            }}
            permission="test_results.ua_tests.create"
          />
        }
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'test_results.ua_tests.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
      />
    </Grid>
  )
}

export default withPermissions(UATests)
