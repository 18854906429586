import React from 'react'
import { Link, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Route as RouteV6, Routes } from 'react-router-dom-v5-compat'
import isFinite from 'lodash/isFinite'

import { clearData, updateData } from '@behavehealth/actions/data'
import { countWord, fullname } from '@behavehealth/utils/functions'
import { store } from '@behavehealth/setup/store'
import { useGet, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useSettings } from '@behavehealth/hooks/useSettings'
import Notifications from '@behavehealth/modules/notifications'
import withBackListener from '@behavehealth/hocs/withBackListener'

import { JoinMeetingButton } from '@behavehealth/components/Buttons/JoinMeetingButton'
import {
  Status,
  PageGrid,
  Nav,
  PageProfile,
  PageLoader,
  EmployeeNavItems,
  Text,
  Flex,
  HelpTagIframe,
  TagsSelector,
  Tooltip,
} from '@behavehealth/components'

import EmployeeQuickActions from '@behavehealth/components/QuickActions/Employee'
import Permission from '@behavehealth/components/Permission'

import AccessList from './pages/access_list'
import ActivityTimeline from './pages/activity_timeline'
import Agreements from './pages/agreements'
import Calendar from './pages/calendar'
import ClinicalNotes from './pages/clinical_notes'
import Credentials from './pages/credentials'
import CustomNotes from './pages/custom_notes'
import Files from './pages/files'
import AdminHRFiles from './pages/admin_hr_files'
import FormSubmissions from './pages/form_submissions'
import GeneralInfo from './pages/general_info'
import InsuranceCredentials from './pages/insurance_credentials'
import IPRestrictions from './pages/ip_restrictions'
import ShiftNotes from './pages/shift_notes'
import SafetyChecks from './pages/safety_checks'
import StaffPermissions from './pages/staff_permissions'
import TestResults from './pages/test_results'
import Todos from './pages/todos/todos'
import Settings from './pages/settings'
import StaffJourney from './pages/staff_journey'
import Account from './pages/account'
import AdminHRNotes from './pages/admin_hr_notes'
import PeerNotes from './pages/peer_notes'
import DailyNotes from './pages/daily_notes'

import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

// New Pages
import PhysicianOrders from './pages/physician_orders'
import Nursing from './pages/nursing'
import ProgressNotes from './pages/progress_notes'
import MedicalAssessments from './pages/medical_assessments'
import OutcomeMeasures from './pages/outcome_measures'
import ClinicalMeasurements from './pages/clinical_measurements'
import ProgressReviews from './pages/progress_reviews'
import ClinicalAssessments from './pages/clinical_assessments'
import RecoveryCoaching from './pages/recovery_coaching'
import TreatmentPlans from './pages/treatment_plans'

import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'

const Employee = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const params: any = useParams()

  const { url } = match

  const { isBehave, isManagement } = useSettings()
  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const { data: employee, isLoading, isError, error }: any = useGet({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}`,
    invalidate: ['employees'],
  })

  const { mutateAsync: blockEmployee }: any = useUpdate({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}/block`,
    invalidate: ['employees'],
  })

  const { mutateAsync: unblockEmployee }: any = useUpdate({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}/unblock`,
    invalidate: ['employees'],
  })

  const { mutateAsync: updateEmployeePassword }: any = useUpdate({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}/update_password`,
    invalidate: ['employees'],
  })

  const { mutateAsync: deleteEmployee }: any = useDelete({
    name: ['employees', params.employee_id],
    url: `/employees/${params.employee_id}`,
    invalidate: ['employees'],
    onSuccess: () => {
      history.push('/employees')
    },
  })

  const toggleArchiveEmployee = async (status: any) => {
    updateEmployee({ status })
  }

  const toggleBlockEmployee = async (isBlocked: any) => {
    if (isBlocked) {
      await unblockEmployee()
    } else {
      await blockEmployee()
    }
  }

  const updatePassword = async (password: any) => {
    updateEmployeePassword({ new_password: password })
  }

  React.useEffect(() => {
    if (!isError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')

    // go back to the Employees list if unauthorized
    if (error?.error?.status === 401) history.push('/employees')
  }, [isError])

  React.useEffect(() => {
    store.dispatch(updateData(`employees.data.${employee?.id}`, employee))

    return () => {
      store.dispatch(clearData('employees'))
    }
  }, [employee])

  if (isLoading) return <PageLoader />

  const auditIssuesCount = employee?.access_control_audits_with_issues_count
  const hasIssues = isFinite(auditIssuesCount) && auditIssuesCount > 0

  return (
    <PageGrid breakpoint={3} columns="280px 1fr">
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={employee}
        title={fullname(employee)}
        avatar={employee?.avatar}
        description={<Status label={employee?.position?.replace('_', ' ')} />}
        desktopProps={{
          icon: 'employees',
          title: 'Staff',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="staff_profile" />}
        profile={
          <PageProfile
            record={employee}
            status={<>{employee?.is_blocked && <Status label="Blocked" color="red" />}</>}
            title={fullname(employee)}
            subtitle={
              <>
                {employee?.houses?.map((property) => (
                  <Text description={property.name} avatar={property.avatar} />
                ))}

                <div className="w-full mt-2">
                  {hasIssues && (
                    <Tooltip
                      color="red"
                      content={
                        <>
                          <div className="font-[600] mb-1">
                            We found {countWord('potential issues', auditIssuesCount)} with the Access List of {employee?.name}.
                          </div>

                          <Permission permission="access_controls.view" featureFlagV2="staff_clients_access">
                            <div>
                              <Link to={`/employees/${employee?.id}/staff-access/audit`} className="font-[600]">
                                Go to Staff Access
                              </Link>{' '}
                              to review and resolve these issues.
                            </div>
                          </Permission>
                        </>
                      }
                    >
                      <Status color="red" glyph="circle_error" label={`${countWord('Access List Issues', auditIssuesCount)}`} />
                    </Tooltip>
                  )}
                </div>
              </>
            }
            description={
              <Flex gap={8}>
                {employee?.status === 'archived' && <Status label="Archived" />}
                {employee?.title && <Status label={employee?.title} color="green" />}
                <Status label={employee?.position?.replace('_', ' ')} color="gray" className="opacity-70" />
              </Flex>
            }
            actions={
              <EmployeeQuickActions
                data={employee}
                onDelete={deleteEmployee}
                toggleBlockEmployee={toggleBlockEmployee}
                toggleArchiveEmployee={toggleArchiveEmployee}
                updatePassword={updatePassword}
              />
            }
            underActions={
              <>
                <JoinMeetingButton
                  title={employee?.name}
                  meetingID={employee?.personal_meeting_room?.id}
                  meetingName={`${employee?.name} Meeting Room`}
                />

                <div className="!mt-3 w-full">
                  <TagsSelector
                    tags={employee?.tags}
                    onSave={(tagIDs: any) => updateEmployee({ tag_ids: tagIDs })}
                    smartCategories="employees"
                  />
                </div>
              </>
            }
          />
        }
      >
        <EmployeeNavItems employeeID={params?.employee_id} />
      </Nav>

      <Switch>
        <ProtectedRoute
          path={`/employees/:resource_id/activity-timeline`}
          component={ActivityTimeline}
          permission="activity_timeline.view"
        />
        <Route path={`/employees/:resource_id/general-info`} component={GeneralInfo} />
        <ProtectedRoute
          path={`/employees/:resource_id/shift-notes`}
          component={ShiftNotes}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <ProtectedRoute
          path={`/employees/:resource_id/safety-checks`}
          component={SafetyChecks}
          permission="safety_checks.view"
          featureFlagV2="safety_checks"
        />
        <ProtectedRoute
          path={`/employees/:resource_id/clinical-notes`}
          component={ClinicalNotes}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />
        <ProtectedRoute
          path={`/employees/:resource_id/peer-notes`}
          component={PeerNotes}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />
        <Route path={`/employees/:resource_id/daily-notes`} component={DailyNotes} />
        <ProtectedRoute path={`/employees/:resource_id/files`} component={Files} permission="employees.files.view" featureFlagV2="files" />
        <ProtectedRoute
          path={`/employees/:resource_id/admin-hr-files`}
          component={AdminHRFiles}
          permission={isBehave || isManagement}
          featureFlagV2="files"
        />
        <ProtectedRoute path={`/employees/:resource_id/todo`} component={Todos} permission="todos.view" featureFlagV2="todos" />
        <ProtectedRoute path={`/employees/:resource_id/calendar`} component={Calendar} permission="events.view" featureFlagV2="calendar" />
        <ProtectedRoute
          path={`/employees/:resource_id/custom-notes`}
          component={CustomNotes}
          permission="staff_custom_notes.view"
          featureFlagV2="staff_custom_notes"
        />
        <ProtectedRoute
          path={`/employees/:resource_id/admin-hr-notes`}
          component={AdminHRNotes}
          // featureFlag="admin_hr_custom_notes"
          permission={isBehave || isManagement}
        />
        <ProtectedRoute
          path={`/employees/:resource_id/agreements`}
          component={Agreements}
          permission="employee_agreements.view"
          featureFlag="staff_agreements"
        />
        <ProtectedRoute
          path={`/employees/:resource_id/credentials`}
          component={Credentials}
          permission="credentials.view"
          featureFlagV2="staff_credentials"
        />
        <ProtectedRoute path={`/employees/:resource_id/ip-restrictions`} component={IPRestrictions} featureFlagV2="staff_ip_restrictions" />
        <Route path={`/employees/:resource_id/insurance-credentials`} component={InsuranceCredentials} />
        <ProtectedRoute
          path={`/employees/:resource_id/form-submissions`}
          component={FormSubmissions}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
        <ProtectedRoute path={`/employees/:resource_id/settings`} component={Settings} featureFlagV2={isBehave} />
        <ProtectedRoute path={`/employees/:resource_id/staff-journey`} component={StaffJourney} featureFlagV2="staff_journey" />
        <Route path={`/employees/:resource_id/account`} component={Account} />

        <ProtectedRoute
          path={`/employees/:resource_id/staff-access`}
          component={AccessList}
          permission="access_controls.view"
          featureFlag="staff_clients_access"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/staff-permissions`}
          component={StaffPermissions}
          permission="employees.permissions.view"
          featureFlag="staff_permissions"
        />

        <ProtectedRoute path={`/employees/:resource_id/nursing`} component={Nursing} featureFlagV2="nursing" permission="nursing.view" />

        <ProtectedRoute
          path={`/employees/:resource_id/medical-assessments`}
          component={MedicalAssessments}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/outcome-measures`}
          component={OutcomeMeasures}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/clinical-measurements`}
          component={ClinicalMeasurements}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/progress-reviews`}
          component={ProgressReviews}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/clinical-assessments`}
          component={ClinicalAssessments}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />

        <ProtectedRoute
          path={`/employees/:resource_id/recovery-coaching`}
          component={RecoveryCoaching}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />

        <Redirect exact from={url} to={`${url}/general-info`} />
      </Switch>

      <Routes>
        <RouteV6
          path="/employees/:resource_id/treatment-plans/*"
          element={
            <ProtectedRouteV6 featureFlagV2="treatment_plans" permission={viewSomeTreatmentPlans}>
              <TreatmentPlans />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/employees/:resource_id/progress-notes/*"
          element={
            <ProtectedRouteV6 featureFlagV2="progress_notes" permission="progress_notes.view">
              <ProgressNotes />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/employees/:resource_id/physician-orders/*"
          element={
            <ProtectedRouteV6 featureFlagV2="physician_orders" permission="physician_orders.view">
              <PhysicianOrders />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/employees/:resource_id/test-results/*"
          element={
            <ProtectedRouteV6 featureFlagV2="test_results" permission="test_results">
              <TestResults />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Employee))
