import React from 'react'

import SmartStatus from '../SmartStatus'

export const STATUSES = {
  acknowledged: {
    label: 'Acknowledged',
    color: 'green',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
}

const Status = ({ ...rest }) => {
  return <SmartStatus statuses={STATUSES} {...rest} />
}

export default Status
