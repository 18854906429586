import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'

class OpenReplay {
  constructor() {
    this.openreplay = null
  }

  init = (id, url) => {
    this.openreplay = new Tracker({
      projectKey: id,
      ingestPoint: url,
      network: {
        capturePayload: true, // Capture HTTP payload
      },
    })

    this.openreplay.use(trackerAssist())
  }

  client = () => this.openreplay

  abortOpenReplay = () => {
    this.openreplay = null
  }
}

export default new OpenReplay()
