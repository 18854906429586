import React from 'react'
import clsx from 'clsx'

import { COLORS, HARD_SHADOW } from '../../theme'

import Button from '../Button'
import Flex from '../Flex'
import Glyph from '../Glyph'

import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu'

import { useStore } from './useStore'

export const DataTableFooter = (props: any) => {
  const { className, pageSize: currentPageSize, onPageSizeChange, supportedPageSizes = [5, 10, 25, 50, 75, 100] } = props

  const meta: any = useStore((state: any) => state.meta)
  const setCurrentPage: any = useStore((state: any) => state.setCurrentPage)

  // if (!meta) return null

  const pagesArray = Array.from({ length: meta?.pages || 0 }, (_, i) => i + 1)

  return (
    <div className={className} css={STYLES.root}>
      <DropdownMenu
        trigger={
          <div css={STYLES.dropdown}>
            <div>
              <span className="!font-[600]">{currentPageSize} rows</span> / <span className="!font-[400] !text-text-muted">page</span>
            </div>
            <Glyph glyph="triangle_down" size={12} />
          </div>
        }
      >
        {supportedPageSizes.map((pageSize: any) => (
          <DropdownMenuItem
            key={pageSize}
            isActive={pageSize === currentPageSize}
            label={
              <>
                <span style={{ fontWeight: 600 }}>
                  {pageSize} <span style={{ fontWeight: 400 }}>rows</span>
                </span>
              </>
            }
            onClick={() => {
              onPageSizeChange?.(pageSize)
              setCurrentPage(1)
            }}
          />
        ))}
      </DropdownMenu>

      {meta && (
        <Flex centerY gap="0.5rem">
          <Button
            label="← Back"
            isDisabled={!meta?.prev}
            display="inline-flex"
            type="minimal"
            size={200}
            onClick={() => {
              if (meta?.prev) setCurrentPage(meta?.prev)
            }}
          />

          {meta?.pages >= 2 ? (
            <DropdownMenu
              trigger={
                <div css={STYLES.dropdown}>
                  <div>
                    <span className="!font-[600]">Page {meta?.page}</span> of{' '}
                    <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
                  </div>
                  <Glyph glyph="triangle_down" size={12} />
                </div>
              }
            >
              {pagesArray.map((pageNumber) => (
                <DropdownMenuItem
                  key={pageNumber}
                  isActive={pageNumber === meta?.page}
                  label={`Page ${pageNumber}`}
                  onClick={() => setCurrentPage(pageNumber)}
                />
              ))}
            </DropdownMenu>
          ) : (
            <div>
              <span className="!font-[600]">Page {meta?.page}</span> of <span className="!font-[400] !text-text-muted">{meta?.pages}</span>
            </div>
          )}

          <Button
            label="Next →"
            isDisabled={!meta?.next}
            display="inline-flex"
            type="minimal"
            size={200}
            onClick={() => {
              if (meta?.next) setCurrentPage(meta?.next)
            }}
          />
        </Flex>
      )}
    </div>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.35rem 1rem',
    borderTop: `1px solid ${COLORS.divider}`,
    background: COLORS.white,
    minHeight: 38,
  },

  dropdown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& > svg': { marginLeft: '0.4rem' },
  },
}
