import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Tenant } from '@behavehealth/declarations'

import { Grid, Flex, Link, Button, HelpTagIframe, Page, Checklist, ChecklistItem } from '@behavehealth/components'
import { Text } from '@behavehealth/components/Typography'

import OnboardingFormAlert from '@behavehealth/components/OnboardingFormAlert'

import { COLORS } from '@behavehealth/theme'
import { apiUpdate } from '@behavehealth/modules/api'
import { withPageError } from '@behavehealth/hocs/withPageError'

const pageConfig = {
  plan: 'lite',
  feature: 'product_tour',
  help: <HelpTagIframe id="product_tour" />,
  marketingID: 'onboarding_checklist',
}

const ProductTour: React.FC = () => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const tenant: Tenant = useSelector((state: any) => state.me?.tenant)

  const data = tenant?.setup_prefs
  const tenantID = tenant?.id

  const client_id = tenant?.setup_helper?.client_id
  const property_id = tenant?.setup_helper?.property_id
  const organization_id = tenant?.setup_helper?.organization_id
  const employee_id = tenant?.setup_helper?.employee_id
  const program_id = tenant?.setup_helper?.program_id
  const local_payer_id = tenant?.setup_helper?.local_payer_id

  const hideSetup = async () => {
    setLoading(true)

    try {
      await apiUpdate({
        name: 'tenant',
        url: `/me/tenant`,
        params: {
          setup_prefs: {
            ...data,
            view_setup: false,
          },
        },
        reducer: 'me',
        notify: false,
      })

      history.push('/dashboard')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page
      breakpoint="0"
      childrenGap="1rem"
      actions={<Button label="Hide Tour From Main Menu" onClick={hideSetup} isLoading={loading} />}
      {...pageConfig}
    >
      <Grid gap="0.5rem">
        <OnboardingFormAlert />

        <Checklist isOpen list="setup_prefs" title="General Setup" data={data}>
          <ChecklistItem
            item="create_programs"
            title="Add your Programs"
            to="/programs"
            icon="checklist"
            plan="pro"
            help={<HelpTagIframe id="programs" label="Learn" />}
          />
          <ChecklistItem
            item="create_organizations"
            title="Add Organizations, Vendors and Referral Partners"
            to="/community/organizations"
            icon="organizations"
            plan="plus"
            help={<HelpTagIframe id="organizations" label="Learn" />}
          />
          <ChecklistItem
            item="upload_files"
            title="Upload your first File"
            to="/files"
            plan="plus"
            icon="files"
            help={<HelpTagIframe id="company_files" label="Learn" />}
          />
        </Checklist>

        <Checklist isOpen list="setup_prefs" title="Message Board Setup" data={data}>
          <ChecklistItem
            item="create_internal_message"
            title="Add an internal Message to your Message Board"
            to="/dashboard/messages/new"
            plan="lite"
            icon="care_level_4"
            help={<HelpTagIframe id="message_board_internal" label="Learn" />}
          />
          <ChecklistItem
            item="create_portal_message"
            title="Add a Client Portal Message to your Message Board"
            to="/dashboard/messages/new"
            plan="lite"
            icon="care_level_4"
            help={<HelpTagIframe id="message_board_client_portal" label="Learn" />}
          />
          <ChecklistItem
            item="create_employee_message"
            title="Add a Message to an Employee Message Board"
            to="/dashboard/messages/new"
            plan="lite"
            icon="employees"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_property_message"
            title="Add a Message to a Location Message Board"
            to="/dashboard/messages/new"
            plan="lite"
            icon="properties"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_organization_message"
            title="Add a Message to an Organization Message Board"
            to="/dashboard/messages/new"
            plan="plus"
            icon="organizations"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist isOpen list="setup_prefs" title="Team Setup" data={data}>
          <ChecklistItem
            item="update_company_settings"
            title="Setup your Company Settings"
            to="/settings/company-profile"
            icon="enterprise"
            plan="lite"
            help={<HelpTagIframe id="settings_company_settings" label="Learn" />}
          />
          <ChecklistItem
            item="update_permissions"
            title="Customize Your Permission Templates"
            to="/settings/permission-templates"
            plan="lite"
            icon="permissions"
            help={<HelpTagIframe id="settings_permissions" label="Learn" />}
          />
          <ChecklistItem
            item="invite_employees"
            title="Invite your Staff"
            to="/employees"
            plan="lite"
            icon="employees"
            help={<HelpTagIframe id="employees" label="Learn" />}
          />
          <ChecklistItem
            item="create_staff_todo"
            title="Add your first Staff To-Do"
            to="/todo/new"
            plan="basic"
            icon="tasks"
            help={<HelpTagIframe id="employees" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Location Setup" data={data}>
          <ChecklistItem
            item="create_properties"
            title="Add your Properties"
            to="/properties"
            plan="lite"
            icon="properties"
            help={<HelpTagIframe id="properties" label="Learn" />}
          />
          <ChecklistItem
            item="create_rooms"
            title="Add your Rooms and Beds"
            to={`/bed-management/${property_id}`}
            plan="lite"
            icon="bed_management"
            noDependent={!property_id}
            noDependentMessage="Please add a property first"
            help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="view_bed_management_calendar"
            title="View your Bed Management Calendar"
            to="/bed-management/calendar"
            plan="lite"
            icon="calendar"
            help={<HelpTagIframe id="bed_management_calendar" label="Learn" />}
          />

          {/* TODO: Custom widget opening */}
          <ChecklistItem
            item="embed_bed_availability_widget"
            title="Embed the Bed Management widget on your website"
            to="/bed-management"
            plan="lite"
            icon="web_form"
            help={<HelpTagIframe id="bed_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Admissions" data={data}>
          <ChecklistItem
            item="embed_contact_us_form"
            title="Embed the Contact Us form on your website"
            plan="basic"
            icon="web_form"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="update_application_form"
            title="Customize your Application Form"
            to="/settings/application-form"
            plan="basic"
            icon="checklist"
            help={<HelpTagIframe id="settings_application_form" label="Learn" />}
          />
          <ChecklistItem
            item="view_application_form"
            title="View your Application Form"
            href={`https://app.behavehealth.com/${tenantID}/forms/full-application`}
            plan="basic"
            icon="checklist"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_agreement_templates"
            title="Customize your Agreement Templates"
            to="/settings/client-agreement-templates"
            plan="basic"
            icon="legal_agreement_alt"
            help={<HelpTagIframe id="settings_agreement_templates" label="Learn" />}
          />
          <ChecklistItem
            item="create_applicant"
            title="Add your first Applicant"
            to="/admissions/applicants"
            plan="lite"
            icon="admissions_department"
            help={<HelpTagIframe id="admissions_applicants" label="Learn" />}
          />
          <ChecklistItem
            item="accept_applicant"
            title="Accept your first Applicant"
            to={`/admissions/${client_id}/dashboard/accept`}
            plan="lite"
            icon="admissions_department"
            noDependent={!client_id}
            noDependentMessage="Please add an applicant first"
            help={<HelpTagIframe id="admissions_accepted" label="Learn" />}
          />
          <ChecklistItem
            item="admit_applicant"
            title="Admit your first Applicant"
            to={`/admissions/${client_id}/dashboard/admit-client`}
            plan="lite"
            icon="admissions_department"
            noDependent={!client_id}
            noDependentMessage="Please add an applicant first"
            help={<HelpTagIframe id="admissions" label="Learn" />}
          />
          <ChecklistItem
            item="view_applicants"
            title="View your Applicants"
            to="/admissions/applicants"
            plan="lite"
            icon="admissions_department"
            help={<HelpTagIframe id="admissions" label="Learn" />}
          />
          <ChecklistItem
            item="view_accepted_applicants"
            title="View your Accepted Applicants"
            to="/admissions/intake"
            plan="lite"
            icon="admissions_department"
            help={<HelpTagIframe id="admissions_accepted" label="Learn" />}
          />
          <ChecklistItem
            item="view_declined_applicants"
            title="View your Declined Applicants"
            to="/admissions/declined"
            plan="lite"
            icon="admissions_department"
            help={<HelpTagIframe id="admissions_declined" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Client Profile" data={data}>
          <ChecklistItem
            item="update_client_general_info"
            title="Update a Client General Info"
            to={`/clients/${client_id}/general-info`}
            plan="lite"
            icon="general_info"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="client_general_info" label="Learn" />}
          />
          {/* <ChecklistItem
            item="update_client_referral_details"
            title="Update referral details"
            to="/clients/A9vz9Z/general-info"
            plan="basic"
            glyph="referral_details"
            help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          /> */}
          <ChecklistItem
            item="view_client_treatment_episodes"
            title="View a Client Treatment Episode"
            to={`/clients/${client_id}/treatment-episodes`}
            plan="lite"
            icon="treatment_data"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="treatment_episodes" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_contacts"
            title="Add your first Contact"
            to={`/clients/${client_id}/contacts/new`}
            plan="lite"
            icon="contacts"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="client_contacts_and_communications" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_communications"
            title="Add your first Communication Log"
            to={`/clients/${client_id}/communications/new`}
            plan="basic"
            icon="communications"
            help={<HelpTagIframe id="client_contacts_and_communications" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_care_team_member"
            title="Add a Care Team member"
            to={`/clients/${client_id}/connections`}
            plan="plus"
            icon="connections"
            help={<HelpTagIframe id="client_care_team" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_recovery_checklist"
            title="Add your first Recovery Coaching Checklist Note"
            to={`/clients/${client_id}/recovery-coaching`}
            plan="plus"
            icon="recovery_coaching"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="recovery_coaching" label="Learn" />}
          />
          <ChecklistItem
            item="upload_client_file"
            title="Upload your first Client File"
            to={`/clients/${client_id}/files`}
            plan="plus"
            icon="files"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_vital_sign"
            title="Add your first Vital Sign"
            to={`/clients/${client_id}/test-results/vital-signs`}
            plan="pro"
            icon="test_results"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="test_results_vital_signs" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_communicable_disease_test"
            title="Add your first Communicable Disease Test"
            to={`/clients/${client_id}/test-results/communicable-disease-tests`}
            plan="pro"
            icon="test_results"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="test_results_communicable_disease_test" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_breathalyzer_test"
            title="Add your first Breathalyzer Test"
            to={`/clients/${client_id}/test-results/breathalyzer-tests`}
            plan="plus"
            icon="test_results"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="test_results_breathalyzer_test" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_ua_test"
            title="Add your first UA Test"
            to={`/clients/${client_id}/test-results/ua-tests`}
            plan="plus"
            icon="test_results"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="test_results_ua_test" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_medication"
            title="Add your first Medication"
            to={`/clients/${client_id}/medications/new`}
            plan="pro"
            icon="medications"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="medication_list_and_logs" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_medication_log"
            title="Add your first Medication Log"
            to={`/clients/${client_id}/medications/logs/new`}
            plan="pro"
            icon="med_pass"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="medication_list_and_logs" label="Learn" />}
          />
          <ChecklistItem
            item="discharge_client"
            title="Discharge your first Client"
            to={`/clients/${client_id}/discharges/new`}
            plan="lite"
            glyph="discharge"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="discharges" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Client Portal Setup" data={data}>
          <ChecklistItem
            item="update_employee_client_portal_access"
            title="Customize Employee Details shared with the Client Portal"
            to={`/employees/${employee_id}/general-info`}
            plan="basic"
            icon="employees"
            noDependent={!employee_id}
            noDependentMessage="Please add an employee first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="update_property_client_portal_access"
            title="Customize Location Details shared with the Client Portal"
            to={`/properties/${property_id}/general-info`}
            plan="basic"
            icon="properties"
            noDependent={!property_id}
            noDependentMessage="Please add a property first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="update_organization_client_portal_access"
            title="Customize Organization Details shared with the Client Portal"
            to={`/community/organizations/${organization_id}/general-info`}
            plan="plus"
            icon="organizations"
            noDependent={!organization_id}
            noDependentMessage="Please add an organization first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="update_program_client_portal_access"
            title="Customize Program Details shared with the Client Portal"
            to={`/programs/${program_id}/general-info`}
            plan="pro"
            icon="checklist"
            noDependent={!program_id}
            noDependentMessage="Please add a program first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Client Portal" data={data}>
          <ChecklistItem
            item="invite_clients_to_portal_access"
            title="Invite your Clients to the Client Portal"
            to={`/clients/${client_id}/dashboard/invite-to-portal`}
            plan="lite"
            glyph="portal"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="create_location_check"
            title="Receive your first Client Portal Location Check-In"
            to={`/clients/${client_id}/location-checkin`}
            plan="plus"
            icon="gps_check_in_out"
            help={<HelpTagIframe id="location_check_in" label="Learn" />}
          />

          <ChecklistItem
            item="create_client_portal_todo"
            title="Add your first Client Portal To-Do"
            to={`/clients/${client_id}/todo`}
            plan="plus"
            icon="tasks"
            help={<HelpTagIframe id="todos" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Reports" data={data}>
          <ChecklistItem
            item="run_all_clients_report"
            title="Run your first Report of All Clients"
            to="/reports"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
          <ChecklistItem
            item="run_current_clients_report"
            title="Run your first Report of All Current Clients"
            to="/reports"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
          <ChecklistItem
            item="run_all_employees_report"
            title="Run your first Report of All Employees"
            to="/reports"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
          <ChecklistItem
            item="run_all_ledger_report"
            title="Run your first Report of All Ledger Transactions"
            to="/reports"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
          <ChecklistItem
            item="run_weekly_business_health_report"
            title="Run your first Report of your Weekly Business Health"
            to="/reports"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Services Book" data={data}>
          <ChecklistItem
            item="create_one_time_service_charge"
            title="Create a one time Service Charge"
            to="/settings/client-services"
            addon="rcm_consumer"
            icon="financials"
            help={<HelpTagIframe id="settings_services_book" label="Learn" />}
          />

          <ChecklistItem
            item="create_recurring_service_charge"
            title="Create a Recurring Service Charge"
            to="/settings/client-services"
            addon="rcm_consumer"
            icon="financials"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Payments Setup" data={data}>
          <ChecklistItem
            item="update_accepted_payment_methods"
            title="Select your accepted Payment Methods"
            to="/settings/online-payments"
            addon="rcm_consumer"
            icon="online_payment_alt"
            help={<HelpTagIframe id="settings_payments" label="Learn" />}
          />
          <ChecklistItem
            item="connect_stripe"
            title="Connect a Stripe account for Online Payments"
            to="/settings/online-payments"
            addon="rcm_consumer"
            icon="online_payment_alt"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Client Financials" data={data}>
          <ChecklistItem
            item="update_client_payment_methods"
            title="Customize Payment Methods accepted at the Client level"
            to={`/clients/${client_id}/financials/payment-methods`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="settings_payments" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_payer"
            title="Create a second Payer for a Client"
            to={`/clients/${client_id}/financials/payers/edit`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            // help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="update_client_ledger_message"
            title="Add a Ledger Message"
            addon="rcm_consumer"
            icon="financials"
            // help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="update_client_financial_status"
            title="Update the Financial Status of a Client"
            addon="rcm_consumer"
            icon="financials"
            // help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="update_client_financial_note"
            title="Add an internal Financial Note"
            addon="rcm_consumer"
            icon="financials"
            // help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_one_time_charge"
            title="Add your first one-time Charge Transaction"
            to={`/clients/${client_id}/financials/charge-builder`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_recurring_charge"
            title="Add your first repeating Charge Transaction"
            to={`/clients/${client_id}/financials/charge-builder`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_payment"
            title="Add your first Client Payment"
            to={`/clients/${client_id}/financials/payments/new`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_credit"
            title="Add your first Client Credit"
            to={`/clients/${client_id}/financials/credits/new`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Financial Data" data={data}>
          <ChecklistItem
            item="view_dashboard_financial_stats"
            title="View financial stats on your Dashboard"
            to="/dashboard"
            addon="rcm_consumer"
            icon="dashboard"
            help={<HelpTagIframe id="dashboard" label="Learn" />}
          />
          <ChecklistItem
            item="view_client_financials"
            title="View a Client Ledger and summary cards"
            to={`/clients/${client_id}/financials`}
            addon="rcm_consumer"
            icon="financials"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="client_financials" label="Learn" />}
          />
          <ChecklistItem
            item="create_all_ledger_transactions_report"
            title="Run All Ledger Transactions Report"
            to="/reports/new"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
          <ChecklistItem
            item="create_weekly_business_health_report"
            title="Run a Weekly Business Health Report"
            to="/reports/new"
            plan="plus"
            icon="patient_progress_measure"
            help={<HelpTagIframe id="reports" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="How to use Stripe" data={data}>
          <ChecklistItem
            item="signup_for_stripe"
            title="Sign up for a Stripe account"
            href="https://connect.stripe.com/register"
            addon="rcm_consumer"
            glyph="stripe"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="get_stripe_apps"
            glyph="stripe"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
            render={({ status }) => (
              <Flex vertical className="!flex-1">
                <Flex css={{ textDecoration: status === 'completed' ? 'line-through' : 'none' }}>
                  <Text color={COLORS.text}>Get the Stripe App (</Text>
                  <Link type="minimal" href="https://apps.apple.com/us/app/stripe-dashboard/id978516833" target="_blank">
                    iPhone
                  </Link>
                  <Text color={COLORS.text}>/</Text>
                  <Link
                    type="minimal"
                    href="https://play.google.com/store/apps/details?id=com.stripe.android.dashboard&hl=en_US&gl=US"
                    target="_blank"
                  >
                    Android
                  </Link>
                  <Text color={COLORS.text}>)</Text>
                </Flex>
              </Flex>
            )}
          />
          <ChecklistItem
            item="watch_stripe_video"
            title="Watch this Stripe Video"
            href="https://support.stripe.com/questions/using-the-stripe-dashboard"
            addon="rcm_consumer"
            glyph="stripe"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="visit_stripe_support_center"
            title="Visit Stripe's Support Center"
            href="https://support.stripe.com/"
            addon="rcm_consumer"
            glyph="stripe"
          />
          <ChecklistItem
            item="visit_stripe_support_center"
            title="Customize your Stripe Payout Settings"
            href="https://dashboard.stripe.com/settings/payouts"
            addon="rcm_consumer"
            glyph="stripe"
          />
        </Checklist>

        <Checklist list="setup_prefs" title="RCM Insurance" data={data}>
          <ChecklistItem
            item="update_facility_npi"
            title="Add your facility NPI number for EVOB activation"
            to="/settings/providers"
            addon="rcm_insurance"
            icon="financials"
            help={<HelpTagIframe id="settings_billing_settings" label="Learn" />}
          />

          <ChecklistItem
            item="create_evob"
            title="Run your first EVOB"
            to={`/admissions/${client_id}/insurance`}
            addon="rcm_insurance"
            icon="insurance"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="insurance_and_vob" label="Learn" />}
          />

          <ChecklistItem
            item="import_payers"
            title="Import Payers"
            to="/payers"
            addon="rcm_insurance"
            icon="insurance"
            help={<HelpTagIframe id="payers" label="Learn" />}
          />

          <ChecklistItem
            item="view_payer_clients"
            title="View your Clients by Payer"
            to={`/payers/${local_payer_id}/clients`}
            addon="rcm_insurance"
            icon="insurance"
            noDependent={!local_payer_id}
            noDependentMessage="Please add a payer first"
            help={<HelpTagIframe id="payer_clients" label="Learn" />}
          />

          <ChecklistItem
            item="view_payer_shared_files"
            title="View Payer Files shared by Behave Health"
            to={`/payers/${local_payer_id}/files`}
            addon="rcm_insurance"
            icon="insurance"
            noDependent={!local_payer_id}
            noDependentMessage="Please add a payer first"
            help={<HelpTagIframe id="payer_files" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="RCM Insurance Pro" data={data}>
          <ChecklistItem
            item="schedule_free_consultation_session"
            title="Schedule a free consultation with an Insurance Specialist"
            href={`mailto:contact@behavehealth.com?subject=Free%20Insurance%20Consultation%20with%20Behave%20Health`}
            addon="rcm_insurance"
            glyph="phone"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="view_learn_more_vob"
            title="Learn more about VOB Confirmation Calls"
            addon="rcm_insurance"
            icon="insurance"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />

          <ChecklistItem
            item="view_learn_more_utilization_review"
            title="Learn more about our Utilization Review Process"
            addon="rcm_insurance"
            icon="insurance"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Clinical Forms" data={data}>
          <ChecklistItem
            item="create_client_clinical_assessment"
            title="Add your first Clinical Asessment"
            to={`/clients/${client_id}/clinical-assessments`}
            addon="ehr"
            icon="clinical_assessments"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="clinical_assessments" label="Learn" />}
          />

          <ChecklistItem
            item="create_client_treatment_plan"
            title="Add your first Treatment Plan"
            to={`/clients/${client_id}/treatment-plans`}
            addon="ehr"
            icon="treatment_plans"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="treatment_plans" label="Learn" />}
          />

          <ChecklistItem
            item="create_client_progress_review"
            title="Add your first Progress Review"
            to={`/clients/${client_id}/progress-reviews`}
            addon="ehr"
            icon="progress_reviews"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="progress_reviews" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Clinical Notes" data={data}>
          <ChecklistItem
            item="update_facility_clinical_prefs"
            title="Set default Company preference for Clinical Notes"
            to="/settings/company-profile"
            addon="ehr"
            icon="settings"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_clinical_note"
            title="Add your first Clinical Note"
            to={`/clients/${client_id}/clinical-notes`}
            addon="ehr"
            icon="clinical_notes"
            noDependent={!client_id}
            noDependentMessage="Please add an client first"
            help={<HelpTagIframe id="clinical_notes" label="Learn" />}
          />
          <ChecklistItem
            item="send_client_clinical_note_for_signoff"
            title="Send a Clinical Note for Sign-Off"
            to={`/clients/${client_id}/clinical-notes`}
            addon="ehr"
            icon="clinical_notes"
            noDependent={!client_id}
            noDependentMessage="Please add a client first"
            help={<HelpTagIframe id="clinical_notes" label="Learn" />}
          />
          <ChecklistItem
            item="receive_client_clinical_note_signoff"
            title="Receive a Supervisor Sign-Off on a Clinical Note"
            to={`/clients/${client_id}/clinical-notes`}
            addon="ehr"
            icon="clinical_notes"
            noDependent={!client_id}
            noDependentMessage="Please add an client first"
            help={<HelpTagIframe id="clinical_notes" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Medical Forms" data={data}>
          <ChecklistItem
            item="create_client_medical_assessment"
            title="Add your first Medical Assessment"
            to={`/clients/${client_id}/medical-assessments`}
            addon="ehr"
            icon="medical_assessments"
            help={<HelpTagIframe id="medical_assessments" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_physician_order"
            title="Add your first Physician Order"
            to={`/clients/${client_id}/physician-orders`}
            addon="ehr"
            icon="physician_orders"
            help={<HelpTagIframe id="physician_orders" label="Learn" />}
          />
          <ChecklistItem
            item="create_client_nursing_note"
            title="Add your first Nursing Note"
            to={`/clients/${client_id}/nursing`}
            addon="ehr"
            icon="nursing"
            help={<HelpTagIframe id="nursing" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Company Chat" data={data}>
          <ChecklistItem
            item="view_company_chat"
            title="View your Company Chat"
            to="/company-chat"
            addon="chat"
            icon="company_chat"
            help={<HelpTagIframe id="company_chat" label="Learn" />}
          />
          <ChecklistItem
            item="send_your_first_company_chat_message_blast_to_your_team"
            title="Send your first Company Chat Message Blast to your team"
            to="/company-chat/message-blasts/new"
            addon="chat"
            icon="company_chat"
            help={<HelpTagIframe id="company_chat_message_blasts" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Client Chat" data={data}>
          <ChecklistItem
            item="view_clients_chat"
            title="View your Client Chat"
            to="/clients-chat"
            addon="clients_chat"
            icon="clients_chat"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="create_clients_chat_message_blast"
            title="Send your first Company Chat Message Blast to all your Clients"
            to="/clients-chat/message-blasts/new"
            addon="clients_chat"
            icon="clients_chat"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="view_clients_chat_magic"
            title="Watch the magic of your team responding to Clients via the automated Channels"
            to="/clients-chat/message-blasts/new"
            addon="clients_chat"
            icon="clients_chat"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>

        <Checklist list="setup_prefs" title="Staff mobile app" data={data}>
          <ChecklistItem
            item="view_staff_mobile_app"
            icon="mobile"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
            render={({ status }) => (
              <Flex vertical css={{ marginLeft: 10, flex: 1 }}>
                <Flex css={{ textDecoration: status === 'completed' ? 'line-through' : 'none' }}>
                  <Text color={COLORS.text}>Check out the Staff Mobile App (</Text>
                  <Link
                    type="minimal"
                    href="https://apps.apple.com/us/developer/behave/id1531441728?utm_campaign=Apple%20App%20Store&utm_source=app.behavehealth.com&utm_medium=product_tour"
                    target="_blank"
                  >
                    iPhone
                  </Link>
                  <Text color={COLORS.text}>/</Text>
                  <Link
                    type="minimal"
                    href="https://play.google.com/store/apps/developer?id=Behave%20Health&utm_campaign=Google%20Play%20Store&utm_source=app.behavehealth.com&utm_medium=product_tour"
                    target="_blank"
                  >
                    Android
                  </Link>
                  <Text color={COLORS.text}>)</Text>
                </Flex>
              </Flex>
            )}
          />
          <ChecklistItem
            item="update_staff_mobile_app_biometrics"
            title="Secure your account with Touch ID"
            plan="lite"
            glyph="lock"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="view_staff_mobile_app_property_map"
            title="View a Location on the map"
            plan="lite"
            icon="gps_check_in_out"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="update_staff_mobile_app_todo"
            title="Complete a Staff To-Do"
            plan="lite"
            icon="tasks"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
          <ChecklistItem
            item="import_staff_mobile_app_contacts"
            title="Import Contacts"
            plan="lite"
            icon="contacts"
            // help={<HelpTagIframe id="bed_management_room_management" label="Learn" />}
          />
        </Checklist>
      </Grid>
    </Page>
  )
}

const styles = {
  title: {
    fontWeight: 'bold!important',
    '&.is-completed': {
      textDecoration: 'line-through',
      color: `${COLORS.text}!important`,
    },
  },
}

export default withPageError(ProductTour)
