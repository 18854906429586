import React from 'react'
import { v4 as uuid } from 'uuid'
import produce from 'immer'

import { DEFAULT_EMPTY_VALUE } from '../../utils/constants'
import { isDefined } from '../../utils/functions'

import BaseInput from './BaseInput'
import { validate } from './validators'
import { withFormContext } from './context'
import Value from '../Value'

import FieldBase from './FieldBase'
import { snakeCase } from 'lodash'

export class NumberInput extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = { ...props.defaultValidations, ...props.validations }
    let value = props.value

    if (!value && props.model) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      if (isDefined(modelVal)) value = modelVal
      else if (isDefined(initialModelVal)) value = initialModelVal
    }

    if (!value) {
      if (isDefined(props.defaultValue)) value = props.defaultValue
      // else value = 0
    }

    if (isDefined(value)) {
      const parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) value = parsedValue
    }

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'NUMBER_INPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isHighlighted: false,
      isBlur: false,
      isValidations: vs,
      isRequired: vs?.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
  }

  processUpdate = (queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }
    const valueDefined = isDefined(this.props.value)
    let newValue = this.props.value

    if (valueDefined) {
      const parsedValue = parseFloat(newValue)
      if (!isNaN(parsedValue)) newValue = parsedValue
    }

    const newState = produce(this.state, (draft: any) => {
      draft.prevValue = draft.value

      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue

      draft.isDirty = draft.value !== queueEvent?.prevState?.value
      draft.isPristine = !draft.isDirty

      if (valueDefined && newValue !== draft.value) draft.value = newValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      value: newState.value,
      prevValue: newState.prevValue,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  processChangeValue = (value: any) => {
    let newValue = value

    // https://stackoverflow.com/questions/46447504/number-input-is-string-not-integer-in-react
    // React returns the target value as string instead of number, so we have to cast it
    if (isDefined(newValue)) {
      const parsedValue = parseFloat(newValue)
      if (!isNaN(parsedValue)) newValue = parsedValue
    }

    const newState = produce(this.state, (draft: any) => {
      draft.value = newValue
      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({ value: newValue, isPristine: false, isDirty: true })

    return newState
  }

  editRender = () => (
    <BaseInput
      id={this.state.id}
      name={this.state.id}
      value={this.state.value}
      placeholder={this.props.placeholder}
      size={this.props.size}
      type={this.props.type}
      onChange={this.onChange}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onKeyDown={this.props.onKeyDown}
      prefix={this.props.prefix}
      suffix={this.props.suffix}
      step={this.props.step}
      min={this.props.min}
      max={this.props.max}
      maxLength={this.props.maxLength}
      autoFocus={this.props.autoFocus}
      data-test={this.props.testKey}
      autocomplete={this.props.autocomplete}
      className={this.props.className}
      glyph={this.props.glyph}
      graphic={this.props.graphic}
      autoCapitalize={this.props.autoCapitalize}
      testKey={this.props.testKey || `${snakeCase(this.props.label)}_input`}
    />
  )

  readOnlyRender = () => {
    const { prefix, suffix, glyph, testKey, label } = this.props
    const { value } = this.state

    return (
      <Value
        glyph={value ? glyph : null}
        testKey={`${testKey || snakeCase(label)}_value`}
        value={`${(value && prefix) || ''}${value || DEFAULT_EMPTY_VALUE} ${(value && suffix) || ''}`}
      />
    )
  }
}

NumberInput.defaultProps = {
  type: 'number',
  isEditable: true,
  isDisabled: false,
  defaultValidations: null,
  validateOn: 'blur-change',
}

export default withFormContext(NumberInput)
