import React from 'react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { persist } from 'zustand/middleware'
import { tint, transparentize } from 'polished'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import create from 'zustand'
import gfm from 'remark-gfm'
import Lottie from 'lottie-react'
import ReactMarkdown from 'react-markdown'
import size from 'lodash/size'

import { apiCreate } from '../modules/api'
import { COLORS, SHADOW } from '../theme'
import { request } from '../modules/axios'
import { THREE_DOTS_ANIMATION } from '../theme/defs/lottie'
import { useCreate } from '../hooks/useNewAPI'
import useAnalytics from '../hooks/useAnalytics'

import Alert from './Alert'
import Attachments from '../components/Forms/Attachments'
import Button from './Button'
import CardTreeItem from './CardTreeItem'
import ConfirmDialog from './Dialogs/ConfirmDialog'
import ContextShow from './Forms/ContextShow'
import Emoji from './Emoji'
import Flex from './Flex'
import Form from './Forms/Form'
import FormSection from './Forms/FormSection'
import Glyph from './Glyph'
import Grid from './Grid'
import Overlay from './Overlay'
import Radio from './Forms/Radio'
import RadioGroup from './Forms/RadioGroup'
import Section from './Section'
import SmartTextarea from './Forms/SmartTextarea'
import Status from './Status'
import SubmitButton from './SubmitButton'
import SummonOverlay from './SummonOverlay'
import Textarea from './Forms/Textarea'
import Tooltip from './Tooltip'
import Type from './Typography/Type'

const API_BASE_URL = `${process.env.BH_API_BASE_PROTOCOL}://${process.env.BH_API_BASE_URL}/v1`

const remarkPlugins = [gfm]

const INITIAL_STORE = {
  activeChatId: null,
  messages: [],
  pathname: '',
  formData: {},
}

const store = (set: any, get: any) => ({
  ...INITIAL_STORE,
  isOpen: false,

  setActiveChatId: (id: string | null) => set({ activeChatId: id }),
  setMessages: (messages: any[]) => set({ messages }),
  setPathname: (pathname: any[]) => set({ pathname }),
  setFormData: (formData: any) => set({ formData }),

  open: () => set({ isOpen: true }),
  clear: () => set(INITIAL_STORE),
  close: () => set({ isOpen: false, ...INITIAL_STORE }),
})

export const useFeedbackStore = create(persist(store, { name: 'bh.feedback_chat' }))

const FeedbackTool: React.FC<any> = ({ className }) => {
  const open = useFeedbackStore((state) => state.open)

  return (
    <div>
      <div css={STYLES.badge} className={className} onClick={open}>
        <Glyph glyph="star" color={COLORS.orange} size="1.4em" />
        <span>Feedback</span>
      </div>
    </div>
  )
}

export const FeedbackSummonOverlay = () => {
  const open = useFeedbackStore((state) => state.open)
  const close = useFeedbackStore((state) => state.close)
  const isOpen = useFeedbackStore((state) => state.isOpen)

  const activeChatId = useFeedbackStore((state) => state.activeChatId)

  React.useEffect(() => {
    if (activeChatId) open()
  }, [activeChatId])

  return <SummonOverlay isOpen={isOpen} onClose={close} overlay={<FeedbackOverlay />} />
}

const SuccessMessage: React.FC<any> = ({ category, canFollowUp }) => {
  const user = useSelector((state) => state.me.user)

  let emoji: any = ''
  let title: any = ''
  let message: any = ''

  if (category === 'feedback') {
    emoji = '💬'
    title = 'Your Feedback Has Been Sent'
    message = canFollowUp ? (
      <>
        Our team will review the feedback and get back to you with any updates at <b>{user.email}</b>
      </>
    ) : (
      `Our team will review your feedback as soon as possible`
    )
  } else if (category === 'bug_report') {
    emoji = '🐞'
    title = 'Your Bug Has Been Reported'
    message = (
      <>
        Thank you for helping us squash bugs. Our team will review your report and contact you at <b>{user.email}</b> when they have any
        updates.
      </>
    )
  } else if (category === 'feature_request') {
    emoji = '🚀'
    title = 'Your Feature Has Been Requested'
    message = (
      <>
        Thank you for helping us improve the Behave Health App. Our team will review your request and contact you at <b>{user.email}</b>{' '}
        when they have any updates.
      </>
    )
  }

  return (
    <div css={STYLES.success}>
      <Emoji emoji={emoji} css={STYLES.emoji} />
      <Type as="h3" variant="H3" children={title} />
      <Type as="p" variant="TEXT" children={message} />
    </div>
  )
}

const CATEGORY_LABELS: any = {
  feedback: '💬 Send Feedback',
  bug_report: '🐞 Report Issue',
  feature_request: '🚀 Request Feature',
}

const STEPS = {
  feedback: 'feedback',
  chat: 'chat',
  success: 'success',
}

export const FeedbackOverlay: React.FC<any> = ({ onClose }) => {
  const form = React.useRef()
  const location: any = useLocation()

  const activeChatId = useFeedbackStore((state) => state.activeChatId)
  const clear = useFeedbackStore((state) => state.clear)
  const pathname = useFeedbackStore((state) => state.pathname)
  const setActiveChatId = useFeedbackStore((state) => state.setActiveChatId)
  const setMessages = useFeedbackStore((state) => state.setMessages)
  const setPathname = useFeedbackStore((state) => state.setPathname)
  const formData = useFeedbackStore((state) => state.formData)

  const [valid, setValid] = React.useState(false)
  const [category, setCategory] = React.useState('feedback')
  const [step, setStep] = React.useState(activeChatId ? STEPS.chat : STEPS.feedback)
  const [chatId, setChatId] = React.useState(activeChatId || null)
  const [isLoading, setIsLoading] = React.useState(false)

  const { trackEvent } = useAnalytics()

  const { mutateAsync: createChat, isLoading: isCreatingChat }: any = useCreate({
    name: ['start-new-chat'],
    url: `/ai/chats`,
  })

  const startAIChat = async () => {
    const newChat = await createChat({
      ai_bot_identifier: 'support',
      category: formData?.category,
    })

    if (newChat?.data?.id) {
      setChatId(newChat.data.id)
      setActiveChatId(newChat.data.id)
      setStep(STEPS.chat)
      setMessages([{ role: 'user', content: formData?.notes }])

      await apiCreate({ name: 'feedback', url: '/feedback', params: { ...formData, pathname, ai_chat_id: newChat.data.id } })

      trackEvent({ name: 'Feedback Submitted', params: formData })
    }
  }

  const sendFeedback = async () => {
    setIsLoading(true)

    try {
      await apiCreate({ name: 'feedback', url: '/feedback', params: { ...formData, pathname, ai_chat_id: null } })

      trackEvent({ name: 'Feedback Submitted', params: formData })

      setStep(STEPS.success)
      clear()
    } catch (errors) {
      console.warn({ errors })
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    setPathname(location.pathname)
    trackEvent({ name: 'Feedback Opened', params: { pathname: location.pathname } })
  }, [])

  return (
    <Overlay isMinimized onClose={onClose} css={STYLES.overlay}>
      <Overlay.Header
        title={step === STEPS.chat ? 'Behave AI Support Assistant' : 'Contact the Behave Health Team'}
        glyph={step !== STEPS.chat && 'star'}
        icon={step === STEPS.chat && 'behave_ai'}
        // subtitle={
        //   <>
        //     <Flex horizontal gap="0.5rem">
        //       <Status label="Alpha" />
        //       <Tooltip
        //         color={COLORS.violet}
        //         className="ml-1"
        //         content={
        //           <div className="italic font-small">
        //             <p>
        //               This support chatbot is an AI tool designed to assist with product-related queries and bug alerts. Although we strive
        //               for precision, the chatbot's responses may not always be entirely accurate, bias-free, or up-to-date. Therefore, it's
        //               recommended not to base crucial decisions solely on the chatbot's responses.
        //             </p>

        //             <p>
        //               We encourage users to cross-verify information with other trusted sources or professional advice. Please note, the
        //               creators and operators of this chatbot are not liable for decisions or actions based on the chatbot's responses.
        //               Users, by engaging with this chatbot, acknowledge the inherent limitations and potential risks of AI technology. All
        //               messages and content generated by this chatbot are reviewed by a member of our Behave Human Team for quality control.
        //             </p>
        //           </div>
        //         }
        //       >
        //         <Status glyph="info" glyphColor="red" label="Ai Disclaimer" color="red" />
        //       </Tooltip>
        //     </Flex>
        //   </>
        // }
      />

      {step === STEPS.success && (
        <Overlay.Content>
          <SuccessStep category={category} />
        </Overlay.Content>
      )}

      {step === STEPS.feedback && (
        <Overlay.Content>
          <FeedbackForm getForm={form} onCategoryUpdate={setCategory} onValidationUpdate={setValid} />
        </Overlay.Content>
      )}

      {step === STEPS.chat && <AIChat chatId={chatId} firstMessage={formData?.notes} onChatEnd={sendFeedback} />}

      {step === STEPS.feedback && (
        <Overlay.Footer>
          <Button label="Submit" color="blue" type="primary" isDisabled={!valid} isLoading={isLoading} onClick={sendFeedback} />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const SuccessStep = ({ category }: any) => {
  return <SuccessMessage category={category} />
}

const FeedbackForm = (props: any) => {
  const { getForm, onCategoryUpdate, onValidationUpdate } = props

  const setFormData = useFeedbackStore((state) => state.setFormData)

  return (
    <Section>
      <Form getForm={getForm} onValidationUpdate={onValidationUpdate} onUpdate={setFormData}>
        <FormSection maxWidth="100%">
          <RadioGroup
            label="Category"
            model="category"
            defaultValue="bug_report"
            layout="horizontal"
            onChange={({ value }) => onCategoryUpdate?.(value)}
          >
            <Radio showCheck={false} label="🐞 Report Issue" value="bug_report" />
            <Radio showCheck={false} label="💬 Feedback" value="feedback" />
            <Radio showCheck={false} label="🤔 Product Question" value="product_question" />
            <Radio showCheck={false} label="🚀 Feature Request" value="feature_request" />
          </RadioGroup>

          <ContextShow when="category" is="feedback">
            <SmartTextarea
              useDictation
              label="Your Feedback"
              model="notes"
              validations={{
                presence: {
                  message: 'Please enter your feedback',
                },
              }}
            />

            <RadioGroup label="Rating" model="rating" layout="horizontal-dense" css={STYLES.ratingRadios}>
              <Radio showCheck={false} label="👍" value="good" />
              <Radio showCheck={false} label="👎" value="bad" />
            </RadioGroup>

            <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
          </ContextShow>

          <ContextShow when="category" is="bug_report">
            <RadioGroup
              label="Some issues can be resolved with a browser refresh.  Have you refreshed your browser and still experience the issue?"
              model="refresh"
              layout="vertical-dense"
              validations={{
                presence: {
                  message: 'Please select an answer',
                },
              }}
            >
              <Radio label="Yes, I refreshed" value={true} />
              <Radio label="No, I have not refreshed" value={false} />
            </RadioGroup>

            <ContextShow when="refresh" is={true}>
              <SmartTextarea
                useDictation
                label="Please describe the bug you encountered and the steps to reproduce it"
                model="notes"
                validations={{
                  presence: {
                    message: 'Please enter a description of this bug',
                  },
                }}
              />

              <RadioGroup label="Impact Rating" model="rating" layout="vertical-dense" defaultValue="limited_impact">
                <Radio label="1 – Limited" description="No business impact, just a question" value="limited_impact" />
                <Radio label="2 – Moderate" description="Something isn't working as expected" value="moderate_impact" />
                <Radio label="3 – Major" description="I cannot do something, but I can still run your business" value="major_impact" />
                <Radio label="4 – Critical" description="I cannot run my business" value="critical_impact" />
              </RadioGroup>

              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </ContextShow>

            <ContextShow when="refresh" is={false}>
              <Alert type="negative" glyph="warning">
                Make sure you saved your data before refreshing
              </Alert>

              <ConfirmDialog
                title="Have you saved your data?"
                message="Please make sure you saved your data before refreshing, as your data will be lost otherwise"
                yesLabel="Yes, I did. Refresh…"
                onYes={() => window.location.reload()}
                render={(openDialog: any) => <Button label="Click here to refresh your browser" description="" onClick={openDialog} />}
              />
            </ContextShow>
          </ContextShow>

          <ContextShow when="category" is="product_question">
            <SmartTextarea
              useDictation
              label="What can we help you out with?"
              model="notes"
              validations={{
                presence: {
                  message: 'Please enter your question',
                },
              }}
            />

            <RadioGroup label="Impact Rating" model="rating" layout="vertical-dense" defaultValue="limited_impact">
              <Radio label="1 – Limited" description="No business impact, just a question" value="limited_impact" />
              <Radio label="2 – Moderate" description="Something isn't working as expected" value="moderate_impact" />
              <Radio label="3 – Major" description="I cannot do something, but I can still run my business" value="major_impact" />
              <Radio label="4 – Critical" description="I cannot run my business" value="critical_impact" />
            </RadioGroup>

            <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
          </ContextShow>

          <ContextShow when="category" is="feature_request">
            <SmartTextarea
              useDictation
              label="What feature would you like to request?"
              model="notes"
              validations={{
                presence: {
                  message: 'Please enter your feedback',
                },
              }}
            />

            <RadioGroup label="How important or critical is this feature to you?" model="rating" layout="vertical-dense">
              <Radio label="1 – Not too important" value="not_important" />
              <Radio label="2 – Moderately important" value="moderately_important" />
              <Radio label="3 – Neutral" value="neutral" />
              <Radio label="4 – Somewhat important" value="somewhat_important" />
              <Radio label="5 – Very important" value="very_important" />
            </RadioGroup>

            <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
          </ContextShow>
        </FormSection>
      </Form>
    </Section>
  )
}

const AIChat = (props: any) => {
  const { chatId, firstMessage, onChatEnd } = props

  const messages = useFeedbackStore((state) => state.messages)
  const setMessages = useFeedbackStore((state) => state.setMessages)

  const [prompt, setPrompt] = React.useState('')
  const [didSendFirstMessage, setDidSendFirstMessage] = React.useState(size(messages) > 0)

  const [isStreaming, setIsStreaming] = React.useState(false)
  const [streamedMessage, setStreamedMessage] = React.useState('')

  const [isSending, setIsSending] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)

  const [scrollRef, setScrollRef]: any = React.useState(null)

  const processMessages = async () => {
    await fetchEventSource(`${API_BASE_URL}/ai/chats/${chatId}/process`, {
      method: 'POST',
      headers: request.defaults.headers.common,
      body: JSON.stringify({ stream: 'true' }),
      onopen() {
        setIsProcessing(false)
      },
      onmessage(event) {
        setIsStreaming(true)
        setStreamedMessage((c) => c + event.data)
      },
      onclose() {
        setIsStreaming(false)
      },
      onerror(err) {
        setIsStreaming(false)
        console.error(err)
      },
    })
  }

  const sendFirstMessage = async () => {
    try {
      setIsProcessing(true)

      await request.post('ai/chat_messages', {
        ai_chat_id: chatId,
        role: 'user',
        content: firstMessage,
      })

      await processMessages()

      setDidSendFirstMessage(true)
    } catch (error) {
      console.error(error)
    } finally {
      setIsProcessing(false)
    }
  }

  const sendMessage = async () => {
    const savedPrompt = prompt

    setPrompt('')
    setMessages([...messages, { role: 'user', content: savedPrompt }])

    try {
      setIsProcessing(true)

      await request.post('ai/chat_messages', {
        ai_chat_id: chatId,
        role: 'user',
        content: prompt,
      })

      await processMessages()
    } catch (error) {
      console.error(error)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleSendFeedback = async () => {
    setIsSending(true)

    try {
      await onChatEnd?.()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSending(false)
    }
  }

  React.useEffect(() => {
    if (isStreaming) return

    if (streamedMessage) {
      setMessages([...messages, { role: 'assistant', content: streamedMessage }])
      setStreamedMessage('')
    }
  }, [isStreaming, streamedMessage])

  React.useEffect(() => {
    if (didSendFirstMessage || !chatId || !firstMessage) return

    sendFirstMessage()
  }, [chatId, firstMessage, didSendFirstMessage])

  React.useEffect(() => {
    if (!scrollRef) return

    scrollRef.scroll({
      top: scrollRef.scrollHeight,
      behavior: 'smooth',
    })
  }, [messages, streamedMessage, scrollRef])

  if (!chatId) return null

  return (
    <div css={STYLES.aiChat}>
      {/* <header css={STYLES.aiChatHeader}>
        <div className="flex justify-center">
          <div css={STYLES.aiIcon}>
            <Icon icon="behave_ai" size={28} />
            <Loader size={35} />
          </div>
        </div>

        <div className="text-center mt-1">
          <h4 className="text-xl">Behave AI</h4>
          <div className="opacity-80">Support Assistant</div>
        </div>
      </header> */}

      <main ref={setScrollRef} css={STYLES.aiChatContent}>
        <Grid gap="0.75rem" className="mt-4">
          {messages.map((message: any) => (
            <React.Fragment key={message.id}>
              {message.role === 'assistant' ? (
                <AIChatMessage message={message.content} />
              ) : message.role === 'user' ? (
                <UserChatMessage message={message.content} />
              ) : null}
            </React.Fragment>
          ))}

          {(streamedMessage || isProcessing) && <AIChatMessage isStreaming={isProcessing} message={streamedMessage} />}
        </Grid>
      </main>

      <footer css={STYLES.aiChatFooter}>
        <Textarea
          withHover={false}
          placeholder="Type your question or reply…"
          value={prompt}
          onUpdate={({ value }: any) => setPrompt(value)}
        />

        <div className="flex justify-between py-2 px-4">
          <SubmitButton
            label="Send"
            glyph="circle_arrow_right"
            size={200}
            type="primary"
            onClick={sendMessage}
            className="mr-auto"
            isDisabled={!prompt}
          />

          {onChatEnd && (
            <Button
              label="End Chat & Send to Behave Team"
              glyph="chat"
              size={200}
              type="primary"
              color="green"
              isLoading={isSending}
              onClick={handleSendFeedback}
            />
          )}
        </div>
      </footer>
    </div>
  )
}

const AIChatMessage = (props: any) => {
  const { message, isStreaming } = props

  return (
    <div css={STYLES.aiMessage}>
      {message && (
        <ReactMarkdown remarkPlugins={remarkPlugins} components={MARKDOWN_COMPONENTS} css={STYLES.markdown}>
          {message}
        </ReactMarkdown>
      )}

      {isStreaming && <Lottie autoplay animationData={THREE_DOTS_ANIMATION} loop={true} style={STYLES.dotsAnimation} />}
    </div>
  )
}

const UserChatMessage = (props: any) => {
  const { message } = props

  return (
    <div css={STYLES.userMessage}>
      <div>{message}</div>
    </div>
  )
}

const IframeLink = (props: any) => {
  const { children, href } = props

  return (
    <>
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {children}
      </a>

      <CardTreeItem title="Preview Website" css={STYLES.cardTreeItem}>
        <iframe
          title="iframe"
          frameBorder="0"
          border="0"
          cellSpacing="0"
          css={STYLES.iframe}
          src={href}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </CardTreeItem>
    </>
  )
}

const MARKDOWN_COMPONENTS = {
  a: IframeLink,
}

const STYLES: any = {
  overlay: {
    '&.is-minimized .dialog': {
      borderRadius: 7,
      maxWidth: '620px !important',
      maxHeight: '680px !important',
      boxShadow: SHADOW(10),
      border: `1px solid ${COLORS.divider}`,
    },
  },

  aiChat: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },

  aiChatHeader: {
    flex: '0 0 auto',
    overflow: 'hidden',
    paddingTop: '1rem',
  },

  aiChatContent: {
    flex: '1 1 auto',
    overflowY: 'auto',
    padding: '1rem',
  },

  aiChatFooter: {
    flex: '0 0 auto',
    overflow: 'hidden',
    borderTop: `1px solid ${COLORS.divider}`,
    position: 'relative',

    textarea: {
      border: 'none !important',
      boxShadow: 'none !important',
      borderRadius: '0 !important',
    },
  },

  aiIcon: {
    position: 'relative',
    width: 32,
    height: 32,

    '& > *': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },

  aiMessage: {
    display: 'grid',
    gridGap: '1rem',
    borderRadius: 5,
    background: COLORS.hover,
    padding: '0.5rem',
    maxWidth: '80%',
    justifySelf: 'flex-start',
  },

  aiMessageActions: {
    display: 'flex',
    marginTop: '0.5rem',
  },

  userMessage: {
    borderRadius: 5,
    background: COLORS.blue,
    color: COLORS.white,
    padding: '0.5rem',
    maxWidth: '80%',
    justifySelf: 'flex-end',
    whiteSpace: 'pre-line',
  },

  markdown: {
    '& > *:first-child': { marginTop: 0 },
    '& > *:last-child': { marginBottom: 0 },

    pre: {
      border: `1px solid ${COLORS.divider}`,
      background: COLORS.lightBackground,
      borderRadius: 5,
      padding: '0.5rem',
    },

    table: {
      borderSpacing: 0,
      borderCollapse: 'collapse',
      fontVariant: 'tabular-nums',
      fontFeatureSettings: 'tnum',
      minWidth: 200,
      tableLayout: 'fixed',
    },

    'td, th': {
      padding: '0.5rem',
      textAlign: 'left',
      border: `1px solid ${COLORS.divider}`,

      '&.selectedCell': {
        background: transparentize(0.85, COLORS.blue),
      },

      p: {
        margin: 0,
      },
    },

    th: {
      background: COLORS.white,
    },
  },

  badge: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.2em 0.7em 0.2em 0.25em',
    borderRadius: 100,
    background: tint(0.6, COLORS.gold),

    color: COLORS.black,
    fontSize: '0.8rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 1,
    textTransform: 'uppercase',
    cursor: 'pointer',

    svg: {
      marginRight: '0.25em',
    },
  },

  ratingRadios: {
    '.item-label': {
      fontSize: '1.35rem',
    },
  },

  success: {
    padding: '2rem',
    textAlign: 'center',
  },

  emoji: {
    fontSize: '2rem',
  },

  dotsAnimation: {
    width: 30,
    height: 30,
    margin: '0 auto',
  },
}

export default FeedbackTool
