import React from 'react'

import { request } from '@behavehealth/modules/axios'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withSettings from '@behavehealth/hocs/withSettings'

import Flex from '@behavehealth/components/Flex'
import Grid from '@behavehealth/components/Grid'
import Loader from '@behavehealth/components/Loader'
import Page from '@behavehealth/components/Page'
import { NotionRenderer } from '@behavehealth/components/Notion/NotionRenderer'

const WELCOME_NOTION_ID = '7aac4e18c1cc4d89b5c01cfe1ea71a3e'

const Updates = () => {
  const [notionData, setNotionData] = React.useState(null)
  const [notionLoading, setNotionLoading] = React.useState(false)

  React.useEffect(() => {
    const getNotionData = async () => {
      setNotionLoading(true)

      try {
        const response = await request.get(`${process.env.BH_NOTION_API_BASE}/api/page/${WELCOME_NOTION_ID}`, {
          headers: null,
        })
        setNotionData(response.data)
      } catch (err) {
        console.error(err)
      } finally {
        setNotionLoading(false)
      }
    }

    getNotionData()
  }, [])

  return (
    <Page label="Updates" breakpoint="0" flex="3rem">
      <Grid gap="1rem">
        {notionLoading ? (
          <Flex centerX>
            <Loader />
          </Flex>
        ) : (
          notionData && (
            <NotionRenderer
              css={styles.notionRenderer}
              disableHeader
              fullPage={false}
              hideBlockId={true}
              darkMode={false}
              recordMap={notionData}
            />
          )
        )}
      </Grid>
    </Page>
  )
}

const styles = {
  notionRenderer: {
    width: '100% !important',
    minHeight: 'auto !important',
    background: 'none',
  },
}

export default withPageError(withSettings(Updates))
