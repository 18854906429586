import React from 'react'
import { transparentize } from 'polished'
import { useVirtualizer } from '@tanstack/react-virtual'
import clsx from 'clsx'
import get from 'lodash/get'
import size from 'lodash/size'

import { COLORS } from '../../theme'

import { DataTableCell } from './DataTableCell'
import { DataTableHeader } from './DataTableHeader'
import { DataTableHeaderCell } from './DataTableHeaderCell'
import { DataTableRow } from './DataTableRow'

import { CELLS } from './constants'
import { useStore } from './useStore'

export const DataTableMain = (props: any) => {
  const {
    invalidate,
    invalidateKeys,
    isRefetching,
    queryKey,
    showBanding,
    openFilters,
    after,
    columnFiltersEnabled,
    autoFocusFirstFilter,
    minHeight,
  } = props

  const columnIds: any = useStore((state: any) => state.columnIds)
  const columnsMap: any = useStore((state: any) => state.columnsMap)
  const dataMap: any = useStore((state: any) => state.dataMap)
  const isResizing: any = useStore((state: any) => state.isResizing)
  const rootDataIds: any = useStore((state: any) => state.rootDataIds)
  const hiddenColumnIds: any = useStore((state: any) => state.hiddenColumnIds)

  const rootClasses = clsx(showBanding && 'show-banding', isRefetching && 'is-refetching', isResizing && 'no-pointer-events')

  const scrollRef = React.useRef()

  const rowVirtualizer = useVirtualizer({
    count: size(dataMap),
    getScrollElement: () => scrollRef.current,
    estimateSize: () => 30,
    overscan: 10,
  })

  return (
    <>
      <main
        ref={scrollRef}
        className={rootClasses}
        css={STYLES.root}
        style={{ '--table-min-height': minHeight && typeof minHeight === 'number' ? `${minHeight}px` : minHeight }}
      >
        {columnIds && (
          <DataTableHeader>
            {columnIds.map((columnId: any, index: number) => {
              const column = columnsMap[columnId]

              if (!column) return null

              return (
                <DataTableHeaderCell
                  key={columnId}
                  isSticky={index === 0}
                  column={column}
                  icon={column.headerIcon}
                  index={index}
                  openFilters={openFilters}
                  columnFiltersEnabled={columnFiltersEnabled}
                  autoFocusFirstFilter={autoFocusFirstFilter}
                >
                  {column.title}
                </DataTableHeaderCell>
              )
            })}
          </DataTableHeader>
        )}

        <div css={STYLES.inner} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {rowVirtualizer.getVirtualItems().map((virtualItem) => {
            const yIndex = virtualItem.index
            const id = rootDataIds?.[yIndex]
            const record: any = dataMap?.[id]

            if (!record) return null

            return (
              <DataTableRow
                id={record.id}
                key={virtualItem.key}
                css={STYLES.virtualRow}
                style={{
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                {columnIds?.map((columnId: any, xIndex: number) => {
                  const column = columnsMap[columnId]
                  const isHidden = hiddenColumnIds.includes(columnId)

                  if (!column || isHidden) return null

                  const CellTag = CELLS[column.type]?.component || DataTableCell
                  const renderCell = column.renderCell
                  const formatValue = column.formatValue
                  const hoverContent = column.hoverContent
                  const flexChildren = column.flexChildren

                  // TODO: clean up props
                  return (
                    <CellTag
                      key={xIndex}
                      align={column.align}
                      canEdit={column.canEdit}
                      centerX={column.centerX}
                      disableSelect={column.disableSelect}
                      formatValue={formatValue}
                      hoverContent={hoverContent}
                      hoverExpand={column.hoverExpand}
                      icon={column.icon}
                      invalidate={invalidate}
                      invalidateKeys={invalidateKeys}
                      isSticky={xIndex === 0}
                      queryKey={queryKey}
                      record={record}
                      render={renderCell}
                      selectOptions={column.selectOptions}
                      tagSmartCategories={column.tagSmartCategories}
                      type={column.type}
                      config={column.config}
                      value={get(record, column.model)}
                      column={column}
                      rowData={record}
                      x={xIndex}
                      y={yIndex}
                      flexChildren={flexChildren}
                    />
                  )
                })}
              </DataTableRow>
            )
          })}
        </div>

        {after}
      </main>
    </>
  )
}

const STYLES = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflow: 'auto',
    flex: '1 1 auto',
    background: '#f7f8fa',
    minHeight: 'var(--table-min-height)',

    '&.no-pointer-events, &.no-pointer-events *': {
      pointerEvents: 'none !important',
    },
  },

  scrollRoot: {
    overflow: 'hidden',
    minHeight: 200,
    flex: '1 1 auto',
    background: '#f7f8fa',

    '--scrollbar-size': '12px',
    '--scrollbar-bg': transparentize(0.8, COLORS.gray),
    '--scrollbar-hover-bg': transparentize(0.7, COLORS.gray),
    '--scrollbar-thumb-bg': transparentize(0.5, COLORS.gray),
    '--scrollbar-thumb-hover-bg': transparentize(0.3, COLORS.gray),

    '&.is-refetching': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  scrollViewport: {
    width: '100%',
    height: '100%',
    border: `1px solid ${COLORS.default}`,
  },

  scrollBar: {
    display: 'flex',
    userSelect: 'none',
    touchAction: 'none',
    padding: 2,
    background: 'var(--scrollbar-bg)',
    transition: 'background 160ms ease-out',

    '&:hover': {
      background: 'var(--scrollbar-hover-bg)',
    },

    '&[data-orientation="vertical"]': {
      width: 'var(--scrollbar-size)',
    },

    '&[data-orientation="horizontal"]': {
      flexDirection: 'column',
      height: 'var(--scrollbar-size)',
    },
  },

  scrollThumb: {
    flex: 1,
    background: 'var(--scrollbar-thumb-bg)',
    borderRadius: 'var(--scrollbar-size)',
    position: 'relative',

    '&:hover': {
      background: 'var(--scrollbar-thumb-hover-bg)',
    },

    /* increase target size for touch devices */
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      minWidth: 44,
      minHeight: 44,
    },
  },

  inner: {
    width: '100%',
    position: 'relative',
  },

  virtualRow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
}
