import React from 'react'

import { CONFIG } from '../../utils/globals'
import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'
import withSettings from '../../hocs/withSettings'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  id: string
  user?: any
  tenant?: any
}

const AdmissionNavItems = ({ id, user, tenant }: Props) => {
  const IS_DEMO = CONFIG.is_demo
  const basePath = `/admissions/${id}`

  const isBehave = user?.type === 'bh_employee'
  const isEHR = tenant?.category === 'healthcare_facility'

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  return (
    <>
      <NavGroup label="General">
        <NavItem feature="general_info" label="General Info" to={`${basePath}/general-info`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="clients.files.view"
          featureFlagV2="files"
        />
        <NavItem
          feature="activity_timeline"
          label="Activity Timeline"
          to={`${basePath}/activity-timeline`}
          featureFlagV2="activity_timeline"
          permission="activity_timeline.view"
        />
      </NavGroup>

      <NavGroup label="Care Coordination">
        {isBehave && (
          <NavItem
            showMarketing
            feature="client_journey"
            label="Client Timeline"
            to={`${basePath}/client-journey`}
            permission={true} // TODO
            featureFlagV2="client_journey"
          />
        )}
        <NavItem showForCommunity feature="treatment_episodes" label="Treatment Episodes" to={`${basePath}/treatment-episodes`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="applications"
          label="Applications"
          to={`${basePath}/applications`}
          permission="applications.view"
          featureFlagV2="admissions"
        />
        <NavItem
          showMarketing
          feature="agreements"
          label="Agreements"
          to={`${basePath}/agreements`}
          permission="agreements.view"
          featureFlagV2="client_agreements"
        />
        <NavItem
          showMarketing
          feature="programs"
          label="Program Assignments"
          to={`${basePath}/programs`}
          permission="programs.view"
          featureFlagV2="programs"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          showForCommunity
          showMarketing
          feature="financials"
          label="Financials"
          to={`${basePath}/financials`}
          permission="ledger.view"
          featureFlagV2="financials"
        />
        <NavItem isDev feature="client_cash" label="Client Cash" to={`${basePath}/client-cash`} permission={IS_DEMO} />
      </NavGroup>

      <NavGroup label="Insurance">
        <NavItem
          showMarketing
          feature="insurance_policies"
          label="Insurance & VOB"
          to={`${basePath}/insurance`}
          permission="insurance_policies.view"
          featureFlagV2="insurance_policies"
        />
        <NavItem
          showMarketing
          feature="insurance_authorizations"
          label="Authorizations"
          to={`${basePath}/authorizations`}
          permission={isBehave ? true : 'insurance_authorizations.view'}
          featureFlagV2="insurance_authorizations"
        />
        <NavItem
          showMarketing
          feature="full_vobs"
          icon="behave_health"
          label="VOBs"
          to={`${basePath}/vobs`}
          permission={isBehave ? true : 'full_vobs.view'}
          featureFlagV2="full_vobs"
        />
        {/* <NavItem
          isDev
          icon="roster"
          label="Billing Roster"
          to={`${basePath}/billing-roster`}
          permission={isBehave}
          featureFlagV2="insurance_rosters_claims_charges"
        /> */}
        {/* <NavItem
          isDev
          icon="invoices"
          label="Claims"
          to={`${basePath}/claims`}
          permission={isBehave}
          featureFlagV2="insurance_rosters_claims_charges"
        /> */}
        {/* <NavItem
          isDev
          icon="insurance"
          label="Charges"
          to={`${basePath}/charges`}
          permission={isBehave}
          featureFlagV2="insurance_rosters_claims_charges"
        /> */}
      </NavGroup>

      <NavGroup label="Contacts">
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <NavItem showMarketing feature="care_team" label="Care Team" to={`${basePath}/connections`} featureFlagV2="care_team" />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/custom-notes`}
          permission="client_custom_notes.view"
          featureFlagV2="custom_notes"
        />
      </NavGroup>

      <NavGroup label="Clinical">
        <NavItem
          showMarketing
          feature="diagnoses"
          label="Diagnoses"
          to={`${basePath}/diagnoses`}
          permission="diagnoses.view"
          featureFlagV2="diagnoses"
        />
        <NavItem
          showMarketing
          feature="clinical_assessments"
          label="Clinical Assessments"
          to={`${basePath}/clinical-assessments`}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />
        <NavItem
          showMarketing
          feature="treatment_plans"
          label="Treatment Plans"
          to={`${basePath}/treatment-plans`}
          featureFlagV2="treatment_plans"
          permission={viewSomeTreatmentPlans}
        />
        <NavItem
          showMarketing
          feature="outcome_measures"
          label="Outcome Measures"
          to={`${basePath}/outcome-measures`}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />
      </NavGroup>

      <NavGroup label="Clinical Archived">
        <NavItem
          showMarketing
          feature="treatment_plans"
          label="Treatment Plans"
          to={`${basePath}/archived-treatment-plans`}
          permission="treatment_plans.view"
          featureFlagV2="treatment_plans"
        />
      </NavGroup>

      <NavGroup label="Medical">
        <NavItem feature="allergies" label="Reported Conditions" to={`${basePath}/reported-conditions`} />
        <NavItem
          showMarketing
          feature="medications"
          label="Medications"
          to={`${basePath}/medications`}
          permission="medications.view"
          featureFlagV2="medications"
        />
        <NavItem
          showMarketing
          feature="medical_assessments"
          label="Medical Assessments"
          to={`${basePath}/medical-assessments`}
          featureFlagV2="medical_assessments"
        />
      </NavGroup>
    </>
  )
}

export default withSettings(AdmissionNavItems)
