import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import { useAPI } from '@behavehealth/hooks'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import Card from '@behavehealth/components/Card'
import Page from '@behavehealth/components/Page'

import InsuranceClaimsTable from '@behavehealth/components/Tables/InsuranceClaimsTable'

const pageConfig = {
  feature: 'treatment_plans',
}

type Props = {
  canCreate: boolean
}

const InsuranceClaims: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()

  const data = useSelector((state) => state.data.insurance_claims?.data)
  const loading = useSelector((state) => state.data.insurance_claims?.loading)

  useAPI('insurance_claims', `/residents/${match.params?.resource_id}/insurance_claims`)
  const isEmpty = size(data) === 0

  return (
    <Page actions={!isEmpty && canCreate && actions} {...pageConfig}>
      <Card>
        <InsuranceClaimsTable
          data={data}
          isLoading={isEmpty && loading}
          localStorageKey="clients_insurance_claims"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </Page>
  )
}

export default withPageError(withMarketing(InsuranceClaims, pageConfig))
