import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { PhysicianOrdersReportDataTable } from '@behavehealth/constructs/LiveReports/PhysicianOrdersReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const PhysiciansOrders = () => {
  const tableProps = useDataTable({
    name: ['reports', 'physician-orders'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_physician_orders_v1',
    params: { category: 'physician_orders' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.client)}/physician-orders/${rowData.id}`, [])

  return (
    <Page feature="physician_orders" title="Live Physicians Orders Report">
      <Grid>
        <PhysicianOrdersReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(PhysiciansOrders)
