import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import compact from 'lodash/compact'

import { getClientLink, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { NotApplicableCell } from '../../components/DataTable/cells/NotApplicableCell'

import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'

export const BreathalyzerTestsDataTable = (props: any) => {
  const { to } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(
    () =>
      compact([
        {
          title: 'Date & Time',
          model: 'collected_at',
          width: 300,
          disableHide: true,
          formatValue: ({ data, value }: any) => {
            return <MainCell as={NavLink} id={data.id} to={to ? to(data) : undefined} value={usDateTime(value, timezone)} />
          },
        },
        isBehave && {
          title: 'Billed',
          model: 'billed_at',
          type: 'date_time',
        },
        {
          title: 'Client',
          model: 'resident',
          formatValue: ({ value }) => {
            if (!value) return null

            return (
              <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/test-results/breathalyzer-tests`} />
            )
          },
        },
        {
          title: 'Status',
          model: 'status',
          formatValue: ({ value }: any) => {
            if (value === 'refused') return <Status color="red" label="Refused Collection" />
            if (value === 'collected') return <Status color="blue" label="Collected" />
            return <NotApplicableCell />
          },
        },
        {
          title: 'Result',
          model: 'result',
          formatValue: ({ value, data }: any) => {
            if (data.status === 'refused') return null
            if (value === 'positive') return <Status color="red" label="Positive" />
            if (value === 'negative') return <Status color="green" label="Negative" />
            if (value === 'not_available') return <Status color="grey" label="N/A" />
            return null
          },
        },
        {
          title: 'Result Value',
          model: 'value',
          disableSort: true,
          formatValue: ({ value, data }: any) => {
            if (data.result === 'negative') return <NotApplicableCell />
            return value
          },
        },
        {
          title: 'Notes',
          model: 'notes',
        },
        {
          title: 'Date Added',
          model: 'created_at',
          type: 'date_time',
        },
        {
          title: 'Date Updated',
          model: 'updated_at',
          type: 'date_time',
        },
        {
          title: 'Added By',
          model: 'author',
          type: 'profile',
        },
      ]),
    [to, timezone, isBehave],
  )

  return (
    <DataTable
      asCard
      testKey="breathalyzer_tests_data_table"
      title="Breathalyzer Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.breathalyzer_tests}
      {...props}
    />
  )
}
