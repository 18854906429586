import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { AlumniDataTable } from '@behavehealth/constructs/Clients/AlumniDataTable'
import { HelpTagIframe, PageHeader } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { useSettings } from '@behavehealth/hooks/useSettings'
import Button from '@behavehealth/components/Button'

import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'

const AlumniPage: React.FC = () => {
  const match = useRouteMatch()
  const { tenant } = useSettings()

  return (
    <div css={STYLES.root}>
      <PageHeader
        showFeedbackTool
        icon="alumni_relations"
        title="Past Clients"
        help={<HelpTagIframe id="alumni" />}
        aside={
          <Button
            type="primary"
            glyph="add"
            label="Add Past Client"
            link={`${match.url}/new`}
            featureFlagV2="add_alumni"
            permission="clients.actions.create"
          />
        }
      />

      <main css={STYLES.main}>
        {tenant?.has_seed_data && <ClearSampleDataBanner isFullWidth />}
        <AlumniDataTable testKey="past_clients_data_table" />
      </main>
    </div>
  )
}

const STYLES = {
  root: {
    display: 'grid',
    gridTemplateRows: 'min-content minmax(100px, 1fr)',
    overflow: 'hidden',
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}

export default withPageError(AlumniPage)
