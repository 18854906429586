import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { countWord, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { FILTERS } from '../Filters/config'

export const ProgramsDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to?.(data)} value={value} avatar={data.avatar || ''} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'programs.edit',
        tagSmartCategories: 'programs',
      },
      {
        title: 'Description',
        model: 'description',
        width: 400,
      },
      {
        title: 'Managing Staff',
        model: 'managing_staff',
        type: 'profiles',
      },
      {
        title: 'Program Lists',
        model: 'phase_count',
        formatValue: ({ value }: any) => `${countWord('Program Lists', value)}`,
      },
    ],
    [to],
  )

  return <DataTable asCard title="Programs" icon="programs" columns={columns} filtersConfig={FILTERS.programs} {...rest} />
}
