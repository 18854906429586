import React from 'react'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { NotificationsReportDataTable } from '@behavehealth/constructs/LiveReports/NotificationsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Notifications = () => {
  const tableProps = useDataTable({
    name: ['reports', 'notifications'],
    endpoint: `/live_reports?category=notifications`,
    localStorageKey: 'report_notifications_v1',
  })

  return (
    <Page feature="contacts" title="Live Notifications Report">
      <Grid>
        <NotificationsReportDataTable {...tableProps} />
      </Grid>
    </Page>
  )
}

export default withPageError(Notifications)
