import React from 'react'
import { useLocation } from 'react-router-dom'
import orderBy from 'lodash/orderBy'

import { mapToArray } from '@behavehealth/utils/functions'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Button from '@behavehealth/components/Button'
import Divider from '@behavehealth/components/Divider'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'
import State from '@behavehealth/components/State'

import { ClientTrackTabs } from '@behavehealth/constructs/ClientTracks/ClientTrackTabs'

const TodoPageActions: React.FC<any> = ({ data, clientTrack, newFeatureFlag }) => {
  const { pathname } = useLocation()

  const [clientTrackId, setClientTrackId] = React.useState(clientTrack?.id)

  const { data: templates, isLoading }: any = useGet({
    name: ['todo-templates', { clientTrackId }],
    url: `/event_templates`,
    params: { event_type: 'task', client_track_id: clientTrackId },
  })

  React.useEffect(() => {
    if (!clientTrack?.id) return

    setClientTrackId(clientTrack?.id)
  }, [clientTrack])

  return (
    <>
      <Button
        label="Manage Todo Templates"
        glyph="settings"
        type="default"
        link="/settings/todo-templates"
        permission="todo_templates.view"
      />

      <Dropdown label="Add Todo…" glyph="add" buttonType="primary" buttonSize={300} permission="todos.create">
        <DropdownItem
          label="New To-Do"
          glyph="add"
          color="paleBlue"
          link={{
            pathname: `${pathname}/todo-builder`,
            data: data,
          }}
          permission="todos.create"
          newFeatureFlag={newFeatureFlag}
        />

        <Divider />

        <ClientTrackTabs clientTrack={clientTrack} activeId={clientTrackId} setActiveId={setClientTrackId} />

        {isLoading ? (
          <State isLoading minHeight={100} />
        ) : (
          orderBy(mapToArray(templates), 'title').map((template) => {
            const { id, ...templateKeys } = template

            return (
              <DropdownItem
                key={template.id}
                label={template.title}
                icon="tasks"
                color="paleBlue"
                link={{
                  pathname: `${pathname}/todo-builder`,
                  data: {
                    ...templateKeys,
                    ...data,
                    template_id: template.id,
                  },
                }}
                permission="todos.create"
                newFeatureFlag={newFeatureFlag}
              />
            )
          })
        )}
      </Dropdown>
    </>
  )
}

export default TodoPageActions
