import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { DeclinedReportDataTable } from '@behavehealth/constructs/LiveReports/DeclinedReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Declined = () => {
  const queryParams = { status: 'declined', category: 'declined' }

  const tableProps = useDataTable({
    name: ['reports', 'clients'],
    // endpoint: `/live_reports/clients`,
    endpoint: `/live_reports`,
    updateDeleteEndpoint: `/residents`,
    params: queryParams,
    localStorageKey: 'report_declined_v2',
  })

  const to = React.useMemo(() => (rowData: any) => getClientLink(rowData), [])

  return (
    <Page icon="care_level_6" title="Live Declined Report">
      <Grid gap="1rem">
        <DeclinedReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Declined)
