import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { TodoCalendar, Chotomate } from '@behavehealth/components'
import CalendarTodoCard from '@behavehealth/components/Cards/CalendarTodoCard'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const CompletedTasks = () => {
  const { params, url }: any = useRouteMatch()
  const { timezone } = useSettings()

  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'task',
    action_status: 'done',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
  }

  const { data, isLoading, refetch, isRefetching }: any = useGet({
    name: ['organizations', params?.resource_id, 'todos', queryParams],
    url: `/organizations/${params?.resource_id}/events`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
  })

  const { mutate: deleteEvents } = useDelete({ name: ['organization', params?.resource_id, 'todos'], url: '/events', invalidate: 'todos' })

  return (
    <>
      <Chotomate name="todo_completed" ready={!isLoading} />

      <Filters config={FILTERS.todos} onUpdate={setFilters} localStorageKey="todos_completed" />

      <TodoCalendar
        events={data}
        onChange={setDates}
        isLoading={isLoading}
        timezone={timezone}
        refetch={refetch}
        isRefetching={isRefetching}
        localStorageKey="internal_organizations_todos"
        to={(id: string) => `${url}/${id}`}
        renderEvent={(task: any) => <CalendarTodoCard task={task} link={`${url}/${task.id}`} />}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'todos.delete',
            action: async ({ ids }: any) => {
              await deleteEvents(ids.join(','))
            },
          },
        ]}
      />
    </>
  )
}

export default CompletedTasks
