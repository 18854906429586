import React from 'react'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Card, Chotomate, TodoCalendar } from '@behavehealth/components'
import CalendarTodoCard from '@behavehealth/components/Cards/CalendarTodoCard'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'
import { TodoCalendarSearch } from '@behavehealth/components/Calendar/TodoCalendarSearch'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const ActiveTasks = () => {
  const { timezone } = useSettings()
  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'task',
    action_status: 'due',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
  }

  const { data, isLoading, refetch, isRefetching }: any = useGet({
    name: ['todos', queryParams],
    url: `/events/search`,
    params: {
      ...queryParams,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates },
  })

  const { mutateAsync: deleteEvents } = useDelete({ name: 'todos', url: '/events', invalidate: 'todos' })

  return (
    <>
      <Chotomate name="todo_active" ready={!isLoading} />

      <Card className="flex px-2 py-2 mq900:sticky mq900:top-16 z-[10] !shadow-soft-3 ring-[0.5px] ring-divider">
        <TodoCalendarSearch className="mr-2" />
        <Filters config={FILTERS.todos_meilisearch} onUpdate={setFilters} localStorageKey="todos_meilisearch_active" />
      </Card>

      <TodoCalendar
        useV6Router
        events={data}
        onChange={setDates}
        isLoading={isLoading}
        timezone={timezone}
        refetch={refetch}
        isRefetching={isRefetching}
        to={(id: string) => id}
        localStorageKey="main_todos"
        renderEvent={(task: any) => <CalendarTodoCard useV6Router task={task} link={task.id} />}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'todos.delete',
            action: async ({ ids }: any) => {
              await deleteEvents(ids.join(','))
            },
          },
        ]}
      />
    </>
  )
}

export default ActiveTasks
