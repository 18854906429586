import React from 'react'
import compact from 'lodash/compact'
import isUndefined from 'lodash/isUndefined'
import pluralize from 'pluralize'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { countWord, isDefined } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { isURL, isBase64Image } from '../../utils/functions'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { useStore } from './utils/useStore'
import { withFormContext } from '../../components/Forms/context'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Glyph from '../../components/Glyph'
import Overlay from '../../components/Overlay'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SummonOverlay from '../../components/SummonOverlay'

export const WorksheetHeaderCell = (props: any) => {
  const { column, columnIndex, children, className } = props

  const isRequired = isDefined(column?.config?.validations?.presence)

  return (
    <div css={STYLES.root} className={className} data-type="worksheet-header-cell" data-test="worksheet_header_cell" data-y={columnIndex}>
      {children}
      {isRequired && <Glyph glyph="asterisk" className="asterisk" size={8} />}
      <div className="!flex-auto" />
      {column?.config?.quickAdd && <QuickAdd column={column} />}
      {column?.type === 'signature' && <SignatureOverlay column={column} />}
    </div>
  )
}

const QuickAdd = ({ column }: any) => {
  const editDisabledColumns: any = useStore((state: any) => state.editDisabledColumns)

  let canEdit = !column.disableEdit && !editDisabledColumns?.includes(column.model)

  if (!column?.config?.quickAdd || !canEdit) return null

  return (
    <SummonOverlay overlay={<QuickAddOverlay column={column} />}>
      <Button glyph="quick_view" label="Quick Add" size={100} type="minimal" />
    </SummonOverlay>
  )
}

const SignatureOverlay = withFormContext((props: any) => {
  const { form, column } = props

  const dataMap: any = useStore((state: any) => state.dataMap)
  const dataIds: any = useStore((state: any) => state.dataIds)
  const allowPin = !!column?.config?.allowPin

  const canSign = column?.config?.getCanSign ? column.config.getCanSign({ dataMap, dataIds }) : true

  return (
    <SignatureDialog
      canSign={canSign}
      model={null}
      allowPin={allowPin}
      title="Bulk Sign"
      column={column}
      before={column?.config?.renderBefore && column.config.renderBefore({ dataMap, dataIds })}
      onYes={({ value }) => {
        if (!canSign) return

        const models = []

        for (const id of dataIds) {
          const model = compact([id, column.model]).join('.')
          models.push(model)
        }

        for (const model of models) {
          if (isUndefined(value) || !model) return

          if (isURL(value)) {
            form.setFieldValue(`${model}_url`, value)
          } else if (isBase64Image(value)) {
            form.setFieldValue(`${model}_data`, value)
          }
        }
      }}
    >
      <Button glyph="signature" label="Bulk Sign" size={100} type="minimal" />
    </SignatureDialog>
  )
})

const QuickAddOverlay = (props: any) => {
  const { column, onClose } = props

  const [canSelectDuplicates, setCanSelectDuplicates] = React.useState(true)

  const { quickAdd } = column.config
  const { endpoint, queryKey, filtersConfig, columns } = quickAdd

  const tableProps = useDataTable({ name: queryKey, endpoint, initialPageSize: 50, enabled: !!column?.config?.quickAdd })

  const [selectedRows, setSelectedRows] = React.useState([])
  const selectedRowsCount = size(selectedRows)

  const quickAddData: any = useStore((state: any) => state.quickAddData)
  // const dataMap: any = useStore((state: any) => state.dataMap)

  // const previouslySelectedIds = React.useMemo(() => {
  //   const result: any = []

  //   if (size(dataMap) === 0) return result

  //   for (const id in dataMap) {
  //     if (dataMap[id]?.[column?.model]?.id) {
  //       result.push(dataMap[id][column.model].id)
  //     }
  //   }

  //   return result
  // }, [dataMap, column.model])

  const handleAdd = () => {
    quickAddData({ model: column.model, data: selectedRows })

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop closeOnBackdrop onClose={onClose} position="center" maxWidth={72}>
      <Overlay.Header title={`Quick Add ${pluralize(column.title)}`} glyph="quick_view" />

      {/* {size(previouslySelectedIds) > 0 && (
        <Overlay.SubHeader>
          <Flex centerY gap="0.35rem">
            <Switch
              label=""
              size={200}
              withHover={false}
              value={!canSelectDuplicates}
              onUpdate={({ value: isChecked }: any) => {
                setCanSelectDuplicates(isChecked)
              }}
              layout={null}
            />
            <Label label={`Block Duplicate Selection`} css={{ whiteSpace: 'nowrap', fontSize: '0.94rem' }} />
          </Flex>
        </Overlay.SubHeader>
      )} */}

      <Overlay.Content>
        <>
          <DataTable
            {...tableProps}
            canBatchSelect
            title={column.title}
            columns={columns}
            filtersConfig={filtersConfig}
            onRowSelectionUpdate={setSelectedRows}
            // getCanSelect={(record: any) => ({
            //   canSelect: canSelectDuplicates || !previouslySelectedIds.includes(record.id),
            //   reason: 'Already selected',
            // })}
          />
        </>
      </Overlay.Content>

      <Overlay.Footer css={STYLES.footer}>
        <Button
          type="primary"
          color="green"
          label={selectedRowsCount === 0 ? 'Select…' : `Add ${countWord(column.title, selectedRowsCount)}`}
          isDisabled={selectedRowsCount === 0}
          onClick={handleAdd}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

const STYLES = {
  root: {
    minHeight: '1.75rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0.2rem',
    borderRight: `1px solid ${COLORS.divider}`,
    fontWeight: 600,

    '.asterisk': {
      position: 'relative',
      left: 2,
      top: -2,
    },
  },

  footer: {
    marginTop: '1.25rem',
  },
}
