import React from 'react'

import Select from '../Forms/Select'
import Option from '../Forms/Option'
import { withFormContext } from '../Forms/context'

const EventAttendanceAbsentStatusSelect = ({ label, model = 'category', defaultValue, isEditable, isDisabled, tooltip }: any) => (
  <Select label={label} model={model} defaultValue={defaultValue} isEditable={isEditable && !isDisabled} tooltip={tooltip}>
    <Option label={'N/A'} value={null} />

    <Option label="Authorized Leave" value="authorized_leave" />
    <Option label="Crisis Intervention" value="crisis_intervention" />
    <Option label="Excused" value="excused" />
    <Option label="Inpatient/Residential Admission" value="inpatient_residential_admission" />
    <Option label="Legal/Court Situation" value="legal_court_situation" />
    <Option label="Medical Situation" value="medical_situation" />
    <Option label="Relapse" value="relapse" />
    <Option label="Unexcused (No-call, No-show" value="unexcused_no_call_no_show" />
    <Option label="Cancelled - by Client" value="cancelled_by_client" />
    <Option label="Cancelled - by Provider" value="cancelled_by_provider" />
    <Option label="Rescheduled" value="rescheduled" />
  </Select>
)

export default withFormContext(EventAttendanceAbsentStatusSelect)
