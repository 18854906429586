import React from 'react'
import clsx from 'clsx'
import useMedia from 'use-media'

import { COLORS } from '../../theme'
import withSettings from '../../hocs/withSettings'

import Button from '../Button'
import Card from '../Card'
import CardContent from '../CardContent'

import AgendaView from './views/AgendaView'
import CalendarView from './views/CalendarView'
import TableView from './views/TableView'

import CalendarBase from './CalendarBase'
import CalendarHeader from './CalendarHeader'
import CalendarSidebar from './CalendarSidebar'

import { CalendarContext } from './context'

const VIEWS: any = {
  agenda: AgendaView,
  calendar: CalendarView,
  table: TableView,
}

const Calendar = ({ desktopBreakpoint = '1300px', sidebarAfter, ...props }: any) => (
  <CalendarBase {...props}>
    {({
      className,
      currentDate,
      days,
      events,
      eventsByDate,
      isLoading,
      listStyle,
      localStorageKey,
      mode,
      onNext,
      onPrev,
      onToday,
      renderEvent,
      setCurrentDate,
      setMode,
      setTableColumns,
      setView,
      tableProps,
      today,
      view,
      week,
    }: any) => {
      const isDesktop = useMedia({ minWidth: desktopBreakpoint })
      const classNames = clsx(isLoading && 'is-loading', isDesktop && 'is-desktop', className)

      let View = VIEWS[view] || CalendarView

      return (
        <CalendarContext.Provider value={{ mode, view }}>
          <Card className={classNames} css={styles.root}>
            <CalendarHeader
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              isLoading={isLoading}
              mode={mode}
              onNext={onNext}
              onPrev={onPrev}
              onToday={onToday}
              setMode={setMode}
              setView={setView}
              today={today}
              view={view}
              desktopBreakpoint={desktopBreakpoint}
              refetch={props.refetch}
              isRefetching={props.isRefetching}
            />

            <main css={styles.main} className={isDesktop ? 'is-desktop' : ''}>
              <div css={styles.sidebar}>
                <CalendarSidebar
                  currentDate={currentDate}
                  isLoading={isLoading}
                  mode={mode}
                  onNext={onNext}
                  onPrev={onPrev}
                  onToday={onToday}
                  setMode={setMode}
                  setView={setView}
                  view={view}
                  today={today}
                  setCurrentDate={setCurrentDate}
                  after={sidebarAfter}
                />
              </div>

              <CardContent css={styles.cardContent} padding="0" showDivider={false}>
                {view !== 'table' && (
                  <>
                    <Button
                      hideLabel
                      color="text"
                      size={100}
                      glyph="chevron_left"
                      glyphSize="1em"
                      onClick={onPrev}
                      css={styles.navButton}
                      className="nav-left"
                    />

                    <Button
                      hideLabel
                      color="text"
                      size={100}
                      glyph="chevron_right"
                      glyphSize="1em"
                      onClick={onNext}
                      css={styles.navButton}
                      className="nav-right"
                    />
                  </>
                )}

                <View
                  batchActionsConfig={props.batchActionsConfig}
                  currentDate={currentDate}
                  days={days}
                  events={events}
                  eventsByDate={eventsByDate}
                  isLoading={isLoading}
                  listStyle={listStyle}
                  localStorageKey={localStorageKey}
                  mode={mode}
                  renderEvent={renderEvent}
                  setTableColumns={setTableColumns}
                  tableProps={tableProps}
                  to={props.to}
                  useV6Router={props.useV6Router}
                  week={week}
                />
              </CardContent>
            </main>
          </Card>
        </CalendarContext.Provider>
      )
    }}
  </CalendarBase>
)

const styles = {
  root: {
    display: 'grid',
  },

  main: {
    display: 'grid',

    '&.is-desktop': {
      gridTemplateColumns: '380px 1fr',
    },
  },

  sidebar: {
    // padding: '0.75rem 1rem',
    borderRight: 'none',
    borderBottom: `1px solid ${COLORS.divider}`,

    '.is-desktop &': {
      borderBottom: 'none',
      borderRight: `1px solid ${COLORS.divider}`,
    },
  },

  cardContent: {
    overflow: 'hidden',
    position: 'relative !important',

    '.is-loading &': {
      opacity: 0.5,
      pointerEvent: 'none',
    },
  },

  navButton: {
    minWidth: 24,
    minHeight: 28,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    svg: { margin: '0 !important' },
    display: 'none',

    '@media (min-width: 1024px)': {
      display: 'flex',
    },

    '&.nav-left': {
      left: -1,
      borderRadius: '0 5px 5px 0',
      borderLeft: 'none',
    },

    '&.nav-right': {
      right: -1,
      borderRadius: '5px 0 0 5px',
      borderRight: 'none',
    },
  },
}

export default withSettings(Calendar)
