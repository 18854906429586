import React from 'react'
import compact from 'lodash/compact'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import TreatmentPlanStatus from '../../components/Statuses/TreatmentPlanStatus'

export const TreatmentPlansArchivedDataTable = (props: any) => {
  const { to, renderDuplicateButton, ...rest } = props

  const columns = React.useMemo(
    () =>
      compact([
        {
          title: 'Name',
          model: 'name',
          width: 300,
          disableHide: true,
          formatValue: ({ data, value }: any) => {
            return <MainCell id={data.id} to={to?.(data)} value={value} />
          },
        },
        {
          title: 'Status',
          model: 'status',
          formatValue: ({ value }: any) => <TreatmentPlanStatus small status={value} />,
        },
        {
          title: 'Date Added',
          model: 'created_at',
          type: 'date_time',
        },
        {
          title: 'Last Updated',
          model: 'updated_at',
          type: 'date_time',
        },
        {
          title: 'Added By',
          model: 'author',
          type: 'profile',
          disableSort: true,
        },
        renderDuplicateButton && {
          title: 'Create New From',
          id: 'duplicate',
          hoverExpand: false,
          disableSort: true,
          width: 150,
          formatValue: ({ data }: any) => renderDuplicateButton(data),
        },
      ]),
    [to],
  )

  return (
    <DataTable
      asCard
      title="Treatment Plans"
      icon="treatment_plans"
      columns={columns}
      filtersConfig={FILTERS.treatment_plans_archived}
      {...rest}
    />
  )
}
