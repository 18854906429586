import { createStore } from 'zustand'
import compact from 'lodash/compact'
import produce from 'immer'
import size from 'lodash/size'

import { CELLS } from './constants'
import { DEBUG } from './utils'
import { getTableSettings, setTableSettings } from './localStorage'
import { isDefined, getPermission } from '../../utils/functions'

import useStore from '../../modules/store'

const filtersMapToArray = (filtersMap: any) => {
  const result: any = []

  if (size(filtersMap) === 0) return result

  for (const key in filtersMap) {
    const filter = filtersMap[key]

    if (filter) result.push(filter)
  }

  return result
}

export const initializeStore = (initProps: any) => {
  const DEFAULT_PROPS: any = {
    activeCell: {},
    cells: {},
    columnIds: [],
    currentPage: 1,
    dataMap: null,
    filters: [],
    filtersConfig: {},
    filtersVisible: true,
    columnFiltersEnabled: false,
    folderDataIds: [],
    foldersTree: [],
    hiddenColumnIds: [],
    hoverCellCoords: {},
    invalidate: null,
    invalidateKeys: null,
    isEditingActiveCell: false,
    isResizing: false,
    localStorageKey: '',
    openFolderIds: [],
    pageSize: 25,
    rootDataIds: [],
    selectedCells: [],
    selectedIds: [],
    selectedRows: [],
    sorting: [],
    updateKey: null,
    hiddenRowIds: [],
    cannotSelectIds: [],
    title: initProps.title || '',
  }

  const { onCurrentPageChange, onDataInitialized, onPageSizeChange, onRowSelect, processRow, getRowId } = initProps

  return createStore<any>()((set, get) => ({
    ...DEFAULT_PROPS,
    ...initProps,

    cannotSelectIds: [],
    setCannotSelectIds: (ids: string[]) => {
      if (!ids) return

      set({ cannotSelectIds: ids })
    },

    canBatchSelect: initProps.canBatchSelect || size(initProps.batchActionsConfig) > 0,

    reorderColumns: (startIndex: number, endIndex: number) => {
      const columnIds = get().columnIds
      const localStorageKey = get().localStorageKey

      const reordered = produce(columnIds, (draft: any) => {
        const [removed] = draft.splice(startIndex, 1)
        draft.splice(endIndex, 0, removed)
      })

      set({ columnIds: reordered })

      if (localStorageKey) setTableSettings(localStorageKey, { columnIds: reordered })
    },

    toggleHideColumn: (id: string) => {
      const columnsMap = get().columnsMap
      const hiddenColumnIds = get().hiddenColumnIds
      const localStorageKey = get().localStorageKey

      if (!columnsMap[id]) return

      const result = produce(hiddenColumnIds, (draft: any) => {
        const index = draft.indexOf(id)

        if (index === -1) draft.push(id)
        else draft.splice(index, 1)
      })

      if (localStorageKey) setTableSettings(localStorageKey, { hiddenColumnIds: result })

      set({ hiddenColumnIds: result })
    },

    setIsResizing: (value: boolean) => {
      set({ isResizing: value })
    },

    setColumnWidth: ({ id, width }: any) => {
      const columnsMap = get().columnsMap
      const localStorageKey = get().localStorageKey

      if (!columnsMap[id]) return

      const newColumns = produce(columnsMap, (draft: any) => {
        draft[id] = { ...draft[id], width }
      })

      set({ columnsMap: newColumns })
      setTableSettings(localStorageKey, { columnsMap: newColumns })
    },

    setActiveCell: ({ x, y }: any) => {
      set({
        activeCell: {
          x: parseInt(x) || 0,
          y: parseInt(y) || 0,
        },
      })
    },

    setHoverCellCoords: ({ x, y }: any) => {
      set({
        hoverCellCoords: {
          x: parseInt(x) || 0,
          y: parseInt(y) || 0,
        },
      })
    },

    resetHoverCellCoords: () => {
      set({ hoverCellCoords: {} })
    },

    editActiveCell: () => {
      set({ isEditingActiveCell: true })
    },

    cancelEditActiveCell: () => {
      set({ isEditingActiveCell: false })
    },

    setIsEditingActiveCell: (value: boolean) => {
      set({ isEditingActiveCell: value })
    },

    setFiltersVisible: (value: boolean) => {
      set({ filtersVisible: value })
    },

    initializeData: ({ data, columns, folders, meta }: any) => {
      const localStorageKey = get().localStorageKey
      const hiddenRowIds = get().hiddenRowIds
      const tableSettings = getTableSettings(localStorageKey)

      const { permissions: userPermissions, tenant, user }: any = useStore.getState()

      const userFeatureFlags = tenant?.feature_flags
      const hasFeatureFlags = size(userFeatureFlags) > 0

      if (!data || !columns) return

      const cells: any = {}
      const columnIds: any = []
      const columnsMap: any = {}
      const dataMap: any = {}
      const rootDataIds: any = []

      for (let y = 0; y < size(data); y++) {
        const row = processRow ? processRow(data[y]) : data[y]
        const id = getRowId ? getRowId(row) : row.id

        if (hiddenRowIds.includes(id)) continue

        if (id) {
          dataMap[id] = row
          rootDataIds.push(id)
        }

        for (let x = 0; x < size(columns); x++) {
          const column = columns[x]
          const { id, type } = column
          const value = row[id]

          const index = `${x}-${y}`

          cells[index] = { value, type, column, row }
        }
      }

      for (let i = 0; i < size(columns); i++) {
        const column = columns[i]
        const config = CELLS[column.type]

        const { featureFlag, permission, editPermission } = column

        let canEdit = false
        const checkForViewPermission = isDefined(permission) || isDefined(featureFlag)
        const checkForEditPermission = isDefined(editPermission)

        if (checkForViewPermission) {
          const canView = getPermission({ featureFlag, permission, tenant, user, userFeatureFlags, userPermissions })

          if (!canView) continue
        }

        if (checkForEditPermission) {
          canEdit = getPermission({ featureFlag, permission: editPermission, tenant, user, userFeatureFlags, userPermissions })
        }

        const id = column?.id || column?.model
        const savedWidth = tableSettings?.columnsMap?.[id]?.width

        if (!id) {
          throw new Error(`Column #${i + 1} does not have an ID`)
        } else if (columnIds.includes(id)) {
          throw new Error(`Found duplicate column ID ${id}`)
        }

        // don't process any columns that are hidden via props
        if (initProps.hiddenColumnIds?.includes(id)) continue

        columnIds.push(id)

        columnsMap[id] = {
          ...column,
          id,
          canEdit,
          width: savedWidth || column.width || config?.width || 200,
          disableSort: column?.disableSort || config?.disableSort,
          disableSelect: column?.disableSelect || config?.disableSelect,
        }
      }

      // const foldersTree = getFoldersTree(folders)
      // const { dataMap, folderDataIds, rootDataIds } = processFoldersData(data, folders)

      let sortedColumnIds = []

      if (size(tableSettings?.columnIds) > 0) {
        // add existing columns in order if they are defined in config
        for (const columnId of tableSettings.columnIds) {
          if (columnIds.includes(columnId)) sortedColumnIds.push(columnId)
        }

        // add any new columns from config if not already added from local storage
        for (const id of columnIds) {
          if (!sortedColumnIds.includes(id)) {
            // find suitable index to insert new column
            const index = columnIds.indexOf(id)
            const prevId = columnIds?.[index - 1]

            let newIndex = sortedColumnIds.indexOf(prevId)

            // insert after previous column if found, otherwise insert at end
            newIndex === -1 ? sortedColumnIds.push(id) : sortedColumnIds.splice(newIndex + 1, 0, id)
          }
        }
      } else {
        sortedColumnIds = columnIds
      }

      set({
        cells,
        columnIds: sortedColumnIds,
        columnsMap,
        data: data,
        dataMap,
        folders: folders,
        hiddenColumnIds: tableSettings?.hiddenColumnIds || [],
        meta,
        rootDataIds,
        // folderDataIds,
        // foldersTree,
      })

      if (localStorageKey) setTableSettings(localStorageKey, { columnIds: sortedColumnIds })

      if (DEBUG) console.debug('🗂 DataTable – DATA INITIALIZED', { data, dataMap })
      if (onDataInitialized) onDataInitialized()
    },

    selectCell: ({ coords, ctrlKey }: any) => {
      if (!coords) return

      const selectedCells = get().selectedCells

      // TODO: refactor code below

      if (ctrlKey) {
        const newSelectedCells = [...selectedCells]
        const index = newSelectedCells.indexOf(coords)

        // add or remove cell from selected array
        index === -1 ? newSelectedCells.push(coords) : newSelectedCells.splice(index, 1)

        set({ selectedCells: newSelectedCells })
      } else {
        const index = selectedCells.indexOf(coords)
        const newSelectedCells: any = index === -1 ? [coords] : []

        set({ selectedCells: newSelectedCells })
      }
    },

    // Hidden Rows
    hiddenRowIds: [],
    setHiddenRowIds: (ids: string[]) => {
      const currentSelectedIds = get().selectedIds
      const currentSelectedRows = get().selectedRows

      if (size(currentSelectedIds) > 0) {
        const newSelectedIds = currentSelectedIds.filter((id: string) => !ids.includes(id))
        const newSelectedRows = currentSelectedRows.filter((row: any) => !ids.includes(row.id))

        set({ selectedIds: newSelectedIds, selectedRows: newSelectedRows })
      }

      set({ hiddenRowIds: ids })
    },

    // Filters
    filters: filtersMapToArray(initProps.filters),

    setFilters: (filtersMap: any) => {
      if (!filtersMap) return

      set({ filters: filtersMapToArray(filtersMap) })
    },

    addFilter: ({ key, value, condition }: any) => {
      const filtersConfig = get().filtersConfig

      if (key && !filtersConfig?.hasOwnProperty(key)) {
        console.error(`Filter key "${key}" not found in filters config`)
        return
      }

      const newFilters = produce(get().filters, (draft: any) => {
        draft.push({
          key: key || null,
          value: value || null,
          condition: condition || null,
        })
      })

      set({ filters: newFilters })
    },

    setFilter: (key: any, args: any) => {
      const filtersConfig = get().filtersConfig

      if (!filtersConfig?.hasOwnProperty(key)) return

      const newFilters = produce(get().filters, (draft: any) => {
        const index = draft.findIndex((filter: any) => filter.key === key)

        if (index === -1) draft.push({ key, ...args })
        else draft[index] = { ...draft[index], ...args }
      })

      set({ filters: newFilters })
    },

    removeFilter: (key: any) => {
      const newFilters = produce(get().filters, (draft: any) => {
        const index = draft.findIndex((filter: any) => filter.key === key)

        if (index !== -1) draft.splice(index, 1)
      })

      set({ filters: newFilters })
    },

    resetFilters: () => {
      set({ filters: [] })
    },

    // Sorting
    // TODO: refactor
    sorting: Array.isArray(initProps.sorting) ? initProps.sorting : size(initProps.sorting) > 0 ? [initProps.sorting] : [],

    addSorting: (id: string, sort?: 'ASC' | 'DESC') => {
      const newSorting = produce(get().sorting, (draft: any) => {
        const index = draft.findIndex((o: any) => o.name === id)

        if (index !== -1) draft.splice(index, 1)

        draft.push({ name: id, sort: sort || 'ASC' })
      })

      set({ sorting: newSorting })
    },

    setSorting: (id: any, args: any) => {
      const newSorting = produce(get().sorting, (draft: any) => {
        const index = draft.findIndex((o: any) => o.name === id)

        if (index !== -1) draft[index] = { ...draft[index], ...args }
      })

      set({ sorting: newSorting })
    },

    removeSorting: (id: any) => {
      const newSorting = produce(get().sorting, (draft: any) => {
        const index = draft.findIndex((o: any) => o.name === id)

        if (index !== -1) draft.splice(index, 1)
      })

      set({ sorting: newSorting })
    },

    resetSorting: () => {
      set({ sorting: [] })
    },

    setCurrentPage: (currentPage: number) => {
      set({ currentPage })

      if (onCurrentPageChange) onCurrentPageChange(currentPage)
      if (DEBUG) console.debug('setCurrentPage', currentPage)
    },

    setPageSize: (pageSize: number) => {
      set({ pageSize })

      if (onPageSizeChange) onPageSizeChange(pageSize)
      if (DEBUG) console.debug('setPageSize', pageSize)
    },

    selectAll: () => {
      const { getCanSelect } = initProps
      const data = get().data
      const cannotSelectIds = get().cannotSelectIds

      let newSelectedIds: any = []
      let newSelectedRows: any = []

      if (getCanSelect || size(cannotSelectIds) > 0) {
        for (const record of data) {
          const { canSelect } = getCanSelect(record)

          // TODO @Andrei: refactor
          const cannotSelect = cannotSelectIds.includes(record.id)

          if (canSelect && !cannotSelect) {
            newSelectedRows.push(record)
            newSelectedIds.push(record.id)
          }
        }
      } else {
        newSelectedRows = [...get().selectedRows]
        newSelectedIds = [...get().selectedIds]

        for (const record of data) {
          if (newSelectedIds.includes(record.id)) continue

          newSelectedRows.push(record)
          newSelectedIds.push(record.id)
        }
      }

      return set({ selectedIds: newSelectedIds, selectedRows: newSelectedRows })
    },

    selectNone: () => set({ selectedIds: [], selectedRows: [] }),

    unselectIds: (ids: string[]) => {
      if (size(ids) === 0) return

      const selectedIds = get().selectedIds
      const selectedRows = get().selectedRows

      const newIds = selectedIds.filter((id: string) => !ids.includes(id))
      const newRows = selectedRows.filter((row: any) => !ids.includes(row.id))

      return set({ selectedIds: newIds, selectedRows: newRows })
    },

    selectId: (id: string) => {
      const { getCanSelect } = initProps
      const dataMap = get().dataMap
      const selectedRows = get().selectedRows

      const record = dataMap[id]

      // record with given ID not found
      if (!record) return

      if (getCanSelect) {
        const { canSelect } = getCanSelect(record)

        if (!canSelect) return
      }

      return set((state: any) => {
        if (state.selectedIds?.includes(id)) {
          // already selected, deselect id
          const index = state.selectedIds.indexOf(id)
          const newIds = [...state.selectedIds]
          newIds.splice(index, 1)

          // find and remove row
          const rowIndex = state.selectedRows.findIndex((row: any) => row?.id === id)
          const newSelectedRows = produce(selectedRows, (draft) => {
            if (rowIndex !== -1) draft.splice(rowIndex, 1)
          })

          return { selectedIds: newIds, selectedRows: newSelectedRows }
        } else {
          // select row
          const row = state.dataMap?.[id]

          if (row && onRowSelect) {
            if (DEBUG) console.debug('🎯 onRowSelect', row)
            onRowSelect(row)
          }

          const selectedIds = [...state.selectedIds, id]
          const selectedRows = [...state.selectedRows, row]

          if (DEBUG) console.debug('🎯 selectId', { id, row, selectedIds })

          return { selectedIds, selectedRows }
        }
      })
    },

    batchSelectIds: (ids: string[]) => {
      return set(
        produce((state: any) => {
          if (size(ids) === 0) return

          for (const id of ids) {
            if (state.selectedIds.includes(id)) continue

            state.selectedIds.push(id)
          }
        }),
      )
    },

    toggleFolderOpen: (id: string) =>
      set((state: any) => {
        if (state.openFolderIds?.includes(id)) {
          const index = state.openFolderIds.indexOf(id)
          const newIds = [...state.openFolderIds]
          newIds.splice(index, 1)

          return { openFolderIds: newIds }
        } else {
          return { openFolderIds: [...state.openFolderIds, id] }
        }
      }),
  }))
}
