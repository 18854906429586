import React from 'react'

import Table from '../Table/Table'
import TableCell from '../Table/TableCell'
import TableFilter from '../Table/TableFilter'

import withSettings from '../../hocs/withSettings'

import { CLEARING_HOUSES } from '../../utils/constants'
import { titleCase } from '../../utils/functions'

const columns = (to: Function = () => {}) => [
  {
    width: 400,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'name',
    Header: 'Payer Name',
    Cell: ({ value }: any) => <TableCell.MainLink label={value} />,
  },
  {
    width: 150,
    accessor: 'states_covered',
    Header: 'States Covered',
    Cell: ({ value }: any) => {
      if (!value) return <TableCell.Empty />
      if (value.length >= 50) return <TableCell value="National" />

      return <TableCell value={value?.join(', ') || '–'} />
    },
  },
  {
    width: 240,
    accessor: 'clearing_house',
    Header: 'Clearing House',
    Cell: ({ value }: any) => <TableCell value={CLEARING_HOUSES[value] || '–'} />,
    Filter: false,
  },
  {
    width: 110,
    id: 'payer_id',
    accessor: 'payer_id',
    Header: 'Payer ID',
  },
  {
    width: 150,
    id: 'eligibility',
    accessor: 'supports.eligibility',
    Header: 'eVOBs',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'claims',
    accessor: 'supports.claims',
    Header: 'Claims',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => {
      if (value === true) return <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />
      else if (value === false) return <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />

      return <TableCell value={titleCase(value)} />
    },
  },
  {
    width: 150,
    id: 'secondary_claims',
    accessor: 'supports.secondary_claims',
    Header: 'Secondary Claims',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'claim_status',
    accessor: 'supports.claim_status',
    Header: 'Claim Status',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'remittance',
    accessor: 'supports.remittance',
    Header: 'Remittance',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
  {
    width: 150,
    id: 'attachments',
    accessor: 'supports.attachments',
    Header: 'Attachments',
    Filter: TableFilter.TrueFalse,
    Cell: ({ value }: any) => <TableCell.TrueFalse trueGlyph="check" falseGlyph="cross" isTrue={value} />,
  },
]

type Props = {
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  timezone: string
  title: string
  to?: Function
  titleAfter?: React.ReactNode
  batchActionsConfig?: Object
}

const InsuranceGlobalPayersTable = (props: Props) => {
  const {
    data,
    emptyActions,
    emptyDescription,
    icon,
    isLoading,
    localStorageKey,
    batchActionsConfig,
    title,
    to,
    timezone,
    hiddenColumns,
    titleAfter,
    ...rest
  } = props

  return (
    <Table
      title={title}
      titleAfter={titleAfter}
      icon={icon}
      data={data}
      columns={columns(to)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

InsuranceGlobalPayersTable.defaultProps = {
  title: 'Insurance Payers',
  icon: 'insurance',
  emptyDescription: 'No payers added yet',
  localStorageKey: 'insurance_local_payers',
}

export default withSettings(InsuranceGlobalPayersTable)
