import React from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { titleCase, countWord } from '../../utils/functions'
import { useGet, useCreate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Accordions from '../../components/Accordions'
import Alert from '../../components/Alert'
import Attachments from '../../components/Forms/Attachments'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Icon from '../../components/Icon'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import MultiOverlaySelector from '../../components/Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Permission from '../../components/Permission'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SignatureDialog from '../../components/Dialogs/SignatureDialog'
import SignaturePad from '../../components/Forms/SignaturePad'
import State from '../../components/State'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import TextareaDialog from '../../components/Dialogs/TextareaDialog'
import TipAlert from '../../components/TipAlert'

import { ExportPDFButton } from '../../components/Buttons/ExportPDFButton'
import { FormFutureDateWarning } from '../Misc/FormFutureDateWarning'
import { NoteSignees } from '../NotesShared/NoteSignees'
import { NoteQuickAddClientsOverlay } from '../NotesShared/NoteQuickAddClientsOverlay'
import { SessionNote } from './components/SessionNote'
import { styles } from '../../components/DropdownItem'
import { COLORS } from '../../theme'

export const RootNewClinicalNoteGroupOverlay = (props: any) => {
  const overlayProps = useOverlay({
    name: 'clinical-notes',
    endpoint: '/clinical_notes',
    invalidate: 'clinical-notes',
    options: props,
    onDeleteSuccessful: props.onDeleteSuccessful,
    onSaveSuccessful: props.onSaveSuccessful,
    invalidateKeys: props.invalidateKeys,
    disableParentRequest: true,
    skipResetForm: true,
  })

  const { data, isOverlayLoading } = overlayProps

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={90} />
  }

  return (
    <InnerOverlay
      key={`updated-${data?.updated_at}`}
      save={props.save}
      onClose={props.onClose}
      overlayProps={overlayProps}
      type={props.type}
    />
  )
}

const InnerOverlay = (props: any) => {
  const { overlayProps } = props

  const {
    cancel,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isSaving,
    isUpdating,
    onValidationUpdate,
    saveWithData,
    updateAsync,
  } = overlayProps

  const location = useLocation()
  const history = useHistory()
  const { resource_id }: any = useParams()

  const { isBehave, isOwner, isManagement, timezone, user, defaultSupervisor, systemPrefs }: any = useSettings()

  const [format, setFormat] = React.useState('individual_assessment')
  const [quickAddOpen, setQuickAddOpen] = React.useState(false)
  const [supervisor, setSupervisor] = React.useState<any>(null)
  const [requireSupervisor, setRequireSupervisor] = React.useState(true)

  const [sessionNotes, setSessionNotes] = React.useState(data?.clinical_notes || initialModel?.local_clinical_notes || [])
  const [deletedNoteIds, setDeletedNoteIds]: any = React.useState([])

  const sessionNotesCount = size(sessionNotes)
  const isSessionNotesEmpty = sessionNotesCount === 0

  let clientId = ''

  if (location.pathname.includes('/clients') || location.pathname.includes('/admissions') || location.pathname.includes('/alumni')) {
    clientId = resource_id
  }

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
    options: { enabled: !!clientId },
  })

  const initialLocalClinicalNotes = React.useMemo(() => {
    const notes = isNew ? initialModel?.local_clinical_notes || [] : data?.clinical_notes || []

    const res: any = {}

    for (const note of notes) {
      const clientId = note?.client_id || note?.client?.id

      if (!clientId) continue

      res[clientId] = note
    }

    return res
  }, [initialModel?.local_clinical_notes || data?.clinical_notes])

  const isSupervisor = initialModel.supervisor?.id === user.id
  const isRequiredSupervisorNonEditable = systemPrefs?.require_clinical_notes_supervisor

  const { mutateAsync: closeClinicalNote, isLoading: isClosingClinicalNote } = useCreate({
    name: ['clinical_notes', id],
    url: `/clinical_notes/${id}/close`,
    invalidate: 'clinical-notes',
  })

  const save = async () => {
    const { local_clinical_notes, ...formData } = form.current.getFormValue()

    const sessionClientIds = sessionNotes.map((note: any) => note.client_id || note.client?.id)

    const clinical_notes_attributes: any = []

    if (size(local_clinical_notes) > 0) {
      for (const clientId in local_clinical_notes) {
        const note = local_clinical_notes[clientId]

        // Skip if the note is deleted
        if (note?.id && deletedNoteIds.includes(note.id)) continue

        // Skip if the note is not in the session notes local state
        if (!sessionClientIds.includes(clientId)) continue

        clinical_notes_attributes.push({
          ...note,
          client_id: clientId,
          category: 'group_note',
        })
      }
    }

    if (size(deletedNoteIds) > 0) {
      for (const id of deletedNoteIds) {
        clinical_notes_attributes.push({ id, _destroy: true })
      }
    }

    const saveData = {
      ...formData,
      clinical_notes_attributes,
    }

    if (props.save) {
      await props.save(saveData)
      props.onClose?.()
    } else {
      await saveWithData(saveData)
    }
  }

  const close = () => {
    if (props.type === 'summon' && props.onClose) return props.onClose()

    const url = location?.parent?.url || location.pathname.substring(0, location.pathname.lastIndexOf('/cg/'))

    history.push(url)
  }

  const signOff = async (signature: any) => {
    await updateAsync({
      [signature.model]: signature.value,
    })

    props.onSaveSuccessful?.()
    // close()
  }

  const closeOff = async () => {
    try {
      await closeClinicalNote({})

      // close()
    } catch (error) {
      console.error(error)
    }
  }

  const sendForReview = async () => {
    await updateAsync({ status: 'pending_review' })

    props.onSaveSuccessful?.()
    cancel()
  }

  const requestUpdates = async (updates: any) => {
    await updateAsync({ status: 'updates_required', rejected_reason: updates.value })

    props.onSaveSuccessful?.()
    cancel()
  }

  React.useEffect(() => {
    if (!data) return

    setRequireSupervisor(data.require_supervisor)
  }, [data])

  return (
    <>
      <SummonOverlay
        isOpen={quickAddOpen}
        onClose={() => setQuickAddOpen(false)}
        overlay={
          <NoteQuickAddClientsOverlay
            addedClientIds={sessionNotes.map((note: any) => note.client?.id)}
            onAdd={(selected: any, initialIsAttending) => {
              if (!selected) return

              setSessionNotes((prev: any) => {
                const prevClientIds = prev.map((note: any) => note.client?.id)

                const newNotes = selected
                  .filter((client: any) => !prevClientIds.includes(client.id))
                  .map((client: any) => ({
                    client,
                    client_id: client.id,
                    _initialIsAttending: initialIsAttending,
                  }))

                return [...prev, ...newNotes]
              })
            }}
          />
        }
      />

      <Overlay isDirty showBackdrop={isEditable} maxWidth={90} onClose={close} position="right">
        <Overlay.Header
          icon="clinical_notes"
          title="Group Note"
          subtitle={<ClinicalNoteStatus status={initialModel?.status} />}
          help={<HelpTagIframe id="clinical_notes_group" />}
        />

        {!isEditable && (
          <Permission permission="clients.actions.export">
            <Overlay.SubHeader className="flex justify-start">
              <Dropdown
                label={<div className="flex items-center">Download Form PDF</div>}
                buttonType="link"
                glyph="download"
                buttonSize={200}
              >
                {clientId && client && (
                  <ExportPDFButton
                    url={`/clinical_notes/${id}/pdf`}
                    label={`Single Session Note PDF (${client.name})`}
                    css={dropdownItemStyles}
                    params={{ client_id: clientId }}
                  />
                )}

                <ExportPDFButton url={`/clinical_notes/${id}/pdf`} label="All Session Notes PDF" css={dropdownItemStyles} />
              </Dropdown>
            </Overlay.SubHeader>
          </Permission>
        )}

        <Overlay.Content className="mq900:grid mq900:grid-cols-[100%] mq900:grid-rows-[100%] mq900:!overflow-hidden">
          <Form
            getForm={form}
            timezone={timezone}
            isEditable={isEditable}
            initialModel={{
              ...initialModel,
              ...(defaultSupervisor && { supervisor: defaultSupervisor }),
              local_clinical_notes: initialLocalClinicalNotes,
            }}
            onValidationUpdate={onValidationUpdate}
            linked={{ category: 'clinical_group' }}
            className="grid grid-cols-1 mq900:grid-cols-[480px_1fr] mq900:grid-rows-[100%] mq900:!overflow-hidden"
            key={`updated-${data?.updated_at}`}
          >
            <div className="mq900:overflow-y-auto mq900:border-r mq900:border-solid mq900:border-divider border-0">
              {initialModel?.status === 'updates_required' && (
                <Section>
                  <Alert type="negative" glyph="note">
                    <MiniRichTextEditor useDictation fullwidth label="Updates Requested before Sign-Off" model="rejected_reason" />
                  </Alert>
                </Section>
              )}

              <Section headingType="h2" title="Session Details">
                <FormSection maxWidth="100%">
                  {!isNew && (
                    <Flex stretchChildrenX gap="1rem">
                      <Select label="Status" model="status">
                        <Option value="draft" label="Draft" />
                        <Option value="to_complete" label="To Complete" />
                        <Option value="rejected" label="Rejected" />
                        <Option value="pending_review" label="Pending Review" />
                        <Option value="updates_required" label="Updates Required" />
                        <Option value="pre_approved" label="Pre-Approved" />
                        <Option value="signed_off" label="Signed Off" />
                        <Option value="closed" label="Closed" />
                      </Select>

                      <ObjectSelector isEditable={false} model="author" label="Author" type="employees" icon="employees" />
                    </Flex>
                  )}

                  <Flex gap="1rem">
                    <DateTimeInput
                      defaultToNow
                      className="!flex-auto"
                      model="started_at"
                      label="Session Date and Time"
                      validations={{
                        presence: {
                          message: 'Please enter a session date and time',
                        },
                      }}
                    />

                    <Input
                      model="duration"
                      label="Duration"
                      suffix="mins"
                      type="number"
                      min={1}
                      size={4}
                      validations={{
                        presence: {
                          message: 'Please enter a session duration',
                        },
                        numericality: {
                          greaterThanOrEqualTo: 1,
                          message: 'The duration must be higher than 0 minutes',
                        },
                      }}
                    />
                  </Flex>

                  <FormFutureDateWarning dateLabel="Session Start Date and Time" model="started_at" />

                  <Input model="topic" label="Group Topic" />
                  <MiniRichTextEditor useDictation useQuickText model="description" label="Group Discussion" />
                  <MiniRichTextEditor useDictation useQuickText model="notes" label="Notes" />

                  <RadioGroup
                    model="format"
                    label="Note Type"
                    layout="horizontal-dense"
                    defaultValue={'individual_assessment'}
                    onUpdate={({ value }) => {
                      setFormat(value)
                    }}
                  >
                    <Radio label="DAP" value="dap" />
                    <Radio label="SOAP" value="soap" />
                    <Radio label="GIRP" value="girp" />
                    <Radio label="Client Note" value="individual_assessment" />
                  </RadioGroup>
                </FormSection>
              </Section>

              <Divider />

              <Section headingType="h2" title="People">
                <FormSection maxWidth="100%" layout="vertical">
                  <Flex gap="1rem" justifyContent="space-between" alignItems="flex-end">
                    <ObjectSelector
                      isPolymorphic
                      css={isEditable && { flex: '1 1 auto' }}
                      model="supervisor"
                      label="Supervisor"
                      type="clinical_supervisors"
                      icon="employees"
                      selectTitle={(data) => data?.name}
                      selectDescription={(data) => titleCase(data?.position)}
                      validations={
                        requireSupervisor && {
                          presence: {
                            message: 'Please select a supervisor',
                          },
                        }
                      }
                      onUpdate={({ object }) => {
                        setSupervisor(object)
                      }}
                      value={isEditable ? supervisor : data?.supervisor}
                    />

                    <CheckboxGroup label="Supervisor" tooltip={isRequiredSupervisorNonEditable ? 'Required from Settings' : null}>
                      <Checkbox
                        isEditable={isRequiredSupervisorNonEditable ? false : isEditable}
                        defaultChecked
                        trueIcon="check"
                        falseIcon="cross"
                        falseStyle="none"
                        label="Require Supervisor"
                        model="require_supervisor"
                        onChange={(state: any) => {
                          const nextValue = state.value
                          setRequireSupervisor(nextValue)

                          if (nextValue === false) {
                            setSupervisor(null)
                          }
                        }}
                      />
                    </CheckboxGroup>
                  </Flex>

                  <MultiOverlaySelector
                    label="Additional Staff Members"
                    blankLabel="Select Staff…"
                    icon="employees"
                    type="employees.active"
                    model="employees"
                    selectTitle={(data) => data.name}
                    selectDescription={(data) => titleCase(data.position)}
                  />

                  <div>
                    {!isSessionNotesEmpty && (
                      <Flex gap="0.5rem" alignItems="center" justifyContent="space-between">
                        <Label label={countWord('Clients', sessionNotesCount)} />
                        {isEditable && (
                          <Button label="Add Clients" size={100} glyph="add" type="primary" onClick={() => setQuickAddOpen(true)} />
                        )}
                      </Flex>
                    )}

                    {isSessionNotesEmpty ? (
                      <Card className="flex flex-col items-center justify-center px-4 py-6">
                        <Icon icon="clients" size={20} />
                        <div className="text-center text-text-muted mt-2 mb-3">Add clients to the group note to create session notes</div>
                        {isEditable && (
                          <Button label="Add Clients" size={100} glyph="add" type="primary" onClick={() => setQuickAddOpen(true)} />
                        )}
                      </Card>
                    ) : (
                      <>
                        {isEditable && (
                          <TipAlert small contrast glyph="info" className="mb-2">
                            Select a client to jump to their session note
                          </TipAlert>
                        )}

                        <Card className="grid gap-0">
                          {sessionNotes.map((note, index) => (
                            <div
                              className="flex flex-nowrap border-b border-solid border-divider border-0 last:border-b-0"
                              onClick={() => {
                                document.getElementById(`client-${note.client?.id}`)?.scrollIntoView()
                              }}
                            >
                              <div className="flex items-center self-stretch flex-nowrap cursor-pointer hover:bg-hover p-2 flex-[1_1_auto]">
                                <Avatar
                                  size={24}
                                  src={note.client?.avatar || ''}
                                  initials={note.client?.name}
                                  className="flex-[0_0_auto] mr-1.5"
                                />
                                <div className="font-[600] text-blue-500 flex-[1_1_auto] truncate pr-1.5">{note.client?.name}</div>

                                {note._isAttending === true && <Status small label="Present" color="green" className="ml-1.5" />}
                                {note._isAttending === false && <Status small label="Absent" color="red" className="ml-1.5" />}
                              </div>

                              {isEditable && (
                                <div className="flex items-center justify-center w-10 border-l border-solid border-divider border-0">
                                  <DeleteDialog
                                    title={`Remove Note for ${note.client?.name}?`}
                                    message={`Are you sure you want to remove the note for ${note.client?.name}? This action cannot be undone.`}
                                    onYes={() => {
                                      if (note.id) setDeletedNoteIds((prev) => [...prev, note.id])

                                      setSessionNotes((prev: any) => {
                                        return produce(prev, (draft) => {
                                          draft.splice(index, 1)
                                        })
                                      })
                                    }}
                                  >
                                    <Button hideLabel glyph="delete" color="red" type="minimal" size={100} />
                                  </DeleteDialog>
                                </div>
                              )}
                            </div>
                          ))}
                        </Card>
                      </>
                    )}
                  </div>
                </FormSection>
              </Section>

              <Divider />

              <Section headingType="h2" title="Signatures">
                <FormSection maxWidth="100%" layout="vertical">
                  <SignaturePad
                    allowPin
                    person={data?.signed_by}
                    label="Author Signature"
                    model="employee_signature"
                    signedAtModel="author_signed_at"
                    showAccept={false}
                  />

                  <SignaturePad
                    allowPin
                    isEditable={false}
                    person={data?.supervisor}
                    label="Supervisor Signature"
                    model="supervisor_signature"
                    signedAtModel="supervisor_signed_at"
                    showAccept={false}
                  />

                  <NoteSignees
                    isEditingRecord={isEditable}
                    signees={data?.clinical_note_signees}
                    name="clinical_note_signees"
                    invalidate="clinical-notes"
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section headingType="h2" title="Attachments" description="Upload the files related to this note">
                <FormSection maxWidth="100%">
                  <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
                </FormSection>
              </Section>
            </div>

            <div className="mq900:overflow-y-auto border-t border-solid border-divider border-0 mq900:border-t-0">
              <Section>
                {isSessionNotesEmpty ? (
                  <Card>
                    <State
                      isEmpty
                      title="Clients"
                      icon="clients"
                      minHeight={200}
                      emptyDescription="Add clients to the group note to create session notes"
                      emptyActions={
                        isEditable && (
                          <Button label="Add Clients" size={200} glyph="add" type="primary" onClick={() => setQuickAddOpen(true)} />
                        )
                      }
                    />
                  </Card>
                ) : (
                  <Accordions
                    header={
                      <Flex gap="1rem" alignItems="center" justifyContent="space-between">
                        <h2>{countWord('Session Notes', sessionNotesCount)}</h2>
                      </Flex>
                    }
                    headerClassName="bg-[#F7F8FB] sticky top-0 z-[10] pb-2"
                  >
                    <div
                      className={clsx(
                        'grid mt-2 gap-2 [&>.is-open]:mb-2 [&_.AccordionCardHeader.is-open]:!rounded-[7px] [&_.AccordionCardHeader.is-open]:!border-solid [&_.AccordionCardHeader.is-open]:!border-blue-500 [&_.AccordionCardHeader.is-open]:!ring-2 [&_.AccordionCardHeader.is-open]:!ring-blue-200',
                      )}
                    >
                      {sessionNotes.map((note: any, index) => {
                        return (
                          <SessionNote
                            isEditable={isEditable}
                            key={`note-${note?.client?.id}`}
                            client={note?.client}
                            format={format}
                            initialIsAttending={note?._initialIsAttending}
                            onAttendanceUpdate={(isAttending) => {
                              setSessionNotes((prev: any) => {
                                return produce(prev, (draft) => {
                                  draft[index]._isAttending = isAttending
                                })
                              })
                            }}
                            onDelete={() => {
                              if (note.id) setDeletedNoteIds((prev) => [...prev, note.id])

                              setSessionNotes((prev: any) => {
                                return produce(prev, (draft) => {
                                  draft.splice(index, 1)
                                })
                              })
                            }}
                          />
                        )
                      })}

                      <div>
                        {isEditable && (
                          <Button label="Add Clients" size={200} glyph="add" type="primary" onClick={() => setQuickAddOpen(true)} />
                        )}
                      </div>
                    </div>
                  </Accordions>
                )}
              </Section>
            </div>
          </Form>
        </Overlay.Content>

        {!isBehave && !isManagement && (initialModel?.status === 'signed_off' || initialModel?.status === 'closed') ? null : (
          <Overlay.Footer>
            {isEditable && (
              <>
                <Button
                  label="Save"
                  glyph="check"
                  type="primary"
                  color="green"
                  isLoading={isSaving}
                  onClick={save}
                  flex="100 1 auto"
                  permission={isNew ? 'clinical_notes.create' : 'clinical_notes.edit'}
                />

                {isInvalid && (
                  <Button
                    color="orange"
                    type="default"
                    glyph="view"
                    label="Highlight Required Fields"
                    isLoading={isSaving}
                    className="application-form-button"
                    onClick={() => {
                      form.current?.highlightInvalid()
                    }}
                  />
                )}

                {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
              </>
            )}

            {!isEditable && (
              <>
                {isManagement || isBehave ? (
                  <Button
                    glyph="edit"
                    label="Edit Clinical Note"
                    testKey="edit_clinical_note_button"
                    type="default"
                    isDisabled={isLoading}
                    onClick={edit}
                    flex="4 1 auto"
                    permission="clinical_notes.edit"
                  />
                ) : (
                  initialModel?.status !== 'signed_off' &&
                  initialModel?.status !== 'closed' && (
                    <Button
                      glyph="edit"
                      label="Edit Clinical Note"
                      testKey="edit_clinical_note_button"
                      type="default"
                      isDisabled={isLoading}
                      onClick={edit}
                      flex="4 1 auto"
                      permission="clinical_notes.edit"
                    />
                  )
                )}

                {requireSupervisor &&
                  !isSupervisor &&
                  initialModel?.status !== 'pending_review' &&
                  initialModel?.status !== 'signed_off' &&
                  initialModel?.status !== 'closed' && (
                    <Button
                      label="Send for Review"
                      type="primary"
                      color="green"
                      glyph="check"
                      isDisabled={isUpdating || isInvalid}
                      onClick={sendForReview}
                      flex="1 1 auto"
                      permission="clinical_notes.edit"
                    />
                  )}

                {requireSupervisor && isSupervisor && initialModel?.status !== 'signed_off' && initialModel?.status !== 'closed' && (
                  <>
                    {initialModel?.status === 'pending_review' && (
                      <TextareaDialog
                        model="rejected_reason"
                        title="What updates should be made?"
                        onYes={requestUpdates}
                        yesLabel="Ask For Updates"
                        permission="clinical_notes.edit"
                      >
                        <Button
                          fullWidth
                          label="Request Updates…"
                          glyph="note"
                          type="negative"
                          isDisabled={isUpdating}
                          permission="clinical_notes.edit"
                        />
                      </TextareaDialog>
                    )}

                    <SignatureDialog
                      model="supervisor"
                      title="Sign Off as Supervisor"
                      yesLabel="Apply Signature & Sign Off"
                      onYes={signOff}
                      permission="clinical_notes.edit"
                    >
                      <Button
                        fullWidth
                        label="Sign Off as Supervisor…"
                        glyph="signature"
                        type="primary"
                        color="green"
                        isDisabled={isUpdating || isInvalid}
                        permission="clinical_notes.edit"
                      />
                    </SignatureDialog>
                  </>
                )}

                {!requireSupervisor && initialModel?.status !== 'signed_off' && initialModel?.status !== 'closed' && (
                  <ConfirmDialog
                    title="Close Clinical Note?"
                    message="Are you sure you want to close the Clinical Note?"
                    yesColor="green"
                    onYes={closeOff}
                    permission="clinical_notes.edit"
                    isValid={!isInvalid}
                  >
                    <Button
                      fullWidth
                      label="Close Clinical Note…"
                      glyph="close"
                      color="green"
                      type="primary"
                      isLoading={isClosingClinicalNote}
                      permission="clinical_notes.edit"
                      isDisabled={isInvalid}
                    />
                  </ConfirmDialog>
                )}

                <DeleteDialog
                  title="Delete Clinical Note?"
                  message="Are you sure you want to delete this clinical note? This action cannot be undone."
                  onYes={deleteRecord}
                  permission="clinical_notes.delete"
                >
                  <Button
                    glyph="delete"
                    label="Delete"
                    testKey="delete_clinical_note_button"
                    type="default"
                    color="red"
                    isLoading={isDeleting}
                    fullWidth
                    permission="clinical_notes.delete"
                  />
                </DeleteDialog>
              </>
            )}
          </Overlay.Footer>
        )}
      </Overlay>
    </>
  )
}

const dropdownItemStyles = {
  ...styles(COLORS.blue),
  display: 'flex !important',
  paddingRight: '1.5rem !important',
}

export const NewClinicalNoteGroupOverlay = withOverlayError(RootNewClinicalNoteGroupOverlay)
