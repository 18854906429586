import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ProgramAssignmentsReportDataTable } from '@behavehealth/constructs/LiveReports/ProgramAssignmentsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ProgramAssignments = () => {
  const tableProps = useDataTable({
    name: ['reports', 'program-assignments'],
    endpoint: `/live_reports?category=program_assignments`,
    localStorageKey: 'report_program_assignments_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      const clientLink = getClientLink(rowData.reference)

      if (clientLink) return `${clientLink}/programs/${rowData.id}`
    },
    [],
  )

  return (
    <Page feature="programs" title="Live Program Assignments Report">
      <Grid>
        <ProgramAssignmentsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ProgramAssignments)
