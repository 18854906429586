import React from 'react'

import Card from '@behavehealth/components/Card'
import Grid from '@behavehealth/components/Grid'

import ClaimFinancialStats from '@behavehealth/components/RCM/ClaimFinancialStats'
import ClaimPaymentsTable from '@behavehealth/components/RCM/ClaimPaymentsTable'

const Posting = () => {
  return (
    <Grid gap="1rem">
      <ClaimFinancialStats />

      <Card>
        <ClaimPaymentsTable />
      </Card>
    </Grid>
  )
}

export default Posting
