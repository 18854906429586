import React from 'react'
import { Link } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Button from '../../components/Button'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { FILTERS } from '../Filters/config'

export const CustomNotesDataTable = (props: any) => {
  const { to, title = 'Custom Notes', icon = 'custom_notes', duplicateLink, duplicatePermission, duplicateLinkPropsV6, mainLinkAs } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={mainLinkAs} id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: true,
        tagSmartCategories: 'custom_notes',
      },
      {
        width: 200,
        model: 'started_at',
        title: 'Start Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'ended_at',
        title: 'End Date and Time',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 220,
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
      ...(duplicateLinkPropsV6
        ? [
            {
              width: 130,
              id: 'duplicate',
              model: 'id',
              hoverExpand: false,
              disableSort: true,
              title: 'Actions',
              formatValue: ({ data }: any) => {
                return (
                  <Button
                    as={Link}
                    label="Duplicate"
                    glyph="add"
                    size={100}
                    {...duplicateLinkPropsV6(data)}
                    permission={duplicatePermission}
                  />
                )
              },
            },
          ]
        : []),
      ...(duplicateLink
        ? [
            {
              width: 130,
              id: 'duplicate',
              model: 'id',
              hoverExpand: false,
              disableSort: true,
              title: 'Actions',
              formatValue: ({ data }: any) => {
                return <Button label="Duplicate" glyph="add" size={100} link={duplicateLink(data)} permission={duplicatePermission} />
              },
            },
          ]
        : []),
    ]
  }, [to])

  return (
    <DataTable
      asCard
      testKey="custom_notes_data_table"
      title={title}
      icon={icon}
      columns={columns}
      filtersConfig={FILTERS.custom_notes}
      {...props}
    />
  )
}
