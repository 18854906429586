// import './utils/wdyr'

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

// Import root app
import Entry from './scenes/index'

import { store } from '@behavehealth/setup/store'

// Vendors
import ThirdParty from '@behavehealth/modules/third_party'
import Analytics from '@behavehealth/modules/analytics'

ThirdParty.initialize({
  version: process.env.BH_VERSION,
  logrocket: {
    id: process.env.BH_LOGROCKET,
    url: process.env.BH_LOGROCKET_URL,
  },
  openreplay: {
    id: process.env.BH_OPENREPLAY,
    url: process.env.BH_OPENREPLAY_URL,
  },
  posthog: {
    id: process.env.BH_POSTHOG_KEY,
    url: process.env.BH_POSTHOG_HOST,
  },
  userflow: process.env.BH_USERFLOW,
  announcekit: process.env.BH_ANNOUNCEKIT,
  sentry: process.env.BH_SENTRY_DSN,
  helpscout: process.env.BH_HELPSCOUT,
})

Analytics.initialize({
  key: process.env.BH_POSTHOG_KEY,
  host: process.env.BH_POSTHOG_HOST,
})

// Mocking
// if (process.env.BH_ENV !== 'production' && process.env.BH_MOCKING === 'true') {
//   startMockingServer()
// }

console.debug('Version: ', process.env.BH_VERSION)

const MOUNT_NODE = document.getElementById('app')

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <CompatRouter>
          <Entry />
        </CompatRouter>
      </BrowserRouter>

      {/* <ConnectedRouter history={history}>
        <Entry />
      </ConnectedRouter> */}
    </Provider>,
    MOUNT_NODE,
  )
}

render()
