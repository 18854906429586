import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { COLORS, SHADOW } from '../../theme'
import { mapToArray } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { AppSidebarContent } from './AppSidebarContent'
import { AppSidebarHeader } from './AppSidebarHeader'
import { AppSidebarView } from './AppSidebarView'

import { usePinsStore } from './pinsStore'
import { Pin } from './Pin'
import Alert from '../Alert'

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const [removed] = list.splice(startIndex, 1)
  list.splice(endIndex, 0, removed)

  return list
}

const PinsView: React.FC<any> = () => {
  const { tenant } = useSettings()
  const pins = usePinsStore((state: any) => state.pins)
  const setPins = usePinsStore((state: any) => state.setPins)

  const tenantPins = mapToArray(pins?.[tenant.subdomain])

  const onDragEnd = (result: any) => {
    if (!result.destination) return // dragged outside the list

    const entries = Object.entries(pins?.[tenant.subdomain])

    const reordered = reorder(entries, result.source.index, result.destination.index)
    const newPins: any = {}

    for (const item of reordered) {
      newPins[item[0]] = item[1]
    }

    setPins(tenant.subdomain, newPins)
  }

  return (
    <AppSidebarView>
      <AppSidebarHeader title="Pins" icon="pins" />

      <AppSidebarContent css={styles.content}>
        <div className="p-4">
          <Alert small contrast glyph="info">
            <b>Please note: </b> pins are only saved locally in your browser. Using a different browser, incognito mode, or clearing your
            browser's cache will make the previously saved pins unavailable.
          </Alert>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="table_columns_reorder">
            {(droppableProvided: any) => (
              <div ref={droppableProvided.innerRef}>
                {tenantPins.map((pin: any, index: number) => (
                  <Draggable key={pin.id} draggableId={pin.id} index={index}>
                    {(draggableProvided: any, draggableSnapshot: any) => (
                      <div
                        key={pin.id}
                        ref={draggableProvided.innerRef}
                        css={styles.item}
                        style={draggableProvided.draggableProps.style}
                        className={draggableSnapshot.isDragging ? 'is-dragging' : ''}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <Pin data={pin} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </AppSidebarContent>
    </AppSidebarView>
  )
}

const styles = {
  content: {
    padding: 0,
  },

  item: {
    '&.is-dragging': {
      borderRadius: 4,
      background: COLORS.white,
      boxShadow: SHADOW(3, COLORS.divider),
    },
  },
}

export default PinsView
