import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { UATestsReportDataTable } from '@behavehealth/constructs/LiveReports/UATestsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const UATests = () => {
  const tableProps = useDataTable({
    name: ['reports', 'ua-tests'],
    endpoint: `/live_reports?category=ua_tests`,
    localStorageKey: 'report_ua_tests_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.reference)}/test-results/ua-tests/${rowData.id}`, [])

  return (
    <Page feature="test_results" title="Live UA Tests Report">
      <Grid>
        <UATestsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(UATests)
