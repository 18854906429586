import React from 'react'
import { usDate, usDateTime } from '../../../utils/functions'

import Glyph from '../../Glyph'
import Markup from '../../Markup'

import { AddressCell } from '../cells/AddressCell'
import { CheckboxCell } from '../cells/CheckboxCell'
import { DateTimeCell } from '../cells/DateTimeCell'
import { InputCell } from '../cells/InputCell'
import { MultiObjectSelectorCell } from '../cells/MultiObjectSelectorCell'
import { MultiSelectCell } from '../cells/MultiSelectCell'
import { NumberCell } from '../cells/NumberCell'
import { ObjectSelectorCell } from '../cells/ObjectSelectorCell'
import { RichTextCell } from '../cells/RichTextCell'
import { SelectCell } from '../cells/SelectCell'
import { SignatureCell } from '../cells/SignatureCell'
import { TextareaCell } from '../cells/TextareaCell'

export const DEBUG = false

export const COUNT_COLUMN_WIDTH = 32
export const DEFAULT_DEBOUNCE = 150
export const ROW_MIN_HEIGHT = 28

export const COLUMNS_CONFIG: any = {
  default: {
    width: 200,
    component: InputCell,
  },
  address: {
    width: 180,
    component: AddressCell,
  },
  select: {
    width: 180,
    component: SelectCell,
  },
  date_time: {
    width: 180,
    formatDisplayValue: ({ value, timezone }: any) => usDateTime(value, timezone),
    component: DateTimeCell,
  },
  date: {
    width: 180,
    formatDisplayValue: ({ value, timezone }: any) => usDate(value, timezone),
  },
  number: {
    width: 100,
    component: NumberCell,
  },
  object_selector: {
    width: 200,
    component: ObjectSelectorCell,
  },
  textarea: {
    width: 300,
    component: TextareaCell,
  },
  input: {
    width: 200,
    component: InputCell,
  },
  multi_object_selector: {
    width: 200,
    component: MultiObjectSelectorCell,
  },
  multi_select: {
    width: 200,
    component: MultiSelectCell,
  },
  checkbox: {
    width: 80,
    component: CheckboxCell,
    formatDisplayValue: ({ value }: any) => {
      return <Glyph glyph={value ? 'check' : 'cross'} size={14} className="mx-auto" />
    },
  },
  rich_text: {
    width: 300,
    component: RichTextCell,
    formatDisplayValue: ({ value }: any) => {
      return <Markup value={value} />
    },
  },
  signature: {
    width: 260,
    component: SignatureCell,
  },
}
