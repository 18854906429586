import React from 'react'

import Alert from '../../components/Alert'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'
import FormSection from '../../components/Forms/FormSection'

export const CustomNoteUpdatesRequestedSection = (props: any) => {
  const { data, isEditable, isSupervisor } = props

  if (!data || !data?.request_updates) return null

  const isFinalStatus = data?.status === 'closed' || data?.status === 'signed_off'

  if (isFinalStatus) return null

  return (
    <>
      <Section className="">
        <Alert small contrast type="negative" glyph="circle_error">
          <FormSection maxWidth="100%">
            {isEditable && isSupervisor ? (
              <Textarea withHover={false} label="Requested Updates" model="request_updates" />
            ) : (
              data?.request_updates
            )}
          </FormSection>
        </Alert>
      </Section>
    </>
  )
}
