import React from 'react'
import isEqual from 'lodash/isEqual'
import produce from 'immer'
import size from 'lodash/size'

import { useGet } from '../../../hooks/useNewAPI'
import Glyph from '../../../components/Glyph'
import Badge from '../../Badge'
import Flex from '../../Flex'
import Button from '../../Button'
import State from '../../State'

import { FilterDropdown } from './FilterDropdown'
import { FilterDropdownGroup } from '../filters/FilterDropdownGroup'
import { PopoverItem } from './PopoverItem'

import { useStore } from '../useStore'

export const TagsFilter = (props: any) => {
  const { config, onUpdate, filter, filterKey } = props

  const [isOpen, setIsOpen] = React.useState(false)
  const [tagIds, setTagsIds]: any = React.useState(filter?.value || [])

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const { data, isLoading }: any = useGet({
    name: ['tag-groups'],
    url: `/tag_groups`,
  })

  const isEmpty = size(data) === 0

  const activeTags = React.useMemo(() => {
    const result: any = []
    const tagsMap: any = {}

    if (size(filter?.value) === 0 || size(data) === 0) return result

    for (const tagGroup of data) {
      if (!tagGroup.tags) continue

      for (const tag of tagGroup.tags) {
        tagsMap[tag.id] = tag
      }
    }

    for (const id of filter.value) {
      if (!tagsMap?.[id]) continue

      result.push(tagsMap[id])
    }

    return result
  }, [data, filter])

  const activeTagsCount = size(activeTags)
  const hasActiveTags = activeTagsCount >= 1

  const handleApply = () => {
    onUpdate?.({ condition: 'in', value: tagIds, model: config?.model })
    setIsOpen(false)
  }

  const handleClear = () => {
    setTagsIds([])
    removeFilter(filterKey)
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (!isEqual(tagIds, filter?.value)) setTagsIds(filter?.value || [])
  }, [filter?.value])

  return (
    <>
      <FilterDropdown
        open={isOpen}
        onOpenChange={(newIsOpen: boolean) => {
          // apply filter when dropdown is closed and values are different
          if (!newIsOpen && !isEqual(tagIds, filter?.value)) {
            onUpdate?.({ condition: 'in', value: tagIds, model: config?.model })
          }

          if (size(tagIds) === 0) removeFilter(filterKey)

          setIsOpen(newIsOpen)
        }}
        testKey="tags_filter_dropdown"
        isEmpty={isEmpty}
        isLoading={isLoading}
        hasActiveFilters={hasActiveTags}
        label={
          activeTagsCount ? (
            <>
              <Badge size={16} children={activeTagsCount} className="mr-1" /> selected
            </>
          ) : (
            'Select…'
          )
        }
      >
        {!data || isLoading || isEmpty ? (
          <State title="Tags" isEmpty={isEmpty} isLoading={isLoading} emptyDescription="No tags created yet" />
        ) : (
          data?.map((tagGroup: any) => {
            if (size(tagGroup?.tags) === 0) return null

            return (
              <FilterDropdownGroup key={tagGroup.id} label={tagGroup.name}>
                {tagGroup.tags.map((tag: any) => (
                  <PopoverItem
                    key={tag.id}
                    title={tag.name}
                    graphic={<Glyph glyph={tag.glyph} size={14} color={tag.color} />}
                    isActive={tagIds.includes(tag.id)}
                    onClick={() => {
                      setTagsIds((currentValue: any = {}) => {
                        return produce(currentValue, (draft: any) => {
                          if (draft.includes(tag.id)) {
                            const index = draft.indexOf(tag.id)
                            draft.splice(index, 1)
                            return
                          }

                          draft.push(tag.id)
                        })
                      })
                    }}
                  />
                ))}
              </FilterDropdownGroup>
            )
          })
        )}

        {size(data) > 0 && (
          <div className="flex items-center px-2 py-2 sticky bottom-0 bg-white border-t border-0 border-solid border-divider overflow-hidden">
            <Button
              label="Apply"
              glyph="tick_circle"
              color="green"
              type="primary"
              size={100}
              className="flex-grow-3"
              onClick={handleApply}
            />
            <Button
              label="Clear All"
              glyph="cross"
              color="red"
              size={100}
              className="flex-grow-0 ml-2"
              isDisabled={size(tagIds) === 0}
              onClick={handleClear}
            />
          </div>
        )}
      </FilterDropdown>
    </>
  )
}
