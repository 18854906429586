import React from 'react'
import produce from 'immer'
import size from 'lodash/size'

import Accordion from './Accordion'
import AccordionCard from './AccordionCard'
import Button from './Button'
import Flex from './Flex'

import { AccordionsContext } from './AccordionsContext'

const Accordions: any = ({ children, showToggle = true, header, headerClassName }: any) => {
  const allItems: any = React.useRef([])

  const [openItems, setOpenItems]: any = React.useState([])
  const isSomeOpen = size(openItems) >= 1

  const register = (name: string) => {
    allItems.current.push(name)
  }

  const open = (name: string) => {
    setOpenItems((c: any) => [...c, name])
  }

  const close = (name: string) => {
    setOpenItems(
      produce(openItems, (draft: any) => {
        const index = openItems.indexOf(name)
        draft.splice(index, 1)
      }),
    )
  }

  const toggleAll = () => {
    if (isSomeOpen) {
      setOpenItems([])
    } else {
      setOpenItems(allItems.current)
    }
  }

  const value = {
    allItems: allItems.current,
    openItems,
    register,
    open,
    close,
  }

  return (
    <AccordionsContext.Provider value={value}>
      {showToggle && (
        <Flex alignItems="center" className={headerClassName}>
          {header}

          <Button
            onClick={toggleAll}
            css={styles.toggleButton}
            label={isSomeOpen ? 'Close All' : 'Open All'}
            glyph={isSomeOpen ? 'close_all_rows' : 'open_all_rows'}
            display="inline-flex"
            type="link"
            size={100}
          />
        </Flex>
      )}

      {children}
    </AccordionsContext.Provider>
  )
}

const AccordionItem: React.FC<any> = (props) => {
  const context: any = React.useContext(AccordionsContext)

  React.useEffect(() => {
    // open default accordions
    if (!props.isOpen) return
    context.open(props.identifier || props.title)
  }, [])

  const isOpen = context.openItems.includes(props.identifier || props.title)

  const handleToggle = () => {
    if (isOpen) {
      context.close(props.identifier || props.title)
    } else {
      context.open(props.identifier || props.title)
    }
  }

  return <Accordion {...props} isOpen={isOpen} onToggle={handleToggle} />
}

const AccordionItemCard: React.FC<any> = (props) => {
  const context: any = React.useContext(AccordionsContext)

  React.useEffect(() => {
    // open default accordions
    if (!props.isOpen) return
    context.open(props.identifier || props.title)
  }, [])

  const isOpen = context.openItems.includes(props.identifier || props.title)

  const handleToggle = () => {
    if (isOpen) {
      context.close(props.identifier || props.title)
    } else {
      context.open(props.identifier || props.title)
    }
  }

  return <AccordionCard {...props} isOpen={isOpen} onToggle={handleToggle} />
}

Accordions.Item = AccordionItem
Accordions.Item.displayName = 'AccordionItem'

Accordions.ItemCard = AccordionItemCard
Accordions.ItemCard.displayName = 'AccordionItemCard'

const styles = {
  toggleButton: {
    marginLeft: 'auto',
    paddingRight: 0,
  },
}

export default Accordions
