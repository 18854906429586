import React from 'react'
import { Route as RouteV6, Routes, NavLink } from 'react-router-dom-v5-compat'
import { Route, Redirect, Switch, useRouteMatch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useMedia } from 'use-media'
import compact from 'lodash/compact'
import size from 'lodash/size'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

import { BREAKPOINT } from '@behavehealth/theme'
import { clearData, updateData } from '@behavehealth/actions/data'
import { store } from '@behavehealth/setup/store'
import { usDate, fullname, age } from '@behavehealth/utils/functions'
import { useGet, useCreate, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import Notifications from '@behavehealth/modules/notifications'
import useStore from '@behavehealth/modules/store'
import withBackListener from '@behavehealth/hocs/withBackListener'

import ClientQuickActions from '@behavehealth/components/QuickActions/Client'
import DeleteDialog from '@behavehealth/components/Dialogs/DeleteDialog'
import RequestVOBOverlay from '@behavehealth/components/Overlays/actions/RequestVOBOverlay'

import { HighRiskAlert } from '@behavehealth/constructs/HighRiskAlert/HighRiskAlert'
import { ClientTrackSelector } from '@behavehealth/constructs/ClientTracks/ClientTrackSelector'

import {
  AlumnusNavItems,
  BillingStatus,
  Button,
  ClientPortalStatus,
  ClientStatus,
  ConfirmDialog,
  Divider,
  DropdownItem,
  HelpTagIframe,
  Nav,
  PageGrid,
  PageProfile,
  Status,
  SummonOverlay,
  TagsSelector,
  Text,
} from '@behavehealth/components'

import PageLoader from '@behavehealth/components/Loaders/PageLoader'
import ClientColorPicker from '@behavehealth/components/ColorPickers/ClientColorPicker'
import ERXStatus from '@behavehealth/components/Statuses/ERXStatus'
import ERXDropdownItem from '@behavehealth/components/Buttons/ERXDropdownItem'

import ActivityTimeline from './pages/activity_timeline'
import Agreements from './pages/agreements'
import Applications from './pages/applications'
import Authorizations from './pages/authorizations'
import BillingRosters from './pages/billing_rosters'
import Calendar from './pages/calendar'
import ChargeNotes from './pages/charge_notes'
import Claims from './pages/claims'
import ClientCash from './pages/client_cash'
import ClinicalAssessments from './pages/clinical_assessments'
import ClinicalMeasurements from './pages/clinical_measurements'
import ClinicalNotes from './pages/clinical_notes'
import Connections from './pages/connections'
import Contacts from './pages/contacts'
import Communications from './pages/communications'
import CustomNotes from './pages/custom_notes'
import PeerNotes from './pages/peer_notes'
import DailyNotes from './pages/daily_notes'
import ClientPortal from './pages/client_portal'
import DataExports from './pages/data_exports'
import Diagnoses from './pages/diagnoses'
import Discharges from './pages/discharges'
import Files from './pages/files'
import Financials from './pages/financials'
import GeneralInfo from './pages/general_info'
import Insurance from './pages/insurance'
import InsuranceCharges from './pages/insurance_charges'
import InsuranceClaims from './pages/insurance_claims'
import IntakeForms from './pages/intake_forms'
import LocationCheckIn from './pages/location_checkin'
import MedicalAssessments from './pages/medical_assessments'
import Medications from './pages/medications'
import Nursing from './pages/nursing'
import OutcomeMeasures from './pages/outcome_measures'
import PhysicianOrders from './pages/physician_orders'
import Programs from './pages/programs'
import ProgressNotes from './pages/progress_notes'
import ProgressReviews from './pages/progress_reviews'
import RCM from './pages/rcm/rcm'
import RecoveryCoaching from './pages/recovery_coaching'
import ShiftNotes from './pages/shift_notes'
import TestResults from './pages/test_results'
import Todos from './pages/todos/todos'
import TreatmentEpisodes from './pages/treatment_episodes'
import TreatmentPlans from './pages/treatment_plans'
import TreatmentPlansV2 from './pages/treatment_plans_v2/treatment_plans'
import VOB from './pages/vob'
import Allergies from './pages/allergies'
import Occupancies from './pages/occupancies'
import { ClientJourneyPage } from './pages/client_journey'
import { ContactsFormSubmissions } from './pages/contacts_form_submissions'
import FormSubmissions from './pages/form_submissions'
import { GrievanceNotes } from './pages/grievance_notes'
import { ProtectedRoute } from '@behavehealth/components/ProtectedRoute'
import ServiceEpisodes from './pages/service_episodes'
import SafetyChecks from './pages/safety_checks'
import RoundLines from './pages/round_lines'

import { useTreatmentPlanPermissions } from '@behavehealth/constructs/TreatmentPlans/useTreatmentPlanPermissions'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'

const Alumnus = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const params: any = useParams()

  const tenant: any = useStore((state) => state.tenant)
  const { isBehave, isManagement, timezone } = useSettings()

  const isDesktop = useMedia({ minWidth: BREAKPOINT[3] })

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  const { data: client, isLoading, isError, error }: any = useGet({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
  })

  const { data: admissions, isError: isAlumniError, error: alumniError }: any = useGet({
    name: ['admissions', params.client_id],
    url: `/residents/${params.client_id}/admissions`,
  })

  const { mutateAsync: updateClient }: any = useUpdate({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
    invalidate: ['clients'],
  })

  const { mutateAsync: deleteClientAsync }: any = useDelete({
    name: ['client', params.client_id],
    url: `/residents/${params.client_id}`,
    invalidate: ['clients'],
  })

  const moveToAdmissionsMutation: any = useCreate({
    name: ['client', params.client_id, 'move_to_admissions'],
    url: `/residents/${params.client_id}/move_to_admissions`,
    invalidate: 'client',
    invalidateKeys: ['clients', 'admissions', 'intake_applications'],
  })

  const isTenantBilledByBehave = tenant?.is_billed
  const isTrialing = tenant?.plan_status === 'trialing'
  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const showERXFeatures = tenant?.feature_flags?.erx === true

  const deleteClient = async () => {
    await deleteClientAsync()
    history.push(`/admissions/applicants`)
  }

  const moveToAdmissions = async () => {
    try {
      await moveToAdmissionsMutation.mutateAsync()

      history.push(`/admissions/${params.client_id}/general-info`)
    } catch (errors) {
      console.error(errors)
    }
  }

  const lastDischargeDate = React.useMemo(() => {
    if (size(admissions) === 0) return null

    const dischargeDates = []

    for (const admission of admissions) {
      if (admission?.discharged_at) dischargeDates.push(admission.discharged_at)
    }

    const sorted = sortBy(dischargeDates)

    return sorted[sorted.length - 1]
  }, [admissions])

  React.useEffect(() => {
    if (!isError && !isAlumniError) return

    console.error(error)
    Notifications.send(error?.error?.data?.errors?.[0], 'negative')
    Notifications.send(error?.data?.errors?.[0], 'negative')

    // go back to the Clients list if unauthorized
    if (error?.error?.status === 401) history.push('/alumni')
    if (alumniError?.error?.status === 401) history.push('/alumni')

    // go back to the Clients list if unauthorized
    if (error?.status === 404) history.push('/alumni')
  }, [isError, isAlumniError])

  React.useEffect(() => {
    store.dispatch(updateData(`clients.data.${client?.id}`, client))
    store.dispatch(updateData(`admissions.data`, admissions))

    return () => {
      store.dispatch(clearData('clients'))
      store.dispatch(clearData('admissions'))
    }
  }, [client, admissions])

  if (!client || isLoading) return <PageLoader />

  const actions = (
    <>
      <ClientQuickActions data={client} />

      <Divider />

      {showERXFeatures && (
        <ERXDropdownItem isDisabled={client.erx_status !== 'synced'} rcopiaID={client.erx_id} rcopiaExternalID={client.external_id} />
      )}

      <Divider />

      {(!client?.client_portal_status || client?.client_portal_status === 'invited') && (
        <DropdownItem
          label={!client?.client_portal_status ? 'Invite to Client Portal' : 'Resend Client Portal Invite'}
          glyph="portal"
          color="blue"
          link={{
            pathname: `${location.pathname}/invite-to-portal`,
            parent: location.pathname,
          }}
          featureFlag="client_portal"
          permission="clients.actions.invite_to_client_portal"
          featureFlagV2="client_portal"
        />
      )}

      <Divider />

      <DropdownItem
        label="Add High-Risk Alert"
        glyph="warning"
        glyphColor="red"
        color="red"
        link={{
          pathname: `${location.pathname}/high-risk-alert`,
          parent: location.pathname,
        }}
        featureFlagV2="risk_alerts"
      />

      <DeleteDialog
        title="Delete Past Client?"
        message="Are you sure you want to delete this Past Client? This action cannot be undone."
        onYes={deleteClient}
        permission="client_journey.actions.delete"
      >
        <DropdownItem label="Delete Past Client…" glyph="delete" color="red" permission="client_journey.actions.delete" />
      </DeleteDialog>

      {!isTrialing && (
        <>
          <Divider />

          <DropdownItem
            label="Export Data…"
            glyph="long_arrow_right"
            color="gray"
            link={`/alumni/${match.params?.client_id}/data-exports`}
            permission="clients.actions.export"
            featureFlagV2="data_export"
          />
        </>
      )}
    </>
  )

  return (
    <PageGrid columns="320px 1fr" breakpoint={3}>
      <Nav
        showBack
        breakpoint={3}
        headingSize={400}
        record={client}
        title={client.name}
        avatar={client.avatar}
        description={`${startCase(client.sex) || '–'}, #${client.behave_id}`}
        desktopProps={{
          icon: 'clients',
          title: 'Client',
          headingSize: 300,
          avatar: undefined,
          description: undefined,
        }}
        help={<HelpTagIframe id="alumnus_profile" />}
        profile={
          <PageProfile
            more
            record={client}
            actions={actions}
            title={fullname(client)}
            subtitle={
              <>
                {client?.current_bed_occupancy && (
                  <Text
                    description={compact([
                      client.current_bed_occupancy.place?.house?.name,
                      client.current_bed_occupancy.place?.room?.name,
                    ]).join(', ')}
                    avatar={client.current_bed_occupancy.place?.house?.avatar}
                  />
                )}

                {client?.dob && (
                  <div>
                    <b>DOB:</b> {usDate(client.dob, timezone)} ({age(client.dob)} y/o)
                  </div>
                )}

                {client?.admitted_at && (
                  <div>
                    <b>Admitted:</b> {usDate(client.admitted_at, timezone)}
                  </div>
                )}

                {lastDischargeDate && (
                  <div>
                    <b>Discharged:</b> {usDate(lastDischargeDate, timezone)}
                  </div>
                )}
              </>
            }
            description={`${startCase(client.sex) || '–'}, #${client.behave_id}`}
            content={
              <>
                <ConfirmDialog
                  title="Move to Admissions?"
                  message="Are you sure you want to move this Past Client to Admissions?"
                  yesColor="green"
                  onYes={moveToAdmissions}
                >
                  <Button
                    type="primary"
                    label="Move to Admissions"
                    glyph="check_in"
                    color="yellow"
                    permission="client_journey.actions.move_to_admissions"
                  />
                </ConfirmDialog>

                <Button
                  type="primary"
                  label="Re-Admit…"
                  glyph="readmit"
                  color="green"
                  link={`${location.pathname}/readmit-client`}
                  permission="client_journey.actions.readmit"
                />
              </>
            }
            underActions={
              <>
                {isTenantBilledByBehave && (
                  <>
                    <SummonOverlay overlay={<RequestVOBOverlay client={client} />}>
                      <Button label="Request Full VOB" glyph="behave_health" color="green" featureFlagV2="full_vobs" />
                    </SummonOverlay>
                  </>
                )}

                <div className="!mt-3 w-full">
                  <TagsSelector
                    tags={client?.tags}
                    onSave={(tagIDs: any) => updateClient({ tag_ids: tagIDs })}
                    smartCategories="past_clients"
                  />
                </div>
              </>
            }
            status={
              <>
                {isDesktop && <HighRiskAlert isRounded title={client?.high_risk_title} notes={client?.high_risk_notes} />}

                {client?.id && (
                  <div className="w-full pb-3">
                    <ClientTrackSelector clientId={client.id} activeTrack={client.client_track} />
                  </div>
                )}

                <ClientColorPicker clientID={client?.id} color={client?.color_code} />
                <ClientStatus status={client?.status} />
                {client?.active_level_of_care && <Status color="green" label={client?.active_level_of_care?.name} />}
                {client?.is_billed_by_behave && <BillingStatus status="billed" small />}
                {client?.client_portal_status && <ClientPortalStatus status={client?.client_portal_status} />}
                {showERXFeatures && client?.erx_status && <ERXStatus status={client?.erx_status} />}
              </>
            }
          />
        }
      >
        <AlumnusNavItems id={match.params?.client_id} />
      </Nav>

      {!isDesktop && <HighRiskAlert title={client?.high_risk_title} notes={client?.high_risk_notes} />}

      <Switch>
        <ProtectedRoute
          path={`/alumni/:resource_id/activity-timeline`}
          children={<ActivityTimeline />}
          featureFlagV2="activity_timeline"
          permission="activity_timeline.view"
        />
        <Route path={`/alumni/:resource_id/general-info`} children={<GeneralInfo />} />
        <Route path={`/alumni/:resource_id/treatment-episodes`} component={TreatmentEpisodes} />
        <ProtectedRoute
          path={`/alumni/:resource_id/service-episodes`}
          component={ServiceEpisodes}
          featureFlagV2="service_episodes"
          permission="service_episodes.view"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/authorizations`}
          component={Authorizations}
          permission={isBehave ? true : 'insurance_authorizations.view'}
          featureFlagV2="insurance_authorizations"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/todo`}
          children={<Todos canCreate={false} />}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <ProtectedRoute path={`/alumni/:resource_id/files`} children={<Files />} permission="clients.files.view" featureFlagV2="files" />
        <ProtectedRoute
          path={`/alumni/:resource_id/safety-checks`}
          component={SafetyChecks}
          permission="safety_checks.view"
          featureFlag="safety_checks"
        />
        <ProtectedRoute path={`/alumni/:resource_id/rounds`} component={RoundLines} permission="rounds.view" featureFlag="rounds" />
        <ProtectedRoute
          path={`/alumni/:resource_id/shift-notes`}
          children={<ShiftNotes canCreate={false} />}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <ProtectedRoute path={`/alumni/:resource_id/programs`} component={Programs} permission="programs.view" featureFlagV2="programs" />
        <ProtectedRoute
          path={`/alumni/:resource_id/recovery-coaching`}
          children={<RecoveryCoaching canCreate={false} />}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/location-checkin`}
          component={LocationCheckIn}
          permission="location_checkin.view"
          featureFlagV2="location_gps_check_in"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/peer-notes`}
          component={PeerNotes}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/daily-notes`}
          component={DailyNotes}
          permission="daily_notes.view"
          featureFlagV2="daily_notes"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/agreements`}
          children={<Agreements canCreate={false} />}
          permission="agreements.view"
          featureFlagV2="client_agreements"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/calendar`}
          children={<Calendar canCreate={false} />}
          permission="events.view"
          featureFlagV2="calendar"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/custom-notes`}
          component={CustomNotes}
          permission="client_custom_notes.view"
          featureFlagV2="client_custom_notes"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/grievance-notes`}
          component={GrievanceNotes}
          permission={isBehave || isManagement}
          featureFlag="grievance_notes"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/client-portal`}
          component={ClientPortal}
          featureFlagV2="client_portal"
          permission="clients.actions.invite_to_client_portal"
        />
        <ProtectedRoute path={`/alumni/:resource_id/occupancies`} component={Occupancies} permission="bed_management.view" />
        <ProtectedRoute
          path={`/alumni/:resource_id/client-journey`}
          component={ClientJourneyPage}
          permission="client_journey.view"
          featureFlagV2="client_journey"
        />
        <Route path={`/alumni/:resource_id/contacts-form-submissions`} component={ContactsFormSubmissions} />
        <ProtectedRoute path={`/alumni/:resource_id/form-submissions`} featureFlagV2="form_submissions" permission="form_submissions.view">
          <FormSubmissions reference={client} allowedReference="past_clients" />
        </ProtectedRoute>

        {/* Contacts */}
        <ProtectedRoute
          path={`/alumni/:resource_id/connections`}
          children={<Connections canCreate={false} />}
          featureFlagV2="care_team"
          permission="connections.view"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/contacts`}
          children={<Contacts />}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/communications`}
          children={<Communications />}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />

        {/* Admissions */}
        <ProtectedRoute
          path={`/alumni/:resource_id/applications`}
          component={Applications}
          permission="applications.view"
          featureFlagV2="admissions"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/insurance`}
          children={<Insurance canCreate />}
          permission="insurance_policies.view"
          featureFlagV2="insurance_policies"
        />
        <ProtectedRoute path={`/alumni/:resource_id/vobs`} component={VOB} permission="full_vobs.view" featureFlagV2="full_vobs" />
        <Route path={`/alumni/:resource_id/intake-forms`} component={IntakeForms} />
        <ProtectedRoute
          path={`/alumni/:resource_id/discharges`}
          component={Discharges}
          permission="discharges.view"
          featureFlagV2="discharges"
        />

        {/* Financials */}
        <ProtectedRoute
          path={`/alumni/:resource_id/financials`}
          component={Financials}
          permission="ledger.view"
          featureFlagV2="financials"
        />
        <Route path={`/alumni/:resource_id/charge-notes`} component={ChargeNotes} />
        <Route path={`/alumni/:resource_id/client-cash`} component={ClientCash} />

        {/* Billing */}
        {/* <Route path={`/alumni/:resource_id/rosters`} component={InsuranceRosters} /> */}
        <Route path={`/alumni/:resource_id/claims`} component={InsuranceClaims} />
        <Route path={`/alumni/:resource_id/charges`} component={InsuranceCharges} />
        <Route path={`/alumni/:resource_id/billing-roster`} component={BillingRosters} />
        <Route path={`/alumni/:resource_id/claims`} component={Claims} />

        {/* Clinical */}
        <ProtectedRoute
          path={`/alumni/:resource_id/clinical-assessments`}
          children={<ClinicalAssessments canCreate={false} />}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/diagnoses`}
          component={Diagnoses}
          permission="diagnoses.view"
          featureFlagV2="diagnoses"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/archived-treatment-plans`}
          component={TreatmentPlans}
          permission="treatment_plans.view"
          featureFlagV2="treatment_plans"
        />
        {/* <Route path={`/alumni/:resource_id/treatment-plans`} component={TreatmentPlansV2} /> */}

        <ProtectedRoute
          path={`/alumni/:resource_id/clinical-notes`}
          children={<ClinicalNotes canCreate={false} />}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/outcome-measures`}
          component={OutcomeMeasures}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/clinical-measurements`}
          component={ClinicalMeasurements}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/progress-reviews`}
          children={<ProgressReviews canCreate={false} />}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />

        {/* Medical */}
        <Route path={`/alumni/:resource_id/reported-conditions`} component={Allergies} />
        <ProtectedRoute
          path={`/alumni/:resource_id/medications`}
          component={Medications}
          permission="medications.view"
          featureFlagV2="medications"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/medical-assessments`}
          children={<MedicalAssessments canCreate={false} />}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />
        <ProtectedRoute
          path={`/alumni/:resource_id/nursing`}
          children={<Nursing canCreate={false} />}
          featureFlagV2="nursing"
          permission="nursing.view"
        />

        <Route path={`/alumni/:resource_id/rcm`} component={RCM} />
        <ProtectedRoute
          path={`/alumni/:resource_id/data-exports`}
          component={DataExports}
          permission="clients.actions.export"
          featureFlagV2="data_export"
        />

        <Redirect exact from={match.url} to={`/alumni/${match.params?.client_id}/general-info`} />
      </Switch>

      {/* <Routes>
        <RouteV6 path="/alumni/:resource_id/treatment-plans/*" element={<TreatmentPlansV2 />} />
      </Routes> */}
      <Routes>
        <RouteV6
          path="/alumni/:resource_id/treatment-plans/*"
          element={
            <ProtectedRouteV6 featureFlagV2="treatment_plans" permission={viewSomeTreatmentPlans}>
              <TreatmentPlansV2 />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/alumni/:resource_id/progress-notes/*"
          element={
            <ProtectedRouteV6 featureFlagV2="progress_notes" permission="progress_notes.view">
              <ProgressNotes canCreate={false} />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/alumni/:resource_id/physician-orders/*"
          element={
            <ProtectedRouteV6 featureFlagV2="physician_orders" permission="physician_orders.view">
              <PhysicianOrders canCreate={false} />
            </ProtectedRouteV6>
          }
        />

        <RouteV6
          path="/alumni/:resource_id/test-results/*"
          element={
            <ProtectedRouteV6 featureFlagV2="test_results" permission="test_results">
              <TestResults canCreate={false} />
            </ProtectedRouteV6>
          }
        />
      </Routes>
    </PageGrid>
  )
}

export default withBackListener(withPageError(Alumnus))
