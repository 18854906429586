import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import size from 'lodash/size'

import { useDelete } from '@behavehealth/hooks/useNewAPI'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import Card from '@behavehealth/components/Card'
import CardContent from '@behavehealth/components/CardContent'
import CardHeader from '@behavehealth/components/CardHeader'
import CardTitle from '@behavehealth/components/CardTitle'
import Icon from '@behavehealth/components/Icon'
import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { MedicationLogsDataTable } from '@behavehealth/constructs/Medications/MedicationLogsDataTable'

const MedicationLogs = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'medication-logs'],
    endpoint: `/residents/${resource_id}/medication_logs`,
    localStorageKey: 'client_medication_logs_v1',
  })

  const { mutate: deleteRecords } = useDelete({
    name: ['client', resource_id, 'meds'],
    url: '/medication_logs',
    invalidate: 'medication-logs',
  })

  const to = React.useCallback(
    (rowData: any) => ({
      pathname: `${match.url}/logs/${rowData.id}`,
      parent: match,
    }),
    [match],
  )

  const isEmpty = size(tableProps.data) === 0

  return (
    <Card>
      <CardHeader
        graphic={<Icon icon="med_pass" size={20} />}
        after={
          canCreate && (
            <Dropdown
              testKey="add_medication_log_table_dropdown"
              label="Add Medication Log…"
              glyph="add"
              buttonType="primary"
              permission="medication_logs.create"
              buttonSize={200}
            >
              <DropdownItem
                testKey="add_medication_log_table_dropdown_item"
                label="Add Medication Log"
                icon="med_pass"
                color="red"
                link={{ pathname: `${match.url}/logs/new`, parent: match }}
              />
              <DropdownItem
                testKey="add_many_medication_log_table_dropdown_item"
                label="Add Many Medication Logs…"
                glyph="stack"
                color="red"
                link={`${match.url}/logs/new-batch`}
                featureFlagV2="bulk_add"
              />
            </Dropdown>
          )
        }
      >
        <CardTitle title="Medication Logs" />
      </CardHeader>

      <CardContent className="!p-0" showDivider={!isEmpty}>
        <MedicationLogsDataTable
          {...tableProps}
          to={to}
          testKey="medication_logs_table"
          asCard={false}
          hiddenColumnIds={['resident']}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/medication-logs',
            },
          ]}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'medication_logs.delete',
              action: async ({ ids }: any) => {
                await deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  )
}

export default MedicationLogs
