import React from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useParams as useParamsV6, useLocation as useLocationV6, useNavigate } from 'react-router-dom-v5-compat'
import compact from 'lodash/compact'
import merge from 'lodash/merge'
import produce from 'immer'

import { fullname, usDateTime } from '../../utils/functions'
import { COLORS } from '../../theme'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { withOverlayError } from '../../hocs/withOverlayError'
import withSettings from '../../hocs/withSettings'

import { Text } from '../../components/Typography'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import DataList from '../../components/DataList'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Workflow from '../../components/Workflow/Workflow'

import Events from './common/Events'
import ProgramMove from './common/ProgramMove'
import Todos from './common/Todos'
import useSettings from '../../hooks/useSettings'

import ServiceEpisodes from './common/ServiceEpisodes'
import Financials from '../BedManagement/common/Financials'
import ProgramStaffAccessLists from './common/ProgramStaffAccessLists'

import { ClientWorkflowSubheader } from './ClientWorkflowSubheader'
import { ContactWorkflowSubheader } from './ContactWorkflowSubheader'
import { StaffWorkflowSubheader } from './StaffWorkflowSubheader'

const stepsReducer = (state: any, payload: any) => {
  const { step, status } = payload
  if (!status || !state.hasOwnProperty(step)) return state

  return produce(state, (draft: any) => {
    draft[step] = status
  })
}

const dataReducer = (state: any, payload: any) => {
  return {
    ...state,
    ...payload,
  }
}

const INITIAL_DATA = {
  cancel_future_charges: false,
}

const INITIAL_STEPS = {
  move_client: 'todo',
  service_episodes: 'todo',
  todos: 'todo',
  events: 'todo',
  financials: 'todo',
  staff_access_lists: 'todo',
  confirm: 'todo',
}

const RootProgramMoveOverlay = ({ initialData, onSaveSuccessful, useV6Router }: any) => {
  const params = useV6Router ? useParamsV6() : useParams()
  const location: any = useV6Router ? useLocationV6() : useLocation()
  const navigate: any = useV6Router ? useNavigate() : useHistory().push

  const { occupancy_id, resource_id: programId }: any = params
  const { timezone, isBehave, isSelfServiceBilling } = useSettings()

  const { data: occupancy, isLoading }: any = useGet({
    name: ['occupancy', occupancy_id],
    url: `/occupancies/${occupancy_id}`,
  })

  const { data: client, isLoading: isLoadingClient }: any = useGet({
    name: ['client', occupancy?.reference?.id],
    url: `/residents/${occupancy?.reference?.id}`,
    options: { enabled: occupancy?.reference?.type === 'resident' },
  })

  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['move-seats'],
    url: `/seats/move`,
    invalidateKeys: ['programs-timeline', 'program-timeline', ['program', programId, 'phases-with-residents']],
  })

  const [steps, setSteps] = React.useReducer(stepsReducer, INITIAL_STEPS)
  const [data, setData] = React.useReducer(dataReducer, INITIAL_DATA)

  const valid = !!(data?.place_id && data?.place)

  const hiddenSteps = React.useMemo(() => {
    if (!occupancy?.reference?.type) return []

    if (occupancy?.reference?.type !== 'resident') {
      return Object.keys(INITIAL_STEPS).filter((step) => step !== 'move_client' && step !== 'confirm')
    }
  }, [occupancy?.reference?.type])

  if (!occupancy || isLoading || isLoadingClient) return <OverlayLoader />

  const { reference } = occupancy

  const close = () => {
    if (location.parent) {
      navigate(location.parent.url)
    } else {
      const path = location.pathname
      const secondLastIndex = path.lastIndexOf('/', path.lastIndexOf('/') - 1)
      navigate(path.substr(0, secondLastIndex))
    }
  }

  const onSave = async () => {
    let accessList = null
    let toAdd: any = []
    let toRemove: any = []

    if (data.staff_access_list) {
      if (data?.staff_access_list?.add) {
        toAdd = Object.entries(data?.staff_access_list?.add).map((arr) => {
          if (arr[1] === true) return arr[0]
        })
      }

      if (data?.staff_access_list?.remove) {
        toRemove = Object.entries(data?.staff_access_list?.remove).map((arr) => {
          if (arr[1] === true) return arr[0]
        })
      }

      accessList = {
        add: compact(toAdd),
        remove: compact(toRemove),
      }
    }

    await mutateAsync({
      from_seat_id: occupancy.place.id,
      to_seat_id: data.place_id,
      use_custom_move_date: data.use_custom_move_date,
      cancel_future_charges: data.cancel_future_charges,
      custom_moved_at: data.custom_moved_at,
      period_type: data.period_type,
      started_at: data.started_at,
      ended_at: data.ended_at,
      reference_id: reference.id,
      reference_type: reference.type,
      staff_access_list: accessList,
    })

    if (onSaveSuccessful) await onSaveSuccessful()

    close()
  }

  const updateData = (newValue: any) => {
    const newData = produce(data, (draft: any) => {
      draft = merge(draft, newValue)
    })

    setData(newData)
  }

  return (
    <Overlay fullheight showBackdrop position="center" onClose={close} maxWidth={40}>
      <Overlay.Header icon="program_lists" title="Move Program Lists" />

      {reference?.type === 'resident' ? (
        <ClientWorkflowSubheader client={client} />
      ) : reference?.type === 'contact' ? (
        <ContactWorkflowSubheader contact={reference} />
      ) : reference?.type === 'employee' ? (
        <StaffWorkflowSubheader employee={reference} />
      ) : (
        <Overlay.SubHeader className="grid gap-2 !py-2 shadow-hard-2 relative z-[3]">
          <Flex gap="1rem" alignItems="center">
            <Text avatar={reference?.avatar || ''} label={fullname(reference)} />
          </Flex>
        </Overlay.SubHeader>
      )}

      <Overlay.Content>
        <Workflow hiddenSteps={hiddenSteps}>
          {/* New Program / List */}
          <Workflow.Panel step="move_client">
            <Workflow.Header title="Move to" after={<Workflow.Status status={steps.move_client} />} />
            <Workflow.Content>
              <ProgramMove
                useV6Router={useV6Router}
                data={data}
                setData={updateData}
                initialData={initialData}
                occupancy={occupancy}
                setStatus={(status: string) => {
                  setSteps({ step: 'move_client', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Service Episodes */}
          <Workflow.Panel step="service_episodes" permission={isBehave ? true : 'service_episodes.view'} featureFlagV2="service_episodes">
            <Workflow.Header title="Service Episodes" after={<Workflow.Status status={steps.service_episodes} />} />
            <Workflow.Content>
              <ServiceEpisodes
                client={reference}
                setStatus={(status: string) => {
                  setSteps({ step: 'service_episodes', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Todos */}
          <Workflow.Panel step="todos" permission="todos.view" featureFlagV2="todos">
            <Workflow.Header title="To-Do's" after={<Workflow.Status status={steps.todos} />} />
            <Workflow.Content>
              <Todos
                useV6Router={useV6Router}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'todos', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Events */}
          <Workflow.Panel step="events" permission="events.view" featureFlagV2="calendar">
            <Workflow.Header title="Events" after={<Workflow.Status status={steps.events} />} />
            <Workflow.Content>
              <Events
                useV6Router={useV6Router}
                timezone={timezone}
                client={client}
                setStatus={(status: string) => {
                  setSteps({ step: 'events', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Financials */}
          <Workflow.Panel step="financials" permission="ledger.view" featureFlagV2="financials">
            <Workflow.Header title="Financials" after={<Workflow.Status status={steps.financials} />} />
            <Workflow.Content>
              <Financials
                data={data}
                setData={updateData}
                client={reference}
                setStatus={(status: string) => {
                  setSteps({ step: 'financials', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Staff Access Lists */}
          <Workflow.Panel hideOnNoPlan plan="pro" featureFlagV2="staff_clients_access" step="staff_access_lists">
            <Workflow.Header title="Staff Access Lists" after={<Workflow.Status status={steps.staff_access_lists} />} />
            <Workflow.Content>
              <ProgramStaffAccessLists
                data={data}
                setData={updateData}
                client={reference}
                currentPhase={occupancy?.place?.phase}
                nextPhase={data?.place?.phase}
                setStatus={(status: string) => {
                  setSteps({ step: 'staff_access_lists', status })
                }}
              />
            </Workflow.Content>
          </Workflow.Panel>

          {/* Confirm */}
          <Workflow.Panel step="confirm">
            <Workflow.Header title="Confirm" after={<Workflow.Status status={steps.confirm} />} />
            <Workflow.Content>
              {!valid && (
                <Alert type="warning" glyph="warning">
                  Please select a new list or program and a move date first.
                </Alert>
              )}

              {valid && (
                <FormSection>
                  <Grid gap="1rem">
                    <Card>
                      <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
                        <Icon icon="programs" size={20} />
                        <div className="text-[1.1rem] font-[700]">Previous Program Assignment</div>
                      </Flex>

                      <DataList isCompact withPadding labelWidth={120}>
                        <DataList.Item
                          label="Program"
                          value={
                            <Flex centerY nowrap gap="0.4rem">
                              <Icon icon="programs" size={18} />
                              <div className="!font-[600]">{occupancy.place.program.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item
                          label="Program List"
                          value={
                            <Flex nowrap centerY gap="0.3rem">
                              <Icon icon="housing_shifts" size={18} />
                              <div>{occupancy.place.phase.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item
                          label="List Seat"
                          value={
                            <Flex nowrap centerY gap="0.3rem">
                              <Glyph glyph="chair" size={16} color={COLORS.text} />
                              <div>{occupancy.place.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item label="Occupied From" value={usDateTime(occupancy.started_at, timezone)} />

                        <DataList.Item
                          label="Occupied Until"
                          value={data.use_custom_move_date ? usDateTime(data.custom_moved_at, timezone) : 'Now'}
                        />
                      </DataList>
                    </Card>

                    <Card>
                      <Flex centerY gap="0.75rem" css={{ padding: '0.7rem 1rem 0.4rem' }}>
                        <Icon icon="programs" size={20} />
                        <div className="text-[1.1rem] font-[700]">Next Program Assignment</div>
                      </Flex>

                      <DataList isCompact withPadding labelWidth={120}>
                        <DataList.Item
                          label="Program"
                          value={
                            <Flex centerY nowrap gap="0.4rem">
                              <Icon icon="programs" size={18} />
                              <div className="!font-[600]">{data.place.program.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item
                          label="Program List"
                          value={
                            <Flex nowrap centerY gap="0.3rem">
                              <Icon icon="housing_shifts" size={18} />
                              <div>{data.place.phase.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item
                          label="List Seat"
                          value={
                            <Flex nowrap centerY gap="0.3rem">
                              <Glyph glyph="chair" size={16} color={COLORS.text} />
                              <div>{data.place.name}</div>
                            </Flex>
                          }
                        />

                        <DataList.Item label="Occupied From" value={usDateTime(data.started_at, timezone)} />

                        <DataList.Item
                          label="Occupied Until"
                          value={data.period_type === 'indefinite' ? 'Discharged' : usDateTime(data.ended_at, timezone)}
                        />
                      </DataList>
                    </Card>

                    <Button
                      label="Move Program Lists"
                      type="primary"
                      color="green"
                      glyph="check_in"
                      isLoading={isSaving}
                      isDisabled={!valid}
                      onClick={onSave}
                    />
                  </Grid>
                </FormSection>
              )}
            </Workflow.Content>
          </Workflow.Panel>
        </Workflow>
      </Overlay.Content>
    </Overlay>
  )
}

export const ProgramMoveOverlay = withOverlayError(withSettings(RootProgramMoveOverlay))
