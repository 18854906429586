import React from 'react'

import SmartStatus from '../../../components/SmartStatus'

const statuses = {
  draft: {
    label: 'Draft',
    color: 'gray',
  },
  active: {
    label: 'Active',
    color: 'blue',
  },
  to_review: {
    label: 'To Review',
    color: 'orange',
  },
  signed_off: {
    label: 'Signed Off',
    color: 'green',
  },
  updates_required: {
    label: 'Updates Required',
    color: 'red',
  },
  closed: {
    label: 'Closed',
    color: 'green',
  },
}

export const TreatmentPlanStatus = ({ ...rest }) => {
  return <SmartStatus statuses={statuses} {...rest} />
}
