import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Calendar, CalendarEvent, HelpTagIframe, Page, Grid } from '@behavehealth/components'

import CalendarPageActions from '../../../components/CalendarPageActions'

import { Filters } from '@behavehealth/constructs/Filters/Filters'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

type CalendarDates = {
  currentDate: string
  endDate: string
  startDate: string
}

const pageConfig = {
  feature: 'calendar',
  help: <HelpTagIframe id="calendar" />,
  marketingID: 'calendar',
}

const CalendarPage: React.FC = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { timezone } = useSettings()
  const [dates, setDates] = React.useState<CalendarDates | null>(null)
  const [filters, setFilters] = React.useState({})

  const queryParams = {
    event_type: 'event',
    from: dates?.startDate,
    until: dates?.endDate,
    filters,
  }

  const { data: employee }: any = useGet({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
  })

  const { data, isLoading, refetch, isRefetching }: any = useGet({
    name: ['employee', resource_id, 'events', queryParams],
    url: `/events/search`,
    params: {
      ...queryParams,
      staff: resource_id,
      filters: btoa(JSON.stringify({ filters })),
    },
    options: { enabled: !!dates && !!resource_id },
  })

  const { mutate: deleteEvents } = useDelete({
    name: ['employee', resource_id, 'events'],
    url: '/events',
    invalidate: 'events',
  })

  return (
    <Page breakpoint="0" actions={<CalendarPageActions data={{ employees: [employee] }} />} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <Card className="flex px-2 py-2 mq900:sticky mq900:top-16 z-[10] !shadow-soft-3 ring-[0.5px] ring-divider">
          <Filters config={FILTERS.events_meilisearch} onUpdate={setFilters} localStorageKey="staff_meilisearch_calendar" />
        </Card>

        <Calendar
          events={data}
          isLoading={isLoading}
          timezone={timezone}
          refetch={refetch}
          isRefetching={isRefetching}
          localStorageKey="staff_calendar"
          renderEvent={(event: any) => <CalendarEvent event={event} timezone={timezone} link={`${match.url}/${event.id}`} />}
          to={(event: any) => `${match.url}/${event.id}`}
          onChange={setDates}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'events.delete',
              action: async ({ ids }: any) => {
                await deleteEvents(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CalendarPage, pageConfig))
