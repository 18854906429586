import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { LabTestsReportDataTable } from '@behavehealth/constructs/LiveReports/LabTestsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Labs = () => {
  const tableProps = useDataTable({
    name: ['reports', 'labs'],
    endpoint: `/live_reports?category=labs`,
    localStorageKey: 'report_labs_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.client)}/test-results/labs/${rowData.id}`, [])

  return (
    <Page feature="test_results" title="Live Labs Report">
      <Grid>
        <LabTestsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Labs)
