import React from 'react'

import Alert from '../../../components/Alert'
import CardTreeItem from '../../../components/CardTreeItem'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import FormSection from '../../../components/Forms/FormSection'
import Textarea from '../../../components/Forms/Textarea'
import ContextShow from '../../../components/Forms/ContextShow'

export const TreatmentPlanSettings = ({ prefix }: any) => {
  const model = `${prefix ? `${prefix}.` : ''}settings`

  return (
    <>
      <CardTreeItem isOpen title="Treatment Sections">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info">
            Choose which sections to include in the treatment plan.
          </Alert>

          <CheckboxGroup layout="vertical" trueIcon="check" falseIcon="empty_checkbox" falseStyle="faded">
            <Checkbox defaultChecked label="Diagnoses" model={`${model}.enabled_sections.diagnoses`} />

            <Checkbox defaultChecked label="Problems" model={`${model}.enabled_sections.problems`} />

            <ContextShow when={`${model}.enabled_sections.problems`} is={true}>
              <div className="pl-6 pb-4">
                <CheckboxGroup
                  className="!rounded-none"
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="empty_checkbox"
                  falseStyle="faded"
                >
                  <Checkbox
                    className="!rounded-none"
                    defaultChecked
                    layout="vertical-dense"
                    label="Start Date"
                    model={`${model}.sections.problems.start_date`}
                  />
                  <Checkbox defaultChecked layout="vertical-dense" label="Target Date" model={`${model}.sections.problems.target_date`} />
                  <Checkbox
                    defaultChecked
                    layout="vertical-dense"
                    label="Completion Date"
                    model={`${model}.sections.problems.completion_date`}
                  />
                </CheckboxGroup>
              </div>
            </ContextShow>

            <Checkbox defaultChecked label="Goals" model={`${model}.enabled_sections.goals`} />

            <ContextShow when={`${model}.enabled_sections.goals`} is={true}>
              <div className="pl-6 pb-4">
                <CheckboxGroup
                  className="!rounded-none"
                  layout="vertical-dense"
                  trueIcon="check"
                  falseIcon="empty_checkbox"
                  falseStyle="faded"
                >
                  <Checkbox
                    className="!rounded-none"
                    defaultChecked
                    layout="vertical-dense"
                    label="Start Date"
                    model={`${model}.sections.goals.start_date`}
                  />
                  <Checkbox defaultChecked layout="vertical-dense" label="Target Date" model={`${model}.sections.goals.target_date`} />
                  <Checkbox
                    defaultChecked
                    layout="vertical-dense"
                    label="Completion Date"
                    model={`${model}.sections.goals.completion_date`}
                  />
                </CheckboxGroup>
              </div>
            </ContextShow>

            <div className="pl-4 grid gap-2">
              <Checkbox
                defaultChecked
                label="Objectives"
                model={`${model}.enabled_sections.objectives`}
                tooltip={
                  <>
                    <b>Please note:</b> Goals need to be active to use Objectives
                  </>
                }
              />

              <ContextShow when={`${model}.enabled_sections.objectives`} is={true}>
                <div className="pl-6 pb-4">
                  <CheckboxGroup
                    className="!rounded-none"
                    layout="vertical-dense"
                    trueIcon="check"
                    falseIcon="empty_checkbox"
                    falseStyle="faded"
                  >
                    <Checkbox
                      className="!rounded-none"
                      defaultChecked
                      layout="vertical-dense"
                      label="Start Date"
                      model={`${model}.sections.objectives.start_date`}
                    />
                    <Checkbox
                      defaultChecked
                      layout="vertical-dense"
                      label="Target Date"
                      model={`${model}.sections.objectives.target_date`}
                    />
                    <Checkbox
                      defaultChecked
                      layout="vertical-dense"
                      label="Completion Date"
                      model={`${model}.sections.objectives.completion_date`}
                    />
                  </CheckboxGroup>
                </div>
              </ContextShow>

              <div className="pl-4 grid gap-2">
                <Checkbox
                  defaultChecked
                  label="Interventions"
                  model={`${model}.enabled_sections.interventions`}
                  tooltip={
                    <>
                      <b>Please note:</b> Goals and Objectives need to be active to use Interventions
                    </>
                  }
                />

                <ContextShow when={`${model}.enabled_sections.interventions`} is={true}>
                  <div className="pl-6 pb-4">
                    <CheckboxGroup
                      className="!rounded-none"
                      layout="vertical-dense"
                      trueIcon="check"
                      falseIcon="empty_checkbox"
                      falseStyle="faded"
                    >
                      <Checkbox
                        className="!rounded-none"
                        defaultChecked
                        layout="vertical-dense"
                        label="Start Date"
                        model={`${model}.sections.interventions.start_date`}
                      />
                      <Checkbox
                        defaultChecked
                        layout="vertical-dense"
                        label="Target Date"
                        model={`${model}.sections.interventions.target_date`}
                      />
                      <Checkbox
                        defaultChecked
                        layout="vertical-dense"
                        label="Completion Date"
                        model={`${model}.sections.interventions.completion_date`}
                      />
                    </CheckboxGroup>
                  </div>
                </ContextShow>
              </div>
            </div>

            <Checkbox defaultChecked label="Summary" model={`${model}.enabled_sections.summary`} />
          </CheckboxGroup>
        </FormSection>
      </CardTreeItem>

      <CardTreeItem title="Instructions">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info">
            The instructions text below will appear on the relevant sections of the treatment plan.
          </Alert>

          <Textarea label="Treatment Plan Instructions" model={`${model}.instructions.treatment_plan`} />
          <Textarea label="Problem Instructions" model={`${model}.instructions.problem`} />
          <Textarea label="Goal Instructions" model={`${model}.instructions.goal`} />
          <Textarea label="Objective Instructions" model={`${model}.instructions.objective`} />
          <Textarea label="Intervention Instructions" model={`${model}.instructions.intervention`} />
          <Textarea label="Interpretive Summary Instructions" model={`${model}.instructions.interpretative_summary`} />
          <Textarea label="Additional Relevant Information Instructions" model={`${model}.instructions.additional_notes`} />
          <Textarea label="Recommendations Instructions" model={`${model}.instructions.recommendations`} />
          <Textarea label="Signature Instructions" model={`${model}.instructions.signature`} />
        </FormSection>
      </CardTreeItem>
    </>
  )
}
