import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { isWebGLAvailable } from '@behavehealth/utils/functions'

import Option from '@behavehealth/components/Forms/Option'
import Page from '@behavehealth/components/Page'
import Select from '@behavehealth/components/Forms/Select'
import Tab from '@behavehealth/components/Tab'
import TabList from '@behavehealth/components/TabList'
import Tabs from '@behavehealth/components/Tabs'
import { withPageError } from '@behavehealth/hocs/withPageError'

import AdmissionsAnalytics from './pages/admissions_analytics'
import ClientsAnalytics from './pages/clients_analytics'
import AlumniAnalytics from './pages/alumni_analytics'
import DemographicsAnalytics from './pages/demographics_analytics'
import CensusAnalytics from './pages/census_analytics'
import FinancialsAnalytics from './pages/financials_analytics'
import InsuranceAnalytics from './pages/insurance_analytics'
import MarketingAnalytics from './pages/marketing_analytics'
import OtherAnalytics from './pages/other_analytics'
import ProductivityAnalytics from './pages/productivity_analytics'
import NotificationsAnalytics from './pages/notifications_analytics'

import { AnalyticsContext } from './context'

const Analytics = () => {
  const match = useRouteMatch()

  const hasCanvasSupport = isWebGLAvailable()
  const renderer = hasCanvasSupport ? 'canvas' : 'svg'

  const [timeframe, setTimeframe] = React.useState(30)

  return (
    <Page
      breakpoint="0"
      icon="reports"
      title="Analytics Dashboard"
      actions={
        <Select value={timeframe} onUpdate={({ value }) => setTimeframe(value)} css={styles.select}>
          <Option value={30} label="30 days" />
          <Option value={60} label="60 days" />
          <Option value={90} label="90 days" />
          <Option value={365} label="365 days" />
        </Select>
      }
    >
      <Tabs>
        <TabList css={styles.tabList}>
          <Tab label="Admissions" to={`${match.url}/admissions`} />
          <Tab label="Census" to={`${match.url}/census`} />
          <Tab label="Clients" to={`${match.url}/clients`} />
          <Tab label="Demographics" to={`${match.url}/demographics`} />
          <Tab label="Financials" to={`${match.url}/financials`} />
          <Tab label="Notifications" to={`${match.url}/notifications`} />
          <Tab label="Other" to={`${match.url}/other`} />
          {/*
          <Tab label="Past Client" to={`${match.url}/alumni`} />
          <Tab label="Marketing" to={`${match.url}/marketing`} />
          <Tab label="Productivity" to={`${match.url}/productivity`} />
          <Tab label="Insurance" to={`${match.url}/insurance`} />
          */}
        </TabList>
      </Tabs>

      <AnalyticsContext.Provider value={{ timeframe, renderer }}>
        <Switch>
          <Route path="/analytics/admissions" component={AdmissionsAnalytics} />
          <Route path="/analytics/marketing" component={MarketingAnalytics} />
          <Route path="/analytics/financials" component={FinancialsAnalytics} />
          <Route path="/analytics/clients" component={ClientsAnalytics} />
          <Route path="/analytics/alumni" component={AlumniAnalytics} />
          <Route path="/analytics/demographics" component={DemographicsAnalytics} />
          <Route path="/analytics/census" component={CensusAnalytics} />
          <Route path="/analytics/productivity" component={ProductivityAnalytics} />
          <Route path="/analytics/insurance" component={InsuranceAnalytics} />
          <Route path="/analytics/other" component={OtherAnalytics} />
          <Route path="/analytics/notifications" component={NotificationsAnalytics} />

          <Redirect exact from={match.url} to={`${match.url}/admissions`} />
        </Switch>
      </AnalyticsContext.Provider>
    </Page>
  )
}

const styles = {
  tabList: {
    marginBottom: '1rem',
  },

  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },

  heading: {
    marginTop: '2rem',
    marginBottom: '1rem',

    '&:first-child': {
      marginTop: 0,
    },
  },

  select: {
    width: 140,
    marginLeft: 'auto',
    marginBottom: '1rem',
  },
}

export default withPageError(Analytics)
