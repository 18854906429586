import React from 'react'
import produce from 'immer'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import Button from '../../components/Button'

import { FILTERS } from '../Filters/config'
import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

const TITLE: any = {
  all: 'Clinical Notes',
  individual: 'Individual Note',
  clinical_group: 'Group Note',
  group_note: 'Group Note (Deprecated)',
  family_session: 'Family Session',
  case_management: 'Case Management',
}

export const ClinicalNotesReportDataTable = (props: any) => {
  const { to } = props

  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'category',
        model: 'data.category',
        width: 200,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => <MainCell id={data.data.id} value={TITLE[data.data.category]} to={to?.(data.data)} />,
      },
      {
        title: 'Client',
        id: 'client',
        model: 'data.client',
        type: 'profiles',
      },
      {
        title: 'Status',
        id: 'status',
        model: 'data.status',
        width: 150,
        formatValue: ({ data }: any) => <ClinicalNoteStatus status={data.data.status} />,
      },
      {
        title: 'Tags',
        id: 'tags',
        model: 'data.tags',
        type: 'tags',
        idModel: 'data.id',
        featureFlag: 'tags',
        editPermission: 'clinical_notes.edit',
        tagSmartCategories: 'clinical_notes',
      },
      {
        title: 'Session Start Date',
        id: 'started_at',
        model: 'data.started_at',
        type: 'date_time',
      },
      {
        title: 'Session End Date',
        id: 'ended_at',
        model: 'data.ended_at',
        type: 'date_time',
      },
      {
        title: 'Duration',
        id: 'duration',
        model: 'data.duration',
        width: 90,
        disableSort: true,
        formatValue: ({ data }: any) => `${data.data.duration} min`,
      },

      {
        title: 'Date Added',
        id: 'created_at',
        model: 'data.created_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        id: 'author',
        model: 'data.author',
        type: 'profile',
      },
      {
        title: 'Supervisor',
        id: 'supervisor',
        model: 'data.supervisor',
        type: 'profile',
      },
      {
        title: 'Supervisor Signed At',
        id: 'supervisor_signed_at',
        model: 'data.supervisor_signed_at',
        type: 'date_time',
      },
      {
        title: 'Signed by Staff',
        id: 'signed_by',
        model: 'data.signed_by',
        type: 'profile',
      },
      {
        title: 'Staff Signed At',
        id: 'author_signed_at',
        model: 'data.author_signed_at',
        type: 'date_time',
      },
      {
        title: 'Submit Date',
        id: 'submitted_at',
        model: 'data.submitted_at',
        type: 'date_time',
      },
      {
        title: 'Service to Submit',
        id: 'service_to_submit',
        model: 'data.service_to_submit',
      },
      {
        title: 'Submit to Signed',
        id: 'submit_to_signed',
        model: 'data.submit_to_signed',
      },
    ]
  }, [to])

  return (
    <>
      <DataTable
        asCard
        title="Clinical Notes"
        icon="clinical_notes"
        columns={columns}
        filtersConfig={FILTERS.live_reports.clinical_notes}
        {...props}
        headerAfter={
          <ReportRefetchButton
            forceShow={true}
            category="clinical_notes"
            invalidate={props.queryKey}
            refetchUrl={'/live_reports/update_live_report?category=clinical_notes'}
          />
        }
      />
    </>
  )
}
