import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'
import { ICONS } from '@behavehealth/theme'

import { InsuranceAgingReportDataTable } from '@behavehealth/constructs/LiveReports/InsuranceAgingReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const AgingReport = () => {
  const tableProps = useDataTable({
    name: ['reports', 'insurance-aging'],
    endpoint: `/live_reports/insurance_claims`,
    localStorageKey: 'report_insurance_aging_v1',
  })

  const to = React.useMemo(() => (rowData: any) => getResourceLink(rowData.primary_insurance?.insurance_local_payer), [])

  return (
    <Page icon={ICONS.insurance} title="Insurance Aging Report">
      <Grid>
        <InsuranceAgingReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(AgingReport)
