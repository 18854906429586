import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import { useGet } from '@behavehealth/hooks/useNewAPI'

import Page from '@behavehealth/components/Page'
import Card from '@behavehealth/components/Card'

const SendReferral: React.FC = () => {
  const match = useRouteMatch()

  const { data: organization }: any = useGet({
    name: ['organization', match.params?.resource_id],
    url: `/organizations/${match.params?.resource_id}`,
  })

  return (
    <Page title="Send Referral" icon="web_form">
      <Card>
        <iframe
          title="iframe"
          frameBorder="0"
          border="0"
          cellSpacing="0"
          className="border-none w-full min-h-[1650px] h-full"
          src={organization?.settings_prefs?.send_referral_url}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        />
      </Card>
    </Page>
  )
}

export default withPageError(SendReferral)
