import axios from 'axios'
import get from 'lodash/get'
import { DateTime } from 'luxon'

import { URL } from '../utils/globals'
import { isPast } from '../utils/functions'

export const getToken = async () => {
  const token = localStorage.getItem('bh.at')
  const sessionToken = localStorage.getItem('bh.stk')
  const hasRT = localStorage.getItem('bh.rt')

  if (!token && !sessionToken) return null

  if (isPast(localStorage.getItem('bh.ea'))) {
    if (!hasRT) return null

    const response = await refreshToken()
    return response ? { token: response?.data?.access_token, tokenType: 'jwt' } : null
  }

  if (!!sessionToken) {
    return { token: sessionToken, tokenType: 'session' }
  } else if (!!token) {
    return { token: token, tokenType: 'jwt' }
  }
}

export const getTokenPromise = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('bh.at')
    const sessionToken = localStorage.getItem('bh.stk')
    const hasRT = localStorage.getItem('bh.rt')

    if (!token && !sessionToken) reject({ error: 'No token found' })
    if (!!token && isPast(localStorage.getItem('bh.ea'))) {
      if (!hasRT) reject({ error: 'No refresh token found' })

      refreshToken().then((response) => {
        resolve({ token: response?.data.access_token, tokenType: 'jwt' })
      })
    }

    if (!!sessionToken) {
      resolve({ token: sessionToken, tokenType: 'session' })
    } else if (!!token) {
      resolve({ token: token, tokenType: 'jwt' })
    }
  })
}

export const getSyncToken = () => {
  const token = localStorage.getItem('bh.at')
  const sessionToken = localStorage.getItem('bh.stk')

  if (!token && !sessionToken) return null

  if (!!sessionToken) {
    return { token: sessionToken, tokenType: 'session' }
  } else if (!!token) {
    return { token: token, tokenType: 'jwt' }
  }
}

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const refresh_token = localStorage.getItem('bh.rt')
    if (!refresh_token) return resolve(null)

    const tokenExpiresOn = localStorage.getItem('bh.ea')
    if (!isPast(tokenExpiresOn)) return resolve(null)

    const session_token = localStorage.getItem('bh.stk')
    if (session_token) return resolve(null)

    axios
      .post('/refresh_token', {
        refresh_token: refresh_token,
      })
      .then((response) => {
        // set the access token
        localStorage.setItem('bh.at', response.data.access_token)

        // set the refresh token
        localStorage.setItem('bh.rt', response.data.refresh_token)

        // set the expires at
        localStorage.setItem('bh.ea', DateTime.local().plus({ seconds: response.data.expires_in }).toISO())

        // update Bearer Token
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`

        resolve(response)
      })
      .catch((errors) => {
        reject(errors)
      })
  })
}

export const buildBaseUrl = async (tenant = null) => {
  const protocol = process.env.BH_API_BASE_PROTOCOL
  const appBaseUrl = process.env.BH_APP_BASE_URL
  const apiBaseUrl = process.env.BH_API_BASE_URL
  const version = process.env.BH_API_VERSION

  URL.api = `${protocol}://${apiBaseUrl}/${version}`
  URL.app = `${protocol}://${appBaseUrl}`

  axios.defaults.baseURL = URL.api
  if (tenant) axios.defaults.headers.common['X-Tenant'] = tenant

  axios.defaults.headers.common['X-App'] = process.env.BH_APP

  return true
}

export const setHeaders = async () => {
  const tokenResult = await getToken()
  if (!tokenResult) return false

  const impersonate = localStorage.getItem('bh.imp')
  const as = localStorage.getItem('bh.as')
  const tenant = sessionStorage.getItem('bh.stn') || localStorage.getItem('bh.ftn') || localStorage.getItem('bh.stn')
  const record = sessionStorage.getItem('bh.str') || localStorage.getItem('bh.str')
  const communityTenant = sessionStorage.getItem('bh.sct')

  if (tokenResult.tokenType === 'jwt') {
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenResult.token}`
  } else if (tokenResult.tokenType === 'session') {
    axios.defaults.headers.common['Session-Token'] = tokenResult.token
  }

  axios.defaults.headers.common['X-App'] = process.env.BH_APP
  if (as) axios.defaults.headers.common['As'] = as
  if (impersonate) axios.defaults.headers.common['Impersonate'] = impersonate
  if (tenant) axios.defaults.headers.common['X-Tenant'] = tenant
  if (record) axios.defaults.headers.common['X-Record-Id'] = record
  if (communityTenant) axios.defaults.headers.common['X-Community-Id'] = communityTenant

  return true
}

export const setHeadersTenant = async (tenant: string) => {
  if (!tenant) return false

  if (!!window.ReactNativeWebView) localStorage.setItem('bh.stn', tenant)
  else sessionStorage.setItem('bh.stn', tenant)

  axios.defaults.headers.common['X-Tenant'] = tenant

  return true
}

export const setHeadersCommunity = async (id: string, slug: string) => {
  if (!id && !slug) return false

  if (!!window.ReactNativeWebView) localStorage.setItem('bh.sct', id)
  else sessionStorage.setItem('bh.sct', id)

  axios.defaults.headers.common['X-Community-Id'] = id
  axios.defaults.headers.common['X-Community-Slug'] = slug

  return true
}

export const setTenantHeader = async (tenant: string) => {
  axios.defaults.headers.common['X-Tenant'] = tenant
}

export const startRequestInterceptors = (_store, history) => {
  axios.interceptors.response.use(
    function (response) {
      // check if version exists and if yes, if it is different from the current version
      const version = get(response.headers, 'x-app-version')
      if (version && version !== 'development' && version !== process.env.BH_VERSION) window.updateAvailable = true

      return response
    },
    function (error) {
      if (get(error, 'response.data.type') === 'invalid_grant') {
        history.push('/logout', {
          type: 'session_expired',
          message: 'Your Session has expired for security purposes. Please log back in to continue',
          from: history.location.pathname,
        })

        window.location.reload()
      } else if (get(error, 'response.data.type') === 'user_blocked') {
        history.push('/logout', {
          type: 'user_blocked',
          message: error?.response?.data?.errors[0] || 'Your access has been blocked. Please get in touch with the Owner of your Facility',
          from: history.location.pathname,
        })

        window.location.reload()
      } else if (get(error, 'response.data.type') === 'account_blocked') {
        history.push('/logout', {
          type: 'account_blocked',
          message: 'Your account has been blocked. Please get in touch with us if you think this is in error',
          from: history.location.pathname,
        })

        window.location.reload()
      } else if (get(error, 'response.data.type') === 'no_tenant_access') {
        history.push('/logout', {
          type: 'no_tenant_access',
          message:
            'You do not have access to this facility. Please check that the URL is correct and get in touch with the person that invited you to join',
          from: history.location.pathname,
        })

        window.location.reload()
      } else if (get(error, 'response.data.type') === 'ip_not_authorized') {
        history.push('/logout', {
          type: 'ip_not_authorized',
          message: get(error, 'response.data.title'),
          from: history.location.pathname,
        })

        window.location.reload()
      }

      // Throw errr again (may be need for some other catch)
      return Promise.reject(error.response)
    },
  )
}

export const request = axios
