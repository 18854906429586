import React from 'react'

import { getClientLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ClientsContactsReportDataTable } from '@behavehealth/constructs/LiveReports/ClientsContactsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ClientsContacts = () => {
  const tableProps = useDataTable({
    name: ['reports', 'clients-contacts'],
    endpoint: `/live_reports?category=client_contacts`,
    localStorageKey: 'report_clients_contacts_v1',
    params: { reference_type: 'resident' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getClientLink(rowData.reference)}/contacts/${rowData.id}`, [])
  return (
    <Page feature="contacts" title="Live Contacts Report">
      <Grid>
        <ClientsContactsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClientsContacts)
