import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import useAPI from '@behavehealth/hooks/useAPI'
import Button from '@behavehealth/components/Button'
import Card from '@behavehealth/components/Card'
import Chotomate from '@behavehealth/components/Chotomate'
import ContactsTable from '@behavehealth/components/Tables/ContactsTable'

const PublicContacts: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state) => state.data.insurance_global_payer_contacts?.data)
  const loading = useSelector((state) => state.data.insurance_global_payer_contacts?.loading)
  const payer = useSelector((state) => state.data.insurance_local_payers?.data?.[match?.params?.resource_id])

  useAPI('insurance_global_payer_contacts', `/insurance_local_payers/${match.params?.resource_id}/insurance_global_payer_contacts`)

  const isEmpty = size(data) === 0

  const actions = (
    <Button
      label="Add Public Contact"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        data: {
          payers: [payer],
        },
      }}
    />
  )

  return (
    <>
      <Chotomate name="payers_public_contact_overlay" ready={!loading} />

      <Card>
        <ContactsTable
          data={data}
          isLoading={isEmpty && loading}
          emptyActions={actions}
          localStorageKey="insurance_global_payer_contacts"
          to={(id) => ({
            pathname: `${match.url}/${id}`,
            parent: match,
          })}
        />
      </Card>
    </>
  )
}

export default PublicContacts
