import React from 'react'
import { DateTime } from 'luxon'
import { NavLink, Route } from 'react-router-dom-v5-compat'
import { useCreate } from '@behavehealth/hooks/useNewAPI'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { titleCase } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import {
  Accordion,
  Button,
  Checkbox,
  CheckboxGroup,
  ConfirmDialog,
  DateInput,
  DeleteDialog,
  Divider,
  Dropdown,
  DropdownItem,
  Flex,
  Form,
  FormSection,
  HelpTagIframe,
  Input,
  Option,
  Page,
  Select,
  URLInput,
  RadioGroup,
  Radio,
} from '@behavehealth/components'

import { apiUpdate, apiCreate, apiGet, apiDelete } from '@behavehealth/modules/api'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { COLORS } from '@behavehealth/theme'

import { FeatureFlagsForm } from '@behavehealth/constructs/FeatureFlags/FeatureFlagsForm'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { useDebug } from '@behavehealth/hooks/useDebug'

import SummonOverlay from '@behavehealth/components/SummonOverlay'
import { SubscriptionOverlay } from '@behavehealth/constructs/Account/SubscriptionOverlay'
import SubscriptionsTable from '@behavehealth/constructs/Account/SubscriptionsTable'
import ImportFeatureFlagConfigOverlay from '@behavehealth/constructs/FeatureFlags/ImportFeatureFlagConfigOverlay'

const Account = () => {
  const { url } = useRouteURL()

  return (
    <>
      <AccountIndex />

      <AnimatedRoutes>
        <Route path="subscriptions/:id" element={<SubscriptionOverlay useV6Router back={url} />} />
      </AnimatedRoutes>
    </>
  )
}

const AccountIndex: React.FC = () => {
  const [loading, setLoading] = React.useState(false)

  const { tenant } = useSettings()
  const { isDebug, toggleDebug } = useDebug()

  const defaultPlan = tenant.plan?.identifier?.split('_')[1]
  const isPermissionsDebugging = isDebug('community_permissions')

  const { data: subscriptions, isLoading: isLoadingSubscriptions }: any = useGet({
    name: ['subscriptions'],
    url: `/subscriptions`,
  })

  const addSeedData = async () => {
    setLoading(true)

    try {
      await apiCreate({ url: '/apps/seeds/add_defaults' })
      window.location.reload()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const archiveTenant = async () => {
    try {
      await apiUpdate({ name: 'tenant', url: '/me/tenant', params: { deleted_at: DateTime.local() }, reducer: 'me' })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const reindexSearch = async () => {
    try {
      await apiCreate({ url: '/search_v2/reindex' })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const reindexEvents = async () => {
    setLoading(true)

    try {
      await apiCreate({ url: '/events/reindex' })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const reindexAllSearch = async () => {
    setLoading(true)

    try {
      await apiCreate({ url: '/search_v2/reindex_all' })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const deleteRecurringRecords = async () => {
    try {
      await apiDelete({ url: '/me/delete_recurring_records' })
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const togglePermissionsDebug = () => {
    toggleDebug('community_permissions')
  }

  return (
    <Page
      feature="super_admin"
      help={<HelpTagIframe id="settings_super_admin" />}
      actions={
        <>
          {tenant.plan_status === 'community' && (
            <Button
              glyph="behave_health"
              type="link"
              label={isPermissionsDebugging ? 'Debug Community: ON' : 'Debug Community: OFF'}
              onClick={togglePermissionsDebug}
            />
          )}

          <Button
            isDisabled={!tenant.stripe_customer_id}
            glyph="stripe"
            target="_blank"
            type="link"
            label="Stripe Customer"
            href={`https://dashboard.stripe.com/customers/${tenant.stripe_customer_id}`}
          />

          <Dropdown label="Hubspot…" buttonType="link">
            <DropdownItem
              isDisabled={!tenant.hubspot_company_id}
              glyph="user_group"
              label="Hubspot Company"
              href={`https://app.hubspot.com/contacts/2828210/company/${tenant.hubspot_company_id}`}
            />
            <DropdownItem
              isDisabled={!tenant.hubspot_account_id}
              glyph="user_group"
              label="Hubspot Account"
              href={`https://app.hubspot.com/contacts/2828210/record/2-1959330/${tenant.hubspot_account_id}`}
            />
            <DropdownItem
              isDisabled={!tenant.hubspot_deal_id}
              glyph="user_group"
              label="Hubspot Deal"
              href={`https://app.hubspot.com/contacts/2828210/deal/${tenant.hubspot_deal_id}`}
            />
          </Dropdown>

          <Dropdown label="Actions">
            <DropdownItem
              glyph="user_group"
              label="Populate Default Seed Data"
              onClick={addSeedData}
              isLoading={loading}
              isDisabled={tenant?.has_seed_data}
            />
            <DropdownItem glyph="reset" label="Re-Index Search" onClick={reindexSearch} isLoading={loading} />
            <DropdownItem glyph="reset" label="Re-Index Events" onClick={reindexEvents} isLoading={loading} />
            <DropdownItem glyph="reset" label="Re-Index All Search" onClick={reindexAllSearch} isLoading={loading} />

            <DeleteDialog
              title="Delete Recurring Records"
              message="Are you sure you want to delete recurring financial records and calendar events?"
              yesLabel="Yes, Delete"
              yesColor="gray"
              onYes={deleteRecurringRecords}
            >
              <DropdownItem glyph="delete" color="red" label="Delete Recurring Records…" />
            </DeleteDialog>

            <DeleteDialog
              title={`Archive ${tenant.name}…`}
              message="Are you sure you want to archive this Tenant?"
              yesLabel="Yes, Archive"
              yesColor="gray"
              onYes={archiveTenant}
            >
              <DropdownItem glyph="delete" color="red" label="Archive Tenant…" />
            </DeleteDialog>
          </Dropdown>
        </>
      }
    >
      <Accordion
        isOpen
        permission
        activateEditMode
        initialModel={tenant}
        title="Facility Details"
        onSubmit={async (data: any) => {
          await apiUpdate({
            url: '/me/tenant',
            params: data,
            reducer: 'me',
          })

          window.location.reload()
        }}
      >
        <Form>
          <FormSection horizontal>
            <Select label="Account Type" model="category" defaultValue={tenant.category}>
              <Option label="Healthcare Facility" value="healthcare_facility" />
              <Option label="Healthcare Practice" value="healthcare_practice" />
              <Option label="Support Facility" value="support_facility" />
              <Option label="Support Practice" value="support_practice" />
              <Option label="Provider Partner" value="provider_affiliate" />
              <Option label="Authority" value="authority" />
            </Select>

            <Select label="Account Status" model="plan_status" defaultValue={tenant.plan_status}>
              <Option label="Trial Requested" value="trial_requested" />
              <Option label="Trialing" value="trialing" />
              <Option label="Trial Expired" value="trial_expired" />
              <Option label="Unpaid" value="unpaid" />
              <Option label="Cancelled" value="canceled" />
              <Option label="Active" value="active" />
              <Option label="Community" value="community" />
              <Option label="Archived" value="archived" />
            </Select>

            <Select label="Sales Status" model="sales_status" defaultValue={tenant.sales_status}>
              <Option label="–" value={null} />
              <Option label="Yes on Value" value="yes_on_value" />
              <Option label="Yes on Price/Unpaid" value="yes_on_price_unpaid" />
              <Option label="Onboarding" value="onboarding" />
            </Select>

            <DateInput label="Trial Ends On" model="trial_ended_at" />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        isOpen
        permission
        activateEditMode
        initialModel={tenant}
        title="Online Payments"
        onSubmit={async (data: any) => {
          await apiUpdate({
            url: '/me/tenant',
            params: { pass_behave_internal_billing_fee_to_payer: data.pass_behave_internal_billing_fee_to_payer },
            reducer: 'me',
          })

          window.location.reload()
        }}
      >
        <Form>
          <FormSection>
            <RadioGroup
              label="Who pays the payment processing fees?"
              model="pass_behave_internal_billing_fee_to_payer"
              layout="vertical-dense"
            >
              <Radio label="Behave Health pays the fee" value={false} />
              <Radio label="Quote/Invoice Payer pays the fee" value={true} />
            </RadioGroup>
          </FormSection>
        </Form>
      </Accordion>

      <Accordion isOpen minimal title="Active Subscriptions">
        <SubscriptionsTable
          mainLinkAs={NavLink}
          data={subscriptions}
          isLoading={isLoadingSubscriptions}
          to={(id: any) => `subscriptions/${id}`}
        />
      </Accordion>

      <Accordion
        isOpen
        permission
        activateEditMode
        initialModel={tenant}
        title="Feature Flags"
        onSubmit={async (data) => {
          await apiUpdate({ url: '/me/tenant', params: { feature_flags: data?.feature_flags }, reducer: 'me' })
          await apiGet({ url: '/me', reducer: 'me' })

          window.location.reload()
        }}
      >
        <Form>
          <FormSection maxWidth="100%">
            <Flex gap="1rem" stretchChildrenX>
              <div className="!max-w-full">
                <div className="h-[40px]">
                  <Flex centerY gap="1rem">
                    <span className="!text-[1.2rem]">
                      Current Plan: <b>{tenant.plan?.public_name || '–'}</b>
                    </span>

                    <SummonOverlay overlay={<ImportFeatureFlagConfigOverlay />}>
                      <Button rounded color="green" glyph="reset" type="primary" label="Apply Feature Flag Config…" />
                    </SummonOverlay>
                  </Flex>
                </div>

                <FeatureFlagsForm className="border border-solid border-divider rounded-md mt-4" />
              </div>
            </Flex>
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        activateEditMode
        initialModel={tenant}
        title="RCM Proposals"
        onSubmit={(data) => apiUpdate({ url: '/me/tenant', params: data, reducer: 'me' })}
      >
        <Form>
          <FormSection>
            <URLInput label="RCM Proposal" model="metadata.rcm_proposal_url" />
            <URLInput label="Internal RCM Proposal" model="metadata.internal_rcm_proposal_url" />
          </FormSection>
        </Form>
      </Accordion>

      <h4 css={styles.headers}>VENDORS</h4>

      <Accordion
        activateEditMode
        initialModel={tenant}
        title="Change Healthcare"
        onSubmit={(data) => apiUpdate({ url: '/me/tenant', params: data, reducer: 'me' })}
      >
        <Form>
          <FormSection>
            <Input label="Super User ID" model="change_health_username" />
            <Input label="Submitter ID" model="change_health_submitter_id" />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion initialModel={tenant} title="Rcopia - Dr First (eRX) [Deprecated]" description="Moved to Account Integrations">
        <Form>
          <FormSection>
            <Input label="Practice Username" model="rcopia.practice_username" />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        permission
        activateEditMode
        initialModel={tenant}
        title="Stripe"
        onSubmit={(data) => apiUpdate({ url: '/me/tenant', params: data, reducer: 'me' })}
      >
        <Form>
          <FormSection>
            <h3>Connect</h3>
            <Input isEditable={false} label="Stripe Connect Account ID" model="stripe_account_id" />

            <Divider />

            <h3>Behave Health</h3>
            <Input label="Stripe Behave Health Customer ID" model="stripe_customer_id" />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion
        permission
        activateEditMode
        initialModel={tenant}
        title="Hubspot"
        onSubmit={(data) => apiUpdate({ url: '/me/tenant', params: data, reducer: 'me' })}
      >
        <Form>
          <FormSection>
            <Input label="Hubspot Company ID" model="hubspot_company_id" />
            <Input label="Hubspot Account ID" model="hubspot_account_id" />
            <Input label="Hubspot Deal ID" model="hubspot_deal_id" />
          </FormSection>
        </Form>
      </Accordion>

      <Accordion permission activateEditMode initialModel={tenant} title="NAATP">
        {tenant.naatp_status ? NaatpStatuses(tenant) : <NaatpRegisterationButton />}
      </Accordion>

      <h4 css={styles.headers}>SYSTEM</h4>

      <Accordion
        permission
        activateEditMode
        initialModel={tenant}
        title={
          <Button
            label="Dangerous Section"
            type="link"
            href="https://youtu.be/ah46n1BY80w?t=41"
            glyph="behave_health"
            target="_blank"
            className="!pl-0"
            color="red"
          />
        }
        onSubmit={(data) => apiUpdate({ url: '/me/tenant', params: data, reducer: 'me' })}
      >
        <Form>
          <FormSection horizontal>
            <CheckboxGroup label="Facility Blocked" falseStyle="faded" falseIcon="cross" trueIcon="check">
              <Checkbox label="Blocked" model="is_blocked" />
            </CheckboxGroup>

            <CheckboxGroup label="Is Billed by Behave" falseStyle="faded" falseIcon="cross" trueIcon="check">
              <Checkbox label="Billed" model="is_billed" />
            </CheckboxGroup>

            <CheckboxGroup label="Self-Service Billing" falseStyle="faded" falseIcon="cross" trueIcon="check">
              <Checkbox label="Self-Service Billing" model="is_self_service_billing" />
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

const NaatpStatuses = (tenant) => {
  let registration_status = titleCase(tenant.naatp_status)

  return (
    <Flex gap="1rem" stretchChildrenX>
      <div className="!max-w-full">
        <div className="h-[40px]">
          <Flex centerY>
            <span className="!text-[1.2rem]">
              Facility Registration Status: <b>{titleCase(registration_status)}</b>
            </span>
          </Flex>
        </div>
      </div>
    </Flex>
  )
}

const NaatpRegisterationButton = () => {
  const { mutateAsync, isLoading } = useCreate({
    name: ['naatp-registration'],
    url: '/external_apps/naatp/register',
    // invalidate: tableProps.queryKey,
  })

  return <Button label="Register" onClick={() => mutateAsync({})} isLoading={isLoading} />
}

const styles = {
  header: {
    display: 'block',
    width: '100%',
    padding: '0.5rem',
    borderBottom: `1px solid ${COLORS.divider}`,
  },
  headers: {
    margin: '1.5rem 0 0.5rem 0',
    textTransform: 'uppercase',
    opacity: 0.6,
  },
  headerTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: '0 !important',
  },
  featureRow: {
    minHeight: 40,
    maxHeight: 50,
  },
  featureCell: {
    paddingLeft: '1.5rem',
  },
}

export default withPageError(Account)
