import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { getClientLink, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { NotApplicableCell } from '../../components/DataTable/cells/NotApplicableCell'

import Status from '../../components/Status'
import { FILTERS } from '../Filters/config'

export const LabsDataTable = (props: any) => {
  const { to } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Date & Time',
        model: 'recorded_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell as={NavLink} id={data.id} to={to ? to(data) : undefined} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/test-results/labs`} />
        },
      },
      {
        title: 'Name',
        model: 'name',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => {
          if (value === 'refused') return <Status color="red" label="Not Collected" />
          if (value === 'collected') return <Status color="blue" label="Collected" />
          return <NotApplicableCell />
        },
      },
      {
        title: 'Result',
        model: 'result',
        formatValue: ({ data, value }: any) => {
          if (data.status === 'refused') return null
          if (value === 'positive') return <Status color="red" label="Positive" />
          if (value === 'negative') return <Status color="green" label="Negative" />
          if (value === 'not_available') return <Status color="grey" label="N/A" />
          return null
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
    ],
    [to, timezone],
  )

  return (
    <DataTable
      asCard
      testKey="labs_data_table"
      title="Lab Tests"
      icon="test_results"
      columns={columns}
      filtersConfig={FILTERS.lab_tests}
      {...props}
    />
  )
}
