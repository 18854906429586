import React from 'react'
import size from 'lodash/size'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Avatar from '../../components/Avatar'
import { countWord, usDate, usTime } from '../../utils/functions'
import { useSettings } from '../../hooks'
import Flex from '../../components/Flex'

import EventStatus from '../../components/Statuses/EventStatus'

import { FILTERS } from '../Filters/config'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'

const CATEGORIES: any = {
  general: 'General',
  client_admission: 'Client Admission',
  recovery_coaching: 'Recovery Coaching',
  housing_activity: 'Housing Activity',
  discharge: 'Discharge',
  psych_appointment: 'Psych Appointment',
  medical_appointment: 'Medical Appointment',
  clinical_appointment: 'Clinical Appointment',
  progress_appointment: 'Progress Appointment',
  individual_therapy: 'Individual Therapy',
  individual_peer_note: 'Individual Peer Note',
  group_peer_notes: 'Group Peer Note',
  individual_daily_note: 'Individual Daily Note',
  group_daily_notes: 'Group Daily Note',
  group_therapy: 'Group Therapy',
  meds_pass: 'Meds Pass',
  urinalysis: 'Urinalysis',
  breathalyzer: 'Breathalyzer',
  vitals: 'Vitals',
  communicable_tests: 'Communicable Tests',
  bed_check: 'Bed Check',
  housing_intake: 'Housing Intake',
  show_as_busy: 'Show as Busy',
}

const EVENT_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Event',
}

const IMPORT_TYPES: any = {
  dynamic: 'Automatic from Locations / Programs',
  manual: 'Manual from clients list',
}

const PROGRAM_IMPORT_TYPES: any = {
  programs: 'Programs',
  program_lists: 'Program Lists',
}

const STYLES = {
  clientsCell: {
    '&:hover.hide-hover': {
      display: 'none',
    },

    '.show-hover': {
      display: 'none',
    },

    '&:hover .show-hover': {
      display: 'flex',
    },
  },
}

export const CalendarDataTable = (props: any) => {
  const { to } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        id: 'title',
        model: 'title',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell as={props.mainLinkAs} id={data.id} value={data.title} to={to?.(data)} />,
      },
      {
        width: 140,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <EventStatus status={value} />,
      },
      {
        width: 150,
        model: 'category',
        title: 'Category',
        formatValue: ({ value }: any) => {
          if (!(value in CATEGORIES)) return null

          return CATEGORIES[value]
        },
      },
      {
        width: 150,
        model: 'meeting_type',
        title: 'Event Type',
        formatValue: ({ value }: any) => {
          if (!(value in EVENT_TYPES)) return null

          return EVENT_TYPES[value]
        },
      },
      {
        width: 150,
        id: 'clients_import_type',
        model: 'clients_import_type',
        title: 'Attendees Import',
        formatValue: ({ value }: any) => {
          if (!(value in IMPORT_TYPES)) return null

          return IMPORT_TYPES[value]
        },
      },
      {
        width: 200,
        model: 'residents',
        title: 'Clients (Group Events)',
        hoverExpand: true,
        formatValue: ({ data, value }: any) => {
          // Dynamic Import
          if (size(value) === 0) {
            if (data?.clients_import_type === 'dynamic') {
              return `Dynamically imported from selected Programs and/or Locations on the Due Date`
            }

            return null
          }

          // Clients
          return (
            <div css={STYLES.clientsCell}>
              <div className="hide-hover">{countWord('Client', size(value))}</div>

              <Flex gap="0.5rem" className="show-hover">
                {data?.residents?.map?.((o: any) => (
                  <ProfileCellInner value={o} />
                ))}
              </Flex>
            </div>
          )
        },
      },
      {
        width: 200,
        model: 'resident',
        title: 'Client (One-to-One)',
        hoverExpand: true,
        type: 'profile',
      },
      {
        width: 150,
        id: 'imported_houses',
        model: 'imported_houses',
        title: 'Import from Locations',
        formatValue: ({ data, value }: any) => {
          if (size(data.imported_houses) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.imported_houses.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 150,
        id: 'programs_import_type',
        model: 'programs_import_type',
        title: 'Programs Import Type',
        formatValue: ({ value }: any) => {
          if (!(value in PROGRAM_IMPORT_TYPES)) return null

          return PROGRAM_IMPORT_TYPES[value]
        },
      },
      {
        width: 150,
        id: 'imported_programs',
        model: 'imported_programs',
        title: 'Programs',
        formatValue: ({ data, value }: any) => {
          if (size(data.imported_programs) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.imported_programs.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 150,
        id: 'imported_phases',
        model: 'imported_phases',
        title: 'Program Lists',
        formatValue: ({ data, value }: any) => {
          if (size(data.imported_phases) === 0) return null

          return (
            <Flex centerY nowrap gap="0.75rem">
              {data.imported_phases.map((o: any) => (
                <Flex key={o.id} centerY nowrap gap="0.5rem">
                  <Avatar src={o.avatar} initials={o.name} size={20} />
                  <div>{o.name}</div>
                </Flex>
              ))}
            </Flex>
          )
        },
      },
      {
        width: 90,
        id: '__color',
        model: 'color',
        title: 'Color',
        type: 'color',
      },
      {
        width: 160,
        model: 'started_at',
        title: 'Date',
        formatValue: ({ value }: any) => usDate(value, timezone),
      },
      {
        width: 160,
        id: '__time',
        model: 'is_all_day',
        title: 'Time',
        formatValue: ({ value, data }: any) => {
          if (value === true) return 'All-day'

          return `${usTime(data?.start_time, timezone)} – ${usTime(data?.end_time, timezone)}`
        },
      },
      {
        width: 200,
        id: 'public_description',
        model: 'public_description',
        title: 'Public Description',
        type: 'rich_text',
      },
      {
        id: 'created_at',
        model: 'created_at',
        title: 'Date Created',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Events"
      testKey="events_data_table"
      icon="calendar"
      columns={columns}
      filtersConfig={FILTERS.events}
      {...props}
    />
  )
}
