import React from 'react'

import Select from '../Forms/Select'
import Option from '../Forms/Option'
import { withFormContext } from '../Forms/context'

const EventAttendancePresentStatusSelect = ({ label, model = 'category', defaultValue, isEditable, isDisabled, tooltip }: any) => (
  <Select label={label} model={model} defaultValue={defaultValue} isEditable={isEditable && !isDisabled} tooltip={tooltip}>
    <Option label={'N/A'} value={null} />
    <Option label="Attend Partial" value="attend_partial" />
    <Option label="Attend Partial/Due to Emotional Distress" value="attend_partial_due_to_emotional_distress" />
    <Option label="Call-Out" value="call_out" />
    <Option label="Call-Out by Provider" value="call_out_by_provider" />
    <Option label="Late" value="late" />
    <Option label="Make-up Session" value="make_up_session" />
    <Option label="Medication Management" value="medication_management" />
    <Option label="Non-Participatory" value="non_participatory" />
    <Option label="Partial Day" value="partial_day" />
    <Option label="Participated Actively" value="participated_actively" />
    <Option label="Telehealth/Remote" value="telehealth_remote" />
    <Option label="Under Influence" value="under_influence" />
    <Option label="With Support Person" value="with_support_person" />
    <Option label="Left Early - Excused" value="left_early_excused" />
    <Option label="Left Early - Unexcused" value="left_early_unexcused" />
  </Select>
)

export default withFormContext(EventAttendancePresentStatusSelect)
