import React from 'react'
import clsx from 'clsx'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'
import padStart from 'lodash/padStart'
import produce from 'immer'
import isEqual from 'react-fast-compare'

import { Manager, Reference } from 'react-popper'

import { DEFAULT_EMPTY_VALUE } from '../../../utils/constants'
import { isDefined } from '../../../utils/functions'
import { validate } from '../validators'
import { withFormContext } from '../context'

import Field from '../Field'
import BaseInput from '../BaseInput'
import Flex from '../../Flex'
import Option from '../Option'
import Select from '../Select'
import Value from '../../Value'

import FieldBase from '../FieldBase'

import SmartPortal from '../../SmartPortal'
import { TimeInputSelectorMenu } from './TimeInputSelectorMenu'

const closestBracketMinute = (minute: any) => {
  if (minute > 0 && minute <= 15) return 15
  else if (minute > 15 && minute <= 30) return 30
  else if (minute > 30 && minute <= 45) return 45
  else if (minute > 45 && minute <= 59) return 0
  else if (minute === 0) return 0
}

const processHour = (hour: any, meridiem: any) => {
  if (isNaN(hour)) return null

  if (meridiem === 'AM') {
    if (hour < 0) return 11
    if (hour > 11) return 0
  } else if (meridiem === 'PM') {
    if (hour > 12) return 1
    if (hour < 1) return 12
  }

  return hour
}

const processMinute = (minute: any) => {
  if (isNaN(minute)) return null
  else if (minute < 0) 59
  else if (minute > 59) 0
  else return minute
}

const allVisualFieldsCompleted = (visual: any) => {
  if (!visual) return false

  return visual.hour.length === 2 && visual.minute.length === 2
}

class TimeInputSelector extends FieldBase {
  constructor(props: any) {
    super(props)

    let errors = []
    let timezone = props.timezone

    const vs = props.validations
    let date = DateTime.fromISO(props.value, { zone: timezone })

    if (!date.isValid) {
      const model = props.form?.getField(props.model)
      const initialModel = props.form?.getInitialInputFieldValue(props.model)
      const val = model || initialModel || props.default

      if (val) {
        const dateObject = props.dateObject || { year: 1970, month: 1, day: 1 }
        date = DateTime.fromISO(val, { zone: timezone }).set({ year: dateObject.year, month: dateObject.month, day: dateObject.day })
        // date = DateTime.fromISO(val, { zone: timezone })
      } else {
        let localTime = DateTime.local()
        const dateObject = props.dateObject || { year: 1970, month: 1, day: 1 }

        if (props.defaultToNow) {
          date = DateTime.fromObject({
            year: dateObject.year,
            month: dateObject.month,
            day: dateObject.day,

            hour: localTime.hour,
            minute: closestBracketMinute(localTime.minute),
            zone: timezone,
          })
        } else if (props.defaultToInOneHour) {
          localTime = localTime.plus({ hours: 1 })
          date = DateTime.fromObject({
            year: dateObject.year,
            month: dateObject.month,
            day: dateObject.day,

            hour: localTime.hour,
            minute: closestBracketMinute(localTime.minute),
            zone: timezone,
          })
        }
      }
    }

    const value = date.toISO()
    if (vs) errors = validate(value, vs)

    const meridiem = date.hour >= 12 ? 'PM' : 'AM'
    const finalHour = date.hour - (meridiem === 'PM' && date.hour > 12 ? 12 : 0)

    const hour = isNaN(date.hour) ? '' : padStart(finalHour, 2, '0')
    const minute = isNaN(date.minute) ? '' : padStart(date.minute.toString(), 2, '0')

    const isRequired = props.validations?.hasOwnProperty('presence')

    this.state = {
      type: 'TIME_INPUT_SELECTOR',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      date: date,
      value: value,
      day: date.isValid ? date.day : null,
      month: date.isValid ? date.month : null,
      year: date.isValid ? date.year : null,
      hour: date.isValid ? date.hour : null,
      minute: date.isValid ? date.minute : null,
      meridiem: meridiem,
      visual: {
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allowErrors: value ? true : false,
      allFieldsCompleted: value ? true : false,
      isNested: props.isNested || false,
      inFocus: null,
      focused: {
        hour: true,
        minute: true,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isRequired: isRequired,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      isPickerOpen: false,
    }

    this.initialData = {
      date: date,
      value: value,
      hour: date.hour,
      minute: date.minute,
      meridiem: meridiem,
      visual: {
        hour: hour,
        minute: minute,
      },
      smartValue: props.smartDescription ? props.smartDescription(null) : '',
      allowErrors: value ? true : false,
      allFieldsCompleted: value ? true : false,
      inFocus: null,
      focused: {
        hour: true,
        minute: true,
      },
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.updateType === 'RESET') {
      this.updateType = 'DATA'
      return true
    }

    if (
      nextProps.isEditable !== this.props.isEditable ||
      nextProps.isDisabled !== this.props.isDisabled ||
      nextProps.layout !== this.props.layout ||
      nextProps.maxWidth !== this.props.maxWidth
    ) {
      this.updateType = 'STATE'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    if (
      this.props.value !== nextProps.value ||
      this.props.defaultValue !== nextProps.defaultValue ||
      this.props.model !== nextProps.model ||
      !isEqual(nextProps.validations, this.props.validations) ||
      !isEqual(nextProps.tooltip, this.props.tooltip)
    ) {
      this.updateType = 'PROPS'
      return true
    }

    if ((this.props.dateObject || nextProps.dateObject) && this.props.dateObject !== nextProps.dateObject) {
      this.updateType = 'PROPS'
      return true
    }

    if (
      this.props.placeholder !== nextProps.placeholder ||
      this.props.description !== nextProps.description ||
      this.props.color !== nextProps.color ||
      this.props.label !== nextProps.label ||
      this.props.suffix !== nextProps.suffix ||
      this.props.children !== nextProps.children
    ) {
      this.updateType = 'EXTERNAL'
      return true
    }

    return this.props.shouldAlwaysUpdate || false
  }

  /*
    CUSTOM FUNCTIONS
  */
  onChange = (event: any) => {
    const target = event.target
    const value = target.value

    // if the target is not a number return
    if (target.id !== 'meridiem' && value !== '' && isNaN(parseInt(value))) return

    // parse the event for the UI
    const newState = produce(this.state, (draft: any) => {
      if (target.id === 'hour') draft.visual.hour = value
      else if (target.id === 'minute') draft.visual.minute = value

      if (target.id === 'meridiem') {
        // update meridiem
        draft.meridiem = value

        // process date with the new meridiem
        const date = this.processDate({
          day: draft.day,
          month: draft.month,
          year: draft.year,
          hour: draft.visual.hour,
          minute: draft.visual.minute,
          meridiem: value,
        })

        draft.date = date
        draft.value = date?.toISO() || null

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        if (draft.date?.isValid) {
          draft.hour = date.hour
          draft.minute = date.minute
        }
      }
    })

    if (target.id === 'meridiem') {
      this.setState({
        visual: newState.visual,
        meridiem: newState.meridiem,

        year: newState.year,
        month: newState.month,
        day: newState.day,
        hour: newState.hour,
        minute: newState.minute,

        date: newState.date,
        value: newState.value,

        allFieldsCompleted: newState.allFieldsCompleted,
        smartValue: newState.smartValue,
      })

      // update form engine
      if (this.props.form && this.props.model) this.props.form.change(newState)
    } else {
      // update visual state
      this.setState({ visual: newState.visual })
    }
  }

  processValidation = (queueEvent: any) => {
    const fieldsCompleted = allVisualFieldsCompleted(queueEvent?.state?.visual)

    const date = DateTime.fromISO(queueEvent.value, { zone: this.props.timezone })
    let errors = []

    if (fieldsCompleted || this.state.isRequired) {
      errors = date.isValid
        ? validate(date, { ...this.props.defaultValidations, ...this.props.validations })
        : [{ message: 'Please enter a valid date time' }]
    }

    const newState = produce(this.state, (draft: any) => {
      draft.isValid = errors.length ? false : true
      draft.isInvalid = errors.length ? true : false
      draft.errors = errors
    })

    // update local state
    this.setState({
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      errors: errors,
    })

    return newState
  }

  getVisualStrings = (state: any) => {
    const finalHour = state.hour - (state.meridiem === 'PM' && state.hour > 12 ? 12 : 0)

    const hourString = padStart(finalHour?.toString(), 2, '0')
    const minuteString = padStart(state.minute?.toString(), 2, '0')

    return {
      hour: isNaN(hourString) ? '' : hourString,
      minute: isNaN(minuteString) ? '' : minuteString,
    }
  }

  processUpdate = (_queueEvent: any) => {
    const time = DateTime.fromISO(this.props.value || this.props.defaultValue, { zone: this.props.timezone })
    const dateObject = this.props.dateObject

    const newState = produce(this.state, (draft: any) => {
      draft.prevValue = draft.value

      if (time) {
        draft.year = time.year
        draft.month = time.month
        draft.day = time.day
        draft.hour = time.hour - (draft.meridiem === 'PM' && time.hour > 12 ? 12 : 0)
        draft.minute = time.minute
        draft.meridiem = time.toFormat('a')

        draft.date = time

        draft.value = draft.date.toISO()

        draft.allFieldsCompleted = true

        // update the final hour that gets saved

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        draft.visual = this.getVisualStrings(draft)

        draft.isDirty = true
        draft.isPristine = false
      } else if (dateObject?.isValid) {
        draft.year = dateObject.year
        draft.month = dateObject.month
        draft.day = dateObject.day

        draft.date = draft.date.set({ year: dateObject.year, month: dateObject.month, day: dateObject.day })
        draft.value = draft.date.toISO()

        draft.allFieldsCompleted = true

        // update the final hour that gets saved

        if (this.props.smartDescription) {
          draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
        }

        draft.visual = this.getVisualStrings(draft)

        draft.isDirty = true
        draft.isPristine = false
      }
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      prevValue: newState.prevValue,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    return newState
  }

  processDate = ({ day, month, year, hour, minute, meridiem }: any) => {
    // try to parse the strings into numbers
    const parsedDay = parseInt(day)
    const parsedMonth = parseInt(month)
    const parsedYear = parseInt(year)
    const parsedHour = parseInt(hour)
    const parsedMinute = parseInt(minute)

    // check if all the numbers are all ok
    const dayDefined = Number.isInteger(parsedDay)
    const monthDefined = Number.isInteger(parsedMonth)
    const yearDefined = Number.isInteger(parsedYear)
    const hourDefined = Number.isInteger(parsedHour)
    const minuteDefined = Number.isInteger(parsedMinute)
    const meridiemDefined = isDefined(meridiem)

    // if yes, create a date object
    let date = null
    if (yearDefined && monthDefined && dayDefined && hourDefined && minuteDefined && meridiemDefined) {
      let meridiemExtra = 0

      // add meridiems
      if (meridiem === 'PM' && parsedHour < 12) meridiemExtra = 12
      if (meridiem === 'AM' && parsedHour >= 12) meridiemExtra = -12

      date = DateTime.fromObject({
        year: parsedYear,
        month: parsedMonth,
        day: parsedDay,
        hour: parsedHour + meridiemExtra,
        minute: parsedMinute,
        zone: this.props.timezone,
      })
    }

    return date
  }

  processFocus = (queueEvent: any) => {
    if (queueEvent.event.target) queueEvent.event.target.select()

    const newState = produce(this.state, (draft: any) => {
      if (queueEvent.event.target.id === 'hour') {
        draft.focused.hour = true
        draft.inFocus = 'hour'
      } else if (queueEvent.event.target.id === 'minute') {
        draft.focused.minute = true
        draft.inFocus = 'minute'
      }

      draft.isTouched = true
      draft.isUntouched = true
    })

    this.setState({
      focused: newState.focused,
      inFocus: newState.inFocus,
      isTouched: true,
      isUntouched: true,
    })

    return newState
  }

  processBlur = (_queueEvent: any) => {
    const newState = produce(this.state, (draft: any) => {
      draft.isBlur = true
      draft.isHighlighted = false

      // beautify hour
      if (draft.inFocus === 'hour') {
        if (draft.visual.hour === '00' || draft.visual.hour === '0' || draft.visual.hour === null) {
          if (draft.meridiem === 'AM') draft.visual.hour = '00'
          else if (draft.meridiem === 'PM') draft.visual.hour = '12'
        } else {
          const hourDefined = Number.isInteger(parseInt(draft.visual.hour))
          if (hourDefined) draft.visual.hour = padStart(draft.visual.hour, 2, '0')
        }
      }

      // beautify minute
      if (draft.inFocus === 'minute') {
        if (draft.visual.minute === '0' || draft.visual.minute === null) {
          draft.visual.minute = '00'
        } else {
          const minuteDefined = Number.isInteger(parseInt(draft.visual.minute))
          if (minuteDefined) draft.visual.minute = padStart(draft.visual.minute, 2, '0')
        }
      }

      const date = this.processDate({
        day: draft.day,
        month: draft.month,
        year: draft.year,
        hour: draft.visual.hour,
        minute: draft.visual.minute,
        meridiem: draft.meridiem,
      })

      draft.date = date
      draft.value = date?.toISO() || null

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day
        draft.hour = date.hour
        draft.minute = date.minute
      }
    })

    this.setState({
      visual: newState.visual,
      isBlur: true,
      isHighlighted: false,

      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,

      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
    })

    return newState
  }

  onKeyDown = (event: any) => {
    const upOrDownPressed = event.keyCode === 38 || event.keyCode === 40
    if (!upOrDownPressed) return

    const newState = produce(this.state, (draft: any) => {
      if (event.keyCode === 40) {
        // DOWN
        if (event.target.id === 'hour') {
          const parsedHour = parseInt(draft.visual.hour)
          const hourDefined = Number.isInteger(parsedHour)

          if (hourDefined) draft.hour = processHour(parsedHour - 1, draft.meridiem)
          else draft.hour = draft.meridiem === 'AM' ? 0 : 12

          draft.visual.hour = padStart(draft.hour.toString(), 2, '0')
        } else if (event.target.id === 'minute') {
          const parsedMinute = parseInt(draft.visual.minute)
          const minuteDefined = Number.isInteger(parsedMinute)

          if (minuteDefined) draft.minute = processMinute(parsedMinute - 1)
          else draft.minute = 0

          draft.visual.minute = padStart(draft.minute.toString(), 2, '0')
        }
      } else if (event.keyCode === 38) {
        // UP
        if (event.target.id === 'hour') {
          const parsedHour = parseInt(draft.visual.hour)
          const hourDefined = Number.isInteger(parsedHour)

          if (hourDefined) draft.hour = processHour(parsedHour + 1, draft.meridiem)
          else draft.hour = draft.meridiem === 'AM' ? 0 : 12

          draft.visual.hour = padStart(draft.hour.toString(), 2, '0')
        } else if (event.target.id === 'minute') {
          const parsedMinute = parseInt(draft.visual.minute)
          const minuteDefined = Number.isInteger(parsedMinute)

          if (minuteDefined) draft.minute = processMinute(parsedMinute + 1)
          else draft.minute = 0

          draft.visual.minute = padStart(draft.minute.toString(), 2, '0')
        }
      }

      const date = this.processDate({
        day: draft.day,
        month: draft.month,
        year: draft.year,
        hour: draft.visual.hour,
        minute: draft.visual.minute,
        meridiem: draft.meridiem,
      })

      draft.date = date
      draft.value = date?.toISO()

      if (draft.date?.isValid) {
        draft.year = date.year
        draft.month = date.month
        draft.day = date.day

        draft.hour = date.hour
        draft.minute = date.minute
      }

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      hour: newState.hour,
      minute: newState.minute,
      meridiem: newState.meridiem,
      visual: newState.visual,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })
  }

  onPickerOpenUpdated = (isOpen: boolean) => {
    this.setState({ isPickerOpen: isOpen })
  }

  open = async (event: any) => {
    event.stopPropagation()

    if (this.props.isDisabled) return
    if (this.state.isPickerOpen) return

    this.setState({ isPickerOpen: true })

    document.addEventListener('click', this.close)
  }

  close = (e) => {
    if (this.props.isDisabled) return

    if (e?.type === 'blur' && this.selectorRef?.current?.contains?.(e.relatedTarget)) return
    // else if (this.selectorRef?.current?.contains?.(e.target)) return

    this.setState({ isPickerOpen: false })

    document.removeEventListener('click', this.close)
  }

  select = (item: any) => {
    if (this.props.isDisabled) return

    let newState = cloneDeep(this.state)

    newState.hour = padStart(item.hour, 2, 0)
    newState.minute = padStart(item.minute, 2, 0)
    newState.meridiem = item.meridiem

    newState.isDirty = true
    newState.isPristine = false

    // this.setState(this.buildState(newState))

    document.removeEventListener('click', this.close)
  }

  onPickerSelected = (date: any) => {
    if (!date) return

    const newState = produce(this.state, (draft: any) => {
      draft.date = date
      draft.value = draft.date.toISO()
      draft.allFieldsCompleted = true

      draft.year = date.year
      draft.month = date.month
      draft.day = date.day
      draft.hour = date.hour
      draft.minute = date.minute
      draft.meridiem = date.toFormat('a')

      if (this.props.smartDescription) {
        draft.smartValue = this.props.smartDescription(draft.date, this.props.form.getFormValue())
      }

      draft.visual = this.getVisualStrings(draft)

      draft.isPristine = false
      draft.isDirty = true
    })

    this.setState({
      year: newState.year,
      month: newState.month,
      day: newState.day,
      minute: newState.minute,
      hour: newState.hour,
      meridiem: newState.meridiem,
      date: newState.date,
      value: newState.value,
      allFieldsCompleted: newState.allFieldsCompleted,
      visual: newState.visual,
      smartValue: newState.smartValue,
      isPristine: newState.isPristine,
      isDirty: newState.isDirty,
    })

    if (this.props.validateOn?.includes('blur')) this.queue({ type: 'VALIDATE', value: newState.value })

    // update Form Engine
    if (this.props.form && this.props.model) this.props.form.change(newState)
  }

  /*
    RENDER
  */
  editRender = () => (
    <Flex className="TimeInput" alignItems="center" gap={2} nowrap>
      <BaseInput
        id="hour"
        type="text"
        placeholder="HH"
        value={this.state.visual?.hour}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        size={2}
        maxLength={2}
        autocomplete="off"
      />
      <BaseInput
        id="minute"
        type="text"
        placeholder="MM"
        value={this.state.visual?.minute}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        size={2}
        maxLength={2}
        autocomplete="off"
      />

      <Select id="meridiem" defaultValue="AM" value={this.state.meridiem} onChange={this.onChange}>
        <Option label="AM" value="AM" />
        <Option label="PM" value="PM" />
      </Select>
    </Flex>
  )

  readOnlyRender = () => <Value value={this.state.date?.isValid ? this.state.date.toFormat('hh:mma') : DEFAULT_EMPTY_VALUE} />

  renderSelector = () => {
    const { timezone } = this.props

    return (
      <SmartPortal useReferenceWidth portal="radix" position="bottom-end" fallbackPositions={['top-start']}>
        <div className="py-2">
          <TimeInputSelectorMenu
            className="!max-w-[180px]"
            day={this.state.day || 1}
            month={this.state.month || 1}
            year={this.state.year || 1970}
            hour={this.state.visual.hour}
            minute={this.state.visual.minute}
            meridiem={this.state.meridiem}
            timezone={timezone}
            onSelect={this.onPickerSelected}
          />
        </div>
      </SmartPortal>
    )
  }

  render() {
    const {
      autoFocus,
      className,
      layout,
      max,
      maxLength,
      maxWidth,
      min,
      model,
      placeholder,
      prefix,
      size,
      step,
      suffix,
      type,
      validations,
      inputGroupMaxWidth,
      after,
      testKey,
      ...rest
    } = this.props

    const { id, isRequired, isValid, isPickerOpen, errors, smartValue } = this.state

    const isEditable = this.props.isEditable && !this.props.isLocked

    const classNames = clsx(className, 'TimeInputSelector', { 'is-open': isPickerOpen })

    return (
      <Manager>
        <Field
          {...rest}
          getRef={(ref) => (this.selectorRef = ref)}
          id={id}
          errors={errors}
          isValid={isValid}
          isRequired={isRequired}
          layout={layout}
          smartValue={smartValue}
          className={classNames}
          maxWidth={maxWidth}
          testKey={testKey}
        >
          <Reference innerRef={(node) => (this.referenceRef = node)}>
            {({ ref }) => (
              <>
                {isEditable ? (
                  <>
                    <div ref={ref} className="!w-full" onFocus={this.open} onClick={this.open} onBlur={this.close}>
                      {this.editRender()}
                    </div>
                  </>
                ) : (
                  this.readOnlyRender()
                )}
              </>
            )}
          </Reference>

          {after}

          {isPickerOpen && this.renderSelector()}
        </Field>
      </Manager>
    )
  }
}

TimeInputSelector.defaultProps = {
  isEditable: true,
  validateOn: 'blur-change',
}

export default withFormContext(TimeInputSelector)
