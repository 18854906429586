import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ClientsCommunicationsReportDataTable } from '@behavehealth/constructs/LiveReports/ClientsCommunicationsReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ClientsCommunications = () => {
  const tableProps = useDataTable({
    name: ['reports', 'clients-communications'],
    endpoint: `/live_reports?category=client_communications`,
    localStorageKey: 'report_clients_communications_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/communications/${rowData.id}`, [])

  return (
    <Page feature="communications" title="Live Client Communications Report">
      <Grid>
        <ClientsCommunicationsReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClientsCommunications)
