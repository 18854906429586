import React from 'react'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'
import { PropertyCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/PropertyCustomNoteTemplatesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'property_custom_note_templates',
  title: 'Location Custom Note Templates',
}

const PropertyCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'property' },
    localStorageKey: 'property_custom_note_templates_v1',
  })

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <PropertyCustomNoteTemplatesDataTable
          to={(row: any) => row.id}
          {...tableProps}
          duplicateColumn={setDuplicateColumnV6({
            link: 'new',
            permission: 'settings.property_custom_note_templates.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withMarketing(PropertyCustomNoteTemplates, pageConfig)
