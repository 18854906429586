import React from 'react'
import { useParams } from 'react-router-dom'

import { DataFormOverlay } from '../DataFormOverlay'

import Button from '../../components/Button'
import Checkbox from '../../components/Forms/Checkbox'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import { DiagnosisOverlay } from '../Diagnoses/DiagnosisOverlay'

export const SEDOverlay = (props: any) => {
  const { resource_id: clientId }: any = useParams()

  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Serious Emotional Disturbance (SED)"
      category="clinical_measurement"
      subcategory="sed"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      {({ isEditable }) => (
        <>
          <Section
            title="Instructions"
            headingType="h2"
            scrollview={{
              id: 'instructions',
              name: 'Instructions',
            }}
            commentsModel="data.instructions"
          >
            <div className="grid gap-4">
              <div>
                <div>
                  <b>SED DEFINITION</b>
                </div>
                <div>
                  Serious Emotional Disturbance (SED) is the inclusive term for children and adolescents whose emotional and mental
                  disturbances severely limit their development and welfare over a significant period of time and requires a comprehensive
                  coordinated system of care to meet their needs.
                </div>
              </div>

              <div>
                <div>
                  <b>SED DETERMINATION</b>
                </div>
                <div>
                  Serious emotional disturbance (SED) means, with respect to a youth between the ages of 6 and 17 years, that the youth
                  meets criteria of A and B. Youth under 6 must meet criteria in C. The severity of the child’s/ adolescent’s disturbance
                  may place or potentially place him/her at significant risk for out of school, home or community placement. Indicate the
                  appropriate response to each of the areas below.
                </div>
              </div>
            </div>
          </Section>

          <Divider />

          <Section
            title="Questionnaire"
            headingType="h2"
            scrollview={{
              id: 'questionnaire',
              name: 'Questionnaire',
            }}
            commentsModel="data.questionnaire"
          >
            <FormSection>
              <YesNoRadioGroup
                label={
                  <>
                    <div>A. PRIMARY DIAGNOSIS:</div>
                    <div className="font-[400]">
                      The youth has been determined by a licensed mental health professional as having an SED diagnosis as described within
                      the Children’s Mental Health Bureau Medicaid Services Provider Manual (Effective October 1, 2021){' '}
                      <a href="https://dphhs.mt.gov/BHDD/cmb/Manuals" target="_blank">
                        https://dphhs.mt.gov/BHDD/cmb/Manuals
                      </a>
                    </div>
                  </>
                }
                model="data.questionnaire.q_a"
              />

              <div>
                {/* <Input label="PRIMARY DIAGNOSIS" model="data.questionnaire.primary_diagnosis" /> */}

                <OverlaySelector
                  includeObject
                  label="PRIMARY DIAGNOSIS"
                  model="data.questionnaire.primary_diagnosis"
                  description="(See above Manual, ICD 10 Name and Code) and date (annual reassessment is required)"
                  type="client.diagnoses"
                  icon="diagnosis"
                  dependentValue={clientId}
                  selectTitle={(data: any) => data?.code}
                  selectDescription={(data: any) => data?.description}
                  labelAfter={
                    isEditable &&
                    clientId && (
                      <SummonOverlay key={`client-${clientId}`} overlay={<DiagnosisOverlay dataID="new" clientId={clientId} />}>
                        <Button label={`Add New`} size={100} glyph="add" type="minimal" className="-mr-1.5" />
                      </SummonOverlay>
                    )
                  }
                />
              </div>

              <Divider className="!m-0" />

              <YesNoRadioGroup
                label={
                  <>
                    <div>B. FUNCTIONAL IMPAIRMENT:</div>
                    <div className="font-[400]">
                      As a result of the youth's diagnosis determined in A and for a period of at least 6 months, or for a predictable
                      period over 6 months, the youth consistently and persistently demonstrates behavioral abnormality in two or more
                      spheres, to a significant degree, well outside normative developmental expectations, that cannot be attributed to
                      intellectual, sensory, or health factors:
                    </div>
                  </>
                }
                model="data.questionnaire.q_b"
              />

              <div className="mt-4">
                <b>PLEASE CHECK THE BOXES AND COMPLETE “As Evidenced By.”</b>
              </div>

              <Checkbox
                label="Has failed to establish or maintain developmentally and culturally appropriate relationships with adult care givers or authority figures;"
                model="data.questionnaire.q_b_1"
              />
              <ContextShow when="data.questionnaire.q_b_1" is={true}>
                <Textarea label="As Evidenced By:" model="data.questionnaire.q_b_1_description" />
              </ContextShow>

              <Divider className="!m-0" />

              <Checkbox
                label="Has failed to demonstrate or maintain developmentally and culturally appropriate peer relationships;"
                model="data.questionnaire.q_b_2"
              />
              <ContextShow when="data.questionnaire.q_b_2" is={true}>
                <Textarea label="As Evidenced By:" model="data.questionnaire.q_b_2_description" />
              </ContextShow>

              <Divider className="!m-0" />

              <Checkbox
                label="Has failed to demonstrate a developmentally appropriate range and expression of emotion or mood;"
                model="data.questionnaire.q_b_3"
              />
              <ContextShow when="data.questionnaire.q_b_3" is={true}>
                <Textarea label="As Evidenced By:" model="data.questionnaire.q_b_3_description" />
              </ContextShow>

              <Divider className="!m-0" />

              <Checkbox
                label="Has displayed disruptive behavior sufficient to lead to isolation in or from school, home, therapeutic or recreation settings;"
                model="data.questionnaire.q_b_4"
              />
              <ContextShow when="data.questionnaire.q_b_4" is={true}>
                <Textarea label="As Evidenced By:" model="data.questionnaire.q_b_4_description" />
              </ContextShow>
            </FormSection>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}
