import React from 'react'

import Grid from '@behavehealth/components/Grid'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { ProgramsDataTable } from '@behavehealth/constructs/Programs/ProgramsDataTable'

const RootProgramsTablePage: React.FC = () => {
  const tableProps: any = useDataTable({
    name: ['programs'],
    endpoint: `/programs`,
    localStorageKey: 'programs_v1',
  })

  return (
    <div css={STYLES.root}>
      <Grid gap="1rem" columns="100%">
        <ProgramsDataTable {...tableProps} to={(row) => `/programs/${row.id}`} />
      </Grid>
    </div>
  )
}

const STYLES = {
  root: {
    padding: '1rem',
  },
}

export const ProgramsTablePage = withPageError(RootProgramsTablePage)
