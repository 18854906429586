import React from 'react'

import { COLORS } from '../../../theme'
import { DT, usDate } from '../../../utils/functions'

import { DatePicker } from '../../../components/DatePicker'
import { DateRangeSelector } from '../../../components/Forms/DateRangeSelector'
import Button from '../../../components/Button'
import ButtonGroup from '../../../components/ButtonGroup'
import Flex from '../../../components/Flex'
import Glyph from '../../../components/Glyph'

import { FilterDropdown } from './FilterDropdown'

import { useStore } from '../useStore'

export const CONDITIONS: any = {
  between: 'Between',
  less_than: 'Before',
  greater_than: 'After',
  eq: 'On',
}

const CONDITION_GLYPHS: any = {
  between: 'between',
  less_than: 'less_than',
  greater_than: 'greater_than',
  eq: 'equals',
}

export const DateTimeFilter: React.FC<any> = (props) => {
  const { filter, filterKey, onUpdate, timezone } = props

  const removeFilter: any = useStore((state: any) => state.removeFilter)

  const [isOpen, setIsOpen] = React.useState(false)
  const [condition, setCondition] = React.useState(filter?.condition || 'between')

  const initialStartDate = filter?.condition === 'between' ? filter?.value?.from : filter?.value
  const initialEndDate = filter?.value?.until

  const [startDate, setStartDate] = React.useState(initialStartDate)
  const [endDate, setEndDate] = React.useState(initialEndDate)

  const handleApply = () => {
    const startDT = startDate ? DT(startDate, timezone).startOf('day') : null
    const endDT = endDate ? DT(endDate, timezone).endOf('day') : startDT ? startDT.endOf('day') : null

    if (condition === 'between' && startDT && endDT) {
      onUpdate?.({
        condition,
        value: { from: startDT.toISO(), until: endDT.toISO() },
      })
    } else if (condition === 'eq' && startDT) {
      onUpdate?.({
        condition: 'eq',
        value: { from: startDT.toISO(), until: startDT.endOf('day').toISO() },
      })
    } else if (startDT) {
      onUpdate?.({
        condition,
        value: startDT.toISO(),
      })
    }

    setIsOpen(false)
  }

  const handleClear = () => {
    setStartDate(null)
    setEndDate(null)
    removeFilter(filterKey)
    setIsOpen(false)
  }

  const label = React.useMemo(() => {
    if (!startDate || !CONDITIONS?.[condition]) return null

    if (condition === 'between' && endDate) {
      return `${usDate(startDate, timezone)} → ${usDate(endDate, timezone)}`
    }

    return `${CONDITIONS[condition]} ${usDate(startDate, timezone)}`
  }, [condition, startDate, endDate, timezone])

  React.useEffect(() => {
    if (!filter?.value?.from) return

    if (filter.value.from !== startDate) setStartDate(filter.value.from)

    if (filter.value.until && filter.value.until !== endDate) setEndDate(filter.value.until)
  }, [filter])

  React.useEffect(() => {
    if (!filter?.condition) return

    if (filter.condition !== condition) setCondition(CONDITIONS.hasOwnProperty(filter.condition) ? filter.condition : 'between')
  }, [filter?.condition])

  return (
    <FilterDropdown
      open={isOpen}
      onOpenChange={(newIsOpen: boolean) => {
        if (!newIsOpen) handleApply() // apply filter when dropdown is closed

        setIsOpen(newIsOpen)
      }}
      testKey="date_time_filter_dropdown"
      hasActiveFilters={!!label}
      label={label || 'Select…'}
    >
      <>
        <div className="px-3 py-1.5 border-b border-solid border-0 border-divider flex justify-center">
          <ButtonGroup>
            {Object.keys(CONDITIONS).map((key) => (
              <Button
                key={key}
                size={100}
                color="gray"
                label={CONDITIONS[key]}
                type={condition === key ? 'primary' : 'default'}
                onClick={() => {
                  setCondition(key)
                }}
                graphic={<Glyph glyph={CONDITION_GLYPHS[key]} color={COLORS.textMuted} size={16} />}
              />
            ))}
          </ButtonGroup>
        </div>

        {condition === 'between' ? (
          <DateRangeSelector
            isInline
            timezone={timezone}
            startDate={initialStartDate}
            endDate={initialEndDate}
            defaultPeriod={null}
            onChange={(rangeDates) => {
              setStartDate(rangeDates?.[0])
              setEndDate(rangeDates?.[1])
            }}
            dropdownCss={STYLES.dropdown}
          />
        ) : (
          <DatePicker
            isInline
            stopPropagation
            timezone={timezone}
            date={startDate}
            maxYear={2030}
            minYear={1950}
            onSelect={(date) => {
              if (!date) return
              setStartDate(date.toISODate())
              setEndDate(date.toISODate())
            }}
          />
        )}
      </>

      <Flex gap="0.5rem" className="p-2">
        <Button
          label="Apply"
          glyph="tick_circle"
          color="green"
          type="primary"
          size={100}
          className="flex-grow-3"
          onClick={handleApply}
          isDisabled={!startDate || condition === 'between' ? !endDate : false}
        />
        <Button label="Clear Filter" glyph="cross" color="red" size={100} className="flex-grow-0" onClick={handleClear} />
      </Flex>
    </FilterDropdown>
  )
}

const STYLES = {
  dropdown: {
    width: 'auto',
  },
}
