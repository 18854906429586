import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import DiagnosisCategoryStatus, { STATUSES as CATEGORY_STATUSES } from '../../components/Statuses/DiagnosisCategoryStatus'
import DiagnosisStatus, { STATUSES } from '../../components/Statuses/DiagnosisStatus'
import { DEFAULT_FILTERS } from '../Filters/constants'

const SOURCE: any = {
  internal: 'Current Facility',
  erx: 'eRX',
}

export const DiagnosesDataTable = (props: any) => {
  const columns = React.useMemo(
    () => [
      {
        title: 'Code',
        model: 'code',
        isSticky: true,
        formatValue: ({ data, value }: any) => <MainCell to={props.to?.(data?.id)} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 100,
        formatValue: ({ value }: any) => <DiagnosisStatus status={value} />,
      },
      {
        title: 'Category',
        model: 'category',
        width: 130,
        formatValue: ({ value }: any) => <DiagnosisCategoryStatus status={value} />,
      },
      {
        title: 'Source',
        model: 'source',
        width: 130,
        formatValue: ({ value }: any) => SOURCE?.[value] || null,
      },
      {
        title: 'Description',
        model: 'description',
        width: 200,
      },
      {
        title: 'From Date',
        model: 'from',
        type: 'date',
      },
      {
        title: 'Until Date',
        model: 'until',
        type: 'date',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
    ],
    [props.to],
  )

  return (
    <DataTable
      title="Diagnoses"
      testKey="diagnoses_data_table"
      icon="diagnosis"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...props}
    />
  )
}

const FILTERS_CONFIG = {
  code: {
    label: 'code',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    condition: 'in',
    options: Object.entries(STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    condition: 'in',
    options: Object.entries(CATEGORY_STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  source: {
    label: 'Source',
    type: 'multi_select',
    condition: 'in',
    options: Object.entries(SOURCE).map(([value, label]) => ({ value, label })),
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  from: {
    label: 'From Date',
    type: 'date_time',
    glyph: 'date',
  },
  until: {
    label: 'Until Date',
    type: 'date_time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
