import React from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import produce from 'immer'
import isEqual from 'react-fast-compare'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import lodashIsEqual from 'lodash/isEqual'
import size from 'lodash/size'

import { Manager, Reference } from 'react-popper'

import { isBlank, arrayToMap, getClientLink } from '../../../utils/functions'
import { request } from '../../../modules/axios'
import { validate } from '../validators'

import Field from '../Field'
import FieldBase from '../FieldBase'

export const LINKS: any = {
  clients: getClientLink,
  current_clients: getClientLink,
  applicants_and_clients: getClientLink,
  billable_clients: getClientLink,
  'clients.admissions_and_current': getClientLink,
  employees: (item: string) => `/employees/${item?.id}`,
  'employees.active': (item: string) => `/employees/${item?.id}`,
  properties: (item: string) => `/locations/${item?.id}`,
  programs: (item: string) => `/programs/${item?.id}`,
  program_lists: (item: string) => `/programs/${item?.program?.id}/lists/today`,
  offices: (item: string) => `/offices/${item?.id}`,
  organizations: (item: string) => `/community/organizations/${item?.id}`,
  insurance_global_payers: (item: string) => `/insurance_global_payers`,
}

const GROUP_BY_CONFIGS = {
  employees: {
    accessor: 'position',
    sections: {
      owner: 'Owners',
      administrator: 'Administrators',
      medical_director: 'Medical Directors',
      psychiatrist: 'Psychiatrists',
      nurse: 'Nurses',
      medical_technician: 'Medical Technicians',
      clinical_director: 'Clinical Directors',
      therapist: 'Therapists',
      counselor: 'Counselors',
      behavioral_health_technician: 'Behavioral Health Technicians',
      housing_director: 'Housing Directors',
      house_manager: 'House Managers',
      program_director: 'Program Directors',
      admissions_director: 'Admissions Directors',
      support_staff: 'Support Staff',
      housing_supervisor: 'Housing Supervisor',
      accounting: 'Accounting',
    },
  },
  clients: {
    accessor: 'sex',
    sections: {
      male: 'Male',
      female: 'Female',
      _other: 'N/A',
    },
  },
  properties: {
    accessor: 'gender',
    sections: {
      male: 'Male',
      female: 'Female',
      mixed: 'Mixed',
    },
  },
  organizations: {
    accessor: 'category',
    sections: {
      provider: 'Provider',
      resource: 'Resource',
      vendor: 'Vendor',
    },
  },
  medications: {
    accessor: 'description',
    sections: {
      medical: 'Medical',
      psychiatric: 'Psychiatric',
      taper: 'Taper',
      palliatives: 'Palliatives',
      mat: 'MAT',
      vaccine: 'Vaccine',
    },
  },
}

const SELECTOR_GROUP_BY_TYPE = {
  applicants_and_clients: GROUP_BY_CONFIGS.clients,
  applicants: GROUP_BY_CONFIGS.clients,
  bh_employees: {
    all: GROUP_BY_CONFIGS.employees,
    billing: GROUP_BY_CONFIGS.employees,
  },
  clients: GROUP_BY_CONFIGS.clients,
  current_clients: GROUP_BY_CONFIGS.clients,
  clients_without_admission: GROUP_BY_CONFIGS.clients,
  clients_without_phase: GROUP_BY_CONFIGS.clients,
  clients_outside_program: GROUP_BY_CONFIGS.clients,
  employees: GROUP_BY_CONFIGS.employees,
  properties: GROUP_BY_CONFIGS.properties,
  offices: GROUP_BY_CONFIGS.offices,
  organizations: GROUP_BY_CONFIGS.organizations,
  clinical_supervisors: GROUP_BY_CONFIGS.employees,
  client: {
    medications: GROUP_BY_CONFIGS.medications,
  },
}

const DEFAULT_EMPTY_VALUE = null

export const getDefaultGroupBy = (type) => {
  return get(SELECTOR_GROUP_BY_TYPE, type)
}

export default class SelectorBase extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations
    let object = props.value || props.defaultValue || props.form?.getInitialInputFieldValue(props.model) || DEFAULT_EMPTY_VALUE

    // if the record is a value and not a relation find the object
    if (!props.isRelation && props.apiData) {
      for (let i = 0; i < props.apiData.length; i++) {
        if (props.apiData[i].model === object) {
          object = props.apiData[i]
          break
        }
      }
    }

    // if the initial model is required, flag the object as such
    // if (props.initialModelRequired && stateValue) stateValue.isRequired = true
    let isDisabled = props.disableUnless ? isBlank(get(object, props.disableUnless)) : false

    if (vs) errors = validate(object, vs)

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      object: object,
      value: object,
      isRelation: props.isRelation,
      isPolymorphic: props.isPolymorphic,
      isNested: props.isNested,
      isHighlighted: false,
      includeObject: props.includeObject,
      apiData: props.apiData || [],
      isLoading: false,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      isDisabled: isDisabled,
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      object: object,
      value: object,
      apiData: props.apiData || [],
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.referenceRef = null
    this.fieldRef = null
    this.inputRef = null

    this.eventsQueue = []
    this.processedEventsQueue = []
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.isEditable !== this.props.isEditable || nextProps.isDisabled !== this.props.isDisabled) {
      this.updateType = 'STATE'
      return true
    }

    if (nextProps.layout !== this.props.layout) {
      this.updateType = 'STATE'
      return true
    }

    if (!lodashIsEqual(nextProps.blacklistIDs, this.props.blacklistIDs)) {
      this.updateType = 'STATE'
      return true
    }

    if (!isEqual(this.state, nextState)) {
      this.updateType = 'DATA'
      return true
    }

    if (!isEqual(arrayToMap(this.props.value), arrayToMap(nextProps.value))) {
      this.updateType = 'PROPS'
      return true
    }

    if (this.props.dependent && get(this.props.data, this.props.dependent) !== get(nextProps.data, this.props.dependent)) {
      this.updateType = 'DATA'
      return true
    }

    if (nextProps.disableUnless && get(this.props.data, this.props.disableUnless) !== get(nextProps.data, this.props.disableUnless)) {
      this.updateType = 'DATA'
      return true
    }

    if (
      this.props.value !== nextProps.value ||
      this.props.defaultValue !== nextProps.defaultValue ||
      this.props.model !== nextProps.model ||
      this.props.isLoading !== nextProps.isLoading ||
      !isEqual(nextProps.validations, this.props.validations) ||
      !isEqual(nextProps.tooltip, this.props.tooltip)
    ) {
      this.updateType = 'PROPS'
      return true
    }

    if (
      this.props.placeholder !== nextProps.placeholder ||
      this.props.description !== nextProps.description ||
      this.props.labelAfter !== nextProps.labelAfter ||
      this.props.isDisabled !== nextProps.isDisabled ||
      this.props.color !== nextProps.color ||
      this.props.label !== nextProps.label ||
      this.props.count !== nextProps.count ||
      this.props.suffix !== nextProps.suffix ||
      this.props.children !== nextProps.children
    ) {
      this.updateType = 'EXTERNAL'
      return true
    }

    return false
  }

  componentDidUpdate = (prevProps: any) => {
    // states update
    if (this.updateType === 'STATE') return

    // internal update
    if (this.updateType === 'DATA') {
      if (this.props.form) this.props.form.change(this.state)
      if (this.props.onUpdate) this.props.onUpdate(this.state)

      // empty the cached API data if the dependent has changed
      if (
        this.props.dependent &&
        !isEqual(get(this.props.form?.getFormValue(), this.props.dependent), get(prevProps.form.getFormValue(), this.props.dependent))
      ) {
        this.setState({ apiData: [] })
      }

      if (this.props.disableUnless) {
        if (isBlank(this.props.data[this.props.disableUnless])) {
          this.setState({ isDisabled: true })
        } else {
          this.setState({ isDisabled: false })
        }
      }
    } else if (this.updateType === 'PROPS') this.queue({ type: 'UPDATE' })
  }

  /*
    CUSTOM FUNCTIONS
  */
  // PROCESSING FUNCTIONS
  findURL = (type: any, dependent: any) => {
    const URLS = {
      applicants_and_clients: '/residents?status=lead,intake,current',
      applicants: '/intake_applications?status=new,sent,opened,partial_completion,submitted',
      application_forms: '/application_forms',
      bh_employees: {
        all: '/bh_employees',
        billing: '/bh_employees?positions=billing',
      },
      clients: '/residents',
      current_clients: '/residents?status=current',
      clients_by_status: {
        applicants: '/residents?status=lead',
        accepted: '/residents?status=intake',
        declined: '/residents?status=declined',
        current: '/residents?status=current',
        alumni: '/residents?status=alumni',
      },
      clients_without_admission: '/residents/without_admission',
      clients_without_phase: '/residents/without_phase',
      clients_outside_program: `/residents/outside_program?program_id=${dependent}`,
      owners_administrators: '/employees?positions=owner,administrator',
      employees: '/employees?status=active',
      employee: {
        credentials: `/employees/${dependent}/credentials`,
      },
      all_properties: '/houses',
      all_portal_shared_properties: '/houses?is_shared_with_portal=true',
      properties: '/houses?category=housing,mixed_category',
      offices: '/houses?category=office',
      organizations: '/organizations',
      all_portal_shared_organizations: '/organizations?is_shared_with_portal=true',
      programs: '/programs',
      level_of_care_programs: '/programs',
      local_treatment_centers: '/local_organizations?category=provider&subcategory=treatment_centers',
      treatment_centers: '/organizations?category=provider&subcategory=treatment_center',
      treatment_plan: {
        treatment_problems: `/treatment_plans/${dependent}/treatment_problems`,
        treatment_goals: `/treatment_plans/${dependent}/treatment_goals`,
        treatment_objectives: `/treatment_plans/${dependent}/treatment_objectives`,
        treatment_interventions: `/treatment_plans/${dependent}/treatment_interventions`,
        treatment_diagnoses: `/treatment_plans/${dependent}/treatment_diagnoses`,
      },
      treatment_book_goals: {
        clinical: `/treatment_book_goals?category=clinical`,
        medical: `/treatment_book_goals?category=medical`,
        case_management: `/treatment_book_goals?category=case_management`,
        peer: `/treatment_book_goals?category=peer`,
      },
      treatment_book_objectives: {
        clinical: `/treatment_book_objectives?category=clinical`,
        medical: `/treatment_book_objectives?category=medical`,
        case_management: `/treatment_book_objectives?category=case_management`,
        peer: `/treatment_book_objectives?category=peer`,
      },
      treatment_book_interventions: {
        clinical: `/treatment_book_interventions?category=clinical`,
        medical: `/treatment_book_interventions?category=medical`,
        case_management: `/treatment_book_interventions?category=case_management`,
        peer: `/treatment_book_interventions?category=peer`,
      },
      chat: {
        company: {
          departments: `/chat/channels/company_by_category?category=departments`,
          roles: `/chat/channels/company_by_category?category=roles`,
          properties: `/chat/channels/company_by_category?category=properties`,
          programs: `/chat/channels/company_by_category?category=programs`,
        },
        clients: {
          applicants: `/chat/channels/clients_by_category?category=lead`,
          accepted: `/chat/channels/clients_by_category?category=intake`,
          current: `/chat/channels/clients_by_category?category=current`,
          declined: `/chat/channels/clients_by_category?category=declined`,
          alumni: `/chat/channels/clients_by_category?category=alumni`,
        },
      },
      clinical_supervisors: '/employees?clinical_supervisors=true&status=active',
      client: {
        admissions: `/residents/${dependent}/admissions`,
        agreements: `/residents/${dependent}/agreements?variant=client`,
        contracts: `/residents/${dependent}/contracts`,
        contacts: `/residents/${dependent}/contacts`,
        contactable: `/residents/${dependent}/contactable`,
        medications: `/residents/${dependent}/meds?status=active`,
        possible_payers: `/residents/${dependent}/possible_payers`,
        payers: `/residents/${dependent}/payers`,
        signees: `/residents/${dependent}/signees?client=false`,
        clinical_notes: `/residents/${dependent}/clinical_notes`,
        housing_notes: `/residents/${dependent}/housing_notes`,
        progress_reviews: `/residents/${dependent}/data_forms?category=progress_review`,
        insurance_policies: `/residents/${dependent}/insurance_policies?status=active`,
        insurance_evobs: `/residents/${dependent}/insurance_evobs`,
        insurance_vobs: `/residents/${dependent}/insurance_vobs`,
        insurance_services: `/residents/${dependent}/insurance_services`,
        insurance_authorizations: `/residents/${dependent}/insurance_authorizations`,
        fee_schedules: `/residents/${dependent}/insurance_fee_schedules`,
        diagnoses: `/residents/${dependent}/diagnoses`,
        payment_methods: `/residents/${dependent}/payment_methods`,
        financial_transactions: {
          payments: `/residents/${dependent}/financial_transactions?category=payment`,
          credits: `/residents/${dependent}/financial_transactions?category=credit`,
          write_offs: `/residents/${dependent}/financial_transactions?category=write_off`,
          refunds: `/residents/${dependent}/financial_transactions?category=refund`,
          charges: `/residents/${dependent}/financial_transactions?category=charge`,
        },
        active_treatment_plans_by_category: {
          clinical: `/residents/${dependent}/treatment_plans?category=clinical`,
          medical: `/residents/${dependent}/treatment_plans?category=medical`,
          case_management: `/residents/${dependent}/treatment_plans?category=case_management`,
          peer: `/residents/${dependent}/treatment_plans?category=peer`,
        },
      },
      organization: {
        contacts: `/organizations/${dependent}/contacts`,
        subcategories: `/organization_subcategories?category=${dependent}`,
        payment_methods: `/organizations/${dependent}/payment_methods`,
        organization_directory_programs: `/organizations/${dependent}/organization_directory_programs`,
        organization_directory_locations: `/organizations/${dependent}/organization_directory_locations`,
      },
      program_organizations: '/organizations',
      insurance_local_payers: '/insurance_local_payers?status=active',
      insurance_local_payer: {
        insurance_services: `/insurance_local_payers/${dependent}/insurance_services`,
        insurance_codes: `/insurance_local_payers/${dependent}/insurance_codes?status=active`,
      },
      property: {
        rooms: `/houses/${dependent}/rooms`,
        floors: `/houses/${dependent}/floors`,
      },
      program: {
        phases: `/programs/${dependent}/phases`,
        available_managing_staff: `/programs/${dependent}/available_managing_staff`,
      },
      program_lists: `/phases`,
      files: {
        file_tags: '/file_tags',
      },
      client_services: '/services?only_active=true&service_type=non_healthcare',
      organization_services: '/services?only_active=true&service_type=organization',
      insurance_services: '/services?only_active=true&service_type=healthcare',
      payers: '/payers',
      client_tracks: '/client_tracks',
      organization_tracks: '/organization_tracks',
      manager_events: '/managerEvents',
      insurance_payers: '/insurance_payers',
      insurance_global_payers: '/insurance_global_payers',
      insurance_global_payer_phone_numbers_phones: `/insurance_global_payers/${dependent}/insurance_global_payer_phone_numbers?phone_type=phone`,
      insurance_global_payer_phone_numbers_faxes: `/insurance_global_payers/${dependent}/insurance_global_payer_phone_numbers?phone_type=fax`,
      insurance_global_oon_services: '/insurance_services?service_type=global_oon',
      insurance_global_services: '/insurance_global_services',
      credentials: '/credentials',
      providers: '/providers',
      organization_providers: '/organization_providers',
      products: '/products?status=active',
      discounts: '/discounts',
      company: {
        npis: `/facilities/${dependent}/credentials?category=npi`,
        providers: `/facilities/${dependent}/credentials?category=npi`,
        organization_providers: `/facilities/${dependent}/credentials?category=npi&level=organization`,
        permission_templates: `/facilities/${dependent}/permission_templates`,
      },
      permission_templates: '/permission_templates',
      insurance_codes: '/insurance_codes?status=active',
      insurance_new_codes: '/insurance_new_codes?status=active',
      insurance: {
        locations: '/houses?insurance_billing_enabled=true',
        providers: '/employees?is_healthcare_provider=true',
        claims: `/residents/${dependent}/insurance_claims`,
        claim_service_lines: `/insurance_claims/${dependent}/insurance_claim_service_lines`,
        insurance_policies: {
          insurance_codes: `/insurance_policies/${dependent}/insurance_codes`,
        },
        fee_schedules: {
          all: `/insurance_fee_schedules`,
          insurance_codes: `/insurance_fee_schedules/${dependent}/insurance_codes`,
        },
        new_fee_schedules: {
          all: `/insurance_new_fee_schedules?status=active`,
          insurance_new_fee_schedule_services: `/insurance_new_fee_schedules/${dependent}/insurance_new_fee_schedule_services`,
          insurance_new_codes: `/insurance_new_fee_schedules/${dependent}/insurance_new_codes?status=active`,
        },
        insurance_claim_templates: `/insurance_claim_templates`,
        billable_clients: `/residents/billable`,
      },
      calendars: `/calendars`,
      subscriptions: `/subscriptions`,
      smart_flags: `/smart_flags`,
      ai_assistant: {
        prompt_template_folders: '/assistant/prompt_template_folders',
      },
      community: {
        locations: '/community/locations',
        contacts: `/community/entities/${dependent}/contacts`,
        possible_staff_matches: `/community/entities/${dependent}/possible_staff_matches`,
      },
      community_profile: {
        employees: `/community/entities/${dependent}/employees`,
        locations: `/community/entities/${dependent}/locations`,
        insurance_payers: `/community/entities/${dependent}/entity_insurance_payers`,
      },
      admin: {
        facilities: '/facilities',
        communities: '/community/entities',
        facility: {
          employees: `/facilities/${dependent}/employees`,
          residents: `/facilities/${dependent}/residents`,
          global_payment_methods: `/facilities/${dependent}/global_payment_methods?category=internal_billing`,
        },
        community: {
          contacts: `/community/entities/${dependent}/contacts`,
          employees: `/community/entities/${dependent}/employees`,
          locations: `/community/entities/${dependent}/locations`,
        },
        plan_addon_service_templates: '/internal_templates?category=product',
        discount_templates: '/internal_templates?category=discount',
        feature_flag_configs: '/feature_flag_configs',
      },
      template_products: {
        healthcare_facility: `/template_products?account_type=healthcare_facility`,
        healthcare_practice: `/template_products?account_type=healthcare_practice`,
        support_practice: `/template_products?account_type=support_practice`,
        support_facility: `/template_products?account_type=support_facility`,
        provider_affiliate: `/template_products?account_type=provider_affiliate`,
      },
      service_episodes: {
        active: `/residents/${dependent}/service_episodes?status=active`,
        levels_of_care: `/residents/${dependent}/service_episodes?category=level_of_care`,
        active_levels_of_care: `/residents/${dependent}/service_episodes?status=active&category=level_of_care`,
      },
    }

    return get(URLS, type)
  }

  loadData = async () => {
    const { type, dependent, dependentValue, form, filters } = this.props
    const req = this.findURL(type, dependentValue || form.getFieldValue(dependent))

    if (!req) return

    let response = await request.get(req, { params: filters, headers: this.props.headers })

    if (this.props.sortBy) return sortBy(response.data.data || [], this.props.sortBy)
    else return get(response.data, this.props.requestKey || 'data')
  }

  open = async (event: any) => {
    event.stopPropagation()

    if (this.props.isDisabled) return
    if (this.state.isOpen) return

    const hasNoFilters = !size(this.props.filters)

    if (hasNoFilters && this.state.apiData && Object.keys(this.state.apiData)?.length > 0) {
      this.setState({ isOpen: true, isLoading: false })
    } else {
      this.setState({ isOpen: true, isLoading: true })
      let data = await this.loadData()
      this.setState({ apiData: data, isLoading: false })
    }

    document.addEventListener('click', this.close)
  }

  close = (e: any) => {
    if (this.props.isDisabled) return

    if (this.fieldRef && e && this.fieldRef.contains && this.fieldRef.contains(e.target)) return

    this.setState({ isOpen: false })

    document.removeEventListener('click', this.close)

    if (this.inputRef?.focus) this.inputRef.focus()
  }

  processUpdate = (queueEvent: any) => {
    const vs = { ...this.props.defaultValidations, ...this.props.validations }

    const newState = produce(this.state, (draft: any) => {
      draft.prevValue = draft.value

      draft.model = this.props.model
      draft.object = this.props.value
      draft.value = this.props.value

      draft.isDirty = !isEqual(draft.value, queueEvent?.prevState?.value)
      draft.isPristine = !draft.isDirty

      draft.isRequired = vs?.hasOwnProperty('presence')
      draft.defaultValue = this.props.defaultValue
    })

    this.setState({
      isHighlighted: false,
      model: this.props.model,
      object: newState.object,
      value: newState.value,
      prevValue: newState.prevValue,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
      isRequired: newState.isRequired,
      defaultValue: newState.defaultValue,
    })

    return newState
  }

  processChangeValue = (item: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = item
      draft.value = item
      draft.isOpen = false

      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
      isHighlighted: false,
    })

    document.removeEventListener('click', this.close)

    return newState
  }

  /*
    RENDER
  */
  renderEdit = () => null
  renderSelected = () => null
  renderReadOnly = () => null
  renderSelector = () => null

  getInputRef = (ref: any) => {
    this.inputRef = ref
  }

  handleSearchKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      this.close()
    }
  }

  render() {
    const { isEditable, label, labelWidth, layout, showLabel, maxWidth, className, hide, description, withHover, testKey } = this.props
    const { id, isOpen, isRequired, isValid, isLoading, isHighlighted, errors } = this.state

    if (hide) return null

    const classNames = clsx(className, 'ObjectSelector', { 'is-open': isOpen })

    return (
      <Manager>
        <Field
          getRef={(ref) => (this.fieldRef = ref)}
          className={classNames}
          errors={errors}
          id={id}
          isEditable={isEditable}
          isLoading={isLoading}
          isRequired={isRequired}
          isValid={isValid}
          isHighlighted={isHighlighted}
          label={label}
          labelAfter={this.props.labelAfter}
          showLabel={showLabel}
          labelWidth={labelWidth}
          layout={layout}
          maxWidth={maxWidth}
          description={description}
          withHover={withHover}
          testKey={testKey}
          isCompact={this.props.isCompact}
          tooltip={this.props.tooltip}
        >
          <Reference innerRef={(node) => (this.referenceRef = node)}>
            {({ ref }) => (
              <>
                {isEditable ? (
                  <>
                    <div ref={ref} css={styles}>
                      {this.renderEdit()}
                    </div>
                    {this.renderSelected()}
                  </>
                ) : (
                  this.renderReadOnly()
                )}
              </>
            )}
          </Reference>
          {isOpen && this.renderSelector()}
        </Field>
      </Manager>
    )
  }
}

const styles = {
  width: '100%',
}

SelectorBase.defaultProps = {
  showAvatars: true,
  showLabel: true,
  prefetch: false,
  searchKeys: ['name', 'description'],
  requestKey: 'data',
  isEditable: true,
  isDisabled: false,
  includeObject: false,
  initialModelRequired: false,
  hide: false,
  isRelation: true,
  isPolymorphic: false,
  isNested: false,
  validateOn: 'blur-change',
}
