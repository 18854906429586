import React from 'react'
import { Route, Switch, Redirect, useParams, useRouteMatch, useLocation } from 'react-router-dom'
import isFinite from 'lodash/isFinite'

import { countWord } from '@behavehealth/utils/functions'
import { getClientLink } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useCreate, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import Notifications from '@behavehealth/modules/notifications'

import { AccessListAuditFixOverlay } from '@behavehealth/constructs/AccessList/AccessListAuditFixOverlay'
import { AccessListDataTable } from '@behavehealth/constructs/AccessList/AccessListDataTable'
import Alert from '@behavehealth/components/Alert'
import Button from '@behavehealth/components/Button'
import Form from '@behavehealth/components/Forms/Form'
import Grid from '@behavehealth/components/Grid'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'
import Page from '@behavehealth/components/Page'
import Radio from '@behavehealth/components/Forms/Radio'
import RadioGroup from '@behavehealth/components/Forms/RadioGroup'
import { StaffAccessListAuditDataTable } from '@behavehealth/constructs/AccessList/StaffAccessListAuditDataTable'

import Flex from '@behavehealth/components/Flex'
import Glyph from '@behavehealth/components/Glyph'
import Status from '@behavehealth/components/Status'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'
import Card from '@behavehealth/components/Card'
import State from '@behavehealth/components/State'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Access',
  help: <HelpTagIframe id="staff_access_lists" />,
}

const AccessList: React.FC = () => {
  const { resource_id: id }: any = useParams()

  const form = React.useRef()
  const match = useRouteMatch()
  const location = useLocation()

  const { url } = match

  const { data: employee, isLoading: isLoadingEmployee }: any = useGet({
    name: ['employees', id],
    url: `/employees/${id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employees', id],
    url: `/employees/${id}`,
    invalidate: ['employees'],
  })

  if (!employee) return <Page isLoading {...pageConfig} />

  const hasFullAccess = employee.access_level === 'full'
  const isOwner = employee.position === 'owner'

  const auditIssuesCount = employee?.access_control_audits_with_issues_count
  const hasIssues = isFinite(auditIssuesCount) && auditIssuesCount > 0

  return (
    <Page
      actions={
        !hasFullAccess && (
          <Button
            label="Bulk Assign Clients"
            type="primary"
            glyph="add"
            link={`${location.pathname}/import`}
            permission="access_controls.actions.import"
          />
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        {isOwner && <Alert glyph="info">Owners have full access to all clients by default. This setting cannot be changed.</Alert>}

        {!isOwner && (
          <>
            <Form getForm={form} initialModel={{ access_level: employee.access_level }}>
              <RadioGroup
                label="Client Access Level"
                layout="vertical-dense"
                model="access_level"
                isDisabled={isLoadingEmployee}
                onChange={async ({ value }: any) => await updateEmployee({ access_level: value })}
              >
                <Radio label="Full access to All Clients" value="full" />
                <Radio label="Restricted access to selected Clients only" value="restricted" />
              </RadioGroup>
            </Form>

            {!hasFullAccess && (
              <div className="grid grid-cols-[100%]">
                <Tabs defaultTab="clients_access_list">
                  <Tabs.List className="-mt-2 mb-4">
                    <Tabs.Item label="Clients Access List" to={`${match.url}/list`} />
                    <Tabs.Item
                      label={
                        <div className="flex flex-nowrap items-center">
                          <span className="mr-1.5">Audit Report</span>

                          {hasIssues && (
                            <Status
                              small
                              label={
                                <span className="flex items-center flex-nowrap">
                                  <Glyph glyph="circle_error" className="!fill-[red] mr-1.5" size={14} />
                                  {countWord('Issues', auditIssuesCount)}
                                </span>
                              }
                              color="red"
                            />
                          )}
                        </div>
                      }
                      to={`${match.url}/audit`}
                    />
                  </Tabs.List>
                </Tabs>

                <Switch>
                  <Route path={`${match.path}/list`}>
                    <AccessListTab employee={employee} />
                  </Route>

                  <Route path={`${match.path}/audit`}>
                    <AuditTab employee={employee} />
                  </Route>

                  <Redirect exact from={url} to={`${url}/list`} />
                </Switch>
              </div>
            )}
          </>
        )}
      </Grid>
    </Page>
  )
}

const AccessListTab = ({ employee }: any) => {
  const id = employee.id

  const tableProps = useDataTable({
    name: ['employees', id, 'access_controls'],
    endpoint: `/employees/${id}/access_controls`,
    localStorageKey: 'employee_access_controls_v2',
  })

  return (
    <div className="grid gap-4">
      <Alert contrast glyph="warning" type="warning">
        <b>Please note:</b> when restricting access to Clients, Staff members who have permissions for features such as Live Reports,
        Generated Reports, Programs, Bed Management and more will still be able to view all clients. To restrict access to these features,
        please update the Staff member's permissions.
      </Alert>

      <AccessListDataTable {...tableProps} to={(row: any) => getClientLink(row?.resource)} />
    </div>
  )
}

const AuditTab = ({ employee }: any) => {
  const id = employee.id

  const auditTableProps = useDataTable({
    name: ['employees', 'audits', id, 'access_control_audits'],
    endpoint: `/employees/${id}/access_control_audits`,
    params: { status: 'issue' },
    keepPreviousData: false,
    localStorageKey: 'employee_access_controls_audit_v1',
  })

  const { mutateAsync: rebuildReport, isLoading: isRebuildingReport }: any = useCreate({
    name: ['employees', id, 'access_control_audits', 'rebuild'],
    url: `/employees/${id}/access_control_audits/rebuild`,
    invalidate: ['employees'],
  })

  const auditIssuesCount = employee?.access_control_audits_with_issues_count
  const hasIssues = isFinite(auditIssuesCount) && auditIssuesCount > 0

  return (
    <div className="grid gap-4">
      <Alert contrast glyph="info">
        <Flex centerY gap="1rem">
          <div className="flex-[1_1_300px]">
            The Audit Report provides a summary of any issues found with the current Staff Access List. We automatically rebuild this report
            every morning. You can also use the Rebuild Report button to refresh the audit report and review the recommendations to update
            the Staff Access List.
          </div>

          {hasIssues && (
            <Button
              label="Rebuild Audit Report"
              glyph="reset"
              type="primary"
              color="blue"
              size={200}
              onClick={async () => {
                await rebuildReport()
                Notifications.send('Audit Report Rebuilt', 'positive')
              }}
              isLoading={isRebuildingReport}
              className="!whitespace-nowrap !flex-[0_0_auto]"
            />
          )}
        </Flex>
      </Alert>

      {hasIssues ? (
        <>
          <Alert contrast glyph="circle_error" type="negative">
            <Flex centerY gap="1rem">
              <div className="flex-[1_1_300px]">
                We found {countWord('issue', auditIssuesCount)} with the current Staff Access List. Please review the audit report below and
                follow the recommendations to update the Staff Access List.
              </div>

              <SummonOverlay overlay={<AccessListAuditFixOverlay employee={employee} />}>
                <Button label="Fix All Issues…" glyph="tick_circle" type="primary" color="green" size={200} />
              </SummonOverlay>
            </Flex>
          </Alert>

          <StaffAccessListAuditDataTable {...auditTableProps} employee={employee} />
        </>
      ) : (
        <Card>
          <State
            isEmpty
            glyph="tick_circle"
            title="Access List Audit Report"
            emptyDescription="There are no issues found with the current Access List"
            emptyActions={
              <Button
                label="Rebuild Audit Report"
                glyph="reset"
                type="primary"
                color="blue"
                size={300}
                onClick={async () => {
                  await rebuildReport()
                  Notifications.send('Audit Report Rebuilt', 'positive')
                }}
                isLoading={isRebuildingReport}
                className="!whitespace-nowrap !flex-[0_0_auto]"
              />
            }
          />
        </Card>
      )}
    </div>
  )
}

export default withPageError(withMarketing(AccessList, pageConfig))
