import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { FILTERS } from '../Filters/config'

import ClinicalNoteStatus from '../../components/Statuses/ClinicalNoteStatus'

const TITLE: any = {
  all: 'Clinical Notes',
  individual: 'Individual Note',
  group_note: 'Group Note',
  clinical_group: 'Group Note',
  family_session: 'Family Session',
  case_management: 'Case Management',
}

export const ClinicalNotesGroupDataTable = (props: any) => {
  const { to, testKey } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'category',
        width: 200,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => {
          return (
            <MainCell
              testKey="clinical_note_main_cell"
              id={data.id}
              value={TITLE[data.category]}
              avatar={data.avatar}
              to={to?.(data.id, data.category)}
            />
          )
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 150,
        formatValue: ({ data }: any) => <ClinicalNoteStatus status={data.status} />,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'clinical_notes.edit',
        tagSmartCategories: 'clinical_notes',
      },
      {
        title: 'Session Start Date',
        model: 'started_at',
        type: 'date_time',
      },
      {
        title: 'Session End Date',
        model: 'ended_at',
        type: 'date_time',
      },
      {
        title: 'Duration',
        model: 'duration',
        width: 90,
        disableSort: true,
        formatValue: ({ value }: any) => (value ? `${value} min` : null),
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Additional Staff',
        model: 'employees',
        type: 'profiles',
      },
      {
        title: 'Supervisor',
        model: 'supervisor',
        type: 'profile',
      },
      {
        title: 'Supervisor Signed At',
        model: 'supervisor_signed_at',
        type: 'date_time',
      },
      {
        title: 'Signed by Staff',
        model: 'signed_by',
        type: 'profile',
      },
      {
        title: 'Staff Signed At',
        model: 'author_signed_at',
        type: 'date_time',
      },
    ]
  }, [to])

  return (
    <>
      <DataTable
        testKey={testKey}
        asCard
        title="Clinical Notes"
        icon="clinical_notes"
        columns={columns}
        filtersConfig={FILTERS.clinical_notes}
        {...props}
      />
    </>
  )
}
