import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'

export const AgreementsDataTable = (props: any) => {
  const { to, duplicateColumn, title = 'Agreements' } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} avatar={data.avatar} to={to?.(data)} />,
        disableHide: true,
      },
      {
        title: 'Status',
        width: 180,
        id: 'status',
        model: 'status',
        type: 'title',
      },
      {
        title: '# Signees',
        width: 180,
        id: 'contracts_count',
        model: 'contracts_count',
      },
      {
        title: 'Added By',
        width: 180,
        id: 'author',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Review Cycle',
        width: 140,
        id: 'review_cycle',
        model: 'review_cycle',
        type: 'title',
      },
      {
        title: 'Effective Date',
        width: 200,
        id: 'dated_at',
        model: 'dated_at',
        type: 'date_time',
      },
      {
        title: 'Expired At',
        width: 200,
        id: 'expired_at',
        model: 'expired_at',
        type: 'date_time',
      },
      {
        title: 'Last Review Date',
        width: 200,
        id: 'reviewed_at',
        model: 'reviewed_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title={title}
      testKey="agreements_data_table"
      icon="legal_agreement_alt"
      columns={columns}
      filtersConfig={FILTERS.agreements}
      {...props}
    />
  )
}
