import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { OUTCOME_MEASURES_SLUGS } from '@behavehealth/utils/constants'

import { DischargeSurveyReportDataTable } from '@behavehealth/constructs/LiveReports/DischargeSurveyReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const DischargeSurvey = () => {
  const tableProps = useDataTable({
    name: ['reports', 'discharge_survey'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_discharge_survey_v1',
    params: { category: 'discharge_survey' },
  })

  const to = React.useMemo(
    () => (rowData: any) =>
      `${getResourceLink(rowData.client)}/outcome-measures/${OUTCOME_MEASURES_SLUGS?.[rowData?.subcategory]}/${rowData.id}`,
    [],
  )

  return (
    <Page feature="outcome_measures" title="Live Discharge Survey Report">
      <Grid>
        <DischargeSurveyReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(DischargeSurvey)
