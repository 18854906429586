import React from 'react'

import { apiDelete } from '../../modules/api'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime } from '../../utils/functions'

import Button from '../../components/Button'
import ClientStatus, { STATUSES } from '../../components/Statuses/ClientStatus'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'

import { DEFAULT_FILTERS } from '../Filters/constants'

export const AccessListDataTable = (props: any) => {
  const { to, timezone, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Client',
        id: 'resource_name',
        model: 'resource.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            id={data.id}
            as={props.mainLinkAs}
            to={to ? to(data) : undefined}
            value={data?.resource?.name}
            avatar={data?.resource?.avatar || ''}
          />
        ),
      },
      {
        title: 'Client ID',
        id: 'resource_behave_id',
        model: 'resource.behave_id',
        formatValue: ({ value }: any) => (value ? `#${value}` : null),
      },
      {
        title: 'Status',
        id: 'resource_status',
        model: 'resource.status',
        formatValue: ({ value }: any) => <ClientStatus small status={value} />,
      },
      {
        title: "Client's Programs",
        id: 'resource_programs',
        model: 'resource.programs',
        type: 'profiles',
        disableSort: true,
      },
      {
        title: "Client's Location",
        id: 'resource_house',
        model: 'resource.house',
        type: 'profile',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        formatValue: ({ value }: any) => usDateTime(value, timezone),
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Actions',
        model: 'actions',
        disableSort: true,
        hoverExpand: false,
        formatValue: ({ data }: any) => (
          <ConfirmDialog
            title="Remove client?"
            message={`Remove ${data.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
            yesLabel="Remove"
            noLabel="Cancel"
            yesColor="red"
            glyph="delete"
            onYes={() => {
              apiDelete({ name: 'access_controls', url: `/access_controls/${data?.id}` })
              rest?.refetch?.()
            }}
          >
            <Button label="Remove" size={100} type="default" color="red" glyph="delete" />
          </ConfirmDialog>
        ),
      },
    ],
    [to, timezone],
  )

  return (
    <DataTable
      asCard
      title="Clients"
      icon="clients"
      emptyDescription="No clients added to this access list yet"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  resource_name: {
    label: 'Name',
    type: 'string',
  },
  resource_behave_id: {
    label: 'Client ID',
    type: 'string',
  },
  resource_status: {
    label: 'Status',
    type: 'multi_select',
    options: Object.keys(STATUSES).map((key) => ({
      label: STATUSES[key].label,
      value: key,
    })),
  },
  resource_house: {
    label: 'Location',
    type: 'multi_object',
    endpoint: '/houses',
    apiKey: 'properties',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  resource_programs: {
    label: 'Programs',
    type: 'multi_object',
    endpoint: '/programs',
    apiKey: 'programs',
    showAvatar: true,
    polymorphic: false,
    selectTitle: (data: any) => data.name,
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
