import React from 'react'

import Alert from './Alert'
import Glyph from './Glyph'

type Props = {
  contrast?: boolean
  glyph?: string
  localStorageKey: string
  type?: string
  className?: string
}

const LOCAL_STORAGE_KEY = 'bh.tips'

const dismiss = (localStorageKey: string) => {
  if (!localStorageKey) return

  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)),
      [localStorageKey]: true,
    }),
  )
}

const getValue = (localStorageKey: string) => {
  const settings: any = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!settings) return null

  return JSON.parse(settings)[localStorageKey]
}

const TipAlert: React.FC<Props> = ({ small, children, contrast, glyph = 'info', localStorageKey, type = 'default', className }) => {
  const [isDismissed, setIsDismissed] = React.useState(getValue(localStorageKey))

  if (isDismissed) return null

  const onDismiss = () => {
    setIsDismissed(true)
    dismiss(localStorageKey)
  }

  return (
    <Alert
      small={small}
      glyph={glyph}
      type={type}
      contrast={contrast}
      actions={
        <button onClick={onDismiss} css={styles}>
          <Glyph glyph="cross" size="1rem" color="text" />
        </button>
      }
      className={className}
    >
      {children}
    </Alert>
  )
}

const styles = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
}

export default TipAlert
