import React from 'react'
import isFinite from 'lodash/isFinite'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'

import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import GridTable from '../../components/GridTable'
import Section from '../../components/Section'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import Score from './common/Score'

export const CAGEAIDOverlay = (props: any) => {
  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="CAGE-AID Questionnaire"
      category="clinical_measurement"
      subcategory="cage_aid"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Questionnaire"
        headingType="h2"
        scrollview={{
          id: 'questionnaire',
          name: 'Questionnaire',
        }}
        commentsModel="data.questionnaire"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = () => {
  const { isPortal } = useSettings()

  const [score, setScore]: any = React.useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  })

  const finalScore = Object.values(score).reduce((a, b) => {
    if (a !== null && b !== null && isFinite(a) && isFinite(b)) {
      return a + b
    }
  })

  return (
    <>
      <FormSection>
        <div>When thinking about drug use, include illegal drug use and the use of prescription drug other than prescribed.</div>

        <YesNoRadioGroup
          label="1. Have you ever felt that you ought to cut down on your drinking or drug use?"
          model="data.questionnaire.q1"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q1: state.value === true ? 1 : 0 }))}
        />

        <YesNoRadioGroup
          label="2. Have people annoyed you by criticizing your drinking or drug use?"
          model="data.questionnaire.q2"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q2: state.value === true ? 1 : 0 }))}
        />

        <YesNoRadioGroup
          label="3. Have you ever felt bad or guilty about your drinking or drug use?"
          model="data.questionnaire.q3"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q3: state.value === true ? 1 : 0 }))}
        />

        <YesNoRadioGroup
          label="4. Have you ever had a drink or used drugs first thing in the morning to steady your nerves or to get rid of a hangover?"
          model="data.questionnaire.q4"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q4: state.value === true ? 1 : 0 }))}
        />
      </FormSection>

      {!isPortal && (
        <>
          <Divider />

          <Score title="Scoring" finalScore={finalScore}>
            <div className="grid gap-2">
              <div className="-mt-1 mb-1 text-text-muted">Regard one or more positive responses to the CAGE-AID as a positive screen.</div>

              <div>
                <b>Psychometric Properties</b>
              </div>

              <GridTable templateColumns="1fr 1fr 1fr" className="border border-solid border-divider rounded-[5px]">
                <GridTable.Header>
                  <GridTable.Cell>The CAGE-AID exhibited</GridTable.Cell>
                  <GridTable.Cell>Sensitivity</GridTable.Cell>
                  <GridTable.Cell>Specificity</GridTable.Cell>
                </GridTable.Header>

                <GridTable.Row>
                  <GridTable.Cell>One or more Yes responses</GridTable.Cell>
                  <GridTable.Cell>0.79</GridTable.Cell>
                  <GridTable.Cell>0.77</GridTable.Cell>
                </GridTable.Row>

                <GridTable.Row>
                  <GridTable.Cell>Two or more Yes responses</GridTable.Cell>
                  <GridTable.Cell>0.70</GridTable.Cell>
                  <GridTable.Cell>0.85</GridTable.Cell>
                </GridTable.Row>
              </GridTable>

              <div className="italic">(Brown 1995)</div>
            </div>
          </Score>
        </>
      )}
    </>
  )
}
