import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import { titleCase, usDateTime } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import GridTable from '../../components/GridTable'
import Label from '../../components/Label'
import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'

import { NoteSignatureOverlay } from './NoteSignatureOverlay'

export const ProfileCell = ({ avatar, name, className, description }: any) => {
  const classNames = clsx('!flex !items-center !flex-nowrap', className)

  return (
    <GridTable.Cell className={classNames}>
      {name ? (
        <>
          <Avatar src={avatar} initials={name} size={20} />
          <div className="ml-1.5">
            <div className="font-[600]">{name}</div>
            <div className="text-text-muted text-[0.98em] -mt-0.5">{description}</div>
          </div>
        </>
      ) : (
        <div className="italic opacity-50">N/A</div>
      )}
    </GridTable.Cell>
  )
}

const SignCell = ({ signature, signedAt, onClick, canEdit, signee }: any) => {
  const didSign = !!signature && !!signedAt

  const { user } = useSettings()

  const isLoggedInSignee = !!user?.id && !!signee?.reference?.id && user.id === signee.reference.id

  return (
    <GridTable.Cell centerY>
      {didSign && (
        <div className="flex flex-nowrap items-center w-full">
          <Status small label={'Signed'} color="green" glyph="tick_circle" className="mr-1" />
          <Status small label={usDateTime(signedAt)} color="lightGray" glyph="date" />

          {canEdit && isLoggedInSignee && (
            <div className="pl-2">
              <Button label="Edit" glyph="edit" size={100} onClick={onClick} css={{ svg: { margin: '0 !important' } }} />
            </div>
          )}
        </div>
      )}

      {!didSign && (
        <div className="flex flex-nowrap items-center justify-between w-full">
          {canEdit && isLoggedInSignee ? (
            <Button glyph="signature" label="Add Signature" size={100} onClick={onClick} />
          ) : (
            <div className="text-text-strongly-muted">–</div>
          )}
        </div>
      )}
    </GridTable.Cell>
  )
}

export const NoteSignees = (props: any) => {
  const { signees, isEditingRecord, name, invalidate } = props

  const [currentSignee, setCurrentSignee]: any = React.useState(null)

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        width: 'minmax(160px, 2fr)',
        render: (signee: any) => (
          <ProfileCell
            avatar={signee?.reference?.avatar}
            name={signee?.reference?.name}
            description={titleCase(signee?.reference?.position)}
          />
        ),
      },
      // {
      //   title: 'Role',
      //   width: 'minmax(180px, 1fr)',
      //   render: (signee: any) => <GridTable.Cell>{titleCase(signee?.reference?.position)}</GridTable.Cell>,
      // },
      {
        title: 'Signature',
        width: 'minmax(300px, 1fr)',
        render: (signee: any) => {
          const shouldSign = true

          return (
            <SignCell
              canEdit={!isEditingRecord}
              shouldSign={shouldSign}
              signee={signee}
              signature={signee.signature}
              signedAt={signee.signed_at}
              // permissionBase={permissionBase}
              onClick={() => {
                setCurrentSignee(signee)
              }}
            />
          )
        },
      },
    ]
  }, [isEditingRecord])

  const templateColumns = React.useMemo(() => {
    return columns.map((col: any) => col.width).join(' ')
  }, [columns])

  return (
    <>
      <div className="grid gap-0">
        <Label label="Additional Staff Signatures" />

        {(isEditingRecord || size(signees) === 0) && (
          <Alert small contrast glyph="info" className="mb-3">
            Select additional staff members and save the note to enable additional signatures
          </Alert>
        )}

        {size(signees) > 0 && (
          <Card className="text-[0.92rem]">
            <GridTable testKey="signees_table" useBanding={false} templateColumns={templateColumns}>
              <GridTable.Header>
                {columns.map((col: any) => (
                  <GridTable.Cell key={col.title}>{col.title}</GridTable.Cell>
                ))}
              </GridTable.Header>

              {signees?.map?.((signee: any) => {
                return (
                  <GridTable.Row key={signee.id}>
                    {columns.map((col: any) => (
                      <React.Fragment key={`${signee.id}-${col.title}`}>{col.render?.(signee)}</React.Fragment>
                    ))}
                  </GridTable.Row>
                )
              })}
            </GridTable>
          </Card>
        )}
      </div>

      <SummonOverlay
        isOpen={!!currentSignee}
        onClose={() => {
          setCurrentSignee(null)
        }}
        overlay={<NoteSignatureOverlay dataID={currentSignee?.id} name={name} invalidate={invalidate} />}
      />
    </>
  )
}
