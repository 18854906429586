import React from 'react'
import { useLocation } from 'react-router-dom'

import Card from '../../components/Card'
import CardTitle from '../../components/CardTitle'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Divider from '../../components/Divider'
import Grid from '../../components/Grid'
import Label from '../../components/Label'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import NumberInput from '../../components/Forms/NumberInput'
import Section from '../../components/Section'

import Score from './common/Score'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'
import { useSettings } from '../../hooks/useSettings'

const INITIAL_STATE = {
  q1: 0,
  q2: 0,
  q3: 0,
  q4: 0,
  q5: 0,
  q6: 0,
  // q7 not used
  q8: 0,
  q9: 0,
  q10: 0,
  q11: 0,
  q12: 0,
  q13: 0,
  q14: 0,
  q15: 0,
  q16: 0,
  q17: 0,
}

const RootBAMROverlay = (props: any) => {
  return (
    <DataFormOverlay
      clientPortalCanEdit
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="BAM – R"
      category="clinical_measurement"
      subcategory="bam_r"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          This is a standard set of questions about several areas of your life such as your health, alcohol and drug use, etc. The questions
          generally ask about the past 30 days. Please consider each question and answer as accurately as possible.
        </p>
      </Section>

      <Divider />

      <Section
        title="Questionnaire & Scoring"
        headingType="h2"
        scrollview={{
          id: 'questionnaire_scoring',
          name: 'Questionnaire & Scoring',
        }}
        commentsModel="data.questionnaire_scoring"
      >
        <Questionnaire />
      </Section>
    </DataFormOverlay>
  )
}

const Questionnaire = (props: any) => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const finalScore = Object.values(score).reduce((a, b) => a + b)

  const { isPortal } = useSettings()

  const useScore = [score.q4, score.q5, score.q6].reduce((a, b) => a + b)
  const riskScore = [score.q1, score.q2, score.q3, score.q8, score.q11, score.q15].reduce((a, b) => a + b)
  const protectiveScore = [score.q9, score.q10, score.q12, score.q13, score.q14, score.q16].reduce((a, b) => a + b)
  const satisfactionScore = score.q17

  return (
    <>
      <Grid gap="1.5rem">
        <RadioGroup
          label="1. In the past 30 days, would you say your physical health has been:"
          model="data.questionnaire.q1"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q1: state.value }))}
        >
          <Radio label="Excellent (0)" value={0} />
          <Radio label="Very Good (8)" value={8} />
          <Radio label="Good (15)" value={15} />
          <Radio label="Fair (22)" value={22} />
          <Radio label="Poor (30)" value={30} />
        </RadioGroup>

        <NumberInput
          label="2. In the past 30 days, how many nights did you have trouble falling asleep or staying asleep?"
          model="data.questionnaire.q2"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q2: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <NumberInput
          label="3. In the past 30 days, how many days have you felt depressed, anxious, angry, or very upset throughout the most of the day"
          model="data.questionnaire.q3"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q3: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <NumberInput
          label="4. In the past 30 days, how many days did you drink ANY alcohol?"
          model="data.questionnaire.q4"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q4: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <NumberInput
          label="5. In the past 30 days, how many days did you have at least 5 drinks (if you are a man) or at least four drinks (if you are a woman)? [One drink is considered one shot of hard liquor (1.5 oz.) or 12-ounce can/bottle of beer or 5 ounce glass of wine.]"
          model="data.questionnaire.q5"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q5: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <NumberInput
          label="6. In the past 30 days, how many days did you use any illegal/street drugs or abuse any prescription medications?"
          model="data.questionnaire.q6"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q6: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <Label label="7. In the past 30 days, how many days did you use any of the following drugs:" />

        <Grid gap="1rem" className="!pl-[1.75rem]">
          <NumberInput
            label="7A. Marijuana (cannabis, pot, weed)?"
            model="data.questionnaire.marijuana_use"
            suffix="days"
            layout="vertical-dense"
          />

          <NumberInput
            label="7B. Sedatives/Tranquilizers (e.g., Benzos, Valium, Xanax, Ativan, Ambien, Barbs, Phenobarbital, downers, etc.)?"
            model="data.questionnaire.sedatives_tranquilizers_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />

          <NumberInput
            label="7C. Cocaine/Crack?"
            model="data.questionnaire.cocaine_crack_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />

          <NumberInput
            label="7D. Other Stimulants (e.g., Amphetamine, Methamphetamine, Dexedrine, Ritalin, Adderall, Speed, Crystal Meth, Ice, etc.)?"
            model="data.questionnaire.other_stimulants_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />

          <NumberInput
            label="7E. Opiates (e.g., Heroin, Morphine, Dilaudid, Demerol, Oxycontin, oxy, codeine (Tylenol 2,3,4), Percocet, Vicodin, Fentanyl, etc.)?"
            model="data.questionnaire.opiates_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />

          <NumberInput
            label="7F. Inhalants (glues/adhesives, nail polish remover, paint thinner, etc.)?"
            model="data.questionnaire.inhalants_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />

          <NumberInput
            label="7G. Other drugs (steroids, non-prescription sleep/diet pills, Benadryl, Ephedra, other overthe-counter/unknown medications)?"
            model="data.questionnaire.other_drugs_use"
            size={4}
            suffix="days"
            validations={{
              numericality: {
                greaterThanOrEqualTo: 0,
                lessThanOrEqualTo: 30,
                message: 'Please enter an number between 0 and 30',
              },
            }}
          />
        </Grid>

        <RadioGroup
          label="8. In the past 30 days, how much were you bothered by cravings or urges to drink alcohol or use drugs?"
          model="data.questionnaire.q8"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q8: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (8)" value={8} />
          <Radio label="Moderately (15)" value={15} />
          <Radio label="Considerably (22)" value={22} />
          <Radio label="Extremely (30)" value={30} />
        </RadioGroup>

        <RadioGroup
          label="9. How confident are you that you will NOT use alcohol and drugs in the next 30 days?"
          model="data.questionnaire.q9"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q9: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (8)" value={8} />
          <Radio label="Moderately (15)" value={15} />
          <Radio label="Considerably (22)" value={22} />
          <Radio label="Extremely (30)" value={30} />
        </RadioGroup>

        <NumberInput
          label="10. In the past 30 days, how many days did you attend self-help meetings like AA or NA to support your recovery?"
          model="data.questionnaire.q10"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q10: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <NumberInput
          label="11. In the past 30 days, how many days were you in any situations or with any people that might put you at an increased risk for using Alcohol or drugs (i.e., around risky people, places or things)?"
          model="data.questionnaire.q11"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q11: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <RadioGroup
          label="12. Does your religion or spiritually help support your recovery?"
          model="data.questionnaire.q12"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q12: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (8)" value={8} />
          <Radio label="Moderately (15)" value={15} />
          <Radio label="Considerably (22)" value={22} />
          <Radio label="Extremely (30)" value={30} />
        </RadioGroup>

        <NumberInput
          label="13. In the past 30 days, how many days did you spend much of the time at work,school, or doing volunteer work?"
          model="data.questionnaire.q13"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q13: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <RadioGroup
          label="14. Do you have enough income (from legal sources) to pay for necessities such as housing, transportation, food and clothing for Yourself and your dependents?"
          model="data.questionnaire.q14"
          layout="horizontal"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q14: state.value }))}
        >
          <Radio label="No (0)" value={0} />
          <Radio label="Yes (30)" value={30} />
        </RadioGroup>

        <RadioGroup
          label="15. In the past 30 days, how much have you been bothered by arguments or problems getting along with any family members or friends?"
          model="data.questionnaire.q15"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q15: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (8)" value={8} />
          <Radio label="Moderately (15)" value={15} />
          <Radio label="Considerably (22)" value={22} />
          <Radio label="Extremely (30)" value={30} />
        </RadioGroup>

        <NumberInput
          label="16. In the past 30 days, how many days were you in contact or spent time with any family members or friends who are supportive of your recovery?"
          model="data.questionnaire.q16"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q16: state.value || 0 }))}
          size={4}
          suffix="days"
          validations={{
            greaterThanOrEqualTo: { value: 0, message: 'Please enter a number greater than 0' },
            lessThanOrEqualTo: { value: 30, message: 'Please enter a number less than or equal to 30' },
          }}
        />

        <RadioGroup
          label="17. How satisfied are you with your progress toward achieving your recovery goals?"
          model="data.questionnaire.q17"
          layout="vertical-dense"
          onUpdate={(state: any) => setScore((o) => ({ ...o, q17: state.value }))}
        >
          <Radio label="Not at all (0)" value={0} />
          <Radio label="Slightly (8)" value={8} />
          <Radio label="Moderately (15)" value={15} />
          <Radio label="Considerably (22)" value={22} />
          <Radio label="Extremely (30)" value={30} />
        </RadioGroup>
      </Grid>

      <Divider />

      <Grid gap="1rem">
        <DateTimeInput defaultToNow label="Time Started" model="data.started_at" />
        <DateTimeInput label="Time Ended" model="data.ended_at" />
      </Grid>

      {!isPortal && (
        <Grid gap="1rem">
          <Divider />

          <p className="m-0">
            The scoring details below are for information only. The scoring for this survey happens on our side. Please do not provide
            survey scores.
          </p>

          <Score title="Use Score" finalScore={useScore} />
          <Score title="Risk Score" finalScore={riskScore} />
          <Score title="Protective Score" finalScore={protectiveScore} />
          <Score title="Satisfaction Score" finalScore={satisfactionScore} />
        </Grid>
      )}
    </>
  )
}

const ScoreListItem = ({ children, isActive }: any) => {
  return (
    <li css={styles.scoreListItem} className={isActive ? 'is-active' : ''}>
      {children}
    </li>
  )
}

const styles = {
  scoreListItem: {
    opacity: 0.4,

    '&.is-active': {
      opacity: 1,
      fontWeight: 700,
    },
  },
}

export const BAMROverlay = withOverlayError(RootBAMROverlay)
