import React from 'react'

import { AdminHRCustomNoteTemplatesDataTable } from '@behavehealth/constructs/CustomNoteTemplates/AdminHRCustomNoteTemplatesDataTable'
import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'admin_hr_custom_note_templates',
  title: 'Admin HR Custom Note Templates',
}

const AdminHRCustomNoteTemplates = () => {
  const tableProps = useDataTable({
    name: ['custom_note_templates'],
    endpoint: `/custom_note_templates`,
    params: { variant: 'admin_hr' },
    localStorageKey: 'admin_hr_custom_note_templates_v1',
  })

  const { isBehave, isManagement } = useSettings()

  return (
    <Page breakpoint={3} showHeader={false} useWrapper={false} {...pageConfig}>
      <Grid>
        <AdminHRCustomNoteTemplatesDataTable
          to={(row: any) => row.id}
          {...tableProps}
          duplicateColumn={setDuplicateColumnV6({
            link: 'new',
            permission: isBehave || isManagement,
          })}
        />
      </Grid>
    </Page>
  )
}

export default withMarketing(AdminHRCustomNoteTemplates, pageConfig)
