import React from 'react'

import Textarea from '../Forms/Textarea'
import Dialog from '../Dialog'

const TextareaDialog = ({ children, model, onYes, value, ...rest }) => {
  const [data, setData] = React.useState(null)

  return (
    <Dialog
      isValid={data?.isValid}
      onYes={async () => await onYes(data)}
      content={
        <Textarea
          isEditable
          model="textarea"
          defaultValue={value}
          debounce={0}
          minRows={15}
          onUpdate={setData}
          // validations={{
          //   presence: {
          //     message: 'Please enter a message',
          //   },
          // }}
        />
      }
      {...rest}
    >
      {children}
    </Dialog>
  )
}

TextareaDialog.defaultProps = {
  title: 'Please provide details',
  yesLabel: 'Save Details',
  yesColor: 'green',
  noLabel: 'Cancel',
}

export default TextareaDialog
