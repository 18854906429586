import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import size from 'lodash/size'

import withSettings from '../../../hocs/withSettings'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Grid from '../../../components/Grid'
import SummonOverlay from '../../../components/SummonOverlay'
import Workflow from '../../../components/Workflow/Workflow'
import Permission from '../../../components/Permission'

import { MainCell } from '../../../components/DataTable/cells/MainCell'
import { TreatmentEpisodeOverlay } from '../../TreatmentEpisodes/TreatmentEpisodeOverlay'
import { TreatmentEpisodesDataTable } from '../../TreatmentEpisodes/TreatmentEpisodesDataTable'
import { useDataTable } from '../../../components/DataTable/useDataTable'

const AddButton = ({ client }) => {
  return (
    <Permission permission="treatment_episodes.create">
      <SummonOverlay overlay={<TreatmentEpisodeOverlay showBackdrop dataID="new" clientId={client?.id} />}>
        <Button label="Add Treatment Episode" glyph="add" type="primary" display="inline-flex" permission="treatment_episodes.create" />
      </SummonOverlay>
    </Permission>
  )
}

const TreatmentEpisodes = ({ client, setStatus }: any) => {
  const match = useRouteMatch()

  const { goNext }: any = React.useContext(Workflow.Context)
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const [openRecord, setOpenRecord] = React.useState(null)

  const tableProps = useDataTable({
    name: ['client', client?.id, 'treatment-episodes'],
    endpoint: `/residents/${client?.id}/admissions`,
    localStorageKey: 'clients_treatment_episodes_v1',
    enabled: !!client?.id,
  })

  const isEmpty = size(tableProps.data) === 0

  const onContinue = async () => {
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  React.useEffect(() => {
    isEmpty ? setStatus('todo') : setStatus('completed')
  }, [isEmpty])

  return (
    <>
      <Grid gap="1rem">
        <Card testKey="applications_table">
          <TreatmentEpisodesDataTable asCard={false} {...tableProps} onClick={(record) => setOpenRecord(record)} />

          <CardContent>
            <AddButton client={client} />
          </CardContent>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton onClick={onContinue} />
          {!isRequired && <Workflow.SkipButton onClick={onSkip} />}
        </Workflow.Buttons>
      </Grid>

      <SummonOverlay
        key={`summon-${openRecord?.id}`}
        isOpen={!!openRecord}
        onClose={() => setOpenRecord(null)}
        overlay={<TreatmentEpisodeOverlay testKey="add_medication_overlay" dataID={openRecord?.id} match={match} />}
      />
    </>
  )
}

export default withSettings(TreatmentEpisodes)
