import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'

import PageHeader from '@behavehealth/components/PageHeader'
import HelpTagIframe from '@behavehealth/components/Help/HelpTagIframe'
import Tabs from '@behavehealth/components/Tabs'
import Tab from '@behavehealth/components/Tab'
import TabList from '@behavehealth/components/TabList'
import { BedsBoardPage } from './beds_board'
import { BedsLocations } from './beds_locations'
import { BedManagementTimeline } from './beds_timeline'

const BedManagement = () => {
  const { url } = useRouteMatch()

  return (
    <div css={styles.root}>
      <PageHeader
        showFeedbackTool
        icon="bed_management"
        title="Bed Management"
        emptyDescription="Create a room for this property to manage beds and clients"
        help={<HelpTagIframe id="bed_management_room_management" />}
        css={styles.header}
      />

      <Tabs css={styles.tabs}>
        <TabList css={styles.tabList}>
          <Tab label="Today" icon="bed_management_locations" to={`${url}/today`} />
          <Tab label="Timeline" icon="bed_management_timeline" to={`${url}/timeline`} />
          <Tab label="Board" icon="bed_management_board" to={`${url}/board`} />
          {/* <Tab label="Calendar" icon="bed_management_calendar" to={`${url}/calendar`} /> */}
        </TabList>
      </Tabs>

      <Switch>
        <Route path={`${url}/today`} component={BedsLocations} />
        <Route path={`${url}/timeline`} component={BedManagementTimeline} />
        <Route path={`${url}/board`} component={BedsBoardPage} />
        {/* <Route path={`${url}/calendar`} component={BedsCalendar} /> */}

        <Redirect exact from={url} to={`${url}/today`} />
      </Switch>
    </div>
  )
}

const styles = {
  root: {
    overflowY: 'auto',

    '@media (min-width: 1024px)': {
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr',
      overflowY: 'hidden',
    },
  },

  header: {
    zIndex: 1,
    position: 'relative',
  },

  tabs: {
    zIndex: 2,
  },

  tabList: {
    paddingLeft: '1rem',
  },
}

export default withPageError(BedManagement)
