import React from 'react'
import { useParams } from 'react-router-dom'
import isFinite from 'lodash/isFinite'

import { DataFormOverlay } from '../DataFormOverlay'
import { useSettings } from '../../hooks/useSettings'
import { DiagnosisOverlay } from '../Diagnoses/DiagnosisOverlay'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import GridTable from '../../components/GridTable'
import Input from '../../components/Forms/Input'
import NumberInput from '../../components/Forms/NumberInput'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'

import Score from './common/Score'

const INITIAL_STATE = {
  area_1: 0,
  area_2: 0,
  area_3: 0,
  area_4: 0,
  area_5: 0,
  area_6: 0,
  area_7: 0,
  area_8: 0,
}

export const SDMIOverlay = (props: any) => {
  const { resource_id: clientId }: any = useParams()

  return (
    <DataFormOverlay
      disablePdf
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Serious Disabling Mental Illness (SDMI) Criteria Form"
      category="clinical_measurement"
      subcategory="sdmi"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      {({ isEditable }) => (
        <>
          <Section
            title="Eligibility Criteria"
            headingType="h2"
            scrollview={{
              name: 'Eligibility Criteria',
              id: 'eligibility_criteria',
            }}
          >
            <FormSection>
              <RadioGroup
                label="Does this member have a diagnosis of dementia?"
                model="data.eligibility_criteria.dementia"
                layout="vertical-dense"
              >
                <Radio label="Yes (contact AMDD to request a stipulation)." value="yes" />
                <Radio
                  label="Yes, and the member has received a stipulation from AMDD. (Continue with the evaluation.)"
                  value="yes_stipulation"
                />
                <Radio label="No, this member was denied a stipulation by AMDD (Do not continue with the evaluation.)" value="no" />
                <Radio label="No, this member does not have a dementia diagnosis (Continue with the evaluation.)" value="no_dementia" />
              </RadioGroup>

              <RadioGroup layout="vertical-dense" model="data.eligibility_criteria.age_criteria">
                <Radio label="Member is 18+ years old." value="is_18_plus" />
                <Radio
                  label="Member is transitional age. (Please call the HCBS SDMI program before continuing.)"
                  value="is_transitional_age"
                />
              </RadioGroup>

              <ContextShow when="data.eligibility_criteria.age_criteria" is={'is_transitional_age'}>
                <NumberInput label="Specify Age:" model="data.eligibility_criteria.transitional_age" size={6} />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section
            title="Mental Health Diagnosis"
            headingType="h2"
            scrollview={{
              name: 'Mental Health Diagnosis',
              id: 'mental_health_diagnosis',
            }}
          >
            <FormSection>
              <Alert small contrast glyph="info">
                Both DSM V and ICD-10 codes are required in this section
              </Alert>

              <OverlaySelector
                includeObject
                label="Primary Mental Health Diagnosis"
                model="data.diagnosis.primary_diagnosis"
                type="client.diagnoses"
                icon="diagnosis"
                dependentValue={clientId}
                selectTitle={(data: any) => data?.code}
                selectDescription={(data: any) => data?.description}
                labelAfter={
                  isEditable &&
                  clientId && (
                    <SummonOverlay key={`client-${clientId}`} overlay={<DiagnosisOverlay dataID="new" clientId={clientId} />}>
                      <Button label={`Add New`} size={100} glyph="add" type="minimal" className="-mr-1.5" />
                    </SummonOverlay>
                  )
                }
              />

              {/* <Input label="Primary Mental Health Diagnosis" model="data.diagnosis.primary_diagnosis" /> */}
              <Input label="DSM V" model="data.diagnosis.dsm_v" />
              <Input label="ICD-10" model="data.diagnosis.icd_10" />
            </FormSection>
          </Section>

          <Divider />

          <Section
            title="HCBS SDMI Eligibility Criteria"
            headingType="h2"
            scrollview={{
              name: 'HCBS SDMI Eligibility Criteria',
              id: 'hcbs_sdmi_eligibility_criteria',
            }}
          >
            <FormSection>
              <CheckboxGroup layout="vertical-dense">
                <Checkbox
                  label="Has been involuntarily hospitalized for at least 30 consecutive days because of a mental disorder at Montana State Hospital (MSH) or the Montana Mental Health Nursing Care Center (MMHNCC) at least once in the past 12 months (go to attached Level of Impairment (LOI) worksheet) OR"
                  model="data.hcbs_sdmi_eligibility_criteria.involuntarily_hospitalized"
                />
                <Checkbox
                  label="Has a primary qualifying SDMI diagnosis (see attached eligible diagnoses) AND"
                  model="data.hcbs_sdmi_eligibility_criteria.primary_qualifying_sdmi_diagnosis"
                />
                <Checkbox
                  label="Has 3 areas of at least high level of impairment as indicated by a LOI score of 3 or above (go to attached Level of Impairment (LOI) worksheet)"
                  model="data.hcbs_sdmi_eligibility_criteria.areas_of_high_level_of_impairment"
                />
              </CheckboxGroup>

              <RadioGroup layout="vertical-dense" model="data.hcbs_sdmi_eligibility_criteria.has_sdmi_defined_by_hcbs">
                <Radio label="Has a SDMI as defined by HCBS waiver criteria." value={true} />
                <Radio label="Does not have a SDMI as defined by HCBS waiver criteria." value={false} />
              </RadioGroup>
            </FormSection>
          </Section>

          <Divider />

          <Section
            title="Instructions"
            headingType="h2"
            scrollview={{
              name: 'Instructions',
              id: 'instructions',
            }}
          >
            <div>
              This worksheet must be used to determine if a member has a HCBS waiver SDMI. The form must be completed by a licensed mental
              health professional. The impaired functioning must tie back to the symptoms of the mental health diagnosis for a period of at
              least 6 months or for a predicable period over 6 months within the past 12 months. Score each area with a range of 0 to 5 with
              0 being no impairment and 5 being grave. Score one time in each area of functioning.
            </div>
          </Section>

          <Divider />

          <Section
            title="Questionnaire"
            headingType="h2"
            scrollview={{
              name: 'Questionnaire',
              id: 'questionnaire',
            }}
          >
            <Questionnaire />
          </Section>

          <Divider />

          <Section
            title="HCBS SDMI Waiver Eligible Diagnoses"
            headingType="h2"
            scrollview={{
              name: 'HCBS SDMI Waiver Eligible Diagnoses',
              id: 'hcbs_sdmi_waiver_eligible_diagnoses',
            }}
          >
            <div>
              <div className="italic text-text-muted mb-4">(Effective 07/01/2020)</div>

              <Card className="overflow-x-auto">
                <GridTable templateColumns="minmax(150px,1fr) minmax(150px,1fr) minmax(250px,3fr)">
                  <GridTable.Header>
                    <GridTable.Cell>ICD 10</GridTable.Cell>
                    <GridTable.Cell>DSM 5</GridTable.Cell>
                    <GridTable.Cell>Diagnosis</GridTable.Cell>
                  </GridTable.Header>

                  {DIAGNOSES.map((diagnosis) => (
                    <GridTable.Row key={diagnosis.icd10}>
                      <GridTable.Cell>{diagnosis.icd10}</GridTable.Cell>
                      <GridTable.Cell>{diagnosis.dsm5}</GridTable.Cell>
                      <GridTable.Cell>{diagnosis.diagnosis}</GridTable.Cell>
                    </GridTable.Row>
                  ))}
                </GridTable>
              </Card>
            </div>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

const DIAGNOSES = [
  {
    icd10: 'F20.0',
    dsm5: '295.30',
    diagnosis: 'Schizophrenia, Paranoid Type',
  },
  {
    icd10: 'F20.1',
    dsm5: '295.10',
    diagnosis: 'Schizophrenia, Disorganized Type',
  },
  {
    icd10: 'F20.2',
    dsm5: '295.20',
    diagnosis: 'Schizophrenia, Catatonic Type',
  },
  {
    icd10: 'F20.3',
    dsm5: '295.90',
    diagnosis: 'Schizophrenia, Undifferentiated Type',
  },
  {
    icd10: 'F20.5',
    dsm5: '295.60',
    diagnosis: 'Schizophrenia, Residual Type',
  },
  {
    icd10: 'F22',
    dsm5: '297.1',
    diagnosis: 'Delusional Disorder',
  },
  {
    icd10: 'F25.0',
    dsm5: '295.70',
    diagnosis: 'Schizoaffective Disorder',
  },
  {
    icd10: 'F25.1',
    dsm5: '295.70',
    diagnosis: 'Schizoaffective Disorder, Depressive Type',
  },
  {
    icd10: 'F31.12',
    dsm5: '296.42',
    diagnosis: 'Bipolar I Disorder, Manic, Moderate',
  },
  {
    icd10: 'F31.13',
    dsm5: '296.43',
    diagnosis: 'Bipolar I Disorder, Manic, Severe without Psychotic Features',
  },
  {
    icd10: 'F31.2',
    dsm5: '296.44',
    diagnosis: 'Bipolar I Disorder, Manic, Severe with Psychotic Features',
  },
  {
    icd10: 'F31.32',
    dsm5: '296.52',
    diagnosis: 'Bipolar I Disorder, Depressed, Moderate',
  },
  {
    icd10: 'F31.4',
    dsm5: '296.53',
    diagnosis: 'Bipolar I Disorder, Depressed, Severe without Psychotic Features',
  },
  {
    icd10: 'F31.5',
    dsm5: '296.54',
    diagnosis: 'Bipolar I Disorder, Depressed with Psychotic Features',
  },
  {
    icd10: 'F31.62',
    dsm5: '296.62',
    diagnosis: 'Bipolar I Disorder, Mixed, Moderate',
  },
  {
    icd10: 'F31.63',
    dsm5: '296.63',
    diagnosis: 'Bipolar I Disorder, Mixed, Severe without Psychotic Features',
  },
  {
    icd10: 'F31.64',
    dsm5: '296.64',
    diagnosis: 'Bipolar I Disorder, Mixed, Severe with Psychotic Features',
  },
  {
    icd10: 'F31.81',
    dsm5: '296.89',
    diagnosis: 'Bipolar II Disorder',
  },
  {
    icd10: 'F32.2',
    dsm5: '296.23',
    diagnosis: 'Major Depressive Disorder, Single, Severe without Psychotic Features',
  },
  {
    icd10: 'F32.3',
    dsm5: '296.24',
    diagnosis: 'Major Depressive Disorder, Single, Severe with Psychotic Features',
  },
  {
    icd10: 'F33.2',
    dsm5: '296.33',
    diagnosis: 'Major Depressive Disorder, Recurrent, Severe without Psychotic Features',
  },
  {
    icd10: 'F33.3',
    dsm5: '296.34',
    diagnosis: 'Major Depressive Disorder, Recurrent, Severe with Psychotic Features',
  },
  {
    icd10: 'F43.11',
    dsm5: '309.81',
    diagnosis: 'Posttraumatic Stress Disorder, Acute',
  },
  {
    icd10: 'F43.12',
    dsm5: '309.81',
    diagnosis: 'Posttraumatic Stress Disorder, Chronic',
  },
  {
    icd10: 'F60.3',
    dsm5: '301.83',
    diagnosis: 'Borderline Personality Disorder',
  },
  {
    icd10: 'F32.1',
    dsm5: '296.22',
    diagnosis: 'Major Depressive Disorder, Single, Moderate',
  },
  {
    icd10: 'F33.1',
    dsm5: '296.32',
    diagnosis: 'Major Depressive Disorder, Recurrent, Moderate',
  },
  {
    icd10: 'F41.1',
    dsm5: '300.02',
    diagnosis: 'Generalized Anxiety Disorder',
  },
]

const Questionnaire = () => {
  const [score, setScore] = React.useState(INITIAL_STATE)

  const { isPortal } = useSettings()

  const score3OrHigher = React.useMemo(() => {
    let res = 0

    for (let key in score) {
      if (!isFinite(score[key])) continue

      if (score[key] >= 3) {
        res++
      }
    }

    return res
  }, [score])

  return (
    <FormSection>
      <RadioGroup
        label="Area 1 – Self-Care"
        layout="vertical-dense"
        model="data.questionnaire.area_1"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_1: value }))}
      >
        <Radio
          label="0 = No problems in this area: Able to care for self and provide for own needs; hygiene is good; demonstrates acceptable grooming."
          value={0}
        />
        <Radio
          label="1 = Mild level of impairment: No assistance needed in caring for self; household cleanliness and/or hygiene are sporadic. Completes self-care tasks 75 percent of the time."
          value={1}
        />
        <Radio
          label="2 = Moderate level of impairment: occasional assistance required in caring for self; household cleanliness and/or hygiene are marginal. Completes self-care tasks 50 percent of the time on own. Requires some assistance; responds to direction and cues; complies to requests to complete self-care needs."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: assistance needed in caring for self, due to inability to care for self with poor household cleanliness and hygiene. Completes self-care tasks 25 percent of the time on own. Requires multiple, cues, requests or direction to complete self-care tasks."
          value={3}
        />
        <Radio
          label="4 = Severe level of impairment: unable to care for self in a safe and sanitary manner. Will only attend to grooming, hygiene, and household cleanliness with continual supervision and direction."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: in extreme need of complete supportive care. Requires one-to-one assistance or requires nursing home level of care."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 2 – Basic Needs"
        layout="vertical-dense"
        model="data.questionnaire.area_2"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_2: value }))}
      >
        <Radio
          label="0 = No problems in this area: able to complete basic needs; follows treatment recommendations and is medication compliant at this time."
          value={0}
        />
        <Radio
          label="1 = Mild level of impairment: No assistance needed in obtaining basic needs. Misses 1 of 4 appointments; medication compliant 5 out of 7 days. Completes basic needs tasks 75 percent of the time."
          value={1}
        />
        <Radio
          label="2 = Moderate impairment: occasional assistance required in obtaining basic needs; regularly misses 50 percent of appointments; medication compliant 50 percent of the time. Completes basic needs tasks 50 percent of the time. Requires some assistance; responds to direction and cues; complies to requests to complete basic needs tasks."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: assistance needed in obtaining basic needs; lack of groceries and/or basic needs; inconsistent treatment and medication compliance. Regularly misses 75 percent of appointments and/or medication compliant 25 percent of the time. Requires multiple, cues, requests or direction to complete basic needs tasks."
          value={3}
        />
        <Radio
          label="4 = Severe level of impairment: unable to obtain basic needs in a safe and sanitary manner. Will not grocery shop without supervision; does not take medication or attend appointments without supervision; does not complete necessary paperwork for resources, services, or programs."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: in extreme need of complete su pportive care. Shut in and requires grocerydelivery, not taking medications or attendingappointments. Requires one-to-one assistance tocomplete tasks."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 3 – Employment/Education"
        layout="vertical-dense"
        model="data.questionnaire.area_3"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_3: value }))}
      >
        <Radio
          label="0 = No problems in this area: by report only and has no issues in this area and/or reports no goals or objectives in this area."
          value={0}
        />
        <Radio label="1 = Mild level of impairment: problems in this area are by report only with minimal consequences." value={1} />
        <Radio
          label="2 = Moderate level of impairment: occasional problems due to reprimands at work/school; has had two or more incidents of absenteeism/tardiness or inappropriate behavior in the past 30 days."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: job/school instability/insecurity due to disciplinary action in last 60 days."
          value={3}
        />
        <Radio
          label="4 = Severe level of impairment: Does not work or go to school due to being easily overwhelmed by demands of work/school; is unemployed with no income."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: severe and chronic difficulties. Receives social security benefits due to severity of mental illness."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 4 – Housing/Financial"
        layout="vertical-dense"
        model="data.questionnaire.area_4"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_4: value }))}
      >
        <Radio
          label="0 = No problems in this area: has an adequate income, has adequate housing, and manages finances appropriately at this time."
          value={0}
        />
        <Radio label="1 = Mild level of impairment: problems in this area are by report only with minimal consequences." value={1} />
        <Radio
          label="2 = Moderate impairment: occasional problems due to limited income, some difficulties with finances. Pays bills but often 30+ days behind; received two or more housing complaints in the past 30 days due to inappropriate behavior or tenancy problems."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: assistance needed in managing finances (impulsive with money, debt higher than income due to spending habits); housing instability due to threat of eviction or living in a residential setting or group home."
          value={3}
        />
        <Radio
          label="4 = Severe level of impairment: easily overwhelmed by finances. Has not maintained checkbook in past 60 days or has a representative payee); homeless, living with family/friends, couch surfing."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: severe and chronic difficulties. No income; risky and inappropriate financial behaviors; collection, garnishment, repossession; homeless with no options, living on the street or homeless shelter."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 5 – Family/Interpersonal Relationships"
        layout="vertical-dense"
        model="data.questionnaire.area_5"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_5: value }))}
      >
        <Radio
          label="0 = No problems in this area: has an adequate support system with family and friends; gets along well with others."
          value={0}
        />
        <Radio label="1 = Mild level of impairment: problems in this area are by report only with minimal consequences." value={1} />
        <Radio
          label="2 = Moderate impairment: difficulty developing or maintaining healthy relationships; difficulty meeting and greeting people, lack of eye contact, presents as odd, identifies 1+ friends; strained family relationships."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: inadequate relational skills resulting in tenuous and strained relationships; argumentative, lack of give and take, does not wait turn, identifies 1 friend but not close."
          value={3}
        />
        <Radio
          label="4 = Severe impairment: impaired relational skills resulting in poor relationship formation and maintenance; poor boundaries; intense love and hate interactions; impulsive; frequent angry outbursts."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: interpersonal relationships are virtually nonexistent; has no friends; isolative or others avoid due to strange or intense behaviors and interactions."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 6 – Mood/Thought Functioning"
        layout="vertical-dense"
        model="data.questionnaire.area_6"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_6: value }))}
      >
        <Radio label="0 = No problems in this area: mood within normal limits, cognitive and thought process are appropriate." value={0} />
        <Radio
          label="1 = Mild level of impairment: mild impairment in mood; exhibits cognitive impairment 10% of the time; (e.g., judgment, memory, insight, orientation); thought disturbance 10% of the time (e.g., worries, ruminations, obsessions, compulsions)."
          value={1}
        />
        <Radio
          label="2 = Moderate impairment: moderate impairment in mood, exhibits cognitive impairment 25% of the time (e.g., judgment, memory, insight, orientation); odd or impoverished thought process/content 25% of the time (e.g., worries, ruminations, obsessions, compulsions)."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: severe impairment in mood, exhibits cognitive impairment 50% of the time (e.g., judgment, memory, insight, orientation); odd or impoverished thought process/content 50% of the time (e.g., worries, ruminations, obsessions, compulsions)."
          value={3}
        />
        <Radio
          label="4 = Severe impairment: severe impairment in mood, persistent cognitive impairment 75% of the time (e.g., judgment, memory, insight, orientation); odd or impoverished thought process/content 75% of the time (e.g., worries, ruminations, obsessions, compulsions)."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: severe impairment in mood; chronic impairment resulting in a higher level of care; thought processes are disorganized and tangential; persistent disruption in communication; extreme disconnection from reality."
          value={5}
        />
      </RadioGroup>

      <RadioGroup
        label="Area 7 – Self-harm Behaviors/Harm to Others"
        layout="vertical-dense"
        model="data.questionnaire.area_7"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_7: value }))}
      >
        <Radio
          label="0 = No problems in this area: no self-harm, suicidal thoughts or behaviors, thoughts of harm to others or aggressiveness toward others."
          value={0}
        />
        <Radio
          label="1 = Mild impairment: in the past 90 days, thoughts of self-harm and/or suicide 1 to 2 times with no plan or intent; thoughts about harming others 1 to 2 times with no intent or plan; no history of aggressive behaviors."
          value={1}
        />
        <Radio
          label="2 = Moderate level of impairment: in the past 12 months, has recurrent thoughts of self-harm and/or suicide with no plan or intent; recurrent thoughts of harming others with no plan, intent, or actions."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: evidence of a history of self-harm behaviors but no thoughts of suicide; intent of self-harm behavior is not death; has a history of harming others that is impulsive without intent to harm others; history of verbal attacks."
          value={3}
        />
        <Radio
          label="4 = Severe impairment: recurrent thoughts of suicide; history of suicide attempts; recurrent aggressive behavior that is intended to cause injury or pain; verbal aggression leading to physical altercation."
          value={4}
        />
        <Radio label="5 = Gravely disabled: demonstrates imminent harm and/or danger to self or others at this time." value={5} />
      </RadioGroup>

      <RadioGroup
        label="Area 8 – Substance Use"
        layout="vertical-dense"
        model="data.questionnaire.area_8"
        onUpdate={({ value }) => setScore((o) => ({ ...o, area_8: value }))}
      >
        <Radio
          label="0 = No problems in this area: no present or past substance use; abstinent if there is a history of substance use problems."
          value={0}
        />
        <Radio
          label="1 = Mild level of impairment: Minimal use with no problem due to usage and/or taking medications as prescribed."
          value={1}
        />
        <Radio
          label="2 = Moderate impairment: substance use likely leads to new health problems or makes existing ones worse (e.g., substance related injuries, ulcer, hypertension, vitamin deficiency, diabetes, memory problems); using substances or medications, including over the counter, in excess (e.g., multiple drinks daily or binge drinking) or not as prescribed."
          value={2}
        />
        <Radio
          label="3 = High level of impairment: has experienced repeated negative consequences due to usage (e.g., missed work, failed obligations with family/friends, DUI, blackouts, withdrawals) and continues to use despite persistent problems."
          value={3}
        />
        <Radio
          label="4 = Severe impairment: experiencing multiple signs of substance use disorder (e.g., unable to stop; withdrawals or sick when stopping; guilt/remorse about using; loss of friends/family due to use; loss of job due to use; and using larger amounts of substances more often to get the same effects)."
          value={4}
        />
        <Radio
          label="5 = Gravely disabled: incapacitated by current substance use or experiencing life-threatening complications from overdose or withdrawal (e.g., loss of consciousness, shock, seizures, vomiting, diarrhea, confusion, breathing problems)."
          value={5}
        />
      </RadioGroup>

      {!isPortal && (
        <>
          <Score title={'Number of Areas With A Score of 3 or Above (High Level) of Impairment'} finalScore={score3OrHigher} />
        </>
      )}
    </FormSection>
  )
}
