import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { ChargesReportDataTable } from '@behavehealth/constructs/LiveReports/ChargesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Charges = () => {
  const tableProps = useDataTable({
    name: ['reports', 'charges'],
    endpoint: `/live_reports`,
    localStorageKey: 'report_charges_v1',
    params: { category: 'charges' },
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.resident)}/financials/charges/${rowData.id}`, [])

  return (
    <Page feature="financials" title="Live Charges Report">
      <Grid>
        <ChargesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Charges)
