import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useAPIwithNoAbort } from '@behavehealth/hooks/useAPI'

import { Button, Card, Grid, Page } from '@behavehealth/components'
import ClientsMessageBlastsTable from '@behavehealth/components/Tables/ClientsMessageBlastsTable'

const ClientsMessageBlasts: React.FC = () => {
  const match = useRouteMatch()
  const data = useSelector((state: any) => state.data?.chat_message_blasts?.data)
  const isLoading = useSelector((state: any) => state.data?.chat_message_blasts?.loading)

  useAPIwithNoAbort('chat_message_blasts', `/chat_message_blasts?chat_type=client`)

  return (
    <Page
      icon="clients_chat"
      title="Clients Message Blasts"
      actions={
        <Button
          type="primary"
          glyph="add"
          label="Send a new Message Blast"
          link={`${match.url}/new`}
          permission="clients_chat_message_blasts.create"
        />
      }
    >
      <Grid>
        <Card>
          <ClientsMessageBlastsTable
            to={(id: string) => `${match.url}/${id}`}
            data={data}
            isLoading={isLoading}
            localStorageKey="clients_chat_message_blasts"
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default ClientsMessageBlasts
