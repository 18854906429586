import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import Card from '@behavehealth/components/Card'
import ChartCard from '@behavehealth/components/ChartCard'
import State from '@behavehealth/components/State'

import { NotificationsChannelsChart, NotificationsUsersChart, NotificationsCategoriesChart } from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const NotificationsAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/notifications',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading || Object.keys(stats).length === 0) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  } else {
    return (
      <>
        <div css={styles.grid}>
          <ChartCard title="Notifications by Channel" subtitle="How many email and sms notifications were sent for the day?">
            <NotificationsChannelsChart
              timeframe={timeframe}
              renderer={renderer}
              notification_emails={stats.notifications_emails}
              notification_sms={stats.notifications_sms}
            />
          </ChartCard>

          <ChartCard title="Notifications by User Type" subtitle="How many notifications were sent to Staff vs Clients?">
            <NotificationsUsersChart
              timeframe={timeframe}
              renderer={renderer}
              notification_staff={stats.notifications_staff}
              notification_clients={stats.notifications_clients}
            />
          </ChartCard>
          <ChartCard title="Notifications by Category" subtitle="How many of each type of notification was sent?">
            <NotificationsCategoriesChart
              timeframe={timeframe}
              renderer={renderer}
              notifications_account={stats.notifications_account}
              notifications_admissions={stats.notifications_admissions}
              notifications_utilization_review={stats.notifications_utilization_review}
              notifications_user_lifecycle={stats.notifications_user_lifecycle}
              notifications_financial={stats.notifications_financial}
              notifications_coordination={stats.notifications_coordination}
              notifications_insurance={stats.notifications_insurance}
            />
          </ChartCard>
        </div>
      </>
    )
  }
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default NotificationsAnalytics
