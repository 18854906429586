import React from 'react'

import { initials } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Grid from '@behavehealth/components/Grid'
import Page from '@behavehealth/components/Page'

import { ClinicalNotesReportDataTable } from '@behavehealth/constructs/LiveReports/ClinicalNotesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const ClinicalNotes = () => {
  const tableProps = useDataTable({
    name: ['reports', 'clinical-notes'],
    endpoint: `/live_reports?category=clinical_notes`,
    updateDeleteEndpoint: `/clinical_notes`,
    localStorageKey: 'report_clinical_notes_v1',
  })

  const to = React.useMemo(
    () => (rowData: any) => {
      let slug = ''
      let client = Array.isArray(rowData.client) ? rowData.client[0] : rowData.client

      if (rowData.category === 'group_note' || rowData.category === 'clinical_group') {
        slug = 'group'
      } else if (rowData.category === 'individual') {
        slug = 'individual'
      } else if (rowData.category === 'case_management') {
        slug = 'case-management'
      } else if (rowData.category === 'family_session') {
        slug = 'family-session'
      }

      return `/clients/${client?.id}/clinical-notes/${slug}/${initials(rowData.category)}/${rowData.id}`
    },
    [],
  )

  return (
    <Page feature="clinical_notes" title="Live Clinical Notes Report">
      <Grid gap="1rem" columns="100%">
        <ClinicalNotesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(ClinicalNotes)
