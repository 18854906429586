import React from 'react'

import { apiGet } from '@behavehealth/modules/api'
import Card from '@behavehealth/components/Card'
import ChartCard from '@behavehealth/components/ChartCard'
import State from '@behavehealth/components/State'

import {
  AverageChargeAmountsChart,
  AveragePaymentAmountsChart,
  ChargeAmountsChart,
  ChargedClientsChart,
  ChargesCountChart,
  PayingClientsChart,
  PaymentAmountsChart,
  PaymentsCountChart,
  PaymentSourceChart,
  WriteoffAmountsChart,
  PaymentsChargesScatterChart,
} from '@behavehealth/components/Charts'

import { AnalyticsContext } from '../context'

const FinancialsAnalytics = () => {
  const { timeframe, renderer } = React.useContext(AnalyticsContext)

  const [loading, setLoading] = React.useState(false)
  const [stats, setStats]: any = React.useState({})

  React.useEffect(() => {
    const getStats = async (timeframe: any) => {
      try {
        setLoading(true)

        const result = await apiGet({
          url: '/metrics_stats/financials',
          params: {
            timeframe: timeframe,
          },
          notify: false,
        })

        // update state
        setStats(result.data)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!loading) getStats(timeframe)
  }, [timeframe])

  if (loading || Object.keys(stats).length === 0) {
    return (
      <Card>
        <State isLoading />
      </Card>
    )
  } else {
    return (
      <>
        <h2 css={styles.heading}>Billing</h2>

        <div css={styles.grid}>
          <ChartCard title="Total Charge Amount" subtitle="How much was billed?">
            <ChargeAmountsChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.total_charge_amount?.x}
              yData={stats.total_charge_amount?.y}
            />
          </ChartCard>

          <ChartCard title="Total Charge Count in Transactions" subtitle="How many charges were made?">
            <ChargesCountChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.total_charge_count?.x}
              yData={stats.total_charge_count?.y}
            />
          </ChartCard>

          <ChartCard title="Total Charge Count in Clients" subtitle="How many clients were charged?">
            <ChargedClientsChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.clients_charged?.x}
              yData={stats.clients_charged?.y}
            />
          </ChartCard>

          <ChartCard title="Average Charge Amount" subtitle="What is the average amount per charge?">
            <AverageChargeAmountsChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.average_total_charge_amount?.x}
              yData={stats.average_total_charge_amount?.y}
            />
          </ChartCard>
        </div>

        <h2 css={styles.heading}>Collections</h2>

        <div css={styles.grid}>
          <ChartCard title="Total Payments Amount" subtitle="How much was collected?">
            <PaymentAmountsChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.total_payment_amount?.x}
              yData={stats.total_payment_amount?.y}
            />
          </ChartCard>

          <ChartCard title="Total Payments Count in Transactions" subtitle="How many payments were made?">
            <PaymentsCountChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.total_payment_count?.x}
              yData={stats.total_payment_count?.y}
            />
          </ChartCard>

          <ChartCard title="Total Payment Count in Clients" subtitle="How many clients made a payment?">
            <PayingClientsChart timeframe={timeframe} renderer={renderer} xData={stats.clients_paid?.x} yData={stats.clients_paid?.y} />
          </ChartCard>

          <ChartCard title="Total Write-off Amount" subtitle="How much was written off?">
            <WriteoffAmountsChart timeframe={timeframe} renderer={renderer} />
          </ChartCard>

          <ChartCard title="Average Payment Amount" subtitle="What is the average payment amount?">
            <AveragePaymentAmountsChart
              timeframe={timeframe}
              renderer={renderer}
              xData={stats.average_total_payment_amount?.x}
              yData={stats.average_total_payment_amount?.y}
            />
          </ChartCard>

          <ChartCard title="Payment Source" subtitle="What is our payment source breakdown?">
            <PaymentSourceChart timeframe={timeframe} renderer={renderer} data={stats.payment_source} />
          </ChartCard>

          <ChartCard title="Payments Charges Scatter" subtitle="Description Needed?">
            <PaymentsChargesScatterChart timeframe={timeframe} renderer={renderer} data={stats.payments_charges_scatter} />
          </ChartCard>
        </div>
      </>
    )
  }
}

const styles = {
  grid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '100%',

    '@media (min-width: 768px)': {
      gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
    },
  },
}

export default FinancialsAnalytics
