import React from 'react'
import { produce } from 'immer'
import { transparentize } from 'polished'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'

import { Color } from '@tiptap/extension-color'
import { useEditor, EditorContent } from '@tiptap/react'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'

import { COLORS } from '../../../../theme'
import { USER_GLYPHS } from '../../../../theme/defs/user_glyphs'
import { USER_ICONS } from '../../../../theme/defs/user_icons'

import { RichTextToolbar } from '../../components/RichTextToolbar'
import BHGridTable from '../../../../components/GridTable'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import Glyph from '../../../../components/Glyph'
import Icon from '../../../../components/Icon'
import State from '../../../../components/State'
import Json from '../../../../components/Json'

import { Box } from './Box'
import { DropdownMenu, DropdownMenuItem } from '../../../../components/DropdownMenu'

const icons = Object.keys(USER_ICONS)
const glyphs = Object.keys(USER_GLYPHS)

export const GridTable: React.FC<any> = React.forwardRef((props: any, ref) => {
  const { element, className, hoverElement, editElementConfig, environment, isEditable, useParsedConfig } = props

  const [isResizing, setIsResizing] = React.useState(false)
  const [editor, setEditor] = React.useState<any>(null)

  const isActive = element?._isActive
  const canEdit = isEditable && environment === 'builder'

  const [sortColumn, setSortColumn] = React.useState(element?.config?.default_sort_column || '')
  const [sortDescending, setSortDescending] = React.useState(element?.config?.default_sort_order === 'desc')

  const [activeRowId, setActiveRowId] = React.useState('')
  const [activeColumnId, setActiveColumnId] = React.useState('')

  React.useEffect(() => {
    if (!element?.config?.default_sort_column) return

    if (element?.config?.default_sort_column === sortColumn) return

    setSortColumn(element.config.default_sort_column)
  }, [sortColumn, element?.config?.default_sort_column])

  React.useEffect(() => {
    if (!element?.config?.default_sort_order) return

    const isDescending = element.config.default_sort_order === 'desc'

    if (sortDescending && isDescending) return

    setSortColumn(element.config.default_sort_order)
  }, [sortDescending, element?.config?.default_sort_order])

  const tableConfig = React.useMemo(() => {
    const result: any = []

    const columns = useParsedConfig
      ? element?.config?.parsed?.grid_table_columns || element?.config?.grid_table_columns
      : element?.config?.grid_table_columns

    if (size(columns) === 0) return result

    for (const column of columns) {
      const columnWidth = column.width_type === 'flexible' ? `minmax(${column.width}px, ${column.width}fr)` : `${column.width || 30}px`

      result.push({
        _id: column._id,
        title: column.title,
        type: column.type,
        width: columnWidth,
        can_sort: !!column.can_sort,
        Cell: CELLS[column.type],
      })
    }

    return result
  }, [useParsedConfig, element?.config])

  const sortedRows = React.useMemo(() => {
    const result: any = []

    if (!element.config || size(element?.config?.grid_table_rows_order) === 0) return result

    for (const rowId of element.config.grid_table_rows_order) {
      if (useParsedConfig && element.config?.parsed?.grid_table_rows) {
        result.push({ _id: rowId, ...element.config.parsed.grid_table_rows[rowId] })
      } else {
        result.push({ _id: rowId, ...element.config.grid_table_rows[rowId] })
      }
    }

    if (sortColumn) {
      return orderBy(result, [sortColumn], [sortDescending ? 'desc' : 'asc'])
    }

    return result
  }, [
    useParsedConfig,
    sortColumn,
    sortDescending,
    element?.config?.grid_table_rows_order,
    element?.config?.parsed?.grid_table_rows,
    element?.config?.grid_table_rows,
  ])

  const templateColumns = React.useMemo(() => {
    let result = tableConfig.map((col: any) => col.width).join(' ')

    if (canEdit) {
      result += ' 40px'
    }

    return result
  }, [tableConfig, canEdit])

  const addRow = () => {
    const columnIds = element?.config?.grid_table_columns?.map((col: any) => col._id) || []

    const newRowId = uuid()
    const newRow = {}

    for (const id of columnIds) {
      newRow[id] = ''
    }

    editElementConfig({
      uuid: element.uuid,
      config: {
        grid_table_rows_order: [...element.config.grid_table_rows_order, newRowId],
        grid_table_rows: {
          ...element.config.grid_table_rows,
          [newRowId]: newRow,
        },
      },
    })
  }

  const deleteRow = (rowId: any) => {
    editElementConfig({
      uuid: element.uuid,
      config: {
        grid_table_rows_order: element.config.grid_table_rows_order.filter((id: any) => id !== rowId),
        grid_table_rows: produce(element.config.grid_table_rows, (draft) => {
          delete draft[rowId]
        }),
      },
    })
  }

  const editCellValue = (rowId: string, columnId: string, value: any) => {
    editElementConfig({
      uuid: element.uuid,
      config: {
        grid_table_rows: produce(element.config.grid_table_rows, (draft) => {
          draft[rowId][columnId] = value
        }),
      },
    })
  }

  const handleSortColumn = (columnId: string) => {
    setSortColumn(columnId)

    editElementConfig({
      uuid: element.uuid,
      config: {
        default_sort_column: columnId,
      },
    })
  }

  const handleSortDirection = (isDescending: boolean) => {
    setSortDescending(!!isDescending)

    editElementConfig({
      uuid: element.uuid,
      config: {
        default_sort_order: isDescending ? 'desc' : 'asc',
      },
    })
  }

  const handleMoveRowUp = (rowId: any) => {
    const index = element.config.grid_table_rows_order?.indexOf?.(rowId)
    const endIndex = index - 1

    if (index < 1) return

    const newRowOrder = [...element.config.grid_table_rows_order]
    const [removed] = newRowOrder.splice(index, 1)

    newRowOrder.splice(endIndex, 0, removed)

    setSortColumn('')

    editElementConfig({
      uuid: element.uuid,
      config: {
        default_sort_column: '',
        grid_table_rows_order: newRowOrder,
      },
    })
  }

  const handleMoveRowDown = (rowId: any) => {
    const index = element.config.grid_table_rows_order?.indexOf?.(rowId)
    const endIndex = index + 1

    if (index === size(element.config.grid_table_rows_order) - 1) return

    const newRowOrder = [...element.config.grid_table_rows_order]
    const [removed] = newRowOrder.splice(index, 1)

    newRowOrder.splice(endIndex, 0, removed)

    setSortColumn('')

    editElementConfig({
      uuid: element.uuid,
      config: {
        default_sort_column: '',
        grid_table_rows_order: newRowOrder,
      },
    })
  }

  const handleMoveColumnLeft = (columnId: any) => {
    const index = element.config.grid_table_columns?.findIndex?.((col: any) => col._id === columnId)
    const endIndex = index - 1

    if (index < 1) return

    const newColumns = [...element.config.grid_table_columns]
    const [removed] = newColumns.splice(index, 1)

    newColumns.splice(endIndex, 0, removed)

    editElementConfig({
      uuid: element.uuid,
      config: {
        grid_table_columns: newColumns,
      },
    })
  }

  const handleMoveColumnRight = (columnId: any) => {
    const index = element.config.grid_table_columns?.findIndex?.((col: any) => col._id === columnId)
    const endIndex = index + 1

    if (index === size(element.config.grid_table_columns) - 1) return

    const newColumns = [...element.config.grid_table_columns]
    const [removed] = newColumns.splice(index, 1)

    newColumns.splice(endIndex, 0, removed)

    editElementConfig({
      uuid: element.uuid,
      config: {
        grid_table_columns: newColumns,
      },
    })
  }

  const resizeRef: any = React.useRef({ x: 0, id: '', currentWidth: 0 })

  const handlePointerDown = React.useCallback(
    (event: any) => {
      if (event.target.dataset) {
        const { type, id } = event.target.dataset

        if (type === 'resizer') {
          const column = element?.config?.grid_table_columns?.find((col: any) => col._id === id)

          if (column) {
            setIsResizing(true)

            resizeRef.current.id = id
            resizeRef.current.x = event.screenX

            if (column.width) resizeRef.current.currentWidth = parseInt(column.width)
          }
        }
      }
    },
    [element?.config],
  )

  const handlePointerMove = React.useCallback(
    (event: any) => {
      if (event.target.dataset) {
        const { id, x }: any = resizeRef.current

        if (id && x) {
          const currentWidth = resizeRef.current.currentWidth
          const diff = event.screenX - parseInt(x)
          const width = parseInt(currentWidth + diff)

          editElementConfig({
            uuid: element.uuid,
            config: {
              grid_table_columns: element.config.grid_table_columns.map((col: any) => {
                if (col._id === id) {
                  return {
                    ...col,
                    width: width < 30 ? 30 : width,
                  }
                }

                return col
              }),
            },
          })
        }
      }
    },
    [editElementConfig, element.config.grid_table_columns],
  )

  const handlePointerUp = React.useCallback(() => {
    if (resizeRef.current) {
      resizeRef.current.x = 0
      resizeRef.current.id = ''
      resizeRef.current.currentWidth = 0

      setIsResizing(false)
    }
  }, [])

  if (!element?.config) return null

  const rootClasses = clsx('GRID_TABLE relative', className)
  const mainClasses = clsx(isResizing && 'is-resizing')

  const { row_banding, max_width = 100, max_width_type = 'percentage', should_center_x } = element.config

  const maxWidth = getSizeValue(max_width, max_width_type)
  const isRowsEmpty = size(element?.config?.grid_table_rows_order) === 0

  return (
    <Box ref={ref} element={element} className={rootClasses} hoverElement={hoverElement}>
      {isActive && canEdit && editor && (
        <RichTextToolbar editor={editor} css={STYLES.toolbar} toolbar={RICH_TEXT_TOOLBAR}>
          <div className="w-full flex flex-nowrap justify-center">
            <div className="px-2 py-1.5 flex items-center border-r border-0 border-divider border-solid">
              <div className="uppercase text-text-muted text-[0.8rem] tracking-[0.5px] font-[700] mr-1.5">Move Row</div>
              <Button
                hideLabel
                color="blue"
                glyph="triangle_up"
                type="minimal"
                size={100}
                display="inline-flex"
                onClick={() => {
                  handleMoveRowUp(activeRowId)
                }}
              />

              <Button
                hideLabel
                color="blue"
                glyph="triangle_down"
                type="minimal"
                size={100}
                display="inline-flex"
                onClick={() => {
                  handleMoveRowDown(activeRowId)
                }}
              />
            </div>

            <div className="px-2 py-1.5 flex items-center">
              <div className="uppercase text-text-muted text-[0.8rem] tracking-[0.5px] font-[700] mr-1.5">Move Column</div>
              <Button
                hideLabel
                color="blue"
                glyph="triangle_left"
                type="minimal"
                size={100}
                display="inline-flex"
                onClick={() => {
                  handleMoveColumnLeft(activeColumnId)
                }}
              />

              <Button
                hideLabel
                color="blue"
                glyph="triangle_right"
                type="minimal"
                size={100}
                display="inline-flex"
                onClick={() => {
                  handleMoveColumnRight(activeColumnId)
                }}
              />
            </div>
          </div>
        </RichTextToolbar>
      )}

      <div
        className={mainClasses}
        css={STYLES.main}
        onPointerUp={canEdit ? handlePointerUp : undefined}
        onPointerDown={canEdit ? handlePointerDown : undefined}
        onPointerMove={canEdit ? handlePointerMove : undefined}
        onPointerLeave={canEdit ? handlePointerUp : undefined}
      >
        <Card className="w-full relative z-[3] overflow-x-auto" style={{ maxWidth, margin: should_center_x ? '0 auto' : 0 }}>
          <BHGridTable templateColumns={templateColumns} useBanding={!!row_banding} useRowHover={false}>
            <BHGridTable.Header className="relative z-[10]">
              {tableConfig.map((column: any, index: number) => {
                const canSort = !!column.can_sort
                const isSorted = sortColumn === column._id

                return (
                  <BHGridTable.Cell
                    key={column._id}
                    className={clsx('relative', canSort && 'cursor-pointer hover:text-blue-500')}
                    onClick={() => {
                      if (!column.can_sort) return

                      if (column._id === sortColumn) {
                        sortDescending ? handleSortColumn('') : handleSortDirection(true)
                      } else {
                        handleSortColumn(column._id)
                        handleSortDirection(false)
                      }
                    }}
                  >
                    {column.title}

                    {isSorted && (
                      <Glyph size={10} glyph={sortDescending ? 'triangle_down' : 'triangle_up'} className="ml-1.5" color={COLORS.blue} />
                    )}

                    {canEdit && <ColumnResizer column={column} index={index} />}
                  </BHGridTable.Cell>
                )
              })}
            </BHGridTable.Header>

            {sortedRows?.map?.((row: any, rowIndex: number) => {
              const rowId = row._id
              const isRowActive = isActive && activeRowId === rowId

              if (!row) return null

              return (
                <BHGridTable.Row key={rowId} paddingY="0.1rem" className={clsx('relative', canEdit && isRowActive && '!bg-hover')}>
                  {tableConfig.map((column: any) => {
                    const Cell = column.Cell
                    const isColumnActive = isActive && activeColumnId === column?._id

                    return (
                      <BHGridTable.Cell
                        key={`${rowId}-${column._id}`}
                        centerY
                        className={clsx(
                          'relative min-h-[2rem]',
                          column.className,
                          canEdit && isRowActive && isColumnActive ? '!bg-vivid-blue-100' : canEdit && isColumnActive && '!bg-hover',
                        )}
                        onClick={() => {
                          setActiveRowId(rowId)
                          setActiveColumnId(column._id)
                        }}
                      >
                        {Cell && (
                          <Cell
                            canEdit={canEdit}
                            columnId={column._id}
                            rowIndex={rowIndex}
                            currentValue={row[column._id]}
                            onUpdate={(value) => {
                              editCellValue(rowId, column._id, value)
                            }}
                            getEditor={setEditor}
                            onFocus={() => {
                              setActiveRowId(rowId)
                              setActiveColumnId(column._id)
                            }}
                          />
                        )}
                      </BHGridTable.Cell>
                    )
                  })}

                  {canEdit && (
                    <BHGridTable.Cell centerY className="sticky right-0">
                      <Button
                        hideLabel
                        color="red"
                        glyph="delete"
                        type="minimal"
                        size={100}
                        display="inline-flex"
                        onClick={() => {
                          deleteRow(rowId)
                        }}
                      />
                    </BHGridTable.Cell>
                  )}
                </BHGridTable.Row>
              )
            })}
          </BHGridTable>

          {isRowsEmpty && (
            <div className="border-t border-0 border-solid border-divider">
              <State isEmpty emptyDescription="No rows added yet" minHeight={120} />
            </div>
          )}
        </Card>

        {canEdit && (
          <div className="w-full" style={{ maxWidth, margin: should_center_x ? '0.75rem auto 0' : '0.75rem 0 0' }}>
            <Button label="Add Row" glyph="add" type="primary" size={100} display="inline-flex" onClick={addRow} />
          </div>
        )}
      </div>
    </Box>
  )
})

const getSizeValue = (value: any, type: 'percentage' | 'pixels') => {
  const valueNumber = parseFloat(value) || 100
  const valueType = SIZE_VALUE_TYPES[type] || SIZE_VALUE_TYPES.pixels

  return `${valueNumber}${valueType}`
}

const ColumnResizer = (props: any) => {
  const { className, column, index } = props

  if (!column) return null

  return <div data-id={column._id} data-type="resizer" data-index={index} className={className} css={STYLES.resizer}></div>
}

const GlyphCell = (props: any) => {
  const { currentValue, onUpdate, canEdit } = props

  const handleChange = (glyph: string) => {
    onUpdate(glyph || '')
  }

  return (
    <>
      {canEdit ? (
        <DropdownMenu
          trigger={
            <div className="w-full h-full flex items-center justify-center text-[0.92rem] text-[600] text-blue-500">
              {currentValue ? <Glyph glyph={currentValue} size={20} /> : <div>Select</div>}
              <Glyph glyph="triangle_down" size={10} color={COLORS.blue} className="ml-1.5" />
            </div>
          }
        >
          <div className="grid grid-cols-4">
            {glyphs.map((glyph) => (
              <DropdownMenuItem
                key={glyph}
                onClick={() => handleChange(glyph)}
                glyph={glyph}
                className="flex items-center justify-center"
              />
            ))}
          </div>
        </DropdownMenu>
      ) : (
        <div className="w-full h-full flex items-center justify-center">{currentValue && <Glyph glyph={currentValue} size={20} />}</div>
      )}
    </>
  )
}

const IconCell = (props: any) => {
  const { currentValue, onUpdate, canEdit } = props

  const handleChange = (icon: string) => {
    onUpdate(icon || '')
  }

  return (
    <>
      {canEdit ? (
        <DropdownMenu
          trigger={
            <div className="w-full h-full flex items-center justify-center text-[0.92rem] text-[600] text-blue-500">
              {currentValue ? <Icon icon={currentValue} size={20} /> : <div>Select</div>}
              <Glyph glyph="triangle_down" size={10} color={COLORS.blue} className="ml-1.5" />
            </div>
          }
        >
          <div className="grid grid-cols-4">
            {icons.map((icon) => (
              <DropdownMenuItem key={icon} onClick={() => handleChange(icon)} icon={icon} className="flex items-center justify-center" />
            ))}
          </div>
        </DropdownMenu>
      ) : (
        <div className="w-full h-full flex items-center justify-center">{currentValue && <Icon icon={currentValue} size={20} />}</div>
      )}
    </>
  )
}

const RichTextCell = (props: any) => {
  const { currentValue, onUpdate, canEdit, getEditor, onFocus } = props

  const handleUpdate = ({ editor }) => {
    onUpdate(editor.getHTML())
  }

  const editor = useEditor(
    {
      content: currentValue,
      editable: canEdit,
      onUpdate: handleUpdate,
      onFocus: ({ editor }) => {
        getEditor(editor)
        onFocus?.()
      },
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
        Underline,
        Link.configure({
          protocols: ['tel', 'mailto'],
          linkOnPaste: true,
          openOnClick: false,
        }),
        Highlight.configure({ multicolor: true }),
      ],
    },
    [canEdit],
  )

  return <EditorContent editor={editor} css={STYLES.richText} className={canEdit ? 'is-editable' : 'is-readonly'} />
}

const RICH_TEXT_TOOLBAR = ['headings', 'paragraph', 'inline', 'link', 'color', 'highlight', 'alignment', 'undo']

const SIZE_VALUE_TYPES = {
  percentage: '%',
  pixels: 'px',
}

const CELLS = {
  glyph: GlyphCell,
  icon: IconCell,
  rich_text: RichTextCell,
}

const STYLES = {
  main: {
    '&.is-resizing, &.is-resizing *': {
      userSelect: 'none  !important',
      cursor: 'ew-resize !important',
    },
  },

  toolbar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    transform: 'translateY(-100%)',
  },

  resizer: {
    width: 2,
    minHeight: 12,
    background: COLORS.transparent,
    cursor: 'ew-resize',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: -2,
    height: 'auto',
    zIndex: 1,

    '&:hover': {
      background: transparentize(0.2, COLORS.blue),
      boxShadow: `-2px 0 0 ${transparentize(0.8, COLORS.vividBlue)}, 2px 0 0 ${transparentize(0.8, COLORS.vividBlue)}`,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 12,
      height: '100%',
      transform: 'translateX(-50%)',
    },
  },

  richText: {
    minWidth: '100%',
    minHeight: '100%',

    '.ProseMirror': {
      cursor: 'text',
      lineHeight: 1.44,
      outline: 'none !important',

      '> * + *': {
        marginTop: '0.25rem',
      },

      p: {
        'br + br': {
          display: 'none',
        },
      },

      a: {
        cursor: 'text',
      },

      ul: {
        paddingLeft: '1.25rem',
      },

      'li > p': {
        margin: 0,
      },

      h1: {
        fontSize: '1.7rem',
        margin: '0.6em 0 0.4em',
      },

      h2: {
        fontSize: '1.5rem',
        margin: '0.8em 0 0.45em',
      },

      h3: {
        fontSize: '1.35rem',
        margin: '1em 0 0.5em',
      },

      h4: {
        fontSize: '1.2rem',
        margin: '1.2em 0 0.6em',
      },

      img: {
        maxWidth: '100%',
        height: 'auto',
      },

      blockquote: {
        marginLeft: '1rem',
        paddingLeft: '0.8em',
        color: COLORS.text,
        borderLeft: `2px solid ${COLORS.textMuted}`,
        fontStyle: 'italic',
      },

      hr: {
        margin: '1.75rem 0',
        border: 'none',
        borderBottom: `1px solid ${COLORS.divider}`,
      },

      '& > *:first-child': {
        marginTop: '0 !important',
        marginBottom: '0.25rem !important',
      },

      '& > *:last-child': {
        marginTop: '0.25rem !important',
        marginBottom: '0 !important',
      },
    },

    '&.is-readonly .ProseMirror': {
      a: {
        cursor: 'pointer',
      },
    },
  },
}
