import React from 'react'

import { isWebGLAvailable } from '@behavehealth/utils/functions'

import { withPageError } from '@behavehealth/hocs/withPageError'

import Card from '@behavehealth/components/Card'
import CardHeader from '@behavehealth/components/CardHeader'
import CardTitle from '@behavehealth/components/CardTitle'
import Link from '@behavehealth/components/Link'
import Option from '@behavehealth/components/Forms/Option'
import Page from '@behavehealth/components/Page'
import Select from '@behavehealth/components/Forms/Select'
import Tab from '@behavehealth/components/Tab'
import TabList from '@behavehealth/components/TabList'
import TabPanel from '@behavehealth/components/TabPanel'
import TabPanels from '@behavehealth/components/TabPanels'
import Tabs from '@behavehealth/components/Tabs'

// Custom chart components
import BarChart from './bar_chart'
import HeatmapChart from './heatmap_chart'
import LineChart from './line_chart'
import PieChart from './pie_chart'
import SankeyChart from './sankey_chart'
import TreeChart from './tree_chart'

// Other chart components
import Example1 from './example1'
import Example3 from './example3'
import Example4 from './example4'
import Example5 from './example5'
import Example6 from './example6'
import Example7 from './example7'
import Example8 from './example8'
import Example9 from './example9'
import Example10 from './example10'
import Example11 from './example11'
import Example13 from './example13'
import Example12 from './example12'
import Example14 from './example14'
import Example15 from './example15'
import Example16 from './example16'

const Analytics = () => {
  const hasCanvasSupport = isWebGLAvailable()
  const renderer = hasCanvasSupport ? 'canvas' : 'svg'

  return (
    <Page breakpoint="0" icon="reports" title="Analytics Dashboard">
      <Tabs defaultTab="marketing">
        <TabList className="!mb-4">
          <Tab label="Marketing" name="marketing" />
          <Tab label="Census" name="census" />
          <Tab label="Financials" name="financials" />
          <Tab label="Clients" name="clients" />
          <Tab label="Productivity" name="productivity" />
          <Tab label="Insurance" name="insurance" />
        </TabList>

        <Select defaultValue="30" css={styles.select}>
          <Option value="30" label="30 days" />
          <Option value="60" label="60 days" />
          <Option value="90" label="90 days" />
          <Option value="365" label="365 days" />
        </Select>

        <TabPanels>
          <TabPanel name="marketing">
            <h2 css={styles.heading}>Custom Chart Components</h2>
            <div css={styles.grid}>
              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Bar Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <BarChart renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Line Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <LineChart renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Pie Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <PieChart renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Heatmap Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <HeatmapChart renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Sankey Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <SankeyChart renderer={renderer} />
              </Card>
            </div>

            <h2 css={styles.heading}>Other Chart Components</h2>

            <div css={styles.grid}>
              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Line Gradient" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example1 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Line Style" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example3 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Line Step" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example4 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Bar Background" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example5 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Zooming Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example6 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Bar Brush" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example7 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Dataset Series" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example8 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Pie Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example9 renderer={renderer} />
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Scatter Simple" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example10 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 700px', overflowX: 'auto' }}>
                <CardHeader>
                  <CardTitle title="Scatter Single Axis" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example12 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 700px' }}>
                <CardHeader>
                  <CardTitle title="Punch Card" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <div className="!py-0 !px-4">
                  <Example11 renderer={renderer} />
                </div>
              </Card>

              <Card css={styles.card}>
                <CardHeader>
                  <CardTitle title="Sankey Simple" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example14 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 40%' }}>
                <CardHeader>
                  <CardTitle title="Funnel" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example15 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 40%' }}>
                <CardHeader>
                  <CardTitle title="Calendar Simple" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example16 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 700px' }}>
                <CardHeader>
                  <CardTitle title="Calendar Charts" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <Example13 renderer={renderer} />
              </Card>

              <Card css={{ ...styles.card, flex: '1 1 700px' }}>
                <CardHeader>
                  <CardTitle title="Tree Chart" after={<Link children="Full Report →" to="/reports" css={styles.link} />} />
                </CardHeader>
                <TreeChart renderer={renderer} />
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  )
}

const styles = {
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-0.5rem',
    // display: 'grid',
    // gridGap: '1rem',
    // '@media (min-width: 600px)': {
    //   gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
    // },
  },

  heading: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
  },

  card: {
    overflow: 'visible',
    flex: '1 1 350px',
    margin: '0.5rem',
  },

  select: {
    width: 140,
    marginLeft: 'auto',
    marginBottom: '1rem',
  },

  link: {
    fontSize: '0.9rem',
    marginLeft: '0.5rem',
  },
}

export default withPageError(Analytics)
