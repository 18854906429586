import React from 'react'
import { useQueryClient } from 'react-query'
import size from 'lodash/size'

import { minutesBetween } from '../../../../../utils/functions'
import { useCreate, getPrefix } from '../../../../../hooks/useNewAPI'
import { get } from '../../../../../modules/api/requests'

import SummonOverlay from '../../../../SummonOverlay'

import { ClinicalNoteCaseManagementOverlay } from '../../../../../constructs/ClinicalNotes/ClinicalNoteCaseManagementOverlay'
import { ClinicalNoteOutcomeItem } from '../common/ClinicalNoteOutcomeItem'
import { EventOutcomesFooter } from '../EventOutcomesFooter'
import { getClientAttendance } from '../common/functions'

type Props = {
  event: any
  outcomes?: any
}

export const CaseManagementOutcome = ({ event, outcomes = [] }: Props) => {
  const queryClient = useQueryClient()

  return (
    <>
      {outcomes.map((outcome: any) => {
        const data = outcome?.reference

        if (!data) return null

        return (
          <SummonOverlay
            key={outcome?.id}
            permission="clinical_notes.view"
            overlay={
              <ClinicalNoteCaseManagementOverlay
                showBackdrop
                dataID={data.id}
                invalidateKeys={[['event', event?.id]]}
                position="center"
                initialData={data}
                onSaveSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
                onDeleteSuccessful={() => {
                  queryClient.invalidateQueries([getPrefix(), 'event', event?.id])
                }}
              />
            }
          >
            <ClinicalNoteOutcomeItem data={data} />
          </SummonOverlay>
        )
      })}

      <AddMore event={event} />
    </>
  )
}

export const AddMore = (props: Props) => {
  const { className }: any = props

  const [event, setEvent] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false)

  const getEvent = async () => {
    if (!props.event?.id) return

    setIsLoading(true)

    const result = await get(`/events/${props.event.id}`)

    setIsLoading(false)

    if (!result?.data?.id) return

    setEvent(result.data)
    setIsOverlayOpen(true)
  }

  const { mutateAsync, isLoading: isSaving }: any = useCreate({
    name: ['event-outcomes'],
    url: `/event_outcomes`,
    invalidate: ['event', event?.id],
    invalidateKeys: ['events'],
  })

  if (!props.event?.id) return null

  const client = event?.resident ? event?.resident : size(event?.residents) > 0 && event?.residents[0]

  return (
    <>
      {event && (
        <SummonOverlay
          isOpen={isOverlayOpen}
          onClose={() => setIsOverlayOpen(false)}
          overlay={
            <ClinicalNoteCaseManagementOverlay
              dataID="new"
              position="center"
              isSaving={isSaving}
              initialData={{
                client,
                started_at: event.started_at,
                duration: event.start_time && event.end_time ? minutesBetween(event.start_time, event.end_time) : null,
                ...getClientAttendance(client?.id, event.event_attendances),
              }}
              save={async (data: any) => {
                await mutateAsync({
                  data: data,
                  event_id: event?.id,
                  category: 'case_management_note',
                })
              }}
            />
          }
        />
      )}

      <EventOutcomesFooter
        onClick={getEvent}
        isLoading={isLoading}
        addLabel="Add Case Management Note"
        permission="clinical_notes.create"
        className={className}
      />
    </>
  )
}

export default CaseManagementOutcome
