import React from 'react'
import { Routes, Route, NavLink, useParams, useNavigate } from 'react-router-dom-v5-compat'
import snakeCase from 'lodash/snakeCase'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { titleCase } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useRouteURL } from '@behavehealth/hooks/useRouteURL'

import { TreatmentPlanCloseDuplicateOverlay } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanCloseDuplicateOverlay'
import { TreatmentPlanOverlay } from '@behavehealth/constructs/TreatmentPlans/TreatmentPlanOverlay'
import { TreatmentPlanStatus } from '@behavehealth/constructs/TreatmentPlans/components/TreatmentPlanStatus'

import Button from '@behavehealth/components/Button'
import Permission from '@behavehealth/components/Permission'
import Portal from '@behavehealth/components/Portal'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import { FILTERS } from '@behavehealth/constructs/Filters/config'

export const TreatmentPlansCategoryPage = (props: any) => {
  const { plan_category: paramsCategory, resource_id: clientId }: any = useParams()

  const category = snakeCase(props.category || paramsCategory)
  const permissionBase = `${category}_treatment_plans`

  return (
    <>
      <ProtectedRouteV6 permission={`${permissionBase}.view`}>
        <TreatmentPlansCategoryPageIndex />
      </ProtectedRouteV6>

      <Routes>
        <Route path=":id/*" element={<TreatmentPlanRoute permissionBase={permissionBase} />} />
      </Routes>
    </>
  )
}

const BLACKLIST_IDS = [
  'invite-to-portal',
  'admit-client',
  'discharge-client',
  'readmit-client',
  'accept-client',
  'decline-client',
  'high-risk-alert',
  'magic-login',
  'update-password',
]

const TreatmentPlanRoute = ({ permissionBase }) => {
  const { id }: any = useParams()

  if (id && BLACKLIST_IDS.includes(id)) return null

  return (
    <ProtectedRouteV6 permission={`${permissionBase}.view`}>
      <Portal type="overlay">
        <TreatmentPlanOverlay useV6Router />
      </Portal>
    </ProtectedRouteV6>
  )
}

const TreatmentPlansCategoryPageIndex = (props: any) => {
  const navigate = useNavigate()
  const { resource_id: clientId, plan_category: paramsCategory }: any = useParams()

  const category = snakeCase(props.category || paramsCategory)
  const permissionBase = `${category}_treatment_plans`

  const tableProps = useDataTable({
    name: ['client', clientId, 'treatment_plans'],
    endpoint: `/residents/${clientId}/treatment_plans`,
    params: { category },
    options: { enabled: !!clientId && !!category },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} to={`${data.id}`} value={value} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        formatValue: ({ value }: any) => <TreatmentPlanStatus status={value} />,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Actions',
        id: '_actions',
        hoverExpand: false,
        width: 140,
        formatValue: ({ data }: any) => {
          return (
            <Permission permission={`${permissionBase}.edit`}>
              <SummonOverlay
                overlay={
                  <TreatmentPlanCloseDuplicateOverlay
                    permissionBase={permissionBase}
                    planId={data.id}
                    currentPlanName={data?.name}
                    onSuccess={(newPlan) => {
                      navigate(newPlan?.id)
                    }}
                  />
                }
              >
                <Button label="Duplicate Plan…" glyph="copy" color="blue" size={100} />
              </SummonOverlay>
            </Permission>
          )
        },
      },
    ],
    [],
  )

  return (
    <div className="p-4">
      <DataTable
        asCard
        title={`${titleCase(category)} Treatment Plans`}
        testKey="treatment_plans_data_table"
        icon="treatment_plans"
        columns={columns}
        filtersConfig={FILTERS.treatment_plans}
        {...tableProps}
      />
    </div>
  )
}
