import React from 'react'
import { tint } from 'polished'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'

import { COLORS, HARD_SHADOW, keyframes, SHADOW } from '../theme'
import { usePortal } from '../hooks/usePortal'

import Glyph from './Glyph'
import Icon from './Icon'
import Link from './Link'

const PopoverContext = React.createContext({})

export const PopoverMenu: React.FC<any> = (props: any) => {
  const {
    align = 'start',
    alignOffset,
    avoidCollisions,
    children,
    closeOnItemClick = true,
    testKey,
    forceMount,
    isOpen: propIsOpen = false,
    maxHeight = '60vh',
    maxWidth = '520px',
    menuClassName,
    menuCss,
    minHeight,
    onInteractOutside,
    onOpenAutoFocus,
    onOpenUpdated,
    preventAutoFocus,
    side,
    sideOffset = 4,
    stopPropagation,
    trigger,
    useTriggerWidth,
    width = '100%',
    portalType = 'radix',
  } = props

  const [isOpen, setIsOpen] = React.useState(propIsOpen)

  // TODO: handle portal containers differently to avoid zIndex issues
  const portalContainer = usePortal(portalType)

  const { current: collisionBoundary } = React.useRef([document.getElementById('portals'), document.getElementById('app'), document.body])

  const handleClick = (event: any) => {
    if (stopPropagation) event.stopPropagation()

    // if (closeOnItemClick && event.target?.dataset?.type === 'popover-menu-item') {
    //   setIsOpen(false)
    // }
  }

  const handleOpenAutoFocus = (event: any) => {
    if (preventAutoFocus) event.preventDefault()
    if (onOpenAutoFocus) onOpenAutoFocus(event)
  }

  React.useEffect(() => {
    setIsOpen(propIsOpen)
  }, [propIsOpen])

  React.useEffect(() => {
    if (onOpenUpdated) onOpenUpdated(isOpen)
  }, [isOpen])

  const menuClasses = clsx(useTriggerWidth && 'use-trigger-width', menuClassName)

  return (
    <PopoverContext.Provider value={{ setIsOpen, closeOnItemClick }}>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        {trigger ? <Popover.Trigger asChild>{trigger}</Popover.Trigger> : <Popover.Anchor />}

        {/* <Popover.Anchor /> */}

        <Popover.Portal forceMount={forceMount} container={portalContainer}>
          <Popover.Content
            asChild
            side={side}
            align={align}
            alignOffset={alignOffset}
            sideOffset={sideOffset}
            onOpenAutoFocus={handleOpenAutoFocus}
            onInteractOutside={onInteractOutside}
            collisionBoundary={collisionBoundary}
            avoidCollisions={avoidCollisions}
            collisionPadding={10}
            sticky="partial"
          >
            <div
              data-test={testKey || 'popover_menu'}
              className={menuClasses}
              css={[STYLES.menu, menuCss]}
              onClick={handleClick}
              style={{
                '--menu-width': width,
                '--menu-max-width': maxWidth,
                '--menu-min-height': minHeight,
                '--menu-max-height': maxHeight,
              }}
            >
              {children}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </PopoverContext.Provider>
  )
}

export const PopoverMenuItem: React.FC<any> = (props: any) => {
  const {
    after,
    className,
    testKey,
    description,
    glyph,
    glyphColor,
    graphic,
    icon,
    isActive,
    label,
    onClick,
    shouldScrollIntoView = false,
    to,
  } = props

  const ref = React.useRef()
  const menuItemClasses = clsx(isActive && 'is-active', className)

  const { setIsOpen, closeOnItemClick }: any = React.useContext(PopoverContext)

  // scroll into view when the active changes
  React.useEffect(() => {
    if (!shouldScrollIntoView) return
    if (!isActive) return
    if (!ref?.current.scrollIntoView) return

    ref.current.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' })
  }, [isActive])

  const Tag = to ? Link : 'div'

  return (
    <Tag
      ref={ref}
      onClick={(e) => {
        if (onClick) onClick(e)
        if (closeOnItemClick) setIsOpen(false)
      }}
      css={STYLES.menuItem}
      className={menuItemClasses}
      data-type="popover-menu-item"
      to={to}
      data-test={testKey || 'popover_menu_item'}
    >
      {graphic}
      {glyph && <Glyph glyph={glyph} size={18} color={glyphColor} />}
      {icon && <Icon icon={icon} size={18} />}

      <div style={{ flex: '1 1 auto' }}>
        {label}
        {description && <div css={STYLES.menuItemDescription}>{description}</div>}
      </div>

      {after}
    </Tag>
  )
}

export const CustomPopoverMenuItem: React.FC<any> = (props) => {
  const { onClick, isActive, children, className, style } = props

  const customClasses = clsx(isActive && 'is-active', className)

  const { setIsOpen, closeOnItemClick }: any = React.useContext(PopoverContext)

  return (
    <div
      data-test="popover_menu_item"
      onClick={(e) => {
        if (onClick) onClick(e)
        if (closeOnItemClick) setIsOpen(false)
      }}
      className={customClasses}
      css={STYLES.customMenuItem}
      style={style}
      data-type="popover-menu-item"
    >
      {children}
    </div>
  )
}

export const PopoverMenuSearch: React.FC<any> = ({ value = '', onChange, onClear }) => {
  return (
    <div css={STYLES.searchRoot}>
      <Glyph glyph="search" css={STYLES.searchGlyph} size={18} />
      <input
        type="text"
        placeholder="Search…"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
        css={STYLES.searchInput}
      />
      {value && onClear && (
        <div css={STYLES.searchClearButton} onClick={onClear}>
          <Glyph glyph="circle_error" size={14} color={COLORS.textMuted} />
        </div>
      )}
    </div>
  )
}

const animation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.96, 0.96, 0.96)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1, 1, 1)',
  },
})

const STYLES = {
  menu: {
    '& > hr': {
      margin: '0 !important',
    },

    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',

    borderRadius: 7,
    background: 'white',
    boxShadow: SHADOW(10, COLORS.divider),

    transformOrigin: '0% 0%',
    outline: `0 !important`,

    maxHeight: 'var(--radix-popper-available-height, 50vh)',
    maxWidth: 'var(--menu-max-width)',
    minWidth: '220px',
    minHeight: 'var(--menu-min-height)',

    width: 'var(--menu-width)',
    animation: `${animation} 120ms cubic-bezier(0.39, 0.575, 0.565, 1)`,

    '&.use-trigger-width': {
      minWidth: 'none',
      maxWidth: 'none',
      width: 'var(--radix-popper-anchor-width)',
    },
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '34px',
    padding: '0.1rem 0.6rem',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: COLORS.text,

    'svg, img': {
      marginRight: '0.4rem',
    },

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-active': {
      background: tint(0.9, COLORS.paleBlue),
    },

    '& > *': {
      pointerEvents: 'none',
    },
  },

  menuItemDescription: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: COLORS.textMuted,
  },

  customMenuItem: {
    position: 'relative',

    // catch all clicks on the menu item and don't propagate to children
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      cursor: 'pointer',
    },
  },

  // Search
  searchRoot: {
    position: 'sticky',
    top: 0,
    borderBottom: `1px solid ${COLORS.divider}`,
    boxShadow: HARD_SHADOW(2),
    zIndex: 1,
  },

  searchGlyph: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0.5rem',
  },

  searchInput: {
    padding: '0.5rem 2rem',
    border: 'none',
    outline: 'none',
  },

  searchClearButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '2rem',

    svg: {
      transition: 'transform 80ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    '&:hover': {
      svg: {
        fill: COLORS.text,
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },
  },
}
