import React from 'react'

import SignaturePad from '../Forms/SignaturePad'
import Dialog from '../Dialog'

const isPortal = process.env.BH_APP === 'portal'

const SignatureDialog = ({ children, model, onYes, allowPin, testKey, before, canSign = true, ...rest }: any) => {
  const [data, setData] = React.useState(null)

  return (
    <Dialog
      {...rest}
      isValid={!!data?.value}
      onYes={async () => await onYes(data)}
      closeOnBackdrop={false}
      showYesButton={canSign}
      content={
        <>
          {before}

          {canSign && (
            <SignaturePad
              isEditable
              testKey={testKey}
              allowPin={allowPin && !isPortal}
              model={model === null ? null : `${model}_signature`}
              signedAtModel={model === null ? null : `${model}_signed_at`}
              maxWidth="100%"
              showHeader={false}
              showAccept={false}
              onUpdate={setData}
            />
          )}
        </>
      }
    >
      {children}
    </Dialog>
  )
}

SignatureDialog.defaultProps = {
  allowPin: true,
  glyph: 'signature',
  title: 'Sign Off',
  yesLabel: 'Sign & Send',
  yesColor: 'green',
  noLabel: 'Cancel',
  model: 'author',
}

export default SignatureDialog
