import React from 'react'

import { getResourceLink } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'

import Page from '@behavehealth/components/Page'
import Grid from '@behavehealth/components/Grid'

import { AllergiesReportDataTable } from '@behavehealth/constructs/LiveReports/AllergiesReportDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'

const Allergies = () => {
  const tableProps = useDataTable({
    name: ['reports', 'allergies'],
    endpoint: `/live_reports?category=allergies`,
    localStorageKey: 'report_allergies_v1',
  })

  const to = React.useMemo(() => (rowData: any) => `${getResourceLink(rowData.reference)}/reported-conditions/${rowData.id}`, [])

  return (
    <Page icon="treatment_data" title="Live allergies Report">
      <Grid>
        <AllergiesReportDataTable {...tableProps} to={to} />
      </Grid>
    </Page>
  )
}

export default withPageError(Allergies)
