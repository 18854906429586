import React from 'react'
import size from 'lodash/size'

import { apiCreate } from '../../modules/api'
import { countWord } from '../../utils/functions'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'
import { useDataTable } from '../../components/DataTable/useDataTable'
import { useCreate, invalidateQueries } from '../../hooks/useNewAPI'
import Notifications from '../../modules/notifications'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import StaffProfileHeader from '../../components/StaffProfileHeader'

export const AccessListAuditFixOverlay = (props: any) => {
  const { employee, onClose } = props

  const [isSaving, setIsSaving] = React.useState(false)

  const {
    data,
    isLoading: isLoadingAudits,
    refetch,
  } = useDataTable({
    name: ['audits', employee?.id, 'access_control_audits'],
    endpoint: `/employees/${employee?.id}/access_control_audits`,
    keepPreviousData: false,
    params: { status: 'issue' },
    localStorageKey: 'employee_access_controls_audit_v1',
    options: { enabled: !!employee?.id },
  })

  const { mutateAsync: rebuildReport, isLoading: isRebuildingReport }: any = useCreate({
    name: ['employees', employee?.id, 'access_control_audits', 'rebuild'],
    url: `/employees/${employee?.id}/access_control_audits/rebuild`,
    invalidate: ['employees'],
  })

  const handleFix = async () => {
    setIsSaving(true)

    await apiCreate({
      name: 'access_control_audits',
      url: `/employees/${employee.id}/access_control_audits/fix_all`,
      notify: false,
    })

    await rebuildReport()
    await refetch()

    Notifications.send('All Access List Audit Issues Resolved', 'positive')

    invalidateQueries(null, 'employees')
    setIsSaving(false)
    onClose?.()
  }

  const sortedClients = React.useMemo(() => {
    const toAdd: any = []
    const toRemove: any = []

    if (size(data) === 0) return { toAdd, toRemove }

    for (const row of data) {
      if (row.result === 'missing_from_access_list') toAdd.push(row.client)
      if (row.result === 'missing_from_programs_and_locations') toRemove.push(row.client)
    }

    return { toAdd, toRemove }
  }, [data])

  if (!data || isLoadingAudits) return <OverlayLoader position="right" showBackdrop />

  return (
    <Overlay onClose={onClose} showBackdrop closeOnBackdrop>
      <Overlay.Header glyph="tick_circle" title="Fix Staff Access List Issues" subtitle={<StaffProfileHeader employee={employee} />} />

      <Overlay.Content className="grid gap-4 p-4 content-start">
        {/* <Card className="px-3 py-3 grid gap-3">
          <StaffProfileHeader employee={employee} />
        </Card> */}

        {size(sortedClients.toAdd) > 0 && (
          <Card className="px-3 py-3 grid gap-3">
            <Alert contrast small glyph="tick_circle" type="positive">
              <div>The following {countWord('client', size(sortedClients.toAdd))} will be added to the Staff Access List.</div>

              <div className="mt-1">
                <b>Reason:</b> the Clients below are part of the Staff member's assigned Programs or Locations, but are not included in the
                Staff Access List.
              </div>
            </Alert>

            {sortedClients.toAdd.map((o) => {
              return <ProfileCellInner value={o} key={o.id} />
            })}
          </Card>
        )}

        {size(sortedClients.toRemove) > 0 && (
          <Card className="px-3 py-3 grid gap-3">
            <Alert contrast small glyph="circle_error" type="negative">
              <div>
                <b>The following {countWord('client', size(sortedClients.toRemove))} will be removed from the Staff Access List.</b>
              </div>

              <div className="mt-1">
                <b>Reason:</b> the Clients below are included in the Staff Access List, but are not part of the Staff member's assigned
                Programs or Locations
              </div>
            </Alert>

            {sortedClients.toRemove.map((o) => {
              return <ProfileCellInner value={o} key={o.id} />
            })}
          </Card>
        )}
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Fix All Staff Access List Issues"
          type="primary"
          color="green"
          glyph="tick_circle"
          onClick={handleFix}
          isLoading={isSaving}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
